import { TextareaAutosize } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { postAnswerFeedback } from '../../../actions/apiPerformanceActions';
import { getCurrentUserId } from '../../../api/manager';
import { convertDate, DATE_FORMAT_FR, DATE_FORMAT_ISO } from '../../../utils/data';
import _ from 'lodash';

const AnswerFeedbackModal = props => {
  const { t } = useTranslation();
  const { feedbackData, readOnly, ...rest } = props;

  return (
    <Modal
      {...rest}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop={'static'}
      dialogClassName="modal-50w"
      centered
    >
      <Card>
        <Card.Body>
          <Card.Title> {t('answer_feedback_modal')}</Card.Title>
          <AnswerFeedback
            onHide={props.onHide}
            feedbackData={feedbackData}
            readOnly={readOnly}
          />
        </Card.Body>
      </Card>
    </Modal>
  );
};

export default AnswerFeedbackModal;

const mapDispatchToProps = dispatch => ({
  dispatchAnswerFeedback: r => dispatch(postAnswerFeedback(r)),
});

const AnswerFeedback = connect(
  null,
  mapDispatchToProps,
)(({ onHide, feedbackData, readOnly, dispatchAnswerFeedback, savingFeedbacks }) => {
  const maxLength = 2000;
  const [question1, setquestion1] = useState('');
  const [question2, setquestion2] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (readOnly) {
      if (!_.isEmpty(feedbackData)) {
        setquestion1(feedbackData?.QUESTION1);
        setquestion2(feedbackData?.QUESTION2);
      }
    }
  }, [feedbackData, readOnly]);

  const answerFeedback = () => {
    if (!_.isEmpty(feedbackData)) {
      const feedbackItemId = feedbackData?.FEEDBACK_ITEM_ID;
      if (feedbackItemId) {
        const payload = {
          personId: getCurrentUserId(),
          feedbackItemId: feedbackItemId,
          question1: question1,
          question2: question2,
        };
        dispatchAnswerFeedback(payload);
        onHide();
      }
    }
  };

  const getExpirationDate = () => {
    let result = '';
    const rawDate = feedbackData?.EXPIRATION_DATE;
    if (rawDate) {
      result = convertDate(rawDate, DATE_FORMAT_ISO).format(DATE_FORMAT_FR);
    }
    return result;
  };

  return (
    <div>
      <Col className="form-group">
        {!_.isEmpty(feedbackData) && (
          <>
            <Row>
              <Col md="6">
                <label className="form-label">{t('answer_feedback_collab_name')}</label>
                <p>{feedbackData?.ASKED_FOR}</p>
              </Col>
              <Col md="6">
                <label className="form-label">{t('answer_feedback_date')}</label>
                <p>{getExpirationDate(feedbackData)}</p>
              </Col>
            </Row>
            <hr />
            <label style={{ whiteSpace: 'pre-wrap' }}>
              {t('answer_feedback_comment')}
            </label>
            <TextareaAutosize
              id="comment"
              name="comment"
              type="text"
              fullWidth
              minRows={4}
              style={{ width: '100%', height: '50%' }}
              disabled={true}
              value={feedbackData?.FEEDBACK_COMMENT}
              maxLength={maxLength}
            />
            <label>{t('answer_feedback_question1')}</label>
            <TextareaAutosize
              id="question1"
              name="question1"
              type="text"
              fullWidth
              minRows={4}
              style={{ width: '100%', height: '50%' }}
              disabled={readOnly}
              value={question1}
              onChange={x => {
                const value = x?.target?.value;
                setquestion1(value);
              }}
              maxLength={maxLength}
            />
            <label>{t('answer_feedback_question2')}</label>
            <TextareaAutosize
              id="question2"
              name="question2"
              type="text"
              fullWidth
              minRows={4}
              style={{ width: '100%', height: '50%' }}
              disabled={readOnly}
              value={question2}
              onChange={x => {
                const value = x?.target?.value;
                setquestion2(value);
              }}
              maxLength={maxLength}
            />
          </>
        )}
      </Col>
      <div className="p-10 mt-20" style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {readOnly ? (
          <>
            <Button className="mr-10 w100" onClick={() => onHide()}>
              {t('Close')}
            </Button>
          </>
        ) : (
          <>
            <Button className="mr-10 w100" onClick={() => onHide()}>
              {t('Cancel')}
            </Button>
            <Button
              className="mr-10 w100"
              disabled={readOnly || question1 == '' || question2 == ''}
              onClick={() => {
                answerFeedback();
              }}
            >
              {savingFeedbacks ? t('save_ongoing') : t('feedback_action_answer')}
            </Button>
          </>
        )}
      </div>
    </div>
  );
});

import React from 'react';

export const getSliderProperties = employeesCount => {
  const maxSlides = 5;
  const slidesToShow = Math.min(employeesCount, maxSlides);
  const slidesToScroll = employeesCount > maxSlides ? slidesToShow : 0;
  const properties = {
    duration: 300,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    autoplay: false,
    indicators: employeesCount > maxSlides,
    infinite: true,
    prevArrow: (
      <div style={{ width: '30px' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.0"
          width="64.000000pt"
          height="64.000000pt"
          viewBox="0 0 64.000000 64.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          {' '}
          <g
            transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
            fill="#000000"
            stroke="none"
          >
            {' '}
            <path d="M245 631 c-92 -24 -173 -90 -215 -176 -33 -69 -34 -199 -2 -265 35 -71 75 -114 144 -151 58 -31 70 -34 148 -33 72 0 93 4 136 26 75 40 107 70 145 140 31 58 34 70 33 148 0 72 -4 93 -26 136 -39 75 -70 107 -137 143 -65 34 -164 49 -226 32z m198 -52 c178 -89 218 -319 80 -461 -53 -55 -123 -82 -208 -82 -111 1 -199 56 -253 159 -36 69 -37 179 -3 245 42 81 112 139 196 161 47 13 141 1 188 -22z" />{' '}
            <path d="M300 393 c-36 -31 -65 -64 -65 -73 0 -16 121 -130 137 -130 23 0 1 34 -49 80 l-58 51 58 50 c50 45 72 79 49 79 -4 0 -36 -25 -72 -57z" />{' '}
          </g>{' '}
        </svg>
      </div>
    ),
    nextArrow: (
      <div style={{ width: '30px', marginLeft: '30px' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.0"
          width="64.000000pt"
          height="64.000000pt"
          viewBox="0 0 64.000000 64.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          {' '}
          <g
            transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
            fill="#000000"
            stroke="none"
          >
            {' '}
            <path d="M245 631 c-92 -24 -173 -90 -215 -176 -33 -69 -34 -199 -2 -265 35 -71 75 -114 144 -151 58 -31 70 -34 148 -33 72 0 93 4 136 26 75 40 107 70 145 140 31 58 34 70 33 148 0 72 -4 93 -26 136 -39 75 -70 107 -137 143 -65 34 -164 49 -226 32z m198 -52 c178 -89 218 -319 80 -461 -53 -55 -123 -82 -208 -82 -111 1 -199 56 -253 159 -36 69 -37 179 -3 245 42 81 112 139 196 161 47 13 141 1 188 -22z" />{' '}
            <path d="M260 437 c0 -9 26 -38 58 -66 l57 -51 -57 -51 c-32 -28 -58 -57 -58 -66 0 -8 4 -13 10 -11 24 8 140 115 140 129 0 8 -30 40 -66 70 -74 61 -84 67 -84 46z" />{' '}
          </g>{' '}
        </svg>
      </div>
    ),
  };
  return properties;
};

import React, { useEffect, useState } from 'react';
import DocumentTable from '../common/DocumentTable';
import { Container, Row, Col, Card } from 'react-bootstrap';
import LoaderInCard from '../../common/LoaderInCard';
import { connect } from 'react-redux';
import { setSelectedEmployee } from '../../../actions/settingsAction';
import { fetchPerformanceGoals } from '../../../actions/apiPerformanceActions';
import { useTranslation } from 'react-i18next';
import { getCurrentUserId, getCurrentUserManagerRole } from '../../../api/manager';
import { getEmployeeDisplayName } from '../../../model/employee';
import Avatar from '../../common/Avatar';
import { Link } from 'react-router-dom';
import { PerformanceGoalDecoder } from '../../../api/HCMProfile/requests/PerformanceGoalsRequester';
import ReviewPeriodSelector, {
  CURRENT_REVIEW_PERIOD,
  filterPerformanceGoalByReviewPeriod,
} from './ReviewPeriodSelector';
import _ from 'lodash';

const PerformanceGoals = ({
  targetEmployeeId,
  employees,
  employeesEAPProfileData,
  performanceGoals,
  dispatchFetchPerformanceGoals,
  dispatchSetSelectedEmployee,
}) => {
  const [selectedReviewPeriod, setSelectedReviewPeriod] = useState(CURRENT_REVIEW_PERIOD);
  const [perfGoalsRows, setPerfGoalsRows] = useState(null);
  const [targetedPersonIds, setTargetedPersonIds] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (targetedPersonIds.length > 0) {
      dispatchFetchPerformanceGoals(targetedPersonIds);
    }
  }, [targetedPersonIds, dispatchFetchPerformanceGoals]);

  useEffect(() => {
    let ids = [getCurrentUserId()];
    if (getCurrentUserManagerRole()) {
      if (employees.fetched) {
        ids = ids.concat(employees.data.map(e => e['PERSON_ID']));
      }
      if (employeesEAPProfileData.fetched) {
        ids = ids.concat(Object.keys(employeesEAPProfileData.data));
      }
    }
    if (ids.length > 0) {
      const uniqueIds = Array.from(new Set(ids)).sort();
      if (!_.isEqual(targetedPersonIds, uniqueIds)) {
        setTargetedPersonIds(uniqueIds);
        const rows = buildIDPRows(employees?.data, employeesEAPProfileData?.data);
        setPerfGoalsRows(rows);
      }
    }
  }, [
    dispatchFetchPerformanceGoals,
    employees,
    employeesEAPProfileData,
    targetEmployeeId,
    targetedPersonIds,
  ]);

  return (
    <Container fluid id="objectives_container">
      <Row>
        <Col lg="12" xs="12">
          <Card style={{ minHeight: '220px' }} className="card_dashboard">
            <Card.Body>
              <ReviewPeriodSelector
                reviewPeriod={selectedReviewPeriod}
                setReviewPeriod={setSelectedReviewPeriod}
              />
              {perfGoalsRows !== null ? (
                <DocumentTable
                  columns={buildColumns(
                    performanceGoals.data,
                    dispatchSetSelectedEmployee,
                    selectedReviewPeriod,
                    t,
                  )}
                  data={perfGoalsRows}
                  targetEmployeeId={targetEmployeeId}
                />
              ) : (
                <LoaderInCard />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    employees: state.hcm.employeeTeam,
    performanceGoals: state.performance.performanceGoals,
    employeesEAPProfileData: state.performance.employeesEAPProfileData,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchFetchPerformanceGoals: personId => dispatch(fetchPerformanceGoals(personId)),
  dispatchSetSelectedEmployee: e => dispatch(setSelectedEmployee(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceGoals);

const buildIDPRows = (employees, eaps) => {
  let idps = employees.map(e => ({
    NAME: `${e['LAST_NAME']} ${e['FIRST_NAME']}`,
    PERSON_ID: e['PERSON_ID'],
  }));
  idps.push({
    NAME: `${getEmployeeDisplayName(getCurrentUserId())}`,
    PERSON_ID: getCurrentUserId(),
  });
  if (eaps) {
    const employeesId = new Set(
      employees.map(x => x.PERSON_ID).concat([getCurrentUserId()]),
    );
    const dataFromEaps = Object.entries(eaps)
      .filter(([personId]) => !employeesId.has(personId))
      .map(([personId, user]) => ({
        NAME: user?.EMP_NAME,
        PERSON_ID: personId,
      }));
    idps = idps.concat(dataFromEaps);
  }
  return idps;
};

const buildColumns = (
  performanceGoals,
  dispatchSetSelectedEmployee,
  selectedReviewPeriod,
  t,
) => {
  const columns = [
    {
      name: t('table_EmployeeName'),
      options: {
        customBodyRender: (value, tableMeta) => {
          const employeeId = tableMeta.rowData[1];
          const employeeName = tableMeta.rowData[0];
          return (
            <>
              <Avatar personId={employeeId} /> {employeeName}
            </>
          );
        },
      },
    },
    {
      name: t('table_performanceGoals_title'),
      options: {
        customBodyRender: (_, tableMeta) => {
          const employeeId = tableMeta.rowData[1];
          let nbGoals = 0;
          if (performanceGoals[employeeId] != undefined) {
            const employeeData = performanceGoals[employeeId];
            const goals = employeeData[PerformanceGoalDecoder.NAME];
            if (goals != undefined) {
              nbGoals = goals.filter(
                filterPerformanceGoalByReviewPeriod(selectedReviewPeriod),
              ).length;
            }
          }
          return <p>{nbGoals}</p>;
        },
      },
    },
    {
      name: t('table_Actions'),
      options: {
        customBodyRender: (_, tableMeta) => {
          const employeeId = tableMeta.rowData[1];
          const url = `/performance/performancegoals/${employeeId}/${selectedReviewPeriod?.value}`;
          return (
            <Row>
              <Col md={10}>
                <Link
                  className="buttonLink documentName trigger btn btn-link"
                  to={`${url}`}
                  onClick={() => dispatchSetSelectedEmployee(employeeId)}
                >
                  {t('edi_view')}
                </Link>
              </Col>
            </Row>
          );
        },
      },
    },
  ];
  return columns;
};

import React, { useState, useEffect } from 'react';
import ActionButtons from './ActionButtons';
import OutsideClickHandler from 'react-outside-click-handler';
import { isClickOnScrollBar } from '../../../common/utils';
import GoalsViewer from '../../../PerformanceGoals/GoalsViewer';
import { Row, Col } from 'react-bootstrap';
import GoalGlobalEval from '../../../PerformanceGoals/GoalsViewer/GoalGlobalEval';
import { getCurrentUserId } from '../../../../../api/manager';
import _ from 'lodash';

const Three = props => {
  const [goalsContent, setGoalsContent] = useState({});
  const [goalsGlobalEvalContent, setGoalsGlobalEvalContent] = useState({});
  const [goalsGlobalRatingContent, setGoalsGlobalRatingContent] = useState({});

  useEffect(() => {
    if (props.eapData && props.eapData.eapGoalsContent != null) {
      setGoalsContent({
        ...props.eapData.eapGoalsContent,
      });
    } else {
      setGoalsContent({});
    }
    if (props.eapData && props.eapData.eapGoalsGlobalEvalContent != null) {
      setGoalsGlobalEvalContent({
        ...props.eapData.eapGoalsGlobalEvalContent,
      });
    } else {
      setGoalsGlobalEvalContent({});
    }
    if (props.eapData && props.eapData.eapRating != null) {
      setGoalsGlobalRatingContent({
        ...props.eapData.eapRating,
      });
    } else {
      setGoalsGlobalRatingContent({});
    }
  }, [props.eapData]);

  const triggerOnSave = () => {
    let requestRatingValue = null;
    let requestRatingType = null;
    let requestGoalsContent = null;
    let requestGoalsGlobalEvalContent = null;
    let needToSendRequest = false;

    if (props.isFunctionalManager || props.isEvaluator) {
      if (
        props.eapData.rating != goalsGlobalRatingContent.rating &&
        goalsGlobalRatingContent.rating != null
      ) {
        requestRatingType = 'performanceGoal';
        requestRatingValue = goalsGlobalRatingContent['rating'];
        needToSendRequest = true;
      }
      if (
        needToSaveGoalsContent(props.eapData.eapGoalsContent, goalsContent) ||
        needToSaveGoalsContent(
          props.eapData.eapGoalsGlobalEvalContent,
          goalsGlobalEvalContent,
        )
      ) {
        requestGoalsContent = removeEmployeeFields(goalsContent);
        requestGoalsGlobalEvalContent = removeEmployeeFields(goalsGlobalEvalContent);
        needToSendRequest = true;
      } else {
        console.warn('-- no need to save goals data');
      }
    } else {
      if (
        needToSaveGoalsContent(props.eapData.eapGoalsContent, goalsContent) ||
        needToSaveGoalsContent(
          props.eapData.eapGoalsGlobalEvalContent,
          goalsGlobalEvalContent,
        )
      ) {
        requestGoalsContent = removeManagerFields(goalsContent);
        requestGoalsGlobalEvalContent = removeManagerFields(goalsGlobalEvalContent);
        needToSendRequest = true;
      } else {
        console.warn('-- no need to save goals data');
      }
    }
    if (needToSendRequest) {
      props.savePerformanceGoalsData(
        requestGoalsContent,
        requestGoalsGlobalEvalContent,
        requestRatingValue,
        requestRatingType,
      );
    }
  };

  const goalsViewerChange = (goalId, value) => {
    if (value.target) {
      const fieldId = value.target.id;
      const fieldValue = value.target.value;
      const isForManagerEAP = fieldId.includes('manager');
      if (!isForManagerEAP && !fieldId.includes('employee')) {
        console.error('-- ERROR: performance goal saving issue: ', fieldId);
      }
      const toSave = {};
      const data = {};
      data[fieldId] = fieldValue;
      if (isForManagerEAP) {
        toSave[goalId] = { ...goalsContent[goalId], ...data };
        setGoalsContent({ ...goalsContent, ...toSave });
      } else {
        toSave[goalId] = { ...goalsContent[goalId], ...data };
        setGoalsContent({ ...goalsContent, ...toSave });
      }
    }
  };

  const goalGlobalEvalChange = value => {
    if (value.target) {
      const fieldId = value.target.id;
      const fieldValue = value.target.value;
      const isForManagerEAP = fieldId.includes('manager');
      if (!isForManagerEAP && !fieldId.includes('employee')) {
        console.error('-- ERROR: performance goal global eval saving issue: ', fieldId);
      }
      const data = {};
      data[fieldId] = fieldValue;
      setGoalsGlobalEvalContent({ ...goalsGlobalEvalContent, ...data });
    }
  };

  const goalGlobalRatingChange = value => {
    if (value.target) {
      const fieldId = value.target.id;
      const fieldValue = value.target.value;
      const data = {};
      data[fieldId] = fieldValue;
      setGoalsGlobalRatingContent({ ...goalsGlobalRatingContent, ...data });
    }
  };

  return (
    <>
      <OutsideClickHandler
        disabled={!props.isActive || props.forceReadOnly}
        onOutsideClick={event => {
          if (!isClickOnScrollBar(event)) {
            console.warn('-- Auto save trigger: SAVING STEP 3');
            triggerOnSave();
          }
        }}
        useCapture={false}
      >
        <Row>
          <Col>
            <GoalsViewer
              forceTargetEmployeeId={props.targetEmployeeId}
              inEap={true}
              isFunctionalManager={props.isFunctionalManager}
              isEvaluator={props.isEvaluator}
              onChange={goalsViewerChange}
              eapGoalsContent={props.eapData?.eapGoalsContent}
            />
            <GoalGlobalEval
              isEmployee={props.targetEmployeeId === getCurrentUserId()}
              isFunctionalManager={props.isFunctionalManager}
              isEvaluator={props.isEvaluator}
              onChangeEval={goalGlobalEvalChange}
              onChangeRating={goalGlobalRatingChange}
              eapGoalsGlobalEvalContent={props.eapData?.eapGoalsGlobalEvalContent}
              eapRating={props.eapData?.rating}
              status={props.eapData?.status}
              employeeId={props.targetEmployeeId}
            />
          </Col>
        </Row>
        <ActionButtons
          {...props}
          nextCallback={triggerOnSave}
          prevCallback={triggerOnSave}
        />
      </OutsideClickHandler>
    </>
  );
};

export default Three;

const needToSaveGoalsContent = (initial, current) => {
  const nullOrEmpty = x => x == null || Object.keys(x).length === 0;
  if (nullOrEmpty(current)) {
    return false;
  }
  if (nullOrEmpty(initial) && !nullOrEmpty(current)) {
    return true;
  }
  return !_.isEqual(initial, current);
};

const removeEmployeeFields = values => {
  const result = { ...values };
  const isEmployeeField = fieldName => {
    return fieldName.toLowerCase().includes('employee');
  };
  Object.entries(result).forEach(([fieldName, goalValues]) => {
    if (isEmployeeField(fieldName)) {
      delete result[fieldName];
    }
    Object.keys(goalValues).forEach(childFieldName => {
      if (isEmployeeField(childFieldName)) {
        delete goalValues[childFieldName];
      }
    });
  });
  return result;
};

const removeManagerFields = values => {
  const result = { ...values };
  const isManagerField = fieldName => {
    return (
      fieldName.toLowerCase().includes('manager') ||
      fieldName.toLowerCase().includes('matrix')
    );
  };

  Object.entries(result).forEach(([fieldName, goalValues]) => {
    if (isManagerField(fieldName)) {
      delete result[fieldName];
    }
    Object.keys(goalValues).forEach(childFieldName => {
      if (isManagerField(childFieldName)) {
        delete goalValues[childFieldName];
      }
    });
  });
  return result;
};

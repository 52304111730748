import React, { useState } from 'react';
import { Button, Modal, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@material-ui/core';

import { connect } from 'react-redux';
import {
  approveRecruitingPendingRequests,
  rejectRecruitingPendingRequests,
} from '../../../actions/uiActions';
import { Error, CheckCircle, Cancel } from '@material-ui/icons';
import { JOB_REQ_LOADING_STATUS } from './index';
import {
  fetchRecruitingJobReqOutcomes,
  fetchRecruitingJobReqs,
} from '../../../actions/apiActions';
import { addCommentToTask, updateTaskOutcome } from '../../../api/BPMTask/actions';
import { TASK_OUTCOMES } from '../../../api/BPMTask/types';

const MassValidationModal = ({
  open,
  handleCloseModal,
  recruitingJobReqs,
  selectedJobs,
  setSelectedJobs,
  isApproving,
  isRejecting,
  setApproveRecruitingPendingRequests,
  setRejectRecruitingPendingRequests,
  dispatchRecruitingJobReqs,
  dispatchRecruitingJobReqOutcomes,
  action,
  jobReqsComments,
}) => {
  const { t } = useTranslation();

  const [comment, setComment] = useState('');
  const [actionInProgress, setActionInProgress] = useState(false);

  const getSelectedTaskIds = () => {
    const jobReqIds = selectedJobs.map(el => el.data[0]);
    const jobReqs = recruitingJobReqs.filter(
      el => jobReqIds.indexOf(el.REQUISITION_NUMBER) !== -1,
    );
    const taskIds = [...new Set(jobReqs.map(el => el.TASKID))];
    return taskIds;
  };

  const getJobReqByTaskId = taskId => {
    let result = {};
    const jobReq = recruitingJobReqs.find(el => el.TASKID === taskId);
    if (jobReq) result = jobReq;
    return result;
  };

  const updateSelectedJobStatus = (jobReqId, status) => {
    setSelectedJobs(ps =>
      ps.map(el => {
        if (el.data[0] === jobReqId) {
          el['LOADING_STATUS'] = status;
        }
        return el;
      }),
    );
  };

  const customRenderStatusJobReq = jobReq => {
    const value = jobReq ? jobReq.LOADING_STATUS : '';
    switch (value) {
      case JOB_REQ_LOADING_STATUS.APPROVING:
        return <CircularProgress color="inherit" size={20} />;
      case JOB_REQ_LOADING_STATUS.REJECTING:
        return <CircularProgress color="inherit" size={20} />;
      case JOB_REQ_LOADING_STATUS.APPROVED:
        return <CheckCircle fontSize="small" style={{ color: '#038F3C' }} />;
      case JOB_REQ_LOADING_STATUS.REJECTED:
        return <Cancel fontSize="small" style={{ color: '#DE1E1F' }} />;
      case JOB_REQ_LOADING_STATUS.FAILED:
        return <Error fontSize="small" style={{ color: '#DE1E1F' }} />;
      default:
        return <></>;
    }
  };

  const handleDecision = async decision => {
    let hasError = false;
    const isApproving = decision === TASK_OUTCOMES.APPROVE;
    if (isApproving) {
      setApproveRecruitingPendingRequests(true);
    } else {
      setRejectRecruitingPendingRequests(true);
    }
    setActionInProgress(true);
    const taskIds = getSelectedTaskIds();
    for (const taskId of taskIds) {
      try {
        const jobReq = getJobReqByTaskId(taskId);
        const jobReqId = jobReq?.REQUISITION_NUMBER;
        if (jobReqId) {
          console.info('--- Debug Mass Validation POST data (save comment)');
          console.info(`- jobReqId: ${jobReqId}`);
          console.info(`- taskId: ${taskId}`);
          console.info(`- primary comment: ${jobReqsComments[jobReqId]}`);
          console.info(`- global comment: ${comment}`);
          let jobStatus = isApproving
            ? JOB_REQ_LOADING_STATUS.APPROVING
            : JOB_REQ_LOADING_STATUS.REJECTING;
          updateSelectedJobStatus(jobReqId, jobStatus);
          // save primary comment
          if (jobReqsComments[jobReqId])
            await addCommentToTask(taskId, jobReqsComments[jobReqId]);
          // save global comment
          if (comment.length > 0) await addCommentToTask(taskId, comment);
          await updateTaskOutcome(taskId, decision);
          jobStatus = isApproving
            ? JOB_REQ_LOADING_STATUS.APPROVED
            : JOB_REQ_LOADING_STATUS.REJECTED;
          updateSelectedJobStatus(jobReqId, jobStatus);
        } else {
          throw 'Can not Find Job Req in list';
        }
      } catch (error) {
        hasError = true;
        setActionInProgress(false);
        const jobReq = getJobReqByTaskId(taskId);
        const jobReqId = jobReq?.REQUISITION_NUMBER;
        if (jobReqId) updateSelectedJobStatus(jobReqId, JOB_REQ_LOADING_STATUS.FAILED);
        console.error(
          'Error RecruitingMassValidation/MassValidationModal in handleApprove() : ',
          error,
        );
      }
    }
    if (isApproving) {
      setApproveRecruitingPendingRequests(false);
    } else {
      setRejectRecruitingPendingRequests(false);
    }
    if (!hasError) {
      setTimeout(() => {
        setActionInProgress(false);
        handleCloseModalWrapper();
        dispatchRecruitingJobReqs();
        dispatchRecruitingJobReqOutcomes();
      }, 800);
    }
  };

  const headerModal = action => {
    switch (action) {
      case TASK_OUTCOMES.APPROVE:
        if (selectedJobs.length === 1) {
          return <h3>{t('mass_validation_modal_approve_title_one')}</h3>;
        } else {
          return <h3>{t('mass_validation_modal_approve_title_other')}</h3>;
        }
      case TASK_OUTCOMES.REJECT:
        if (selectedJobs.length === 1) {
          return <h3>{t('mass_validation_modal_reject_title_one')}</h3>;
        } else {
          return <h3>{t('mass_validation_modal_reject_title_other')}</h3>;
        }
    }
  };

  const handleCloseModalWrapper = () => {
    setComment('');
    handleCloseModal();
  };

  const handleApprove = () => handleDecision(TASK_OUTCOMES.APPROVE);
  const handleReject = () => handleDecision(TASK_OUTCOMES.REJECT);

  return (
    <Modal
      dialogClassName="jobreq_modal modal-50w"
      show={open}
      onHide={handleCloseModalWrapper}
      backdrop="static"
    >
      <Modal.Header closeButton={!actionInProgress}>{headerModal(action)}</Modal.Header>
      <Modal.Body>
        {action === TASK_OUTCOMES.APPROVE ? (
          <p>
            {t('mass_validation_modal_approve_description', { job: selectedJobs.length })}
          </p>
        ) : (
          <p>
            {t('mass_validation_modal_reject_description', { job: selectedJobs.length })}
          </p>
        )}
        <TableContainer style={{ maxHeight: '350px' }}>
          <Table>
            <TableBody>
              {selectedJobs &&
                selectedJobs.map(row => {
                  return (
                    <TableRow key={row.data[0]}>
                      <TableCell component="th" scope="row">
                        {row.data[0]} - {row.data[7]?.TITLE}
                      </TableCell>
                      <TableCell>
                        {jobReqsComments[row.data[0]] ? (
                          <p>
                            {t('actionComment')} : {jobReqsComments[row.data[0]]}
                          </p>
                        ) : (
                          ''
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {customRenderStatusJobReq(row)}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Row>
          <Col md="12 pt-4">
            <b>{t('mass_validation_modal_global_comment')}</b>
          </Col>
          <Col md="12 pt-2">
            <small>{t('mass_validation_modal_description')}</small>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <TextField
              id="comment"
              name="comment"
              type="text"
              className="w-100 pt-10 mt-2"
              value={comment}
              onChange={e => setComment(e.target.value)}
              variant="outlined"
              rows={4}
              InputProps={{
                inputProps: {
                  style: {
                    border: 'none',
                  },
                },
              }}
              style={{ backgroundColor: 'white' }}
              multiline
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {action === TASK_OUTCOMES.APPROVE ? (
          <>
            <Button
              variant="success"
              className="mr-3"
              onClick={handleApprove}
              disabled={actionInProgress}
            >
              {isApproving ? (
                <>{t('mass_validation_modal_approving')}</>
              ) : (
                <>{t('mass_validation_modal_approve')}</>
              )}
            </Button>
            <Button
              variant="secondary"
              className="mr-3"
              onClick={handleCloseModalWrapper}
              disabled={actionInProgress}
            >
              <>{t('Cancel')}</>
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="danger active"
              className="mr-3"
              onClick={handleReject}
              disabled={actionInProgress}
            >
              {isRejecting ? (
                <>{t('mass_validation_modal_rejecting')}</>
              ) : (
                <>{t('mass_validation_modal_reject')}</>
              )}
            </Button>
            <Button
              variant="secondary"
              className="mr-3"
              onClick={handleCloseModalWrapper}
              disabled={actionInProgress}
            >
              <>{t('Cancel')}</>
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    isApproving: state.uiReducer.recruiting.pendingRequests.isApproving,
    isRejecting: state.uiReducer.recruiting.pendingRequests.isRejecting,
  };
};

const mapDispatchToProps = dispatch => ({
  setApproveRecruitingPendingRequests: isSaving =>
    dispatch(approveRecruitingPendingRequests(isSaving)),
  setRejectRecruitingPendingRequests: isSaving =>
    dispatch(rejectRecruitingPendingRequests(isSaving)),
  dispatchRecruitingJobReqs: () => dispatch(fetchRecruitingJobReqs()),
  dispatchRecruitingJobReqOutcomes: () => dispatch(fetchRecruitingJobReqOutcomes()),
});
export default connect(mapStateToProps, mapDispatchToProps)(MassValidationModal);

import React from 'react';
import { Card, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TileKPI, { extractDataFromActions } from './TileKPI';
import {
  getCurrentUserId,
  getCurrentUserManagerRole,
  isCurrentUserHiringManager,
} from '../../api/manager';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { setSelectedEmployee } from '../../actions/settingsAction';

const Tile = ({ actions, tileData, dispatchSetSelectedEmployee }) => {
  const { t } = useTranslation();
  const isManager = getCurrentUserManagerRole();
  let title = tileData.title;
  const clickableTitle = tileData.clickableTitle || false;

  if (tileData.managerTitle && isManager) {
    title = `${title}_manager`;
  }

  const alert =
    tileData.alertSelecter && actions ? tileData.alertSelecter(actions) : false;
  const nothingTodo = computeNothingTodo(actions, tileData);

  let cardClass = '';
  let iconClass = '';

  if (alert) {
    cardClass = 'tile_alert';
    iconClass = 'tile_icon_alert';
  } else {
    if (!nothingTodo) {
      cardClass = 'tile_todo';
      iconClass = 'tile_icon_todo';
    }
  }

  const optionalTile = tileData.optionalTile ? tileData.optionalTile : false;
  let displayTile = optionalTile ? !nothingTodo : true;
  if (tileData.onlyForHiringManager) {
    const isHiringManger = isCurrentUserHiringManager();
    displayTile = displayTile && isHiringManger;
  }

  const onClick = () => {
    dispatchSetSelectedEmployee(getCurrentUserId());
  };

  return (
    <>
      {displayTile ? (
        <Col lg="2" xs="12" className="mb-10">
          <Card
            className={`text-center h-100 white tile tile_shadow ${cardClass}`}
            style={{ minHeight: '200px' }}
          >
            <Card.Body className="black tile_body">
              <i
                className={`fa ${tileData.icon} tile_icon ${iconClass}`}
                style={{ marginBottom: '10px', fontSize: '1.5rem' }}
              />
              {clickableTitle ? (
                <NavLink to={clickableTitle} onClick={() => onClick()}>
                  <h6 style={{ minHeight: '90px' }}>{t(title)}</h6>
                </NavLink>
              ) : (
                <h6 style={{ minHeight: '90px' }}>{t(title)}</h6>
              )}
              {tileData.noKPI ? null : <TileKPI actions={actions} tileData={tileData} />}
            </Card.Body>
          </Card>
        </Col>
      ) : null}
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  dispatchSetSelectedEmployee: e => dispatch(setSelectedEmployee(e)),
});

export default connect(null, mapDispatchToProps)(Tile);

const computeNothingTodo = (actions, tileData) => {
  const [me, team] = extractDataFromActions(actions, tileData.selecter);
  const nothingMe = me == null || me == 0;
  const nothingTeam = team == null || team == 0;
  const nothingTodo = nothingMe && nothingTeam;
  return nothingTodo;
};

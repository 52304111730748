import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { openLeftMenuAction } from '../../actions/settingsAction';

const withOpenMenu = WrappedComponent => {
  const WithOpenMenu = ({ dispatchOpenLeftMenuAction, ...rest }) => {
    useEffect(() => {
      dispatchOpenLeftMenuAction();
    }, [dispatchOpenLeftMenuAction]);

    return <WrappedComponent {...rest} />;
  };

  const mapDispatchToProps = dispatch => ({
    dispatchOpenLeftMenuAction: () => dispatch(openLeftMenuAction()),
  });

  return connect(null, mapDispatchToProps)(WithOpenMenu);
};

export default withOpenMenu;

import React from 'react';
import { Modal } from 'react-bootstrap';
import IframeContainer from '../../../common/IframeContainer';

const ViewEDIArchiveModal = props => {
  return (
    <Modal
      {...props}
      size="lg"
      fullscreen
      backdrop="static"
      keyboard={true}
      animation={true}
    >
      <Modal.Header closeButton className="pt-1 pb-1"></Modal.Header>
      <ViewEDIArchive documentUrl={props.documentUrl} />
    </Modal>
  );
};

export default ViewEDIArchiveModal;

const ViewEDIArchive = ({ documentUrl }) => {
  return (
    <IframeContainer
      allowfullscreen="true"
      height={800}
      width={'100%'}
      frameBorder={0}
      src={documentUrl}
    />
  );
};

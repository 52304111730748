import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { fetchActions } from '../../actions/apiActions';
import { TileWrapper, TILES } from './Tiles';
import { useTranslation } from 'react-i18next';
import { getCurrentUserManagerRole } from '../../api/manager';
import {
  fetchEDIEligibility,
  fetchEAPEligibility,
} from '../../actions/apiPerformanceActions';
import {
  isEDICompleted,
  isEAPCompleted,
  isFunctionalManager,
} from '../Performance/common/utils';

const MyActions = ({
  actions,
  employeesProfileData,
  dispatchFetchActions,
  dispatchFetchEDIEligibility,
  dispatchfetchEAPEligibility,
  employeesEAPProfileData,
}) => {
  const [data, setData] = useState(null);
  const [isEligibleJimmy, setisEligibleJimmy] = useState(false);
  const isManager = getCurrentUserManagerRole();
  const { t } = useTranslation();

  useEffect(() => {
    if (!employeesEAPProfileData.fetched) {
      dispatchfetchEAPEligibility();
    }
  }, [dispatchfetchEAPEligibility, employeesEAPProfileData.fetched]);

  useEffect(() => {
    if (!employeesProfileData.fetched) {
      dispatchFetchEDIEligibility();
    }

    if (!actions.fetched) {
      dispatchFetchActions();
    } else {
      setData(actions.data[0]);
      const actionJimmy = actions.data[0].ELIGIBLE_JIMMY;
      if (actionJimmy != null && actionJimmy != 0 && actionJimmy != '') {
        setisEligibleJimmy(true);
      } else {
        setisEligibleJimmy(false);
      }
    }

    if (actions.fetched && employeesProfileData.fetched) {
      const [myEDI, teamEDIs] = countEDIs(Object.values(employeesProfileData.data));
      const [myEAP, teamEAPs] = countEAPs(Object.values(employeesEAPProfileData.data));
      actions.data[0].INTERVIEWS_AS_EMPLOYEE_COUNT = myEDI;
      actions.data[0].INTERVIEWS_AS_MANAGER_COUNT = teamEDIs;
      actions.data[0].INTERVIEWS_EAP_AS_EMPLOYEE_COUNT = myEAP;
      actions.data[0].INTERVIEWS_EAP_AS_MANAGER_COUNT = teamEAPs;
    }
  }, [
    actions,
    employeesProfileData,
    dispatchFetchActions,
    dispatchFetchEDIEligibility,
    dispatchfetchEAPEligibility,
    employeesEAPProfileData,
  ]);

  return (
    <Container id="my_actions" className="tile_container">
      <Row>
        <Col>
          <h4>{t('tile_actions')}</h4>
        </Col>
      </Row>
      <Row xs="1">
        <TileWrapper actions={data} tile={TILES.perfAppraisalToComplete} />
        {!isEligibleJimmy ? (
          <TileWrapper actions={data} tile={TILES.appraisalToComplete} />
        ) : (
          <TileWrapper actions={data} tile={TILES.appraisalJimmy} />
        )}
        <TileWrapper actions={data} tile={TILES.jobReq} />
        <TileWrapper actions={data} tile={TILES.mandatoryTraining} />
        <TileWrapper actions={data} tile={TILES.sessionRegistration} />
        {isManager ? <TileWrapper actions={data} tile={TILES.peopleReview} /> : null}
        <TileWrapper actions={data} tile={TILES.preboarding} />
      </Row>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    actions: state.hcm.actions,
    employeesProfileData: state.performance.employeesProfileData,
    employeesEAPProfileData: state.performance.employeesEAPProfileData,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchFetchActions: () => dispatch(fetchActions()),
  dispatchFetchEDIEligibility: () => dispatch(fetchEDIEligibility()),
  dispatchfetchEAPEligibility: () => dispatch(fetchEAPEligibility()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyActions);

const countEDIs = edis => {
  const myEDI = edi => edi.TYPE === 'MY EDI';
  const teamEDI = edi => {
    const isTeamEDI = edi.TYPE === 'Teams EDI';
    const nonTransfered =
      isTeamEDI &&
      (edi.PCONNECTEDUSERID === edi.EDI_EVALUATOR ||
        (edi.EDI_EVALUATOR == '' && !isFunctionalManager(edi)));
    const transfered =
      edi.PCONNECTEDUSERID === edi.EDI_EVALUATOR && edi.MANAGER_TYPE === 'TRANSFERT';
    return nonTransfered || transfered;
  };

  const nonCompletedEDI = edis
    .filter(edi => edi != undefined)
    .filter(edi => edi.EDI_ELIGIBILITY === 'ELIGIBLE')
    .filter(edi => !isEDICompleted(edi));
  return [nonCompletedEDI.filter(myEDI).length, nonCompletedEDI.filter(teamEDI).length];
};

const countEAPs = eaps => {
  const myEAP = eap => eap.TYPE === 'MY EAP';
  const teamEAP = eap => {
    const isTeamEAP = eap.TYPE === 'Teams EAP';
    const nonTransfered =
      isTeamEAP &&
      (eap.PCONNECTEDUSERID === eap.EAP_EVALUATOR ||
        (eap.EAP_EVALUATOR == '' && !isFunctionalManager(eap)));
    const transfered =
      eap.PCONNECTEDUSERID === eap.EAP_EVALUATOR && eap.MANAGER_TYPE === 'TRANSFERT';
    return nonTransfered || transfered;
  };

  const nonCompletedEAP = eaps
    .filter(eap => eap != undefined)
    .filter(eap => eap.EAP_ELIGIBILITY === 'ELIGIBLE')
    .filter(eap => !isEAPCompleted(eap));
  return [nonCompletedEAP.filter(myEAP).length, nonCompletedEAP.filter(teamEAP).length];
};

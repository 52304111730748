import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Survey } from 'survey-react-ui';
import { Model } from 'survey-core';
import ActionButtons from './ActionButtons';
import { getCurrentUserId } from '../../../../../api/manager';
import OutsideClickHandler from 'react-outside-click-handler';
import { isClickOnScrollBar, isStatusEAPCompleted } from '../../../common/utils';

var myCss = {
  question: {
    description: 'sd-title',
    titleOnAnswer: 'question-title-answered',
  },
};

const Two = props => {
  const [survey] = useState(new Model(bilan6ans));
  const [isManager, setIsManager] = useState(false);
  const [isMatrixManager, setIsMatrixManager] = useState(false);
  const [isEmployee, setIsEmployee] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);

  useEffect(() => {
    let isEmployee = false;
    let isManager = false;
    let isMatrixManager = false;

    if (props.targetEmployeeId) {
      isEmployee = getCurrentUserId() === props.targetEmployeeId;
    }
    isManager = props.isEvaluator;
    isMatrixManager =
      props.isEvaluator == props.isFunctionalManager ? false : props.isFunctionalManager;

    setIsMatrixManager(isMatrixManager);
    setIsManager(isManager);
    setIsEmployee(isEmployee);
    setIsReadOnly((isEmployee == false && isManager == false) || props.forceReadOnly);

    survey.locale = props.userLang;
    survey.focusFirstQuestionAutomatic = true;
    survey.onTextMarkdown.add(function (survey, options) {
      var str = options.text;
      options.html = str;
    });
    const setFetchedValues = () => {
      if (props.eapData && props.eapData.eapPayload && props.eapData.eapPayload.mifid2) {
        const mifid2 = props.eapData.eapPayload.mifid2;
        mifid2.forEach(x => {
          survey.setValue(x.name, x.value);
          if (x.comment != undefined && x.comment != '') {
            survey.setComment(x.name, x.comment);
          }
        });
      } else {
        survey.clear();
      }
    };
    const setQuestionReadonlyness = () => {
      survey.getAllQuestions().forEach(q => {
        if (isStatusEAPCompleted(props?.eapData?.status)) {
          q.setPropertyValue('readOnly', true);
        } else {
          if (q.name.startsWith('employee')) {
            q.setPropertyValue('readOnly', !isEmployee || props.forceReadOnly);
          }
          if (q.name.startsWith('manager')) {
            q.setPropertyValue('readOnly', !isManager || props.forceReadOnly);
          }
          if (q.name.startsWith('matrix')) {
            q.setPropertyValue('readOnly', !isMatrixManager || props.forceReadOnly);
          }
        }
      });
    };
    setFetchedValues();
    setQuestionReadonlyness();
  }, [
    props.eapData,
    props.targetEmployeeId,
    props.userLang,
    isReadOnly,
    survey,
    props.forceReadOnly,
    props.isEvaluator,
    props.isFunctionalManager,
  ]);

  const prepareData = plainData => {
    return plainData.map(x => {
      const questionName = x.name;
      const comment = survey.getComment(questionName);
      return { name: x.name, title: x.title, value: x.value ?? '', comment: comment };
    });
  };

  const handleChangeStep = () => {
    if (!isReadOnly) {
      const data = prepareData(survey.getPlainData());
      props.saveEAP({ mifid2: data });
    }
  };

  const handleNext = handleChangeStep;
  const handlePrev = handleChangeStep;

  const setQuestionReadonlyness = () => {
    survey.getAllQuestions().forEach(q => {
      if (q.name.startsWith('employee')) {
        q.setPropertyValue('readOnly', !isEmployee || props.forceReadOnly);
      }
      if (q.name.startsWith('manager')) {
        q.setPropertyValue('readOnly', !isManager || props.forceReadOnly);
      }
      if (q.name.startsWith('matrix')) {
        q.setPropertyValue('readOnly', !isMatrixManager || props.forceReadOnly);
      }
    });
  };

  const onChangeSurvey = (_, update) => {
    if (update['name'] == 'question1') {
      const bypass = update['value'].length > 0;
      setQuestionReadonlyness(bypass);
    }
  };

  return (
    <>
      <OutsideClickHandler
        disabled={!props.isActive || props.forceReadOnly}
        onOutsideClick={event => {
          if (!isClickOnScrollBar(event)) {
            console.warn('-- Auto save trigger: SAVING STEP 2');
            handleNext();
          }
        }}
        useCapture={false}
      >
        <Row>
          <Col md={12}>
            <Survey
              model={survey}
              css={myCss}
              onValueChanged={(_, update) => onChangeSurvey(_, update)}
            />
          </Col>
        </Row>
        <ActionButtons {...props} nextCallback={handleNext} prevCallback={handlePrev} />
      </OutsideClickHandler>
    </>
  );
};

export default Two;

const bilan6ans = {
  pages: [
    {
      elements: [
        {
          type: 'panel',
          name: 'managerpanel1',
          elements: [
            {
              type: 'html',
              name: 'question1',
              html: {
                fr: "<br><p>Dans le but de l’évaluation, l'analyse peut s’appuyer sur les questions suivantes : </p><b>Le collaborateur dispose-t-il d'une connaissance suffisante : </b><br><br><p>1. Des produits / services qu’il vend ou conseille, ou sur lesquels il est amené à fournir des informations ?</p><p>2. Des marchés sur lesquels il opère ?</p><p>3. Des activités et des besoins de ses clients ?</p><p>4. Des réclamations provenant de clients concernent-elles le collaborateur ?</p><p>5. Le collaborateur a-t-il suivi les formations obligatoires ? A-t-il suivi d’autres formations pour améliorer son niveau de compétences ?</p><p><b>Pour les traders algorithmiques : </b></p><p>6. Le collaborateur dispose-t-il d’une connaissance technique suffisante des systèmes de soumission des ordres et des plateformes de négociation ?</p><p>7. Le collaborateur dispose-t-il d’une connaissance réglementaire suffisante du trading algorithmique et des stratégies en la matière ?</p><b>Pour les collaborateurs directement impliqués dans les fonctions de contrôle du risque et de la vérification de la conformité du trading algorithmique :</b><br><br><p>8. Le collaborateur dispose-t-il d'une connaissance suffisante du trading algorithmique et des stratégies en la matière et des compétences suffisantes pour donner suite aux informations fournies par les alertes automatiques ?</p><p><b>Pour les collaborateurs directement impliqués dans la structuration d’indices (BMR) :</b></p>9. Le collaborateur impliqué dans la structuration d’indices au sens de la règlementation Indices de Référence dispose-t-il d’une connaissance règlementaire suffisante sur les indices ?",
                default:
                  '<br><p>In order to complete the assessment, the analysis may be supported by the following questions: </p><b>Does the employee have good and sufficient knowledge of: </b><br><br><p>1. The products / services he(she) sells, provides advice on or provides information on?</p><p>2. The markets on which he(she) operates?</p><p>3. His(her) clients’ activities and needs?</p><p>4. Have claims been received from clients in relation with the employee?</p><p>5. Did the employee attend all mandatory trainings? Did the employee attend other trainings to enhance his(her) competencies?</p><p><b>For algorithmic traders:</b></p><p>6. Does the employee have good and sufficient knowledge of order submission systems and trading platforms?</p><p>7. Does the employee have good and sufficient regulatory knowledge of algorithmic trading and the trading strategies deployed through its algorithmic trading systems and trading algorithms?</p><b>For the staff responsible for the risk and compliance functions of algorithmic trading:</b><br><br><p>8. Does the employee have a sufficient knowledge of algorithmic trading and strategies and  sufficient skills to follow up on information provided by automatic alerts?</p><p><b>For employees directly involved in index furniture (BMR):</b></p>9. Does the employee involved in the provision of indices (within the Benchmarks regulation) have the appropriate business and regulatory knowledge and competencies required for the position?',
              },
            },
            {
              type: 'radiogroup',
              name: 'managerquestion',
              title: {
                fr: 'Le collaborateur a-t-il les connaissances et compétences métiers et réglementaires appropriées nécessaires à la tenue de son poste ?',
                default:
                  'Does the employee have the appropriate business and regulatory knowledge and competencies in order to fully carry on his(her) job?',
              },
              choices: [
                {
                  value: 'Yes',
                  text: {
                    fr: 'Oui',
                  },
                },
                {
                  value: 'No',
                  text: {
                    fr: 'Non',
                  },
                },
              ],
              readOnly: true,
              isRequired: true,
            },
          ],
          title: {
            default:
              'This section only applies to staff subject to the Markets in Financial Instruments Directive (MIFID II) or Benchmarks Regulations (BMR) and whocarry out their professional activity in the European Union (and similar United Kingdom employees).<br><br>GFS employees involves are professionals who provide advisory services or information to clients on financial instruments, investment services or non-core services (MiFID II), or staff who work on structuring indices (BMR).<br><br>Each year, the manager assesses whether the employee  has an appropriate level of knowledge and skills, which is suited to their company’s context, particularly products and services offered to the client, the categories of clients that the company addresses, and the types of market where the company operates.',
            fr: 'Cette rubrique ne concerne que les collaborateurs soumis à la réglementation Markets in Financial Instruments Directive (MIFID2) ou Benchmarks Regulations (BMR - Indices de référence) et dont l’activité professionnelle s’exerce au sein de l’Union Européenne (et collaborateurs Royaume-Unis équivalents).<br><br>Sont concernés les salariés de GFS qui fournissent une prestation de conseil, ou d’information aux clients, sur les instruments financiers, les services d’investissements ou services auxiliaires (MIFID2), ou sont impliqués dans la structuration d’indices (BMR).<br><br>Chaque année, le manager apprécie si le collaborateur dispose d’un niveau approprié de connaissances et de compétences, adapté au contexte de son entreprise, en particulier sur les produits et services proposés au client, les catégories de clients que l’entreprise adresse et les types de marchés sur lesquels l’entreprise intervient.',
          },
        },
        {
          type: 'comment',
          name: 'managerquestion2',
          readOnly: true,
          title: {
            default:
              'If you tick No, report in the 2023 Goal Setting section the necessary training and/or identified actions with a deadline no later than June 30. Until completion, the employee has to be under appropriate supervision.',
            fr: 'Si vous cochez la case Non, mentionnez dans la Fixation des Objectifs 2023 le plan de formation  et/ou autres actions nécessaires à réaliser avant le 30 juin. En attendant la réalisation de ces actions, le collaborateur devra faire l’objet d’une supervision appropriée. ',
          },
        },
      ],
    },
  ],
  showNavigationButtons: 'none',
  showCompletedPage: false,
  showQuestionNumbers: 'off',
  widthMode: 'responsive',
};

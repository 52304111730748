export class GenericDecoder {
  static print() {
    console.info('Decoder:', this.SECTION_ID, ' ', this.NAME);
  }

  static match(sectionId) {
    return sectionId == this.SECTION_ID;
  }

  static decode(input) {
    let result = {};
    for (let [source, target] of Object.entries(this.ATTRIBUTES)) {
      const sourceData = input[source];
      if (sourceData != undefined) {
        if (target.prepare) {
          result[target.key] = target.prepare(input[source]._text);
        } else {
          result[target.key] = input[source]._text;
        }
      } else {
        result[target.key] = '';
      }
    }
    return result;
  }
}

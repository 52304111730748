import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';
import LoaderInCard from '../common/LoaderInCard';
import JobReqs from './JobReqs';
import Feedbacks from './RecruitingFeedbacks';
import JobReqModal from './JobReqs/JobReqModal';
import {
  JobReqsSource,
  filterJobReqsBySource,
  filterJobReqsMyApprovalsWaiting,
  filterOutJobReqsMyApprovalsWaiting,
} from '../../utils/data';
import {
  openRecruitingModal,
  openRecruitingModalLinkOnly,
  resetFilterJobReqs,
} from '../../actions/settingsAction';
import { useTranslation } from 'react-i18next';
import {
  getCurrentUserManagerRole,
  isCurrentUserAllowedMassValidation,
  isCurrentUserHR,
} from '../../api/manager';
import { useParams } from 'react-router-dom';
import RecruitingValidation from './RecruitingMassValidation';

const RecruitingMainContainer = ({
  recruitingJobReqs,
  recruitingJobReqOutcomes,
  candidates,
  filterJobReqs,
  dispatchResetFilterJobReqs,
  dispatchOpenRecruitingModal,
  dispatchOpenRecruitingModalLinkOnly,
}) => {
  const { defaultTab } = useParams();
  const defaultActiveTab = getDefaultActiveTab(defaultTab);
  const [tabKey, setTabKey] = useState(defaultActiveTab);
  const [nameFilter, setNameFilter] = useState(undefined);
  const { t } = useTranslation();
  const isManager = getCurrentUserManagerRole();
  const isHR = isCurrentUserHR();

  useEffect(() => {
    if (filterJobReqs.filter) {
      setNameFilter(filterJobReqs.name);
      setTabKey('team_jobreqs');
      dispatchResetFilterJobReqs();
    } else {
      setNameFilter(undefined);
    }
  }, [filterJobReqs, dispatchResetFilterJobReqs]);

  const openModal = event => {
    event.preventDefault();
    if (event.target.dataset.linkonly == 'true') {
      dispatchOpenRecruitingModalLinkOnly(event.target.id);
    } else {
      dispatchOpenRecruitingModal(event.target.id);
    }
  };

  const onSelectTab = key => {
    if (key == 'hr_recruitments') {
      const url =
        '/hcmUI/faces/FndOverview?pageParams=fndGlobalItemNodeId%3DitemNode_Recruiting_Hiring&fndGlobalItemNodeId=itemNode_Recruiting_Hiring';
      if (url && url != '') {
        window.open(url);
      }
    } else {
      setTabKey(key);
    }
  };

  return (
    <>
      <JobReqModal recruitingJobReqs={recruitingJobReqs.data} />
      {typeof recruitingJobReqs != 'undefined' ? (
        <Tabs
          defaultActiveKey="my_jobreqs"
          activeKey={tabKey}
          onSelect={onSelectTab}
          className="pl-2 mb-4 page-header-tab nav-tabs"
        >
          <Tab
            eventKey="my_jobreqs"
            title={t('recruiting_my')}
            tabClassName="card-title font-weight-bold mr-15"
          >
            <JobReqs
              recruitingJobReqs={filterJobReqsBySource(
                recruitingJobReqs,
                JobReqsSource.FromMe,
              )}
              openModal={openModal}
            />
          </Tab>
          {isManager ? (
            <Tab
              eventKey="team_jobreqs"
              title={t('recruiting_team')}
              tabClassName="font-weight-bold card-title mr-15"
            >
              <JobReqs
                recruitingJobReqs={filterOutJobReqsMyApprovalsWaiting(
                  filterJobReqsBySource(recruitingJobReqs, JobReqsSource.FromMyTeam),
                )}
                openModal={openModal}
                filter={nameFilter}
              />
            </Tab>
          ) : null}
          <Tab
            eventKey="feedbacks_jobreqs"
            title={t('recruiting_feedbacks_candidat')}
            tabClassName="font-weight-bold card-title mr-15"
          >
            <Feedbacks candidates={candidates} />
          </Tab>
          {isCurrentUserAllowedMassValidation() ? (
            <Tab
              eventKey="validate_jobreqs"
              title={t('recruiting_pending')}
              tabClassName="font-weight-bold card-title mr-15"
            >
              <RecruitingValidation
                recruitingJobReqs={filterJobReqsMyApprovalsWaiting(
                  filterJobReqsBySource(recruitingJobReqs, JobReqsSource.FromMyTeam),
                )}
                handleOpenDetailsModal={openModal}
                recruitingJobReqOutcomes={recruitingJobReqOutcomes}
              />
            </Tab>
          ) : (
            <></>
          )}
          {isHR ? (
            <Tab
              eventKey="hr_recruitments"
              title={t('tab_recruiting_hr')}
              tabClassName="font-weight-bold card-title mr-15"
            ></Tab>
          ) : null}
        </Tabs>
      ) : (
        <LoaderInCard />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    filterJobReqs: state.settings.filterJobReqs,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchResetFilterJobReqs: () => dispatch(resetFilterJobReqs()),
  dispatchOpenRecruitingModal: id => dispatch(openRecruitingModal(id)),
  dispatchOpenRecruitingModalLinkOnly: id => dispatch(openRecruitingModalLinkOnly(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecruitingMainContainer);

const getDefaultActiveTab = defaultTab => {
  let result = 'my_jobreqs';
  switch (defaultTab) {
    case 'team':
      result = 'team_jobreqs';
      break;
    case 'feedbacks':
      result = 'feedbacks_jobreqs';
      break;
    case 'approve':
      result = 'validate_jobreqs';
      break;
  }
  return result;
};

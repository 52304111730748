import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Select from 'react-select';
import { TextareaAutosize } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  isStatusEAPCompleted,
  loadPerformanceGoalRatingOptions,
} from '../../common/utils';

const GoalGlobalEval = ({ ...props }) => {
  const [values, setValues] = useState({});
  const [rating, setRating] = useState({});
  const [readOnly, setReadOnly] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setValues({ ...props.eapGoalsGlobalEvalContent });
    setRating({ rating: props.eapRating });
    setReadOnly(isStatusEAPCompleted(props.status));
  }, [props.eapGoalsGlobalEvalContent, props.eapRating, props.status, props.employeeId]);

  const localOnChange = value => {
    props.onChangeEval(value);
    const fieldId = value.target.id;
    const fieldValue = value.target.value;
    setValues({ ...values, [fieldId]: fieldValue });
  };

  const onRatingChange = value => {
    props.onChangeRating(value);
    const fieldId = value.target.id;
    const fieldValue = value.target.value;
    setRating({ [fieldId]: fieldValue });
  };

  return (
    <Container className="generic_fluid_container bg-light" fluid>
      <div className="goals_viewer">
        <div className="goal_viewer form-group">
          <form>
            <Row>
              <Col md="12" className="mt-20">
                <h5 className="text font-weight-bold pb-2 sd-html">
                  {t('goal_global_eval')}
                </h5>
              </Col>
              <hr style={{ width: '100%' }} />
              <Col md="6" className="pr-5">
                <Row>
                  <h6 className="sv-string-viewer mt-3">
                    {t('goal_employee_global_comment')}
                  </h6>
                  <TextareaAutosize
                    id="goal_employee_global_comment"
                    name="goal_employee_global_comment"
                    type="text"
                    minRows={4}
                    className="w-100 h-50 p-10 mt-2"
                    value={values.goal_employee_global_comment || ''}
                    onChange={x => {
                      localOnChange(x);
                    }}
                    disabled={readOnly ? readOnly : !props.isEmployee}
                  />
                  <h6 className="sv-string-viewer mt-3">
                    {t('goal_funct_manager_global_comment')}
                  </h6>
                  <TextareaAutosize
                    id="goal_funct_manager_global_comment"
                    name="goal_funct_manager_global_comment"
                    type="text"
                    minRows={4}
                    className="w-100 h-50 p-10 mt-2"
                    value={values.goal_funct_manager_global_comment || ''}
                    onChange={x => {
                      localOnChange(x);
                    }}
                    disabled={
                      readOnly
                        ? readOnly
                        : props.isEvaluator
                        ? props.isEvaluator
                        : !props.isFunctionalManager
                    }
                  />
                </Row>
              </Col>
              <Col md="6">
                <Row>
                  <Col md="12">
                    <h6 className="mt-3 sv-string-viewer">{t('goal_manager_eval')}</h6>
                    <Select
                      id="goal_manager_global_rating"
                      name="goal_manager_global_rating"
                      className="sd-selectbase mt-3 goal_manager_eval"
                      placeholder={t('goal_eval_placeholder')}
                      options={loadPerformanceGoalRatingOptions(t)}
                      closeMenuOnSelect={true}
                      defaultOptions
                      isDisabled={readOnly ? readOnly : !props.isEvaluator}
                      value={
                        rating.rating
                          ? {
                              value: rating.rating,
                              label: t(`perf_goal_rating_${rating.rating}`),
                            }
                          : null
                      }
                      onChange={x => {
                        onRatingChange({
                          target: {
                            id: 'rating',
                            value: x.value,
                          },
                        });
                      }}
                    />
                    <h6 className="mt-3 sv-string-viewer">
                      {t('goal_manager_global_comment')}
                    </h6>
                    <TextareaAutosize
                      id="goal_manager_global_comment"
                      name="goal_manager_global_comment"
                      type="text"
                      minRows={3}
                      className="w-100 h-75 p-2 mt-2"
                      value={values.goal_manager_global_comment || ''}
                      onChange={x => {
                        localOnChange(x);
                      }}
                      disabled={readOnly ? readOnly : !props.isEvaluator}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default GoalGlobalEval;

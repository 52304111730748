import {
  doFetchEmployeeTeam,
  doFetchEmployeeSubTeam,
  doFetchFeedbacks,
  doFetchAnnouncements,
  doFetchKPIsGoals,
  doFetchKPIsPerformance,
  doFetchTutorials,
  doFetchGoalsList,
  doFetchRecruitingJobReqs,
  doFetchRecruitingCandidates,
  doFetchActions,
  doFetchKPILearning,
  doFetchEmployeeArchive,
  doFetchJobReqOutcomes,
} from '../api/OracleBIP/api';

export function fetchEmployeeArchive() {
  return function (dispatch) {
    doFetchEmployeeArchive(dispatch);
  };
}
export function fetchFeedbacks() {
  return function (dispatch) {
    doFetchFeedbacks(dispatch);
  };
}

export function fetchEmployeeTeam() {
  return function (dispatch) {
    doFetchEmployeeTeam(dispatch);
  };
}

export function fetchEmployeeSubTeam(personId) {
  return function (dispatch) {
    doFetchEmployeeSubTeam(dispatch, personId);
  };
}

export function fetchAnnouncements(context) {
  return function (dispatch) {
    doFetchAnnouncements(dispatch, context);
  };
}

export function fetchKPIsGoals() {
  return function (dispatch) {
    doFetchKPIsGoals(dispatch);
  };
}

export function fetchKPIsPerformance() {
  return function (dispatch) {
    doFetchKPIsPerformance(dispatch);
  };
}

export function fetchTutorials() {
  return function (dispatch) {
    doFetchTutorials(dispatch);
  };
}

export function fetchGoalsList() {
  return function (dispatch) {
    doFetchGoalsList(dispatch);
  };
}

export function fetchRecruitingJobReqs() {
  return function (dispatch) {
    doFetchRecruitingJobReqs(dispatch);
  };
}

export function fetchRecruitingCandidates() {
  return function (dispatch) {
    doFetchRecruitingCandidates(dispatch);
  };
}
export function fetchActions() {
  return function (dispatch) {
    doFetchActions(dispatch);
  };
}

export function fetchKPILearning() {
  return function (dispatch) {
    doFetchKPILearning(dispatch);
  };
}

export function fetchRecruitingJobReqOutcomes() {
  return function (dispatch) {
    doFetchJobReqOutcomes(dispatch);
  };
}

import moment from 'moment';
import { getEmployeeProfileId } from '../../../model/employee';
import { DATE_FORMAT_ISO } from '../../../utils/data';
import { getCurrentEnv, getCurrentUserId } from '../../manager';
import { GenericDecoder } from '../common/GenericDecoder';
import { EDI_SECTION_IDS } from '../common/section_ids_configuration';
import {
  constructFilterPersonIds,
  constructFilterSectionId,
  decodeBase64,
} from '../helpers';
import { MissionAndMainActivityDecoder } from './MissionAndMainActivityRequester';

const updateStatus = requestData => {
  const [personId, profileItemId, edi] = requestData.destructure();
  const { creationDate, managerId, status, action } = edi;
  const profileId = getEmployeeProfileId(personId);

  console.info('--- Debug EDI POST data (UPDATE STATUS)');
  console.info(`- PersonId: ${personId}`);
  console.info(`- ProfileId: ${profileId}`);
  console.info(`- ProfileItemId: ${profileItemId}`);
  console.info(`- ManagerId: ${managerId}`);
  console.info(`- Status: ${status}`);
  if (action != null) {
    console.info(`- Action: ${action}`);
  }

  const requestBody = `<soapenv:Envelope
  xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
  xmlns:typ="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/types/"
  xmlns:pub="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/"
  xmlns:prof="http://xmlns.oracle.com/apps/hcm/profiles/core/flex/profileItem/">
  <soapenv:Header/>
  <soapenv:Body>
    <typ:mergeProfile>
      <typ:profile>
        <pub:ProfileId>${profileId}</pub:ProfileId>
        <pub:ProfileItem>
          <pub:ProfileId>${profileId}</pub:ProfileId>
          ${EDIDecoder.getRequestHeader()}
          <pub:ProfileItemId>${profileItemId}</pub:ProfileItemId>
          // *************
          ${creationDate ? `<pub:DateFrom>${creationDate}</pub:DateFrom>` : ''}
          <pub:SourceId>${managerId}</pub:SourceId>
          ${getStatus(status)}
          ${action ? getAction(action) : ''}
          ${getLastUpdateData()}
        </pub:ProfileItem>
      </typ:profile>
    </typ:mergeProfile>
  </soapenv:Body>
</soapenv:Envelope>`;
  return requestBody;
};

const transferDocument = requestData => {
  const [personId, profileItemId, edi] = requestData.destructure();
  const { creationDate, managerId } = edi;
  const profileId = getEmployeeProfileId(personId);

  console.info('--- Debug EDI POST data');
  console.info('- EDI Payload: Transfer');

  console.info(`- PersonId: ${personId}`);
  console.info(`- ProfileId: ${profileId}`);
  console.info(`- ProfileItemId: ${profileItemId}`);
  console.info(`- New ManagerId: ${managerId}`);

  const requestBody = `<soapenv:Envelope
  xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
  xmlns:typ="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/types/"
  xmlns:pub="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/"
  xmlns:prof="http://xmlns.oracle.com/apps/hcm/profiles/core/flex/profileItem/">
  <soapenv:Header/>
  <soapenv:Body>
    <typ:mergeProfile>
      <typ:profile>
        <pub:ProfileId>${profileId}</pub:ProfileId>
        <pub:ProfileItem>
          <pub:ProfileId>${profileId}</pub:ProfileId>
          ${EDIDecoder.getRequestHeader()}
          <pub:ProfileItemId>${profileItemId}</pub:ProfileItemId>
          // *************
          ${creationDate ? `<pub:DateFrom>${creationDate}</pub:DateFrom>` : ''}
          <pub:SourceId>${managerId}</pub:SourceId>
          ${getAction('TRANFER')}
          ${getLastUpdateData()}
        </pub:ProfileItem>
      </typ:profile>
    </typ:mergeProfile>
  </soapenv:Body>
</soapenv:Envelope>`;
  return requestBody;
};

const createOrUpdate = requestData => {
  const [personId, profileItemId, edi] = requestData.destructure();
  const { creationDate, managerId, ediPayload, status } = edi;
  const profileId = getEmployeeProfileId(personId);

  console.info('--- Debug EDI POST data');
  if (ediPayload == null) {
    console.info('- EDI Payload: null / Transfer');
  }
  console.info(`- PersonId: ${personId}`);
  console.info(`- ProfileId: ${profileId}`);
  console.info(`- ProfileItemId: ${profileItemId}`);
  console.info(`- ManagerId: ${managerId}`);
  console.info(`- Status: ${status}`);

  const requestBody = `<soapenv:Envelope
  xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
  xmlns:typ="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/types/"
  xmlns:pub="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/"
  xmlns:prof="http://xmlns.oracle.com/apps/hcm/profiles/core/flex/profileItem/">
  <soapenv:Header/>
  <soapenv:Body>
    <typ:mergeProfile>
      <typ:profile>
        <pub:ProfileId>${profileId}</pub:ProfileId>
        <pub:ProfileItem>
          <pub:ProfileId>${profileId}</pub:ProfileId>
          ${EDIDecoder.getRequestHeader()}
          <pub:ProfileItemId>${profileItemId}</pub:ProfileItemId>
          ${creationDate ? `<pub:DateFrom>${creationDate}</pub:DateFrom>` : ''}
          ${ediPayload ? `<pub:ItemClob1File>${ediPayload}</pub:ItemClob1File>` : ''}
          <pub:SourceId>${managerId}</pub:SourceId>
          ${getLastUpdateData()}
          ${status ? getStatus(status) : ''}
        </pub:ProfileItem>
      </typ:profile>
    </typ:mergeProfile>
  </soapenv:Body>
</soapenv:Envelope>`;
  return requestBody;
};

const getByPersonIds = personIds => {
  if (!Array.isArray(personIds)) {
    personIds = [personIds];
  }

  console.info('--- Debug EDI GET data');
  console.info(`- PersonIds: ${personIds.join(',')}`);

  const requestBody = `<soapenv:Envelope
  xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
  xmlns:typ="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/types/"
  xmlns:typ1="http://xmlns.oracle.com/adf/svc/types/">
  <soapenv:Header/>
  <soapenv:Body>
      <typ:findProfile>
          <typ:findCriteria>
              <typ1:fetchStart>0</typ1:fetchStart>
              <typ1:fetchSize>-1</typ1:fetchSize>
              <typ1:filter>
                  <typ1:group>
                      <typ1:conjunction>And</typ1:conjunction>
                      <typ1:upperCaseCompare>true</typ1:upperCaseCompare>
                      ${constructFilterPersonIds(personIds)}
                  </typ1:group>
              </typ1:filter>
              <typ1:childFindCriteria>
                  <typ1:fetchStart>0</typ1:fetchStart>
                  <typ1:fetchSize>-1</typ1:fetchSize>
                  <typ1:filter>
                      <typ1:conjunction>And</typ1:conjunction>
                      <typ1:group>
                          <typ1:conjunction>And</typ1:conjunction>
                          <typ1:upperCaseCompare>false</typ1:upperCaseCompare>
                          ${constructFilterSectionId(EDIDecoder.SECTION_ID)}
                          ${constructFilterSectionId(
                            MissionAndMainActivityDecoder.SECTION_ID,
                          )}
                      </typ1:group>
                  </typ1:filter>
                  <typ1:childAttrName>ProfileItem</typ1:childAttrName>
              </typ1:childFindCriteria>
              <typ1:findAttribute>ProfileId</typ1:findAttribute>
              <typ1:findAttribute>ProfileItem</typ1:findAttribute>
              <typ1:findAttribute>PersonId</typ1:findAttribute>
          </typ:findCriteria>
          <typ:findControl>
              <typ1:retrieveAllTranslations>false</typ1:retrieveAllTranslations>
          </typ:findControl>
      </typ:findProfile>
  </soapenv:Body>
</soapenv:Envelope>`;
  return requestBody;
};

const EDIRequester = {
  createOrUpdate: createOrUpdate,
  getByPersonIds: getByPersonIds,
  transferDocument: transferDocument,
  updateStatus: updateStatus,
};

export class EDIRequestData {
  constructor(personId, profileItemId, lastUpdateBy, payload) {
    this._personId = personId;
    this._profileItemId = profileItemId;
    this._payload = payload;
    this._lastUpdateBy = lastUpdateBy;
  }

  get personId() {
    return this._personId;
  }

  destructure() {
    return [this._personId, this._profileItemId, this._payload];
  }
}

export class EDIDecoder extends GenericDecoder {
  static SECTION_ID = EDI_SECTION_IDS[getCurrentEnv()];
  static NAME = 'EDI_DOCUMENT';
  static ATTRIBUTES = {
    'ns1:ProfileItemId': { key: 'profileItemId' },
    'ns1:DateFrom': { key: 'date' },
    'ns1:SourceId': { key: 'managerId' },
    'ns1:ItemText301': { key: 'status' },
    'ns1:ItemDate9': { key: 'lastUpdateDate' },
    'ns1:ItemText308': { key: 'lastUpdateBy' },
    'ns1:ItemClob1File': {
      key: 'ediPayload',
      prepare: x => {
        if (x == null) {
          return {};
        } else {
          return JSON.parse(decodeBase64(x));
        }
      },
    },
  };

  static getContentType() {
    return 'ACCOMPLISHMENT';
  }

  static getSectionName() {
    return `${EDIDecoder.getContentType()}_${EDIDecoder.SECTION_ID}`;
  }

  static getRequestHeader() {
    return `<pub:ContentType>${EDIDecoder.getContentType()}</pub:ContentType>
            <pub:SectionName>${EDIDecoder.getSectionName()}</pub:SectionName>`;
  }
}

const getLastUpdateData = () => {
  const today = moment().format(DATE_FORMAT_ISO);
  const currentUserId = getCurrentUserId();
  return `
    // Last Updated by
    <pub:ItemDate9>${today}</pub:ItemDate9>
    //Last Updated by
    <pub:ItemText308>${currentUserId}</pub:ItemText308>
  `;
};

const getAction = action => {
  const today = moment().format(DATE_FORMAT_ISO);
  return `
    //ACTION
    <pub:ItemText302>${action}</pub:ItemText302>
    //Action Date
    <pub:ItemDate8>${today}</pub:ItemDate8>`;
};

const getStatus = status => {
  const today = moment().format(DATE_FORMAT_ISO);
  return `
    //Status (NOT_STARTED, IN_PROGRESS, WRK_PREPARATION, COMPLETED)
    <pub:ItemText301>${status}</pub:ItemText301>
    //Status Date
    <pub:ItemDate7>${today}</pub:ItemDate7>`;
};

export default EDIRequester;

export const EDIStatus = {
  INELIGIBLE: 'INELIGIBLE',
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  WRK_PREPARATION: 'WRK_PREPARATION',
  COMPLETED: 'COMPLETED',
  COMPLETED_ABSENT: 'COMPLETED_ABSENT',
};

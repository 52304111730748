import React from 'react';
import Tile from './Tile';
import CrossfeedbacksAdditionalRow from './CrossfeedbacksAdditionalRow';
import { TileKPIOnly } from './TileKPI';
import ApproveRecruitingAdditionalRow from './ApproveRecruitingAdditionalRow';

export const TileWrapper = ({ actions, tile }) => {
  return <Tile actions={actions} tileData={tile} />;
};

export const TILES = {
  perfAppraisalToComplete: {
    title: 'tile_perfAppraisalToComplete',
    selecter: actions => [
      actions.INTERVIEWS_EAP_AS_EMPLOYEE_COUNT,
      actions.INTERVIEWS_EAP_AS_MANAGER_COUNT,
    ],
    alertSelecter: actions =>
      actions.INTERVIEWS_EAP_AS_EMPLOYEE_EVAL_ALERT == 'YES' ||
      actions.INTERVIEWS_EAP_AS_MANAGER_EVAL_ALERT == 'YES',
    links: ['/performance/appraisals', '/performance/appraisals'],
    linkType: 'internal',
    icon: 'fa-comments',
    AdditionalRow: CrossfeedbacksAdditionalRow,
    onClickDispatchCurrentUser: [true, false],
  },
  appraisalToComplete: {
    title: 'tile_appraisalJimmy',
    selecter: () => [false, false],
    alertSelecter: () => false,
    links: ['/performance/edi_documents/', '/performance/edi_documents/'],
    onClickDispatchCurrentUser: [true, false],
    linkType: 'internal',
    icon: 'fa-comments',
    clickableTitle: '/performance/edi_documents/',
  },
  jobReq: {
    title: 'tile_jobReq',
    selecter: actions => [actions.JOB_REQ_AS_EMPLOYEE, actions.JOB_REQ_AS_MANAGER],
    alertSelecter: () => false,
    links: ['/recruiting/feedbacks', '/recruiting/team'],
    linkType: 'internal',
    icon: 'fa-address-book',
    onlyForHiringManager: true,
    linkIfZeroForMe: '/recruiting',
    AdditionalRow: ApproveRecruitingAdditionalRow,
  },
  mandatoryTraining: {
    title: 'tile_mandatoryTraining',
    selecter: actions => [
      actions.MANDATORY_TRAINING_AS_EMPLOYEE,
      actions.MANDATORY_TRAINING_AS_MANAGER,
    ],
    alertSelecter: actions =>
      actions.MANDATORY_TRAINING_AS_EMPLOYEE_ALERT == 'YES' ||
      actions.MANDATORY_TRAINING_AS_MANAGER_ALERT == 'YES',
    links: [
      'https://natixis.e-tipi.com/presentation/profil/index?openTab=mandatoryTraining',
      '/xmlpserver/Custom/Reporting/Learning/Learning%20-%20Indicators%20Manager/Indicators%20Manager.xdo?_xiasynch=&_xpf=&_xpt=0&_dFlag=false&_edIndex=0&_dIndex=0&_rToken=&_ranDiag=false&_xdo=%2FCustom%2FReporting%2FLearning%2FLearning%20-%20Indicators%20Manager%2FIndicators%20Manager.xdo&_xmode=2&_xt=Report%2011&_xf=html&_xautorun=true&_linkToReport=true',
    ],
    linkType: 'external',
    icon: 'fa-university',
  },
  sessionRegistration: {
    title: 'tile_sessionRegistration',
    managerTitle: true,
    selecter: actions => [
      actions.SESSION_REGISTRATION_AS_EMPLOYEE,
      actions.SESSION_REGISTRATION_AS_MANAGER,
    ],
    alertSelecter: () => false,
    links: [
      'https://natixis.e-tipi.com/presentation/profil/index?opentab=yourRequestTraining',
      '/fscmUI/faces/FuseOverview?fndGlobalItemNodeId=ATK_HOMEPAGE_FUSE_WORKLIST',
    ],
    linkType: 'external',
    icon: 'fa-graduation-cap',
  },
  peopleReview: {
    title: 'tile_peopleReview',
    selecter: actions => [0, actions.PEOPLE_REVIEW_AS_MANAGER],
    alertSelecter: actions => actions.PEOPLE_REVIEW_AS_MANAGER_ALERT == 'YES',
    links: [
      '/performance/me',
      '/hcmUI/faces/FndOverview?fnd=%3B%3B%3B%3Bfalse%3B256%3B%3B%3B&fndGlobalItemNodeId=itemNode_manager_resources_talent_review_mobile',
    ],
    linkType: 'external',
    icon: 'fa-line-chart',
    keepOnlyOneKPI: TileKPIOnly.TEAM,
    optionalTile: true,
  },
  preboarding: {
    title: 'tile_preboarding',
    selecter: actions => [actions.PREBOARDING_AS_EMPLOYEE, 0],
    alertSelecter: actions => actions.PREBOARDING_AS_EMPLOYEE_ALERT == 'YES',
    links: [
      '/hcmUI/faces/FndOverview?fnd=%3B%3B%3B%3Bfalse%3B256%3B%3B%3B&fndGlobalItemNodeId=itemNode_my_information_onboarding',
      null,
    ],
    linkType: 'external',
    icon: 'fa-user-circle',
    keepOnlyOneKPI: TileKPIOnly.ME,
    optionalTile: true,
  },
};

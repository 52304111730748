/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Title from './Title';
import Avatar from '../../common/Avatar';
import {
  employeeId,
  employeeName,
  isFunctionalWorker,
  isTrainee,
  countFunctionalWorkers,
  countTrainees,
} from '../../../utils/data';
import { Link, useLocation } from 'react-router-dom';
import Checkbox from '../../common/Checkbox';
import { useTranslation } from 'react-i18next';
import { setSelectedEmployee } from '../../../actions/settingsAction';
import FunctionalWorkerIcon from '../../common/FunctionalWorkerIcon';

const MyTeam = ({ employees, dispatchSetSelectedEmployee }) => {
  const [keepStudents, setKeepStudents] = useState(true);
  const [keepFunctionalWorkers, setKeepFunctionalWorkers] = useState(true);
  const [filteredEmployees, setFilteredEmployees] = useState(employees.data);
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (!location.pathname.includes('/performance')) {
      dispatchSetSelectedEmployee(0);
    }
  }, [location, dispatchSetSelectedEmployee]);

  useEffect(() => {
    let filtered = false;
    let result = employees.data;

    if (!keepStudents) {
      filtered = true;
      result = result.filter(e => {
        return !isTrainee(e);
      });
    }
    if (!keepFunctionalWorkers) {
      filtered = true;
      result = result.filter(e => {
        return !isFunctionalWorker(e);
      });
    }

    if (filtered) {
      setFilteredEmployees(result);
    } else {
      setFilteredEmployees(employees.data);
    }
  }, [keepStudents, keepFunctionalWorkers, employees]);

  const handleKeepStudents = () => {
    setKeepStudents(v => !v);
  };

  const handleKeepFunctionalWorkers = () => {
    setKeepFunctionalWorkers(v => !v);
  };

  const functionalWorkersCount = countFunctionalWorkers(employees.data);
  const traineesCount = countTrainees(employees.data);

  return (
    <>
      {employees.data.length > 0 ? (
        <>
          <Title text={`${t('my_team')} (${employees.data.length})`} />
          <div>
            {functionalWorkersCount > 0 ? (
              <Checkbox
                label={`${t('team_func_workers')} (${functionalWorkersCount})`}
                handleChange={handleKeepFunctionalWorkers}
                checked={keepFunctionalWorkers}
              />
            ) : null}
            {traineesCount > 0 ? (
              <Checkbox
                label={`${t('team_trainees')} (${traineesCount})`}
                handleChange={handleKeepStudents}
                checked={keepStudents}
              />
            ) : null}
            <div id="my_team_container">
              {filteredEmployees.map((p, index) => {
                const key = index + 1;
                return (
                  <div key={key} className="my_team_container_item">
                    <Avatar
                      key={key}
                      personId={employeeId(p)}
                      style={{ marginLeft: '5px', marginRight: '5px' }}
                    />
                    <Link
                      to={`/performance/`}
                      className="employee_name"
                      onClick={() => {
                        dispatchSetSelectedEmployee(employeeId(p));
                      }}
                    >
                      {isFunctionalWorker(p) ? <FunctionalWorkerIcon /> : null}
                      {employeeName(p)}
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  dispatchSetSelectedEmployee: e => dispatch(setSelectedEmployee(e)),
});

export default connect(null, mapDispatchToProps)(MyTeam);

import React, { useEffect, useState } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchGoalsList } from '../../actions/apiActions';
import MUIDataTable from 'mui-datatables';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import LoaderInCard from '../common/LoaderInCard';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const convertDate = rawDate => moment(rawDate, 'DD/MM/YYYY');

const isFromThisYear = rawDate => {
  const date = convertDate(rawDate);
  const now = moment(new Date());
  return date.year() == now.year();
};

const prepareGoals = (goals, typeFilter) => {
  const filteredGoals = goals.filter(g => {
    let keep = cleanType(g.GOAL_TYPE_CODE) == cleanType(typeFilter);
    if (!isDevelopment(g.GOAL_TYPE_CODE)) {
      keep = keep && isFromThisYear(g.GOAL_START_DATE);
    }
    return keep;
  });
  const data = filteredGoals.map(g => {
    return [
      g.GOAL_NAME,
      g.GOAL_SOURCE_CODE,
      g.GOAL_START_DATE,
      g.GOAL_TYPE_CODE,
      g.STATUS_CODE,
    ];
  });
  return data;
};

const Objectives = ({ goalsList, dispatchFetchGoalsList }) => {
  const [goals, setGoals] = useState(null);
  const { filter } = useParams();
  const { t } = useTranslation();

  // configured within the render function to force MUI Datatable rerender
  const columns = [
    t('my_obj_title'),
    {
      name: t('my_obj_source'),
      options: {
        customBodyRender: value => {
          return cleanSource(value, t);
        },
      },
    },
    {
      name: t('my_obj_starting_date'),
      options: {
        sortCompare: order => {
          return (obj1, obj2) => {
            const l = convertDate(obj1.data);
            const r = convertDate(obj2.data);
            return order === 'asc' ? l > r : l < r;
          };
        },
      },
    },
    {
      name: t('my_obj_type'),
      options: {
        customBodyRender: value => {
          return cleanType(value, t);
        },
      },
    },
    {
      name: t('my_obj_status'),
      options: {
        sort: true,
        customBodyRender: value => {
          return cleanStatus(value, t);
        },
      },
    },
  ];

  useEffect(() => {
    if (goalsList.fetched) {
      setGoals(prepareGoals(goalsList.data, filter));
    } else {
      dispatchFetchGoalsList();
    }
  }, [goalsList, dispatchFetchGoalsList, filter]);

  return (
    <Container fluid id="objectives_container">
      <Row>
        <Col lg="12" xs="12">
          <Card style={{ minHeight: '220px' }} className="card_dashboard">
            <Card.Body>
              <Card.Title>
                {t(`my_obj_${cleanType(filter)}`)}
                <div style={{ textAlign: 'right' }}>
                  <Button>
                    <NavLink to="/performance/me">{t('my_obj_link_to_perf')}</NavLink>
                  </Button>
                </div>
              </Card.Title>
              <>
                {goals !== null ? (
                  <MUIDataTable data={goals} columns={columns} options={options(t)} />
                ) : (
                  <LoaderInCard />
                )}
              </>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    goalsList: state.hcm.goalsList,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchFetchGoalsList: () => dispatch(fetchGoalsList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Objectives);

const options = t => {
  return {
    search: false,
    filter: false,
    download: false,
    searchOpen: false,
    viewColumns: false,
    selectableRows: 'none',
    sortOrder: {
      name: t('my_obj_status'),
      direction: 'desc',
    },
  };
};

const cleanSource = (v, t) => {
  switch (v) {
    case 'HR':
      return t('my_obj_source_hr');
    case 'WORKER':
      return t('my_obj_source_worker');
    case 'MANAGER':
      return t('my_obj_source_manager');
    default:
      return v;
  }
};

const isDevelopment = v => {
  return v == 'DEVELOPMENT';
};

const cleanType = (v, t = null) => {
  if (t == null) {
    return v == undefined ? v : v.toLowerCase();
  }
  switch (v) {
    case 'PERFORMANCE':
      return t('my_obj_type_perf');
    case 'DEVELOPMENT':
      return t('my_obj_type_dev');
    default:
      return v;
  }
};

const cleanStatus = (v, t) => {
  const label =
    v == 'IN_PROGRESS'
      ? 'my_obj_status_in_progress'
      : v == 'COMPLETED'
      ? 'my_obj_status_completed'
      : 'my_obj_status_not_in_progress';
  return (
    <>
      {v == 'IN_PROGRESS' ? (
        <span className="p-2 badge badge-success ">{t(label)}</span>
      ) : v == 'COMPLETED' ? (
        <span className="p-2 badge badge-info">{t(label)}</span>
      ) : (
        <span className="p-2 badge badge-primary">{t(label)}</span>
      )}
    </>
  );
};

import moment from 'moment';
import { getCurrentUserId } from '../api/manager';

export const DATE_FORMAT_ISO = 'YYYY-MM-DD';

export const DATE_FORMAT_FR = 'DD/MM/YYYY';

export const employeeName = e => `${e.LAST_NAME} ${e.FIRST_NAME}`;

export const employeeId = e => e.PERSON_ID;

export const getDate = userLang => {
  var options = { year: 'numeric', month: 'long', day: 'numeric' };
  var today = new Date();
  return today.toLocaleDateString(userLang, options);
};

export const partition = (array, fn) => {
  return array.reduce(
    ([pass, fail], elem) => {
      return fn(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]];
    },
    [[], []],
  );
};

export const isPerformanceGoal = goal => goal.GOAL_TYPE_CODE === 'PERFORMANCE';

export class JobReqsSource {
  static FromMe = new JobReqsSource('FromMe');
  static FromMyTeam = new JobReqsSource('FromMyTeam');

  constructor(value) {
    this.value = value;
  }

  toString() {
    return `JobReqsSource ${this.value}`;
  }
}

export const filterJobReqsMyApprovalsWaiting = data => {
  return data.filter(jobReq => jobReq.NEXT_APPROVER_ID === getCurrentUserId());
};
// TO BE update with return data.filter(jobReq => jobReq.NEXT_APPROVER_ID !== getCurrentUserId()); after delivering PVR
export const filterOutJobReqsMyApprovalsWaiting = data => {
  return data.filter(jobReq => jobReq.NEXT_APPROVER_ID !== 0);
};

export const filterJobReqsBySource = (data, source) => {
  data = data.data;
  return data.filter(jobReq => jobReq.SOURCE == source);
};

export const filterOngoingJobReqsBySource = (data, source) => {
  data = data.data;
  return data.filter(
    jobReq => jobReq.SOURCE == source && jobReq.JOB_REQ_STATUS != 'CANDIDATE_ARRIVE',
  );
};

export const hasTrainee = employees => {
  return employees.find(e => isTrainee(e)) != undefined;
};

export const hasFunctionalWorker = employees => {
  return employees.find(e => isFunctionalWorker(e)) != undefined;
};

export const countTrainees = employees => {
  return countWithinEmployees(employees, isTrainee);
};

export const countFunctionalWorkers = employees => {
  return countWithinEmployees(employees, isFunctionalWorker);
};

const countWithinEmployees = (employees, selecter) => {
  return employees.reduce((acc, e) => (selecter(e) ? acc + 1 : acc), 0);
};

export const isFunctionalWorker = e => e.MANAGER_TYPE == 'NA_FUNCTIONAL_MGR';

export const isTrainee = e => e.EMPLOYEE_TYPE == 'alternant';

export const convertDate = (rawDate, format = DATE_FORMAT_FR) => moment(rawDate, format);

export const isFromThisYear = rawDate => {
  const date = convertDate(rawDate);
  const now = moment(new Date());
  return date.year() == now.year();
};

export const getTodayDate = (format = DATE_FORMAT_FR) => {
  return moment().format(format);
};

export const deepAssign = (a, b) => {
  Object.entries(b).forEach(
    ([k, v]) =>
      (a[k] = v && typeof v === 'object' ? deepAssign((a[k] = a[k] || {}), v) : v),
  );
  return a;
};

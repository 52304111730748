import React from 'react';
import { Button, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Avatar from '../../common/Avatar';
import moment from 'moment';
import EDIStatusBadge from '../common/EDIStatusBadge';
import { getCurrentEnv } from '../../../api/manager';
import { getEDI2022PrintLink } from '../common/utils';

export const getColumnsArchive = (handleOpenArchive, t) => {
  const columnsArchive = [
    {
      name: 'person_id',
      options: {
        display: false,
      },
    },
    {
      name: t('table_EmployeeName'),
      options: {
        sort: true,
        sortOrder: 'desc',
        customBodyRender: (_, tableMeta) => {
          const employeeId = getEmployeeId(tableMeta);
          const employeeName = getEmployeeName(tableMeta);
          return (
            <>
              <Avatar personId={employeeId} /> {employeeName}
            </>
          );
        },
      },
    },
    {
      name: t('table_DocumentName'),
      options: {
        customBodyRender: (_, tableMeta) => {
          const isCompletedEDIArchive = getIsCompletedEDIArchive(tableMeta);
          const documentName = getDocumentName(tableMeta);
          let url = '';
          if (isCompletedEDIArchive) {
            const employeeId = getEmployeeId(tableMeta);
            url = `/performance/edidocument/${employeeId}`;
          } else {
            url =
              `https://ekez${getCurrentEnv()}.fa.em2.oraclecloud.com/` +
              getDocumentURL(tableMeta);
          }

          return (
            <>
              {isCompletedEDIArchive ? (
                <Row>
                  <Link
                    className="buttonLink documentName trigger btn btn-link"
                    to={`${url}`}
                  >
                    {documentName}
                  </Link>
                </Row>
              ) : (
                <Row>
                  <Button
                    href="#"
                    type="button"
                    className="buttonLink documentName trigger btn btn-link"
                    onClick={() => handleOpenArchive(url)}
                    data-linkonly="true"
                  >
                    {documentName}
                  </Button>
                </Row>
              )}
            </>
          );
        },
      },
    },
    {
      name: t('table_CurrentStep'),
      options: {
        display: false,
        customBodyRender: value => {
          return <>{value != null && <EDIStatusBadge status={value} />}</>;
        },
      },
    },
    {
      name: t('table_InterviewDate'),
      options: {
        customBodyRender: value => moment(value).format('DD/MM/YYYY'),
      },
    },
    {
      name: 'EVALUATOR',
      options: {
        display: false,
      },
    },
    {
      name: t('table_Actions'),
      options: {
        customBodyRender: (_, tableMeta) => {
          const isCompletedEDIArchive = getIsCompletedEDIArchive(tableMeta);
          let url = '';
          if (isCompletedEDIArchive) {
            const ediItemId = getEDIItemId(tableMeta);
            url = getEDI2022PrintLink(ediItemId);
          } else {
            url =
              `https://ekez${getCurrentEnv()}.fa.em2.oraclecloud.com/` +
              getDocumentURL(tableMeta);
          }
          return (
            <Row>
              <Button
                type="button"
                className="buttonLink documentName trigger btn btn-link"
                data-linkonly="true"
                onClick={() => handleOpenArchive(url)}
              >
                {t('Print')}
              </Button>
            </Row>
          );
        },
      },
    },
    {
      name: 'URL',
      options: {
        display: false,
      },
    },
    {
      name: 'DOCUMENT_TYPE',
      options: {
        display: false,
      },
    },
    {
      name: 'ALLOW_REOPEN',
      options: {
        display: false,
      },
    },
    {
      name: 'EDI_ITEM_ID',
      options: {
        display: false,
      },
    },
  ];
  return columnsArchive;
};

// Common
const getEmployeeId = tableMeta => tableMeta.rowData[0];

const getEmployeeName = tableMeta => tableMeta.rowData[1];

const getDocumentName = tableMeta => tableMeta.rowData[2];

// Archive
const getDocumentURL = tableMeta => tableMeta.rowData[7];

const getIsCompletedEDIArchive = tableMeta => tableMeta.rowData[8] === 'COMPLETED_EDI';

const getEDIItemId = tableMeta => tableMeta.rowData[10];

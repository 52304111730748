import React from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const ReviewPeriodSelector = ({ reviewPeriod, setReviewPeriod }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row className="d-flex justify-content-between">
        <div className="d-flex">
          <label className="ml-3 mt-1">{t('goal_review_period_selector')}</label>
          <Select
            id="goal_review_period_selector"
            name="goal_review_period_selector"
            className="ml-3"
            styles={{
              menu: provided => ({ ...provided, zIndex: 9999 }),
            }}
            options={loadPerformanceGoalReviewPeriods()}
            closeMenuOnSelect={true}
            defaultOptions
            components={animatedComponents}
            value={reviewPeriod}
            onChange={x => {
              setReviewPeriod(x);
            }}
          />
        </div>
      </Row>
    </>
  );
};

export default ReviewPeriodSelector;

const loadPerformanceGoalReviewPeriods = () => {
  return REVIEW_PERIODS;
};

export const REVIEW_PERIODS = [
  {
    value: 0,
    label: 2019,
    dates: { periodStartDate: '2019-01-01', periodEndDate: '2019-12-31' },
    canAdd: false,
  },
  {
    value: 1,
    label: 2020,
    dates: { periodStartDate: '2020-01-01', periodEndDate: '2020-12-31' },
    canAdd: false,
  },
  {
    value: 2,
    label: 2021,
    dates: { periodStartDate: '2021-01-01', periodEndDate: '2021-12-31' },
    canAdd: false,
  },
  {
    value: 3,
    label: 2022,
    dates: { periodStartDate: '2022-01-01', periodEndDate: '2022-12-31' },
    canAdd: false,
  },
  {
    value: 4,
    label: 2023,
    dates: { periodStartDate: '2023-01-01', periodEndDate: '2023-12-31' },
    canAdd: true,
  },
];

export const PAST_REVIEW_PERIOD = REVIEW_PERIODS.find(p => p.label === 2022);
export const CURRENT_REVIEW_PERIOD = REVIEW_PERIODS.find(p => p.label === 2023);
export const NEXT_REVIEW_PERIOD = REVIEW_PERIODS.find(p => p.label === 2023);

export const filterPerformanceGoalByReviewPeriod = reviewPeriod => goal => {
  if (reviewPeriod && reviewPeriod.dates) {
    return (
      goal?.periodStartDate == reviewPeriod?.dates.periodStartDate &&
      goal?.periodEndDate == reviewPeriod?.dates.periodEndDate
    );
  } else {
    return true;
  }
};

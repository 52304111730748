import React from 'react';
import { useTranslation } from 'react-i18next';
import DonutChart, { extractDataFromRecruitments } from './DonutChart';
import DashboardKPI from '../../common/DashboardKPI';

const OngoingRecruitments = ({ recruitingJobReqs }) => {
  const { t } = useTranslation();
  const recruitments = extractDataFromRecruitments(recruitingJobReqs, t);

  const loadingKPI = !recruitingJobReqs.fetched;
  const hideKPI = recruitingJobReqs.fetched && recruitingJobReqs.data.length <= 0;

  const getDonutChart = () => (
    <DonutChart
      series={recruitments.series}
      labels={recruitments.labels}
      labels2={recruitments.labels2}
    />
  );

  return (
    <DashboardKPI
      title={t('recruiting_kpis_title')}
      Component={getDonutChart}
      loadingKPI={loadingKPI}
      hideKPI={hideKPI}
    />
  );
};

export default OngoingRecruitments;

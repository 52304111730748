import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { TextareaAutosize } from '@material-ui/core';
import { fetchEAPFeedbacksByEAPItemId } from '../../../../actions/apiPerformanceActions';
import LoaderInCard from '../../../common/LoaderInCard';

const ConsultFeedbacksModal = ({
  dispatchFetchFeedbackByEAP,
  feedbacks,
  eapItemId,
  ...props
}) => {
  const { t } = useTranslation();
  const [targetedFeedbacks, setTargetedFeedbacks] = useState(null);
  const { ...rest } = props;

  useEffect(() => {
    if (eapItemId != null && eapItemId != '') {
      dispatchFetchFeedbackByEAP(eapItemId);
    }
  }, [eapItemId, dispatchFetchFeedbackByEAP]);

  useEffect(() => {
    setTargetedFeedbacks(getAnsweredFeedbacks(feedbacks, eapItemId));
  }, [feedbacks, eapItemId]);

  return (
    <Modal
      {...rest}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop={'static'}
      dialogClassName="modal-50w"
      centered
    >
      <Card>
        <Card.Body>
          <Card.Title> {t('eap_consult_feedbacks_title')}</Card.Title>
          {targetedFeedbacks?.length > 0 ? (
            <div>
              {targetedFeedbacks.map((feedback, i) => {
                return (
                  <>
                    <DisplayFeedback feedbackData={feedback} key={i} />
                    <hr />
                  </>
                );
              })}
            </div>
          ) : (
            <>
              {targetedFeedbacks == null ? (
                <LoaderInCard />
              ) : (
                <label className="form-label">{t('eap_consult_feedbacks_nothing')}</label>
              )}
            </>
          )}
          <>
            <Button className="mr-10 w100" onClick={() => props.onHide()}>
              {t('Close')}
            </Button>
          </>
        </Card.Body>
      </Card>
    </Modal>
  );
};

const mapStateToProps = state => ({
  feedbacks: state.performance.feedbacks,
});

const mapDispatchToProps = dispatch => ({
  dispatchFetchFeedbackByEAP: eapItemId =>
    dispatch(fetchEAPFeedbacksByEAPItemId(eapItemId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsultFeedbacksModal);

export const DisplayFeedback = ({ feedbackData }) => {
  const readOnly = true;
  const [question1, setquestion1] = useState('');
  const [question2, setquestion2] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    if (readOnly) {
      setquestion1(feedbackData?.QUESTION1);
      setquestion2(feedbackData?.QUESTION2);
    }
  }, [feedbackData, readOnly]);

  return (
    <div>
      <Col className="form-group">
        {feedbackData != null && feedbackData['STATUS_CODE'] != 'TODO' && (
          <>
            <Row>
              <Col md="12">
                <label className="form-label">
                  {t('cross_feedback_from')} {feedbackData['ASKED_TO']} {t('for')}{' '}
                  {feedbackData['ASKED_FOR']}
                </label>
              </Col>
            </Row>
            <label>{t('answer_feedback_question1')}</label>
            <TextareaAutosize
              id="question1"
              name="question1"
              type="text"
              fullWidth
              minRows={4}
              style={{ width: '100%', height: '50%' }}
              disabled={readOnly}
              value={question1}
            />
            <label>{t('answer_feedback_question2')}</label>
            <TextareaAutosize
              id="question2"
              name="question2"
              type="text"
              fullWidth
              minRows={4}
              style={{ width: '100%', height: '50%' }}
              disabled={readOnly}
              value={question2}
            />
          </>
        )}
      </Col>
    </div>
  );
};

const getAnsweredFeedbacks = (feedbacks, eapId) => {
  if (feedbacks && feedbacks.data) {
    return Object.values(feedbacks.data)
      .filter(f => f.STATUS_CODE != 'TODO')
      .filter(f => f.EAP_ITEM_ID == eapId);
  } else {
    return [];
  }
};

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import _ from 'lodash';

const DisplayErrorHeader = ({ label, error }) => {
  return (
    <>
      {_.isEmpty(error) ? null : (
        <>
          <Row className=" mb-10">
            <Col md={1}></Col>
            <Col md={10} className="noteBoxes typeError bandeau">
              {label}
              <Collapsible
                className="error_header_collapsible"
                openedClassName="error_header_collapsible_open"
                triggerClassName="error_header_collapsible_trigger"
                triggerStyle={{ cursor: 'pointer' }}
                trigger={'Details'}
              >
                {error.basic}
                <hr />
                {error?.details}
              </Collapsible>
            </Col>
            <Col md={1}></Col>
          </Row>
        </>
      )}
    </>
  );
};

export default DisplayErrorHeader;

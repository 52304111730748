import { deepAssign } from '../utils/data';

const initialState = {
  jimmyEligibility: {
    fetched: false,
    isEligible: null,
  },
  professionalProjects: {
    fetched: false,
    data: [],
  },
  employeesProfileData: {
    fetched: false,
    data: {},
  },
  employeesEAPProfileData: {
    fetched: false,
    data: {},
  },
  developmentGoals: {
    fetched: false,
    data: {},
  },
  performanceGoals: {
    fetched: false,
    data: {},
  },
  edis: {
    fetched: false,
    data: {},
  },
  eaps: {
    fetched: false,
    data: {},
  },
  eprs: {
    fetched: false,
    data: [],
  },
  feedbacks: {
    fetched: false,
    data: {},
  },
};

const performance = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PROFESSIONAL_PROJECTS':
      return {
        ...state,
        professionalProjects: {
          ...state.professionalProjects,
          fetched: true,
          data: action.payload,
        },
      };

    case 'SET_EMPLOYEES_PROFILE_DATA':
      return {
        ...state,
        employeesProfileData: {
          ...state.employeesProfileData,
          fetched: true,
          data: deepAssign(state.employeesProfileData.data, action.payload),
        },
      };
    case 'SET_EMPLOYEES_EAP_PROFILE_DATA':
      return {
        ...state,
        employeesEAPProfileData: {
          ...state.employeesEAPProfileData,
          fetched: true,
          data: deepAssign(state.employeesEAPProfileData.data, action.payload),
        },
      };
    case 'SET_EMPLOYEES_EDI_DATA': {
      return {
        ...state,
        employeesProfileData: {
          ...state.employeesProfileData,
          fetched: true,
          data: deepAssign(state.employeesProfileData.data, action.payload),
        },
      };
    }
    case 'SET_EMPLOYEES_EAP_DATA': {
      return {
        ...state,
        employeesEAPProfileData: {
          ...state.employeesEAPProfileData,
          fetched: true,
          data: deepAssign(state.employeesEAPProfileData.data, action.payload),
        },
      };
    }
    case 'SET_DEVELOPMENT_GOALS':
      return {
        ...state,
        developmentGoals: {
          ...state.developmentGoals,
          fetched: true,
          data: Object.assign(state.developmentGoals.data, action.payload),
        },
      };

    case 'SET_EDIS':
      return {
        ...state,
        edis: {
          ...state.edis,
          fetched: true,
          data: Object.assign(state.edis.data, action.payload),
        },
      };
    case 'SET_EAPS':
      return {
        ...state,
        eaps: {
          ...state.eaps,
          fetched: true,
          data: Object.assign(state.eaps.data, action.payload),
        },
      };
    case 'SET_PERFORMANCE_GOALS':
      return {
        ...state,
        performanceGoals: {
          ...state.performanceGoals,
          fetched: true,
          data: Object.assign(state.performanceGoals.data, action.payload),
        },
      };
    case 'SET_EPRS':
      return {
        ...state,
        eprs: {
          ...state.eprs,
          fetched: true,
          data: Object.assign(state.eprs.data, action.payload),
        },
      };
    case 'SET_EAP_FEEDBACK':
      return {
        ...state,
        feedbacks: {
          ...state.feedbacks,
          fetched: true,
          data: Object.assign(
            state.feedbacks.data,
            arrayOfObjectsToDictionary('FEEDBACK_ITEM_ID', action.payload),
          ),
        },
      };
    case 'SET_JIMMY_ELIGIBILITY':
      return {
        ...state,
        jimmyEligibility: {
          fetched: true,
          isEligible: action.payload,
        },
      };

    default:
      return state;
  }
};

export default performance;

const arrayOfObjectsToDictionary = (key, array) => {
  const dictionary = Object.assign({}, ...array.map(x => ({ [x[key]]: x })));
  return dictionary;
};

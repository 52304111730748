import React from 'react';

const Announcement = ({ announcement }) => {
  const content = announcement.DESCRIPTION;
  const subject = announcement.SUBJECT;

  return (
    <>
      <h6>{subject}</h6>
      <p className="px-5 py-3" dangerouslySetInnerHTML={{ __html: content }}></p>
    </>
  );
};

export default Announcement;

import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getCurrentUserId } from '../../api/manager';
import { setSelectedEmployee } from '../../actions/settingsAction';
import { connect } from 'react-redux';
import { fetchEAPFeedbacks } from '../../actions/apiPerformanceActions';

const CrossfeedbacksAdditionalRow = ({
  dispatchSetSelectedEmployee,
  dispatchFetchFeedbacks,
  feedbacks,
}) => {
  const [nbFeedbacks, setNbFeedbacks] = useState(null);
  const link = `/performance/feedbacks`;
  const { t } = useTranslation();

  useEffect(() => {
    dispatchFetchFeedbacks();
  }, [dispatchFetchFeedbacks]);

  useEffect(() => {
    if (feedbacks && feedbacks.fetched && feedbacks.data) {
      const nbFeedbacks = Object.values(feedbacks.data).filter(
        f => f.ASKED_TO_PERSON_ID == getCurrentUserId() && f.STATUS_CODE != 'DONE',
      ).length;
      setNbFeedbacks(nbFeedbacks);
    }
  }, [feedbacks]);

  const onClick = () => {
    dispatchSetSelectedEmployee(getCurrentUserId());
  };

  return (
    <>
      {nbFeedbacks != null ? (
        <div id="tile_crossfeedbacks">
          <NavLink to={link} onClick={() => onClick()}>
            {t('tile_crossfeedbacks')} : {nbFeedbacks}
          </NavLink>
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = state => {
  return {
    feedbacks: state.performance.feedbacks,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchSetSelectedEmployee: e => dispatch(setSelectedEmployee(e)),
  dispatchFetchFeedbacks: personId => dispatch(fetchEAPFeedbacks(personId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CrossfeedbacksAdditionalRow);

import React, { useEffect, useRef } from 'react';
import MUIDataTable, { TableSearch } from 'mui-datatables';
import { ThemeProvider, createTheme } from '@material-ui/core';
import { getCurrentUserId, getCurrentUserManagerRole } from '../../../api/manager';
import { useTranslation } from 'react-i18next';
const DocumentTable = ({
  columns,
  data = [],
  targetEmployeeId = 0,
  noFilter = false,
}) => {
  const isManager = getCurrentUserManagerRole();
  const employeeTable = useRef();
  const { t } = useTranslation();

  const filteredData =
    noFilter || !isManager
      ? data
      : data.filter(filterByEmployeeId(targetEmployeeId, getCurrentUserId()));
  useEffect(() => {
    if (targetEmployeeId != 0) employeeTable.current.searchTextUpdate('');
  }, [targetEmployeeId]);

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        ref={employeeTable}
        className="table table-hover table-striped table-vcenter mb-0 fixtable"
        style={{ border: '1px simple #B3B3B3 !important' }}
        columns={columns}
        data={prepareData(filteredData)}
        options={{
          selectableRows: 'none',
          searchOpen: true,
          pagination: false,
          resizableColumns: false,
          filter: false,
          print: false,
          download: false,
          viewColumns: false,
          sortOrder: {
            name: t('table_EmployeeName'),
            direction: 'asc',
          },
          customSearchRender: (searchText, handleSearch, hideSearch, options) => (
            <TableSearch
              searchText={searchText}
              onSearch={handleSearch}
              onHide={hideSearch}
              options={{
                ...options,
                searchProps: {
                  autoFocus: false,
                  ...options.searchProps,
                },
              }}
            />
          ),
        }}
      />
    </ThemeProvider>
  );
};

const filterByEmployeeId = (targetId, currentUserId) => employee =>
  targetId == 0
    ? employee['PERSON_ID'] != currentUserId
    : employee['PERSON_ID'] == targetId;

const prepareData = data => data.map(x => Object.values(x));

const getMuiTheme = () => createTheme({});

export default DocumentTable;

import React from 'react';
import { Card } from 'react-bootstrap';
import LoaderInCard from './LoaderInCard';
import NoData from './NoData';

const DashboardKPI = ({ title, Component, loadingKPI, hideKPI }) => {
  return (
    <Card className={`card_dashboard min_height ${hideKPI ? '' : ''}`}>
      <Card.Body>
        <p className="kpis_title">{title}</p>
        {loadingKPI ? (
          <LoaderInCard loaderMarginTop="50%" />
        ) : hideKPI ? (
          <NoData />
        ) : (
          <Component />
        )}
      </Card.Body>
    </Card>
  );
};

export default DashboardKPI;

import React from 'react';
import Avatar from '../../common/Avatar';
import { computeJobReqDetails, computeJobReqActions } from './detailsActions';
import moment from 'moment';
import { getCurrentUserId } from '../../../api/manager';
import TableSearchWithoutAutofocus from '../../common/TableSearchWithoutAutofocus';

// -----------------------------------------------
// DataTable customisation
const customRenderTitle = handler => {
  // eslint-disable-next-line react/display-name
  return (value, tableMeta) => {
    const requisitionId = tableMeta.rowData[1];
    const url = `/fscmUI/faces/deeplink?objType=IRC_RECRUITING&action=REQUISITION_DETAIL_MY_TEAM_RESP&objKey=RequisitionId=${requisitionId};pHideNavigation=true;pCloseMainTaskOnDone=Y`;
    return (
      <a
        href={url}
        className="trigger"
        onClick={handler}
        id={requisitionId}
        data-linkonly="false"
      >
        {value}
      </a>
    );
  };
};

const customRenderHRTeam = getJobReq => {
  // eslint-disable-next-line react/display-name
  return (value, tableMeta) => {
    const jobReq = getJobReq(tableMeta.rowData[0]);
    const recruiterName = value;
    const recruiterId = (jobReq != undefined && jobReq.RECRUITED_ID) || '';
    const hiringName = tableMeta.rowData[7];
    const hiringId = (jobReq != undefined && jobReq.HIRING_MANAGER_ID) || '';

    return (
      <div>
        <Avatar
          personId={recruiterId}
          style={{ marginRight: '5px', marginBottom: '5px' }}
        />
        {recruiterName}
        <br />
        <Avatar personId={hiringId} style={{ marginRight: '5px' }} />
        {hiringName}
      </div>
    );
  };
};

const customRenderStatus = (getJobReq, t) => {
  // eslint-disable-next-line react/display-name
  return (_, tableMeta) => {
    const jobReq = getJobReq(tableMeta.rowData[0]);
    return customRenderStatusJobReq(jobReq, t);
  };
};

export const customRenderStatusJobReq = (jobReq, t) => {
  var value = jobReq ? jobReq.JOB_REQ_STATUS : '';
  var translate = t(`recruiting_${value.toLowerCase()}`);

  if (
    jobReq
      ? jobReq.JOB_REQ_STATUS === 'JOB_REQUISITION' &&
        jobReq.GLOBAL_STATUS_DETAILS === 'CANDIDATE_ARRIVE'
      : false
  ) {
    value = 'CANDIDATE_ARRIVE';
    translate = t(`recruiting_${value.toLowerCase()}`);
  }
  switch (value.toUpperCase()) {
    case 'JOB_REQUISITION':
      return <div className="p-2 badge badge-info ">{translate}</div>;
    case 'SELECTION':
      return <div className="p-2 badge badge-danger">{translate}</div>;
    case 'JOB_OFFER':
      return <div className="p-2 badge badge-warning">{translate}</div>;
    case 'ONBOARDING':
      return <div className="p-2 badge badge-success">{translate}</div>;
    case 'CANDIDATE_ARRIVE':
      return <div className="p-2 badge badge-default">{translate}</div>;
    default:
      return <div className="p-2 badge badge-default">{translate}</div>;
  }
};

const customRenderDetails = (getJobReq, t) => {
  return (_, tableMeta) => {
    const jobReq = getJobReq(tableMeta.rowData[0]);
    return computeJobReqDetails(jobReq, t);
  };
};

const customRenderActions = (getJobReq, handler, t) => {
  return (_, tableMeta) => {
    const jobReq = getJobReq(tableMeta.rowData[0]);
    return computeJobReqActions(jobReq, handler, t);
  };
};

// BE CAREFUL WHILE CHANGING THE ORDER OF THE COLUMNS (see columns)
export const columns = (handler, filter = undefined, getJobReq, t) => {
  const defaultColumns = [
    {
      name: t('recruiting_job_req_id'),
    },
    { name: 'Technical id', options: { display: false } },
    {
      name: t('recruiting_title'),
      options: {
        customBodyRender: customRenderTitle(handler),
      },
    },
    {
      name: t('recruiting_hr_team'),
      options: {
        customBodyRender: customRenderHRTeam(getJobReq),
      },
    },
    {
      name: t('recruiting_date_create'),
      options: {
        sort: true,
        filter: false,
        sortCompare: order => {
          return (obj1, obj2) => {
            const l = convertDate(obj1.data);
            const r = convertDate(obj2.data);
            return order === 'desc' ? l > r : l < r;
          };
        },
      },
    },
    {
      name: t('recruiting_date_update'),
      options: {
        sort: true,
        filter: false,
        sortCompare: order => {
          return (obj1, obj2) => {
            const l = convertDate(obj1.data);
            const r = convertDate(obj2.data);
            return order === 'desc' ? l > r : l < r;
          };
        },
      },
    },
    {
      name: t('recruiting_status'),
      options: {
        customBodyRender: customRenderStatus(getJobReq, t),
      },
    },
    {
      name: t('recruiting_hiring_name'),
      options: {
        filter: true,
        display: false,
      },
    },
    {
      name: t('recruiting_details'),
      options: {
        customBodyRender: customRenderDetails(getJobReq, t),
        sort: true,
        filter: false,
        sortCompare: order => {
          return (obj1, obj2) => {
            const jobReq1 = getJobReq(obj1.rowData[0]);
            const jobReq2 = getJobReq(obj2.rowData[0]);

            const v = jobReq => {
              let status = jobReq.GLOBAL_STATUS_DETAILS;
              if (jobReq.NEXT_APPROVER_ID == getCurrentUserId()) {
                status = 'NEXT_APPROVER';
              }
              return GLOBAL_STATUS_DETAILS_ORDER.indexOf(status);
            };

            const sortJobReqByCreationDate = order => {
              return (jobReq1, jobReq2) => {
                const l = convertDate(jobReq1.CREATION_DATE);
                const r = convertDate(jobReq2.CREATION_DATE);
                return order === 'desc' ? l > r : l < r;
              };
            };

            if (jobReq1 && jobReq2) {
              const res = v(jobReq1) - v(jobReq2);
              if (res == 0) {
                return sortJobReqByCreationDate(order);
              } else {
                return res * (order === 'asc' ? 1 : -1);
              }
            } else {
              return 0;
            }
          };
        },
      },
    },
    {
      name: t('recruiting_actions'),
      options: {
        customBodyRender: customRenderActions(getJobReq, handler, t),
        filter: false,
      },
    },
  ];

  if (filter == undefined) {
    return defaultColumns;
  } else {
    let columns = defaultColumns;
    columns[7].options = {
      ...columns[7].options,
      filterList: [filter],
    };
    return columns;
  }
};

export const options = t => {
  return {
    filter: true,
    filterType: 'dropdown',
    searchOpen: true,
    selectableRows: 'none',
    sortOrder: {
      name: t('recruiting_details'),
      direction: 'asc',
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => (
      <TableSearchWithoutAutofocus
        options={options}
        searchText={searchText}
        onSearch={handleSearch}
        onHide={hideSearch}
      />
    ),
  };
};

// -----------------------------------------------
// Data Manipulation
// EMPLOYEE -> job req closed with a candidate (job offer finished)
// CANDIDATE_ARRIVE -> candidate incoming

const isJobReqFinished = jobReq =>
  ['EMPLOYEE', 'CANDIDATE_ARRIVE'].includes(jobReq.GLOBAL_STATUS_DETAILS);

const isJobReqArchived = jobReq => {
  try {
    const today = moment();
    const expirationDate = moment(jobReq.LAST_UPDATE_DATE, 'DD/MM/YYYY').add(3, 'months');
    return (
      (jobReq.GLOBAL_STATUS_DETAILS === 'JR_CANCELED' ||
        jobReq.GLOBAL_STATUS_DETAILS === 'JR_APPROVAL_REJECTED' ||
        jobReq.GLOBAL_STATUS_DETAILS === 'OFFER_REJECTED') &&
      today.isAfter(expirationDate)
    );
  } catch (err) {
    console.error('Recruiting/JobReqs/dataTableConfig isJobReqArchived() err : ', err);
    return false;
  }
};

export const getFinishedRecruitments = (data, t) => {
  return filterRecruitingJobReq(
    data,
    jobReq => isJobReqFinished(jobReq) || isJobReqArchived(jobReq),
    t,
  );
};

export const getOngoingRecruitments = (data, t) => {
  return filterRecruitingJobReq(
    data,
    jobReq => !isJobReqFinished(jobReq) && !isJobReqArchived(jobReq),
    t,
  );
};

const filterRecruitingJobReq = (data, condition, t) => {
  return data
    .filter(recruitingJobReq => condition(recruitingJobReq))
    .map(inProgressJobReq => {
      return [
        inProgressJobReq.REQUISITION_NUMBER,
        inProgressJobReq.REQUISITION_ID,
        inProgressJobReq.TITLE,
        inProgressJobReq.RECRUITER_NAME,
        inProgressJobReq.CREATION_DATE,
        inProgressJobReq.LAST_UPDATE_DATE,
        t(`recruiting_${inProgressJobReq.JOB_REQ_STATUS.toLowerCase()}`),
        inProgressJobReq.HIRING_NAME,
        inProgressJobReq.GLOBAL_STATUS_DETAILS,
        '', // Action ?
      ];
    })
    .sort(sortJobReqByCreationDate('desc'));
};

export const constructJobReqsMap = jobReqs => {
  return jobReqs.reduce(function (map, obj) {
    map[obj.REQUISITION_NUMBER] = obj;
    return map;
  }, {});
};

const convertDate = rawDate => moment(rawDate, 'DD/MM/YYYY');

const GLOBAL_STATUS_DETAILS_ORDER = [
  'NEXT_APPROVER',
  'JR_APPROVAL_PENDING',
  'JR_DRAFT',
  'JR_APPROVAL_REJECTED',
  'JR_CANCELED',
  'JR_CANDIDATE_INIT',
  'JR_CANDIDATE_START',
  'SELECTION_START',
  'SELECTION_MGR',
  'OFFER_DRAFT',
  'OFFER_START',
  'OFFER_ACCEPTED',
  'OFFER_REJECTED',
  'ONBOARDING_START',
  'ONBOARDING_COMPLETED',
  'EMPLOYEE',
];

const sortJobReqByCreationDate = order => {
  return (obj1, obj2) => {
    const l = convertDate(obj1[4]);
    const r = convertDate(obj2[4]);
    return order === 'desc' ? l > r : l < r;
  };
};

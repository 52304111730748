import React from 'react';
import { getCurrentUserId } from '../../../api/manager';

const getDetailsValue = t => {
  const printValue = value => {
    let name = value;
    if (name == undefined || name == 'undefined') {
      name = `${t('details_unknown')}`;
      return (
        <strong>
          <i>{name}</i>
        </strong>
      );
    } else {
      return <strong>{name}</strong>;
    }
  };
  return printValue;
};

export const computeJobReqDetails = (jobReq, t) => {
  let status = '';
  if (jobReq) {
    status = jobReq.GLOBAL_STATUS_DETAILS;
  }

  let result = 'status';
  const printValue = getDetailsValue(t);
  switch (status) {
    case 'JR_DRAFT':
      result = (
        <>
          {t('details_req_created_by')} {printValue(jobReq.HIRING_NAME)}
        </>
      );
      break;
    case 'JR_APPROVAL_PENDING':
      result = (
        <>
          {t('details_req_transmitted')} {printValue(jobReq.RECRUITER_NAME)}
          <br />
          {t('details_req_next_approver')} {printValue(jobReq.NEXT_APPROVER)}
        </>
      );
      break;
    case 'JR_APPROVAL_REJECTED':
      result = (
        <>
          {t('details_req_rejected_by')} {printValue(jobReq.ACTION_BY)}
        </>
      );
      break;
    case 'JR_CANCELED':
      result = (
        <>
          {t('details_req_canceled')} {printValue(jobReq.LAST_UPDATE_DATE)}
        </>
      );
      break;
    case 'JR_CANDIDATE_INIT':
      result = (
        <>
          {t('details_req_validated_on')} {printValue(jobReq.LAST_UPDATE_DATE)}
        </>
      );
      break;
    case 'JR_CANDIDATE_START':
      result = (
        <>
          {t('details_req_published')} {printValue(jobReq.OPEN_DATE)}
        </>
      );
      break;
    case 'SELECTION_START':
      result = (
        <>
          {t('details_req_candidate_process_started_on')} {printValue(jobReq.OPEN_DATE)}
        </>
      );
      break;
    case 'SELECTION_MGR':
      result = (
        <>
          {t('details_req_candidate_selection')} {printValue(jobReq.LAST_UPDATE_DATE)}
        </>
      );
      break;
    case 'OFFER_DRAFT':
      result = (
        <>
          {t('details_req_candidate_retained_on')} {printValue(jobReq.LAST_UPDATE_DATE)}
        </>
      );
      break;
    case 'OFFER_START':
      result = (
        <>
          {t('details_req_job_offer_sent_on')} {printValue(jobReq.OPEN_DATE)}
        </>
      );
      break;
    case 'OFFER_ACCEPTED':
      result = (
        <>
          {t('details_req_job_offer_accepted_on')} {printValue(jobReq.OPEN_DATE)}
        </>
      );
      break;
    case 'OFFER_REJECTED':
      result = <>{t('details_req_job_offer_declined_on')}</>;
      break;
    case 'ONBOARDING_START':
      result = <>{t('details_req_contract_in_progress')}</>;
      break;

    case 'ONBOARDING_COMPLETED':
      result = <>{t('details_req_preboard_completed')}</>;
      break;

    case 'EMPLOYEE':
      result = (
        <>
          {t('details_req_employee_arrived')} {printValue(jobReq.DATE_START)}
        </>
      );
      break;
    case 'CANDIDATE_ARRIVE':
      result = (
        <>
          {t('details_req_employee_arrived')} {printValue(jobReq.DATE_START)}
        </>
      );
      break;
    default:
      result = 'default';
      break;
  }
  return result;
};

export const computeJobReqActions = (jobReq, handlerOpenModal, t) => {
  let status = jobReq;
  let urlAccessCareerSite = '';
  let urlAccessCandidates = '';
  let urlApproveJobReq = '';
  let result = '';
  if (jobReq) {
    urlAccessCareerSite = `https://mobiliteinterne.natixis.com/fr/jobs/list?keywords=${jobReq.REQUISITION_NUMBER}`;
    urlAccessCandidates = `/xmlpserver/Custom/Human Capital Management/Natixis Custom/Embedded Report/Home Page/Embedded Report/CV Screeming details rp.xdo?_xiasynch=&_xpf=&_xpt=1&_dFlag=false&_edIndex=0&_dIndex=0&_rToken=&_ranDiag=false&_xdo=%2FCustom%2FHuman%20Capital%20Management%2FNatixis%20Custom%2FEmbedded%20Report%2FHome%20Page%2FEmbedded%20Report%2FCV%20Screeming%20details%20rp.xdo&_xmode=4&_paramspRequisitionId=${jobReq.REQUISITION_ID}`;
    urlApproveJobReq = `/hcmUI/faces/adf.task-flow?tz=Europe%2FBelgrade&df=medium&dt=both&tf=short&lg=en&cy=&bpmWorklistTaskId=${jobReq.TASKID}&bpmBrowserWindowStatus=taskFlowReturn&bpmClientType=&sf=Natixis_logo6alta&adf.tfDoc=%2FWEB-INF%2Foracle%2Fapps%2Ffinancials%2FcommonModules%2Fshared%2FpublicUi%2FonlineNotification%2Fflow%2FUnifiedNotificationFlow.xml&adf.tfId=UnifiedNotificationFlow`;
    status = status.GLOBAL_STATUS_DETAILS;
  }

  switch (status) {
    case 'JR_DRAFT':
    case 'JR_APPROVAL_REJECTED':
    case 'JR_CANCELED':
    case 'JR_CANDIDATE_INIT':
      result = '';
      break;
    case 'JR_APPROVAL_PENDING':
      result = (
        <>
          {jobReq.NEXT_APPROVER_ID == getCurrentUserId() ? (
            <>
              <div style={{ fontWeight: '700' }}>
                {buildOpenModalLink(
                  handlerOpenModal,
                  urlApproveJobReq,
                  t('details_req_action_approuve_job_req'),
                )}
              </div>
            </>
          ) : null}
        </>
      );
      break;

    case 'JR_CANDIDATE_START':
    case 'SELECTION_START':
      result = buildOpenModalLink(
        handlerOpenModal,
        urlAccessCareerSite,
        t('details_req_action_access_internal_posting'),
      );
      break;

    case 'SELECTION_MGR':
      result = buildOpenModalLink(
        handlerOpenModal,
        urlAccessCandidates,
        t('details_req_action_access_candidates'),
      );
      break;
    case 'OFFER_DRAFT':
      result = (
        <>
          <div>
            {buildOpenModalLink(
              handlerOpenModal,
              urlAccessCandidates,
              t('details_req_action_access_candidates'),
            )}
          </div>
        </>
      );
      break;
    case 'OFFER_START':
      result = (
        <>
          <div>
            {buildOpenModalLink(
              handlerOpenModal,
              urlAccessCandidates,
              t('details_req_action_access_candidates'),
            )}
          </div>
        </>
      );
      break;
    case 'OFFER_REJECTED':
    case 'OFFER_ACCEPTED':
      result = (
        <>
          <div>
            {buildOpenModalLink(
              handlerOpenModal,
              urlAccessCandidates,
              t('details_req_action_access_candidates'),
            )}
          </div>
        </>
      );
      break;
    case 'ONBOARDING_START':
      result = buildOpenModalLink(
        handlerOpenModal,
        urlAccessCandidates,
        t('details_req_action_access_candidates'),
      );
      break;
    case 'ONBOARDING_COMPLETED':
      result = buildOpenModalLink(
        handlerOpenModal,
        urlAccessCandidates,
        t('details_req_action_access_candidates'),
      );
      break;
    case 'EMPLOYEE':
      result = '';
      break;
    case 'CANDIDATE_ARRIVE':
      result = '';
      break;
    default:
      result = 'default';
      break;
  }
  return result;
};

const buildOpenModalLink = (openModal, url, text) => {
  return (
    <a href={url} className="trigger" onClick={openModal} id={url} data-linkonly="true">
      <i className="dropdown-icon fe fe-link" />
      {text}
    </a>
  );
};

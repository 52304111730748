import React, { useEffect, useState } from 'react';
import { Modal, Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EAPStatus } from '../../../../../api/HCMProfile/requests/EAPRequester';
import { ExclamationTriangleFill, InfoCircle } from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';

const SigningModal = ({
  updateEAPStatus,
  wasEmployeeAbsent,
  isManager,
  isMatrixManager,
  missingFields = [],
  ...props
} = props) => {
  const [mode, setMode] = useState(SigningMode.NONE);
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (missingFields?.length > 0) {
      setMode(SigningMode.MANAGER_MISSING_FIELDS);
    } else {
      if (isManager) {
        setMode(SigningMode.MANAGER_OK);
      } else {
        setMode(SigningMode.EMPLOYEE_OK);
      }
    }
  }, [missingFields, isManager]);

  const validateManager = () => {
    if (wasEmployeeAbsent) {
      updateEAPStatus(EAPStatus.COMPLETED_ABSENT, true);
    } else {
      updateEAPStatus(EAPStatus.COMPLETED, true);
    }
    props.onHide();

    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 500);
  };

  const validateMatrixManager = () => {
    props.onHide();

    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 500);
  };

  const validateEmployee = () => {
    updateEAPStatus(EAPStatus.WRK_PREPARATION, false);
    props.onHide();
    setTimeout(() => {
      history.push('/performance/');
    }, 1000);
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop={'static'}
      dialogClassName="modal-25w"
      centered
      id="signing_modal_container"
    >
      <Card id="signing_modal_card">
        <Card.Body>
          <>
            {isManager ? (
              <>
                {mode == SigningMode.MANAGER_MISSING_FIELDS && (
                  <>
                    <div className="text-center mb-10">
                      <ExclamationTriangleFill color="red" size={46} />
                    </div>
                    <p className="font-weight-bold">
                      {t('eap_validate_popup_missing_evaluator_fields')}
                    </p>
                    {missingFields?.length > 0 && (
                      <>
                        {missingFields.map((section, i) => {
                          return <p key={i}>{section.section_name}</p>;
                        })}
                      </>
                    )}
                    <OkButtons onHide={props.onHide} />
                  </>
                )}
                {mode == SigningMode.MANAGER_OK && (
                  <>
                    <p>{t('popupCloseEDI')}</p>
                    <ConfirmationButtons
                      validate={validateManager}
                      onHide={props.onHide}
                    />
                  </>
                )}
              </>
            ) : isMatrixManager ? (
              <>
                <div className="text-center mb-10">
                  <InfoCircle color="green" size={46} />
                </div>
                <p className="text-center font-weight-bold">
                  {t('popupCloseEAPMatrixManager')}
                </p>
                <ValidateMatrixManagerButtons
                  validate={validateMatrixManager}
                  onHide={props.onHide}
                />
              </>
            ) : (
              <>
                {mode == SigningMode.EMPLOYEE_OK && (
                  <>
                    <div className="text-center mb-10">
                      <InfoCircle color="green" size={46} />
                    </div>
                    <p className="font-weight-bold">{t('popupCloseEDIEmployee')}</p>
                    <p>{t('popupCloseEAPEmployeeInfo')}</p>
                    <ValidateEmployeeButtons
                      validate={validateEmployee}
                      onHide={props.onHide}
                    />
                  </>
                )}
              </>
            )}
          </>
        </Card.Body>
      </Card>
    </Modal>
  );
};

export default SigningModal;

export const SigningMode = {
  NONE: 0,
  EMPLOYEE_OK: 1,
  EMPLOYEE_MISSING_FIELDS: 2,
  MANAGER_OK: 3,
  MANAGER_MISSING_FIELDS: 4,
};

const ConfirmationButtons = ({ validate, onHide }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="text-center">
        <Button className="mr-10 w100" onClick={() => onHide()} type="submit">
          {t('no')}
        </Button>
        <Button className="mr-10 w100" onClick={() => validate()}>
          {t('yes')}
        </Button>
      </div>
    </>
  );
};

const ValidateEmployeeButtons = ({ validate }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="text-center">
        <Button className="mr-10 w100" onClick={() => validate()} type="submit">
          {t('Close')}
        </Button>
      </div>
    </>
  );
};

const ValidateMatrixManagerButtons = ({ validate }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="text-center">
        <Button className="mr-10 w100" onClick={() => validate()} type="submit">
          {t('Close')}
        </Button>
      </div>
    </>
  );
};

const OkButtons = ({ onHide }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="text-center">
        <Button className="mr-10 w100" onClick={() => onHide()} type="submit">
          {t('Close')}
        </Button>
      </div>
    </>
  );
};

import moment from 'moment';
import {
  getEmployeeEAPEvaluatorId,
  getEmployeeEAPMatrixManagerId,
  getEmployeeProfileId,
  getManagerPersonId,
} from '../../../model/employee';
import { DATE_FORMAT_ISO, getTodayDate } from '../../../utils/data';
import { getCurrentEnv, getCurrentUserId } from '../../manager';
import { GenericDecoder } from '../common/GenericDecoder';
import {
  EAP_MANAGER_SECTION_IDS,
  EAP_SECTION_IDS,
} from '../common/section_ids_configuration';
import {
  buildProperty,
  constructFilterPersonIds,
  constructFilterSectionId,
  constructFilterSectionIdSecured,
  constructFilterSectionIdSecuredManager,
  decodeBase64,
  getDateFromEntry,
  isProfileItemIdEmpty,
  prepareBase64JsonPayload,
} from '../helpers';
import { MissionAndMainActivityDecoder } from './MissionAndMainActivityRequester';

const createEmptyEAP = requestData => {
  const [personId, , , ,] = requestData.destructure();
  const profileId = getEmployeeProfileId(personId);

  console.info('--- Debug EAP POST data');
  console.info('- Force Create Empty EAP');
  console.info(`- personId: ${personId}`);
  console.info(`- profileId: ${profileId}`);

  const requestBody = `<soapenv:Envelope
  xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
  xmlns:typ="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/types/"
  xmlns:pub="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/"
  xmlns:prof="http://xmlns.oracle.com/apps/hcm/profiles/core/flex/profileItem/">
  <soapenv:Header/>
  <soapenv:Body>
    <typ:mergeProfile>
      <typ:profile>
        <pub:ProfileId>${profileId}</pub:ProfileId>
        // ***** EMPLOYEE
        <pub:ProfileItem>
          <pub:ProfileId>${profileId}</pub:ProfileId>
          ${EAPDecoder.getRequestHeader(personId)}
          ${getDateFromEntry()}
          ${getLastUpdateData()}
          ${getStatus(EAPStatus.NOT_STARTED)}
          ${getEvaluatorSourceId(personId)}
          ${getMatrixManagerId(personId)}
          ${getManagerSourceId(personId)}
        </pub:ProfileItem>
        // ***** MANAGER 
        <pub:ProfileItem>
          <pub:ProfileId>${profileId}</pub:ProfileId>
          ${EAPManagerDecoder.getRequestHeader(personId)}
          ${getDateFromEntry()}
          ${getLastUpdateData()}
          ${getStatus(EAPStatus.NOT_STARTED)}
          ${getEvaluatorSourceId(personId)}
          ${getMatrixManagerId(personId)}
          ${getManagerSourceId(personId)}
        </pub:ProfileItem>
      </typ:profile>
    </typ:mergeProfile>
  </soapenv:Body>
</soapenv:Envelope>`;
  return requestBody;
};

const setGoalsData = requestData => {
  const [personId, profileItemIdEmployee, profileItemIdManager, EAP] =
    requestData.destructure();
  const { goalsData, goalsGlobalEvalData, ratingValue, ratingType } = EAP;
  const profileId = getEmployeeProfileId(personId);
  const isForManagerEAP =
    getEmployeeEAPEvaluatorId(personId) == getCurrentUserId() ||
    getEmployeeEAPMatrixManagerId(personId) == getCurrentUserId();

  console.info('--- Debug EAP POST data (SAVE GOALS + Rating)');
  console.info(`- PersonId: ${personId}`);
  console.info(`- ProfileId: ${profileId}`);
  console.info(`- profileItemIdEmployee: ${profileItemIdEmployee}`);
  console.info(`- ProfileItemIdManager: ${profileItemIdManager}`);

  const requestBody = `<soapenv:Envelope
  xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
  xmlns:typ="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/types/"
  xmlns:pub="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/"
  xmlns:prof="http://xmlns.oracle.com/apps/hcm/profiles/core/flex/profileItem/">
  <soapenv:Header/>
  <soapenv:Body>
    <typ:mergeProfile>
      <typ:profile>
        <pub:ProfileId>${profileId}</pub:ProfileId>
        <pub:ProfileItem>
          <pub:ProfileId>${profileId}</pub:ProfileId>
          ${
            isForManagerEAP
              ? `${EAPManagerDecoder.getRequestHeader(personId)}
                   ${
                     isProfileItemIdEmpty(profileItemIdManager) ? getDateFromEntry() : ''
                   }`
              : `${EAPDecoder.getRequestHeader(personId)}
                  ${
                    isProfileItemIdEmpty(profileItemIdEmployee) ? getDateFromEntry() : ''
                  }`
          }
          ${getEvaluatorSourceId(personId)}
          ${getMatrixManagerId(personId)}
          ${getManagerSourceId(personId)}
          ${buildProperty('ItemClob2File', goalsData)}
          ${buildProperty('ItemClob3File', goalsGlobalEvalData)}
          ${getRating(ratingValue, ratingType)}
        </pub:ProfileItem>
      </typ:profile>
    </typ:mergeProfile>
  </soapenv:Body>
</soapenv:Envelope>`;
  return requestBody;
};

const setRating = requestData => {
  const [personId, , profileItemIdManager, EAP] = requestData.destructure();
  const { creationDate, rating, type } = EAP;
  const profileId = getEmployeeProfileId(personId);

  console.info('--- Debug EAP POST data (SET RATING)');
  console.info(`- PersonId: ${personId}`);
  console.info(`- ProfileId: ${profileId}`);
  console.info(`- ProfileItemIdManager: ${profileItemIdManager}`);
  console.info(`- Rating: ${rating} - Type: ${type}`);

  const requestBody = `<soapenv:Envelope
  xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
  xmlns:typ="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/types/"
  xmlns:pub="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/"
  xmlns:prof="http://xmlns.oracle.com/apps/hcm/profiles/core/flex/profileItem/">
  <soapenv:Header/>
  <soapenv:Body>
    <typ:mergeProfile>
      <typ:profile>
        <pub:ProfileId>${profileId}</pub:ProfileId>
        <pub:ProfileItem>
          <pub:ProfileId>${profileId}</pub:ProfileId>
          ${EAPManagerDecoder.getRequestHeader(personId)}
          ${creationDate ? `<pub:DateFrom>${creationDate}</pub:DateFrom>` : ''}
          ${getEvaluatorSourceId(personId)}
          ${getMatrixManagerId(personId)}
          ${getManagerSourceId(personId)}
          ${rating ? getRating(rating, type) : ''}
        </pub:ProfileItem>
      </typ:profile>
    </typ:mergeProfile>
  </soapenv:Body>
</soapenv:Envelope>`;
  return requestBody;
};

const updateStatus = requestData => {
  const [personId, profileItemIdEmployee, profileItemIdManager, EAP] =
    requestData.destructure();
  const { status, action } = EAP;
  const profileId = getEmployeeProfileId(personId);

  console.info('--- Debug EAP POST data (UPDATE STATUS)');
  console.info(`- PersonId: ${personId}`);
  console.info(`- ProfileId: ${profileId}`);
  console.info(`- ProfileItemIdEmployee: ${profileItemIdEmployee}`);
  console.info(`- ProfileItemIdManager: ${profileItemIdManager}`);
  console.info(`- Status: ${status}`);
  if (action != null) {
    console.info(`- Action: ${action}`);
  }
  const canSaveManagerSection = getEmployeeEAPEvaluatorId(personId) == getCurrentUserId();

  const requestBody = `<soapenv:Envelope
  xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
  xmlns:typ="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/types/"
  xmlns:pub="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/"
  xmlns:prof="http://xmlns.oracle.com/apps/hcm/profiles/core/flex/profileItem/">
  <soapenv:Header/>
  <soapenv:Body>
    <typ:mergeProfile>
      <typ:profile>
        <pub:ProfileId>${profileId}</pub:ProfileId>
        <pub:ProfileItem>
          <pub:ProfileId>${profileId}</pub:ProfileId>
          ${EAPDecoder.getRequestHeader(personId)}
          ${isProfileItemIdEmpty(profileItemIdEmployee) ? getDateFromEntry() : ''}
          ${getEvaluatorSourceId(personId)}
          ${getMatrixManagerId(personId)}
          ${getManagerSourceId(personId)}
          ${getStatus(status)}
          ${action ? getAction(action) : ''}
          ${getLastUpdateData()}
        </pub:ProfileItem>
        // ***** MANAGER 
          ${
            canSaveManagerSection
              ? getManagerSectionData(
                  personId,
                  profileId,
                  profileItemIdManager,
                  null, // no payload
                  status,
                )
              : ''
          }
      </typ:profile>
    </typ:mergeProfile>
  </soapenv:Body>
</soapenv:Envelope>`;
  return requestBody;
};

const transferDocument = requestData => {
  const [personId, profileItemIdEmployee, profileItemIdManager, payload] =
    requestData.destructure();
  const { managerId } = payload;
  const profileId = getEmployeeProfileId(personId);

  console.info('--- Debug EAP POST data');
  console.info('- EAP Payload: Transfer');

  console.info(`- PersonId: ${personId}`);
  console.info(`- ProfileId: ${profileId}`);
  console.info(`- ProfileItemIdEmployee: ${profileItemIdEmployee}`);
  console.info(`- New ManagerId: ${managerId}`);

  const requestBody = `<soapenv:Envelope
  xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
  xmlns:typ="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/types/"
  xmlns:pub="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/"
  xmlns:prof="http://xmlns.oracle.com/apps/hcm/profiles/core/flex/profileItem/">
  <soapenv:Header/>
  <soapenv:Body>
    <typ:mergeProfile>
      <typ:profile>
        <pub:ProfileId>${profileId}</pub:ProfileId>
        <pub:ProfileItem>
          <pub:ProfileId>${profileId}</pub:ProfileId>
          ${EAPDecoder.getRequestHeader(personId)}
          ${isProfileItemIdEmpty(profileItemIdEmployee) ? getDateFromEntry() : ''}
          <pub:SourceId>${managerId}</pub:SourceId>
          ${getAction('TRANFER')}
          ${getLastUpdateData()}
        </pub:ProfileItem>
        <pub:ProfileItem>
          <pub:ProfileId>${profileId}</pub:ProfileId>
          ${EAPManagerDecoder.getRequestHeader(personId)}
          ${isProfileItemIdEmpty(profileItemIdManager) ? getDateFromEntry() : ''}
          <pub:SourceId>${managerId}</pub:SourceId>
          ${getMatrixManagerId(personId)}
          ${getManagerSourceId(personId)}
          ${getAction('TRANFER')}
          ${getLastUpdateData()}
        </pub:ProfileItem>
      </typ:profile>
    </typ:mergeProfile>
  </soapenv:Body>
</soapenv:Envelope>`;
  return requestBody;
};

const createOrUpdate = requestData => {
  const [personId, profileItemIdEmployee, profileItemIdManager, EAP] =
    requestData.destructure();
  const { creationDate, eapPayload, status, ratingValue, ratingType } = EAP;
  const profileId = getEmployeeProfileId(personId);

  const canSaveManagerSection =
    getEmployeeEAPEvaluatorId(personId) == getCurrentUserId() ||
    getEmployeeEAPMatrixManagerId(personId) == getCurrentUserId();

  console.info('--- Debug EAP POST data');
  if (eapPayload == null) {
    console.info('- EAP Payload: null / Transfer');
  }
  console.info(`- PersonId: ${personId}`);
  console.info(`- ProfileId: ${profileId}`);
  console.info(`- ProfileItemIdEmployee: ${profileItemIdEmployee}`);
  console.info(`- ProfileItemIdManager: ${profileItemIdManager}`);
  console.info(`- Status: ${status}`);
  console.info(`- canSaveManagerSection: ${canSaveManagerSection}`);
  console.info(`- creationDate: ${creationDate}`);

  const requestBody = `<soapenv:Envelope
  xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
  xmlns:typ="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/types/"
  xmlns:pub="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/"
  xmlns:prof="http://xmlns.oracle.com/apps/hcm/profiles/core/flex/profileItem/">
  <soapenv:Header/>
  <soapenv:Body>
    <typ:mergeProfile>
      <typ:profile>
        <pub:ProfileId>${profileId}</pub:ProfileId>
        // ***** EMPLOYEE
        <pub:ProfileItem>
          <pub:ProfileId>${profileId}</pub:ProfileId>
          ${EAPDecoder.getRequestHeader(personId)}
          ${isProfileItemIdEmpty(profileItemIdEmployee) ? getDateFromEntry() : ''}
          ${
            eapPayload && !canSaveManagerSection
              ? `<pub:ItemClob1File>${eapPayload}</pub:ItemClob1File>`
              : ''
          }
          ${getEvaluatorSourceId(personId)}
          ${getMatrixManagerId(personId)}
          ${getManagerSourceId(personId)}
          ${getLastUpdateData()}
          ${status ? getStatus(status) : ''}
        </pub:ProfileItem>
        // ***** MANAGER 
        ${
          canSaveManagerSection
            ? getManagerSectionData(
                personId,
                profileId,
                profileItemIdManager,
                eapPayload,
                status,
                getRating(ratingValue, ratingType),
              )
            : ''
        }
      </typ:profile>
    </typ:mergeProfile>
  </soapenv:Body>
</soapenv:Envelope>`;
  return requestBody;
};

const getByPersonIds = personIds => {
  if (!Array.isArray(personIds)) {
    personIds = [personIds];
  }

  console.info('--- Debug EAP GET data');
  console.info(`- PersonIds: ${personIds.join(',')}`);
  const requestBody = `<soapenv:Envelope
  xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
  xmlns:typ="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/types/"
  xmlns:typ1="http://xmlns.oracle.com/adf/svc/types/">
  <soapenv:Header/>
  <soapenv:Body>
      <typ:findProfile>
          <typ:findCriteria>
              <typ1:fetchStart>0</typ1:fetchStart>
              <typ1:fetchSize>-1</typ1:fetchSize>
              <typ1:filter>
                  <typ1:group>
                      <typ1:conjunction>And</typ1:conjunction>
                      <typ1:upperCaseCompare>true</typ1:upperCaseCompare>
                      ${constructFilterPersonIds(personIds)}
                  </typ1:group>
              </typ1:filter>
              <typ1:childFindCriteria>
                  <typ1:fetchStart>0</typ1:fetchStart>
                  <typ1:fetchSize>-1</typ1:fetchSize>
                  <typ1:filter>
                      <typ1:conjunction>And</typ1:conjunction>
                      <typ1:group>
                          <typ1:conjunction>Or</typ1:conjunction>
                          <typ1:upperCaseCompare>false</typ1:upperCaseCompare>
                          ${constructFilterSectionId(EAPDecoder.SECTION_ID)}
                          ${constructFilterSectionId(
                            MissionAndMainActivityDecoder.SECTION_ID,
                          )}
                      </typ1:group>
                      <typ1:group>
                          <typ1:conjunction>Or</typ1:conjunction>
                          <typ1:upperCaseCompare>false</typ1:upperCaseCompare>
                          ${constructFilterSectionIdSecured(EAPManagerDecoder.SECTION_ID)}
                      </typ1:group>
                      <typ1:group>
                      <typ1:conjunction>Or</typ1:conjunction>
                          <typ1:upperCaseCompare>false</typ1:upperCaseCompare>
                          ${constructFilterSectionIdSecuredManager(
                            EAPManagerDecoder.SECTION_ID,
                            getCurrentUserId(),
                          )}
                      </typ1:group>
                  </typ1:filter>
                  <typ1:childAttrName>ProfileItem</typ1:childAttrName>
              </typ1:childFindCriteria>
              <typ1:findAttribute>ProfileId</typ1:findAttribute>
              <typ1:findAttribute>ProfileItem</typ1:findAttribute>
              <typ1:findAttribute>PersonId</typ1:findAttribute>
          </typ:findCriteria>
          <typ:findControl>
              <typ1:retrieveAllTranslations>false</typ1:retrieveAllTranslations>
          </typ:findControl>
      </typ:findProfile>
  </soapenv:Body>
</soapenv:Envelope>`;
  return requestBody;
};

const EAPRequester = {
  createOrUpdate: createOrUpdate,
  createEmptyEAP: createEmptyEAP,
  getByPersonIds: getByPersonIds,
  transferDocument: transferDocument,
  updateStatus: updateStatus,
  setRating: setRating,
  setGoalsData: setGoalsData,
};

export class EAPRequestData {
  constructor(personId, profileItemIdEmployee, profileItemIdManager, payload) {
    this._personId = personId;
    this._profileItemIdEmployee = profileItemIdEmployee;
    this._profileItemIdManager = profileItemIdManager;
    this._payload = payload;
  }

  get personId() {
    return this._personId;
  }

  destructure() {
    return [
      this._personId,
      this._profileItemIdEmployee,
      this._profileItemIdManager,
      this._payload,
    ];
  }
}

export class EAPDecoder extends GenericDecoder {
  static SECTION_ID = EAP_SECTION_IDS[getCurrentEnv()];
  static NAME = 'EAP_DOCUMENT';
  static ATTRIBUTES = {
    'ns1:ProfileItemId': { key: 'profileItemId' },
    'ns1:DateFrom': { key: 'date' },
    'ns1:SourceId': { key: 'managerId' },
    'ns1:SourceKey1': { key: 'matrixManagerId' },
    'ns1:SourceKey2': { key: 'baseManagerId' },
    'ns1:ItemText301': { key: 'status' },
    'ns1:ItemClob1File': {
      key: 'eapPayload',
      prepare: x => prepareBase64JsonPayload(x),
    },
    'ns1:ItemClob2File': {
      key: 'eapGoalsContent',
      prepare: x => {
        if (x == null) {
          return {};
        } else {
          return JSON.parse(decodeBase64(x));
        }
      },
    },
    'ns1:ItemClob3File': {
      key: 'eapGoalsGlobalEvalContent',
      prepare: x => {
        if (x == null) {
          return {};
        } else {
          return JSON.parse(decodeBase64(x));
        }
      },
    },
  };

  static getContentType() {
    return 'ACCOMPLISHMENT';
  }

  static getSectionName() {
    return `${EAPDecoder.getContentType()}_${EAPDecoder.SECTION_ID}`;
  }

  static getRequestHeader(personId) {
    return `<pub:ContentType>${EAPDecoder.getContentType()}</pub:ContentType>
            <pub:SectionName>${EAPDecoder.getSectionName()}</pub:SectionName>
            ${EAPDecoder.getRequestSourceHeader(personId)}`;
  }

  static getRequestSourceHeader(personId) {
    return `<pub:SourceSystemId>REACT_${personId}_EMPLOYEE</pub:SourceSystemId>
            <pub:SourceSystemOwner>PSFT</pub:SourceSystemOwner>`;
  }
}

export class EAPManagerDecoder extends GenericDecoder {
  static SECTION_ID = EAP_MANAGER_SECTION_IDS[getCurrentEnv()];
  static NAME = 'EAP_DOCUMENT_MANAGER';
  static ATTRIBUTES = {
    'ns1:ProfileItemId': { key: 'profileItemId' },
    'ns1:DateFrom': { key: 'date' },
    'ns1:SourceId': { key: 'managerId' },
    'ns1:SourceKey1': { key: 'matrixManagerId' },
    'ns1:SourceKey2': { key: 'baseManagerId' },
    'ns1:ItemText301': { key: 'status' },
    'ns1:ItemText3010': { key: 'rating' },
    'ns1:ItemText3011': { key: 'ratingComp' },
    'ns1:ItemClob1File': {
      key: 'eapPayload',
      prepare: x => {
        if (x == null) {
          return {};
        } else {
          return JSON.parse(decodeBase64(x));
        }
      },
    },
    'ns1:ItemClob2File': {
      key: 'eapGoalsContent',
      prepare: x => {
        if (x == null) {
          return {};
        } else {
          return JSON.parse(decodeBase64(x));
        }
      },
    },
    'ns1:ItemClob3File': {
      key: 'eapGoalsGlobalEvalContent',
      prepare: x => {
        if (x == null) {
          return {};
        } else {
          return JSON.parse(decodeBase64(x));
        }
      },
    },
  };

  static getContentType() {
    return 'ACCOMPLISHMENT';
  }

  static getSectionName() {
    return `${EAPManagerDecoder.getContentType()}_${EAPManagerDecoder.SECTION_ID}`;
  }

  static getRequestHeader(personId) {
    return `<pub:ContentType>${EAPManagerDecoder.getContentType()}</pub:ContentType>
            <pub:SectionName>${EAPManagerDecoder.getSectionName()}</pub:SectionName>
            ${EAPManagerDecoder.getRequestSourceHeader(personId)}`;
  }

  static getRequestSourceHeader(personId) {
    return `<pub:SourceSystemId>REACT_${personId}_MANAGER</pub:SourceSystemId>
            <pub:SourceSystemOwner>PSFT</pub:SourceSystemOwner>`;
  }
}

const getLastUpdateData = () => {
  const today = moment().format(DATE_FORMAT_ISO);
  const currentUserId = getCurrentUserId();
  return `
    // Last Updated by
    <pub:ItemDate9>${today}</pub:ItemDate9>
    //Last Updated by
    <pub:ItemText308>${currentUserId}</pub:ItemText308>
  `;
};

const getAction = action => {
  const today = moment().format(DATE_FORMAT_ISO);
  return `
    //ACTION
    <pub:ItemText302>${action}</pub:ItemText302>
    //Action Date
    <pub:ItemDate8>${today}</pub:ItemDate8>`;
};

const getStatus = status => {
  const today = moment().format(DATE_FORMAT_ISO);
  return `
    //Status (NOT_STARTED, IN_PROGRESS, WRK_PREPARATION, COMPLETED)
    <pub:ItemText301>${status}</pub:ItemText301>
    //Status Date
    <pub:ItemDate7>${today}</pub:ItemDate7>`;
};

const getRating = (rating, type) => {
  if (rating == null || type == null) {
    return '';
  } else if (type == 'performanceGoal') {
    return `<pub:ItemText3010>${rating}</pub:ItemText3010>`;
  } else {
    return `<pub:ItemText3011>${rating}</pub:ItemText3011>`;
  }
};

export default EAPRequester;

export const EAPStatus = {
  INELIGIBLE: 'INELIGIBLE',
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  WRK_PREPARATION: 'WRK_PREPARATION',
  COMPLETED: 'COMPLETED',
  COMPLETED_ABSENT: 'COMPLETED_ABSENT',
  NOT_COMPLETED: 'NOT_COMPLETED',
};

const getManagerSectionData = (
  personId,
  profileId,
  profileItemId,
  eapPayload,
  status,
  additionalProperties = null, // inject additional soap properties
) => {
  let today = null;
  if (profileItemId == null || profileItemId == '') {
    today = getTodayDate(DATE_FORMAT_ISO);
  }
  const data = `
        <pub:ProfileItem>
          <pub:ProfileId>${profileId}</pub:ProfileId>
          ${EAPManagerDecoder.getRequestHeader(personId)}
          ${today ? `<pub:DateFrom>${today}</pub:DateFrom>` : ''}
          ${eapPayload ? `<pub:ItemClob1File>${eapPayload}</pub:ItemClob1File>` : ''}
          ${getMatrixManagerId(personId)}
          ${getManagerSourceId(personId)}
          ${getLastUpdateData()}
          ${status ? getStatus(status) : ''}
          ${getEvaluatorSourceId(personId)}
          ${additionalProperties ? additionalProperties : ''}
        </pub:ProfileItem>`;
  return data;
};

const getEvaluatorSourceId = personId => {
  const evaluatorId = getEmployeeEAPEvaluatorId(personId);
  if (evaluatorId == null) {
    return '// unknown evaluator id';
  } else {
    return `<pub:SourceId>${evaluatorId}</pub:SourceId> // evaluator id`;
  }
};

const getMatrixManagerId = personId => {
  const matrixManagerId = getEmployeeEAPMatrixManagerId(personId);
  if (matrixManagerId == null) {
    return '// unknown matrix manager id';
  } else {
    return `<pub:SourceKey1>${matrixManagerId}</pub:SourceKey1> // matrix manager id`;
  }
};

const getManagerSourceId = personId => {
  const managerId = getManagerPersonId(personId);
  if (managerId == null) {
    return '// unknow manager id';
  } else {
    return `<pub:SourceKey2>${managerId}</pub:SourceKey2> // manager id`;
  }
};

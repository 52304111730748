export const setProfessionalProjects = e => dispatch => {
  dispatch({
    type: 'SET_PROFESSIONAL_PROJECTS',
    payload: e,
  });
};

export const setEmployeesProfileData = e => dispatch => {
  dispatch({
    type: 'SET_EMPLOYEES_PROFILE_DATA',
    payload: e,
  });
};

export const setEmployeesEAPProfileData = e => dispatch => {
  dispatch({
    type: 'SET_EMPLOYEES_EAP_PROFILE_DATA',
    payload: e,
  });
};

export const setDevelopmentGoals = e => dispatch => {
  dispatch({
    type: 'SET_DEVELOPMENT_GOALS',
    payload: e,
  });
};

export const setEDIs = e => dispatch => {
  dispatch({
    type: 'SET_EDIS',
    payload: e,
  });
};

export const setEAPs = e => dispatch => {
  dispatch({
    type: 'SET_EAPS',
    payload: e,
  });
};

export const setEPRs = e => dispatch => {
  dispatch({
    type: 'SET_EPRS',
    payload: e,
  });
};

export const setEmployeesEDIData = e => dispatch => {
  dispatch({
    type: 'SET_EMPLOYEES_EDI_DATA',
    payload: e,
  });
};

export const setPerformanceGoals = e => dispatch => {
  dispatch({
    type: 'SET_PERFORMANCE_GOALS',
    payload: e,
  });
};

export const setEmployeesEAPData = e => dispatch => {
  dispatch({
    type: 'SET_EMPLOYEES_EAP_DATA',
    payload: e,
  });
};

export const setEAPFeedbacks = e => dispatch => {
  dispatch({
    type: 'SET_EAP_FEEDBACK',
    payload: e,
  });
};

export const setJimmyEligibility = e => dispatch => {
  dispatch({
    type: 'SET_JIMMY_ELIGIBILITY',
    payload: e,
  });
};

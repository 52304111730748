import { getCurrentEnv } from '../../manager';
import { GenericDecoder } from '../common/GenericDecoder';
import { EPR_COLLAB_SECTION_IDS } from '../common/section_ids_configuration';
import {
  constructFilterPersonIds,
  constructFilterSectionId,
  decodeBase64,
  encodeBase64,
} from '../helpers';

const getByPersonId = personId => {
  const requestBody = `<soapenv:Envelope
  xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
  xmlns:typ="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/types/"
  xmlns:typ1="http://xmlns.oracle.com/adf/svc/types/">
  <soapenv:Header/>
  <soapenv:Body>
      <typ:findProfile>
          <typ:findCriteria>
              <typ1:fetchStart>0</typ1:fetchStart>
              <typ1:fetchSize>-1</typ1:fetchSize>
              <typ1:filter>
                  <typ1:group>
                      <typ1:conjunction>And</typ1:conjunction>
                      <typ1:upperCaseCompare>true</typ1:upperCaseCompare>
                      ${constructFilterPersonIds([personId])}
                  </typ1:group>
              </typ1:filter>
              <typ1:childFindCriteria>
                  <typ1:fetchStart>0</typ1:fetchStart>
                  <typ1:fetchSize>-1</typ1:fetchSize>
                  <typ1:filter>
                      <typ1:conjunction>And</typ1:conjunction>
                      <typ1:group>
                          <typ1:conjunction>And</typ1:conjunction>
                          <typ1:upperCaseCompare>false</typ1:upperCaseCompare>
                          ${constructFilterSectionId(EPRDecoder.SECTION_ID)}
                      </typ1:group>
                  </typ1:filter>
                  <typ1:childAttrName>ProfileItem</typ1:childAttrName>
              </typ1:childFindCriteria>
              <typ1:findAttribute>ProfileId</typ1:findAttribute>
              <typ1:findAttribute>ProfileItem</typ1:findAttribute>
              <typ1:findAttribute>PersonId</typ1:findAttribute>
          </typ:findCriteria>
          <typ:findControl>
              <typ1:retrieveAllTranslations>false</typ1:retrieveAllTranslations>
          </typ:findControl>
      </typ:findProfile>
  </soapenv:Body>
</soapenv:Envelope>`;
  return requestBody;
};

const update = (profileId, profileItemId, payload) => {
  let requestBody = '';
  try {
    const encodedBody = encodeBase64(JSON.stringify(payload));
    requestBody = `<soapenv:Envelope
      xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
      xmlns:typ="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/types/"
      xmlns:pub="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/"
      xmlns:prof="http://xmlns.oracle.com/apps/hcm/profiles/core/flex/profileItem/"
      xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
      <soapenv:Header/>
      <soapenv:Body>
        <typ:mergeProfile>
          <typ:profile>
            <pub:ProfileId>${profileId}</pub:ProfileId>
            ${EPRDecoder.getRequestHeader()}
            <pub:ProfileItem>
              <pub:ProfileId>${profileId}</pub:ProfileId>
              <pub:ProfileItemId>${profileItemId}</pub:ProfileItemId>
              <pub:ItemClob1File>${encodedBody}</pub:ItemClob1File>
            </pub:ProfileItem>
          </typ:profile>
        </typ:mergeProfile>
      </soapenv:Body>
    </soapenv:Envelope>`;
  } catch (err) {
    console.error(err);
  }
  return requestBody;
};

const EPRRequester = {
  getByPersonId: getByPersonId,
  update: update,
};

export class EPRRequestData {
  constructor(personId, profileItemId, lastUpdateBy, payload) {
    this._personId = personId;
    this._profileItemId = profileItemId;
    this._payload = payload;
    this._lastUpdateBy = lastUpdateBy;
  }

  get personId() {
    return this._personId;
  }

  destructure() {
    return [this._personId, this._profileItemId, this._payload];
  }
}

export class EPRDecoder extends GenericDecoder {
  static SECTION_ID = EPR_COLLAB_SECTION_IDS[getCurrentEnv()];
  static NAME = 'EPR_DOCUMENT';
  static ATTRIBUTES = {
    'ns1:ProfileItemId': { key: 'profileItemId' },
    'ns1:DateFrom': { key: 'creationDate' },
    'ns1:ItemClob1File': {
      key: 'eprPayload',
      prepare: x => {
        if (x == null) {
          return {};
        } else {
          return JSON.parse(decodeBase64(x));
        }
      },
    },
  };

  static getContentType() {
    return 'ACCOMPLISHMENT';
  }

  static getSectionName() {
    return `${EPRDecoder.getContentType()}_${EPRDecoder.SECTION_ID}`;
  }

  static getRequestHeader() {
    return `<pub:ContentType>${EPRDecoder.getContentType()}</pub:ContentType>
                <pub:SectionName>${EPRDecoder.getSectionName()}</pub:SectionName>`;
  }
}

export default EPRRequester;

import { isProductionEnvironment } from '../api/manager';

export const openHCMMenu = ({ displayHROnly }) => {
  doOpenHCMMenu();

  if (displayHROnly) {
    let counter = 0;
    var intervalId = setInterval(() => {
      doOpenHCMMenuStep2(intervalId);
      counter += 1;
      if (counter >= 10) {
        console.warn('DEBUG: cannot open HCM Menu, clearing interval');
        clearInterval(intervalId);
        counter = 0;
      }
    }, 500);
  }
};

const doOpenHCMMenu = () => {
  console.warn('DEBUG: Opening HCM MENU step1');
  if (isProductionEnvironment()) {
    let element = getElement('pt1:_UISmmLink');
    if (element != undefined) {
      element.click();
    }
  } else {
    console.warn('DEBUG LOCAL: cannot open HCM Menu on local environment (step 1)');
  }
};

const doOpenHCMMenuStep2 = intervalId => {
  console.warn('DEBUG: Opening HCM MENU step2: ', intervalId);
  if (isProductionEnvironment()) {
    let clear = true;
    const groupWorkforceElement = getElement(
      'pt1:_UISnvr:0:nvgpgl1_groupNode_workforce_management',
    );
    const menuElement = getElement('pt1:_UISnvr:0:nv_npgl1');

    if (groupWorkforceElement && menuElement) {
      menuElement.after(groupWorkforceElement);
      menuElement.childNodes.forEach(n => {
        if (n.firstChild != null) {
          n.firstChild.remove();
        }
      });
      menuElement.style.height = '';
      getElement('pt1:_UISnvr:0:nv_pgl3').style.height = '';
    } else {
      clear = false;
    }

    if (clear) {
      console.warn('DEBUG (step2): clearing interval ', intervalId);
      clearInterval(intervalId);
    }
  } else {
    console.warn('DEBUG LOCAL: cannot HR open on local environment (step2)');
    clearInterval(intervalId);
  }
};

const getElement = id => {
  const element = document.getElementById(id);
  if (!element) {
    console.warn('DEBUG: cannot find element ', id);
  }
  return element;
};

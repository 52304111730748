import React from 'react';
import { Button } from 'react-bootstrap';
import { BsQuestionCircle as Help } from 'react-icons/bs';
import { HELP_PHONE, HELP_MAIL, HELP_WEBSITE } from '../../../utils/constants';
import { useTranslation } from 'react-i18next';

const HelpMenu = () => {
  const { t } = useTranslation();

  return (
    <div className="notification d-flex">
      <div className="dropdown d-flex">
        <Button variant="outline-secondary" data-toggle="dropdown">
          {t('help')} <Help size={20} />
        </Button>

        <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
          <a href="#" className="dropdown-item">
            <i className="dropdown-icon fe fe-phone" />
            {HELP_PHONE}
          </a>
          <div className="dropdown-divider" />
          <a href={`mailto:${HELP_MAIL}`} className="dropdown-item">
            <i className="dropdown-icon fe fe-mail" />
            {HELP_MAIL}
          </a>
          <a
            href={HELP_WEBSITE}
            target="_blank"
            rel="noreferrer"
            className="dropdown-item">
            <i className="dropdown-icon fe fe-external-link" />
            {t('help_website')}
          </a>
        </div>
      </div>
    </div>
  );
};
export default HelpMenu;

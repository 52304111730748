import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { fetchEmployeeTeam } from '../../../actions/apiActions';
import Shortcuts from './Shortcuts';
import MyTeam from './MyTeam';
import { getCurrentUserManagerRole, getCurrentUserId } from '../../../api/manager';
import { NATIXIS_LOGO_URL } from '../../../utils/constants';
import { fetchEmployeesProfileData } from '../../../actions/apiPerformanceActions';
import { useTranslation } from 'react-i18next';

const LeftSideBar = ({
  employees,
  employeesProfileData,
  dispatchFetchEmployeeTeam,
  dispatchFetchEmployeesProfileData,
}) => {
  const { t } = useTranslation();
  const [fetchingProfileData, setFetchingProfileData] = useState(false);
  const isManager = getCurrentUserManagerRole();

  useEffect(() => {
    if (isManager) {
      dispatchFetchEmployeeTeam();
    }
  }, [isManager, dispatchFetchEmployeeTeam]);

  useEffect(() => {
    if (!fetchingProfileData && employees.fetched && !employeesProfileData.fetched) {
      setFetchingProfileData(true);
      const ids = employees.data.map(e => e['PERSON_ID']);
      ids.push(getCurrentUserId());
      dispatchFetchEmployeesProfileData(ids);
    }
  }, [
    fetchingProfileData,
    employees,
    dispatchFetchEmployeesProfileData,
    employeesProfileData.fetched,
  ]);

  return (
    <div id="left-sidebar" className="sidebar">
      <NavLink to="/" className="header-brand">
        <img src={NATIXIS_LOGO_URL} alt="Logo Natixis" />
      </NavLink>
      <Shortcuts />
      <div
        id="left-sidebar-nav"
        className="sidebar-nav"
        style={{ marginTop: '30px', marginRight: '-8px' }}
      >
        {isManager ? <MyTeam employees={employees} /> : null}
        <div style={{ position: 'fixed', bottom: '0', margin: '10px' }}>
          <a href={t('rgpd_link')} target="_blank" rel="noreferrer">
            {t('rgpd').toUpperCase()}
          </a>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    employees: state.hcm.employeeTeam,
    employeesProfileData: state.performance.employeesProfileData,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchFetchEmployeeTeam: () => dispatch(fetchEmployeeTeam()),
  dispatchFetchEmployeesProfileData: personIds =>
    dispatch(fetchEmployeesProfileData(personIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftSideBar);

import React from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import NotFound from './components/ErrorPages/404';
import InternalServer from './components/ErrorPages/500';
import Home from './components/Home';

const App = () => {
  return (
    <div id="main_content" className="font-montserrat">
      <Router hashType="noslash">
        <Switch>
          <Route path="/notfound" component={NotFound} />
          <Route path="/internalserver" component={InternalServer} />
          <Route component={Home} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;

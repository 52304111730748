import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import DataTableCard from '../../common/DataTableCard';
import { buildColumns, constructFeedbacksMap, options } from './dataTableConfig';
import { useTranslation } from 'react-i18next';

const Feedbacks = ({ candidates }) => {
  const [feedbacksMap, setFeedbacksMap] = useState(new Map());
  const { t } = useTranslation();

  useEffect(() => {
    setFeedbacksMap(constructFeedbacksMap(candidates.data));
  }, [candidates]);

  const getFeedback = id => {
    return feedbacksMap[id];
  };

  return (
    <>
      <Row>
        <DataTableCard
          data={getActiveFeedbacks(candidates.data, t)}
          columns={buildColumns(getFeedback, t)}
          options={options}
          title={t('recruiting_feedbacks_active')}
        />
      </Row>
      <Row>
        <DataTableCard
          data={getInactiveFeedbacks(candidates.data, t)}
          columns={buildColumns(getFeedback, t)}
          options={options}
          title={t('recruiting_feedbacks_inactive')}
        />
      </Row>
    </>
  );
};

export default Feedbacks;

const getActiveFeedbacks = (data, t) => {
  return filterFeedbacks(
    data,
    t,
    f => f.OBJECT_STATUS == 'ORA_REQUESTED' || f.OBJECT_STATUS == 'ORA_SAVED',
  );
};

const getInactiveFeedbacks = (data, t) => {
  return filterFeedbacks(
    data,
    t,
    f => f.OBJECT_STATUS == 'ORA_COMPLETED' || f.OBJECT_STATUS == 'ORA_EXPIRED',
  );
};

const filterFeedbacks = (data, t, statusCondition) => {
  return data
    .filter(f => statusCondition(f))
    .map(f => {
      return [
        f.REQUISITION_NUMBER,
        f.REQUISITION_ID,
        f.TITLE,
        f.FEEDBACK_ID,
        f.CREATION_DATE,
        f.EXPIRY_DATE,
        `${f.CANDIDATE_FIRST_NAME} ${f.CANDIDATE_LAST_NAME}`,
        buildFeedbackLink(f, t),
      ];
    });
};

const buildFeedbackLink = (feedback, t) => {
  return (
    <>
      {feedback.FEEDBACK_URL &&
      (feedback.OBJECT_STATUS == 'ORA_REQUESTED' ||
        feedback.OBJECT_STATUS == 'ORA_SAVED') ? (
        <a href={feedback.FEEDBACK_URL} target="_blank" rel="noreferrer">
          <i className="dropdown-icon fe fe-external-link" />
          {t('recruiting_access_feedbacks_in_progress')}
        </a>
      ) : null}
      {feedback.FEEDBACK_URL && feedback.OBJECT_STATUS == 'ORA_COMPLETED' ? (
        <a href={feedback.FEEDBACK_URL} target="_blank" rel="noreferrer">
          <i className="dropdown-icon fe fe-external-link" />
          {t('recruiting_access_feedbacks')}
        </a>
      ) : null}
    </>
  );
};

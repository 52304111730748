import { TASK_OUTCOMES } from './types';
/**
 *
 * @param {string} taskId ID of the task (example: 2b0ca0d1-764b-49ba-829f-471900cb61e1)
 * @param {string} outcome Task decision must be APPROVE or REJECT
 * @returns {string} updateTaskOutcome SOAP Body
 */

export const getUpdateTaskOutcomeBody = (taskId, outcome) => {
  let body = ``;
  try {
    if (Object.values(TASK_OUTCOMES).indexOf(outcome) === -1)
      throw 'outcome must be APPROVE or REJECT';
    body = `
        <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tas="http://xmlns.oracle.com/bpel/workflow/taskService" xmlns:com="http://xmlns.oracle.com/bpel/workflow/common" xmlns:task="http://xmlns.oracle.com/bpel/workflow/task" xmlns:tas1="http://xmlns.oracle.com/bpel/workflow/TaskEvidenceService" xmlns:tas2="http://xmlns.oracle.com/bpel/workflow/taskError">
        <soapenv:Header/>
        <soapenv:Body>
            <tas:updateTaskOutcome>  
                <tas:taskId>${taskId}</tas:taskId>
                <tas:outcome>${outcome}</tas:outcome>
            </tas:updateTaskOutcome>
        </soapenv:Body>
        </soapenv:Envelope>
        `;
  } catch (error) {
    console.error('BPMTask/index.js => getUpdateTaskOutcomeBody() : ', error);
  }
  return body;
};

/**
 *
 * @param {string} taskId ID of the task (example: 2b0ca0d1-764b-49ba-829f-471900cb61e1)
 * @param {string} comment Outcome comment for the task
 * @returns {string} addCommentToTask SOAP Body
 */
export const getAddCommentToTaskBody = (taskId, comment) => {
  return `
    <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tas="http://xmlns.oracle.com/bpel/workflow/taskService" xmlns:com="http://xmlns.oracle.com/bpel/workflow/common" xmlns:task="http://xmlns.oracle.com/bpel/workflow/task" xmlns:tas1="http://xmlns.oracle.com/bpel/workflow/TaskEvidenceService" xmlns:tas2="http://xmlns.oracle.com/bpel/workflow/taskError">
    <soapenv:Header/>
    <soapenv:Body>
        <tas:addComment>
            <tas:taskId>${taskId}</tas:taskId>
            <tas:comment>${comment}</tas:comment>
        </tas:addComment>
    </soapenv:Body>
    </soapenv:Envelope>
    `;
};

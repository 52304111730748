import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Title from './Title';
import { useTranslation } from 'react-i18next';
import {
  getCurrentEnv,
  getCurrentUserManagerRole,
  isCurrentUserHiringManager,
  isCurrentUserP1,
  getCurrentUserId,
  getDisableCreateJobReq,
  getPVRDashboardAccess,
  ENVs,
} from '../../../api/manager';

const Shortcuts = () => {
  const isHiringManger = isCurrentUserHiringManager();
  const allowCreateJobReq = isHiringManger && !getDisableCreateJobReq();
  const allPVRDashboard = getPVRDashboardAccess();
  const isLineManager = getCurrentUserManagerRole();
  const isP1Employee = isCurrentUserP1();
  const { t } = useTranslation();

  return (
    <Nav defaultActiveKey="/home" className="metismenu" as="ul">
      <Title text={t('shortcuts')} />
      <ul>
        <NavLink to={`/performance/performancegoals/${getCurrentUserId()}`}>
          {t('perf_objectives')}
        </NavLink>
        <a
          href="https://natixis.e-tipi.com/presentation/search/index"
          target="_blank"
          rel="noreferrer"
        >
          {t('ask_training')}
        </a>
        {allowCreateJobReq ? (
          <a rel="noreferrer" href={buildCreateJobReqLink()}>
            {t('create_job_req')}
          </a>
        ) : null}
        {allPVRDashboard ? (
          <a href={buildPvrDashboardLink()} target="_blank" rel="noreferrer">
            {t('pvr_dashboard')}
          </a>
        ) : null}
        {isP1Employee ? (
          <a
            href="/fscmUI/faces/FuseOverview?fndGlobalItemNodeId=EXT_EXTN1570785366865_MENU_1620133533515"
            rel="noreferrer"
          >
            {t('mgl_site')}
          </a>
        ) : null}
        {isLineManager ? (
          <a
            href="/hcmUI/faces/FndOverview?fnd=%3B%3B%3B%3Bfalse%3B256%3B%3B%3B&fndGlobalItemNodeId=itemNode_manager_resources_talent_review_mobile"
            rel="noreferrer"
          >
            {t('shortcut_talent_review')}
          </a>
        ) : null}
      </ul>
    </Nav>
  );
};

export default Shortcuts;

const buildCreateJobReqLink = () => {
  const redirect_link = `ekez${getCurrentEnv()}.fa.em2.oraclecloud.com`;
  const link = `/hcmUI/faces/FuseOverview?fnd=%252FWEB-INF%252Foracle%252Fapps%252Fhcm%252Frecruiting%252Fhiring%252Frequisitions%252Fcore%252FpublicUi%252Fresponsive%252Fflow%252FRequisitionCreationFlow.xml%2523RequisitionCreationFlow%3BfndWarnChanges%253D%2523%257BProfile.values.HCM_SAVE_CHANGES_WARNING_ENABLED%2Beq%2B%2527Y%2527%257D%253BpCalledFrom%253D%2523%257B%2527FUSESHELL%2527%257D%253B%3B%3BCreate%2BJob%2BRequisition%3Bfalse%3B256%3B%3B%3B&pageParams=fndDetailsMode%3Dtrue%3BreturnFndUrl%3Dhttps%3A%2F%2F${redirect_link}%3A443%2FfscmUI%2Ffaces%2FFuseWelcome`;
  return link;
};

const buildPvrDashboardLink = () => {
  let nodeId = '';
  switch (getCurrentEnv()) {
    case ENVs.DEV:
      nodeId = 'EXT_EXTN1570785366865_MENU_1667890597812';
      break;
    case ENVs.TEST:
      nodeId = 'EXT_EXTN1570785366865_MENU_1670672275247';
      break;
    case ENVs.PROD:
      nodeId = 'EXT_EXTN1570785366865_MENU_1670918622845';
      break;
    default:
      nodeId = 'EXT_EXTN1570785366865_MENU_1670918622845';
  }
  const link = `/fscmUI/faces/FuseOverview?fndGlobalItemNodeId=${nodeId}`;
  return link;
};

import React from 'react';
import KPIPerformanceGoalsTable from './KPIPerformanceGoalsTable';
import PerformanceGoals from './PerformanceGoals';

const KPIs = () => {
  const displayTableMode = true;
  return <>{displayTableMode ? <KPIPerformanceGoalsTable /> : <PerformanceGoals />}</>;
};

export default KPIs;

import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  fetchEAPFeedbacks,
  fetchMyTeamEAPFeedbacks,
} from '../../../actions/apiPerformanceActions';
import { EAPStatus } from '../../../api/HCMProfile/requests/EAPRequester';
import { getCurrentUserId, getCurrentUserManagerRole } from '../../../api/manager';
import { getEmployeeDisplayName } from '../../../model/employee';
import LoaderInCard from '../../common/LoaderInCard';
import DocumentTable from '../common/DocumentTable';
import AnswerFeedbackModal from './AnswerFeedbackModal';
import { getColumns } from './DocumentTableStructure';

const Feedbacks = ({ feedbacks, dispatchFetchFeedbacks, dispatchMyTeamEAPFeedbacks }) => {
  const [showAnswerFeedbackModal, setshowAnswerFeedbackModal] = useState(false);
  const [feedbackItemId, setfeedbackItemId] = useState(null);
  const [answerFeedbackModalRO, setAnswerFeedbackModalRO] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    dispatchFetchFeedbacks();
    dispatchMyTeamEAPFeedbacks();
  }, [dispatchFetchFeedbacks, dispatchMyTeamEAPFeedbacks]);

  const onAnswerFeedback = feedbackItemId => {
    setfeedbackItemId(feedbackItemId);
    setAnswerFeedbackModalRO(false);
    setshowAnswerFeedbackModal(true);
  };

  const onViewFeedback = feedbackItemId => {
    setfeedbackItemId(feedbackItemId);
    setAnswerFeedbackModalRO(true);
    setshowAnswerFeedbackModal(true);
  };

  return (
    <Container fluid id="objectives_container">
      {feedbacks.fetched && (
        <AnswerFeedbackModal
          show={showAnswerFeedbackModal}
          onHide={() => setshowAnswerFeedbackModal(false)}
          feedbackData={feedbacks?.data[feedbackItemId]}
          readOnly={answerFeedbackModalRO}
        />
      )}

      <Row>
        <Col lg="12" xs="12">
          <Card style={{ minHeight: '220px' }} className="card_dashboard">
            <Card.Body>
              <Card.Title>
                {t('table_feedbacks_title_answer')}
                <div style={{ textAlign: 'right' }}></div>
              </Card.Title>
              {feedbacks.fetched == true ? (
                <DocumentTable
                  columns={getColumns(t, true, onAnswerFeedback, onViewFeedback)}
                  data={prepareFeedbacks(feedbacks.data, true)}
                />
              ) : (
                <LoaderInCard />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {getCurrentUserManagerRole() ? (
        <Row>
          <Col lg="12" xs="12">
            <Card style={{ minHeight: '220px' }} className="card_dashboard">
              <Card.Body>
                <Card.Title>
                  {t('table_feedbacks_title_follow')}
                  <div style={{ textAlign: 'right' }}></div>
                </Card.Title>
                {feedbacks.fetched == true ? (
                  <DocumentTable
                    columns={getColumns(t, false, onAnswerFeedback, onViewFeedback)}
                    data={prepareFeedbacks(feedbacks.data, false)}
                  />
                ) : (
                  <LoaderInCard />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    employees: state.hcm.employeeTeam,
    feedbacks: state.performance.feedbacks,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchFetchFeedbacks: personId => dispatch(fetchEAPFeedbacks(personId)),
  dispatchMyTeamEAPFeedbacks: () => dispatch(fetchMyTeamEAPFeedbacks()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Feedbacks);

const prepareFeedbacks = (rawFeedbacks, keepCurrentUser) => {
  const feedbackFilter = f => {
    if (keepCurrentUser) {
      return f.ASKED_TO_PERSON_ID == getCurrentUserId();
    } else {
      return f.ASKED_TO_PERSON_ID != getCurrentUserId();
    }
  };
  const feedbacks = Object.values(rawFeedbacks)
    .filter(feedbackFilter)
    .filter(
      f =>
        f.ASKED_FOR?.toLowerCase() !=
        getEmployeeDisplayName(getCurrentUserId())?.toLowerCase(),
    )
    .map(f => ({
      FEEDBACK_ITEM_ID: f['FEEDBACK_ITEM_ID'],
      EAP_ITEM_ID: f['EAP_ITEM_ID'],
      ASKED_BY: f['ASKED_BY'],
      ASKED_FOR: f['ASKED_FOR'],
      ASKED_TO: f['ASKED_TO'],
      EXPECTED_DATE: f['EXPIRATION_DATE'],
      STATUS: f['STATUS_CODE'],
      ACTIONS: f['STATUS_CODE'],
      EAP_STATUS_CODE: f['EAP_STATUS_CODE'] || EAPStatus.NOT_STARTED,
    }));
  return feedbacks;
};

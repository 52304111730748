import React from 'react';
import Loader from './Loader';

const LoaderInCard = ({ loaderMarginTop = '10%' } = {}) => {
  return (
    <div className="dimmer active" style={{ marginTop: loaderMarginTop }}>
      <Loader />
      <div className="dimmer content"></div>
    </div>
  );
};

export default LoaderInCard;

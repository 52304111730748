import store from '../store';
import _ from 'lodash';

export const getEmployeeData = personId => {
  const state = store.getState();
  const employeeDataEDI = state.performance.employeesProfileData.data[personId];
  const employeeDataEAP = state.performance.employeesEAPProfileData.data[personId];

  if (_.isEmpty(employeeDataEDI) && _.isEmpty(employeeDataEAP)) {
    return null;
  } else {
    const mergedEmployeeData = {};
    _.mergeWith(mergedEmployeeData, employeeDataEAP, employeeDataEDI, (a, b) =>
      b == '' ? a : undefined,
    );
    return mergedEmployeeData;
  }
};

const employeeGetter = key => personId => {
  const employeeData = getEmployeeData(personId);
  if (employeeData) {
    const value = employeeData[key];
    return value;
  }
  return null;
};

// TODO: merge with simple employeeGetter
export const employeeGetterMultiple = keys => personId => {
  const employeeData = getEmployeeData(personId);
  if (employeeData) {
    let profileId = null;
    for (const key of keys) {
      const value = employeeData[key];
      if (value != null) {
        profileId = value;
      }
    }
    if (profileId == null) {
      console.error(
        `Model/employee: cannot find ${keys.toString()} (personId: ${personId})`,
      );
    }
    return profileId;
  }
  return null;
};

export const getEmployeeProfileId = employeeGetterMultiple(['PROFILE_ID', 'ProfileId']);

export const getEmployeeProfileCode = employeeGetter('ProfileCode');

export const getEmployeePersonNumber = employeeGetter('PersonNumber');

export const getEmployeeDisplayName = employeeGetterMultiple(['EMP_NAME', 'DisplayName']);

export const getManagerPersonId = employeeGetterMultiple([
  'MANAGER_ID',
  'ManagerPersonId',
]);

export const getManagerDisplayName = employeeGetter('MGR_NAME');

export const getEmployeeEAPEvaluatorId = personId => {
  const state = store.getState();
  const employeeDataEAP = state.performance.employeesEAPProfileData.data[personId];
  let evaluatorId = employeeDataEAP?.EAP_EVALUATOR || null;
  if (evaluatorId == null) {
    evaluatorId = getManagerPersonId(personId);
  }
  return evaluatorId;
};

export const getEmployeeEAPMatrixManagerId = personId => {
  const state = store.getState();
  const employeeDataEAP = state.performance.employeesEAPProfileData.data[personId];
  let evaluatorId = employeeDataEAP?.MATRIX_ID || null;
  return evaluatorId;
};

const getEmployeeType = personId => {
  const state = store.getState();
  const employeeTeam = state.hcm.employeeTeam.data;
  if (employeeTeam) {
    const found = employeeTeam.find(e => e?.PERSON_ID == personId);
    if (found) {
      return found?.EMPLOYEE_TYPE;
    }
  }
  return null;
};

export const isEmployeeTrainee = personId => {
  const employeeType = getEmployeeType(personId);
  return employeeType === 'alternant';
};

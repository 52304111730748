import _ from 'lodash';
import moment from 'moment';
import { getEmployeeProfileId } from '../../../model/employee';
import { DATE_FORMAT_ISO } from '../../../utils/data';
import { getCurrentEnv, getCurrentUserId } from '../../manager';
import { GenericDecoder } from '../common/GenericDecoder';
import { FEEDBACK_IDS } from '../common/section_ids_configuration';
import {
  constructFilterPersonIds,
  constructFilterSectionId,
  getDateFromEntry,
} from '../helpers';

const createNewFeedback = requestData => {
  const {
    personIdReceiver,
    profileIdReceiver,
    personIdEmitter,
    nameEmitter,
    nameAskedFor,
    eapProfileItemId,
    expirationDate,
    comment,
  } = requestData;

  console.info('--- Debug Feedback POST data (create new feedback)');
  console.info(`- personIdReceiver: ${personIdReceiver}`);
  console.info(`- profileIdReceiver: ${profileIdReceiver}`);
  console.info(`- personIdEmitter: ${personIdEmitter}`);
  console.info(`- EAP id: ${eapProfileItemId}`);
  console.info(`- nameEmitter: ${nameEmitter}`);
  console.info(`- nameAskedFor: ${nameAskedFor}`);
  console.info(`- expirationDate: ${expirationDate}`);
  console.info(`- comment: ${comment}`);

  const requestBody = `<soapenv:Envelope
  xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
  xmlns:typ="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/types/"
  xmlns:pub="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/"
  xmlns:prof="http://xmlns.oracle.com/apps/hcm/profiles/core/flex/profileItem/">
  <soapenv:Header/>
  <soapenv:Body>
    <typ:mergeProfile>
      <typ:profile>
        <pub:ProfileId>${profileIdReceiver}</pub:ProfileId>
        <pub:ProfileItem>
          <pub:ProfileId>${profileIdReceiver}</pub:ProfileId>
          ${FeedbackDecoder.getRequestHeader()}
          <pub:ProfileItemId></pub:ProfileItemId>
          <pub:SourceId>${eapProfileItemId}</pub:SourceId>
          ${getDateFromEntry()}
          ${comment && `<pub:ItemText20003>${_.escape(comment)}</pub:ItemText20003>`}
          ${nameEmitter && `<pub:ItemText20004>${nameEmitter}</pub:ItemText20004>`}
          ${nameAskedFor && `<pub:ItemText20005>${nameAskedFor}</pub:ItemText20005>`}
          ${expirationDate && `<pub:ItemDate10>${expirationDate}</pub:ItemDate10>`}
          ${getLastUpdateData()}
          ${getStatus(FeedbackStatus.TODO)}
        </pub:ProfileItem>
      </typ:profile>
    </typ:mergeProfile>
  </soapenv:Body>
</soapenv:Envelope>`;
  return requestBody;
};

const answerFeedback = requestData => {
  const { personId, feedbackItemId, question1, question2 } = requestData;
  const profileIdReceiver = getEmployeeProfileId(personId);

  console.info('--- Debug Feedback POST data (answer feedback)');
  console.info(`- personId: ${personId}`);
  console.info(`- profileIdReceiver: ${profileIdReceiver}`);
  console.info(`- feedbackItemId: ${feedbackItemId}`);

  const requestBody = `<soapenv:Envelope
  xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
  xmlns:typ="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/types/"
  xmlns:pub="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/"
  xmlns:prof="http://xmlns.oracle.com/apps/hcm/profiles/core/flex/profileItem/">
  <soapenv:Header/>
  <soapenv:Body>
    <typ:mergeProfile>
      <typ:profile>
        <pub:ProfileId>${profileIdReceiver}</pub:ProfileId>
        <pub:ProfileItem>
          <pub:ProfileId>${profileIdReceiver}</pub:ProfileId>
          ${FeedbackDecoder.getRequestHeader()}
          <pub:ProfileItemId>${feedbackItemId}</pub:ProfileItemId>
          ${
            question1
              ? `<pub:ItemText20001>${_.escape(question1)}</pub:ItemText20001>`
              : ''
          }
          ${
            question2
              ? `<pub:ItemText20002>${_.escape(question2)}</pub:ItemText20002>`
              : ''
          }
          ${getLastUpdateData()}
          ${getStatus(FeedbackStatus.DONE)}
        </pub:ProfileItem>
      </typ:profile>
    </typ:mergeProfile>
  </soapenv:Body>
</soapenv:Envelope>`;
  return requestBody;
};

const getByPersonIds = personIds => {
  if (!Array.isArray(personIds)) {
    personIds = [personIds];
  }

  console.info('--- Debug Feedback GET data');
  console.info(`- PersonIds: ${personIds.join(',')}`);

  const requestBody = `<soapenv:Envelope
  xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
  xmlns:typ="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/types/"
  xmlns:typ1="http://xmlns.oracle.com/adf/svc/types/">
  <soapenv:Header/>
  <soapenv:Body>
      <typ:findProfile>
          <typ:findCriteria>
              <typ1:fetchStart>0</typ1:fetchStart>
              <typ1:fetchSize>-1</typ1:fetchSize>
              <typ1:filter>
                  <typ1:group>
                      <typ1:conjunction>And</typ1:conjunction>
                      <typ1:upperCaseCompare>true</typ1:upperCaseCompare>
                      ${constructFilterPersonIds(personIds)}
                  </typ1:group>
              </typ1:filter>
              <typ1:childFindCriteria>
                  <typ1:fetchStart>0</typ1:fetchStart>
                  <typ1:fetchSize>-1</typ1:fetchSize>
                  <typ1:filter>
                      <typ1:conjunction>And</typ1:conjunction>
                      <typ1:group>
                          <typ1:conjunction>And</typ1:conjunction>
                          <typ1:upperCaseCompare>false</typ1:upperCaseCompare>
                          ${constructFilterSectionId(FeedbackDecoder.SECTION_ID)}
                      </typ1:group>
                  </typ1:filter>
                  <typ1:childAttrName>ProfileItem</typ1:childAttrName>
              </typ1:childFindCriteria>
              <typ1:findAttribute>ProfileId</typ1:findAttribute>
              <typ1:findAttribute>ProfileItem</typ1:findAttribute>
              <typ1:findAttribute>PersonId</typ1:findAttribute>
          </typ:findCriteria>
          <typ:findControl>
              <typ1:retrieveAllTranslations>false</typ1:retrieveAllTranslations>
          </typ:findControl>
      </typ:findProfile>
  </soapenv:Body>
</soapenv:Envelope>`;
  return requestBody;
};

const FeedbackRequester = {
  createNewFeedback: createNewFeedback,
  answerFeedback: answerFeedback,
  getByPersonIds: getByPersonIds,
};

export class FeedbackDecoder extends GenericDecoder {
  static SECTION_ID = FEEDBACK_IDS[getCurrentEnv()];
  static NAME = 'Feedback_DOCUMENT';
  static ATTRIBUTES = {
    'ns1:ProfileItemId': { key: 'profileItemId' },
    'ns1:DateFrom': { key: 'date' },
    'ns1:SourceId': { key: 'eapId' },
    'ns1:ItemText301': { key: 'status' },
    'ns1:ItemDate9': { key: 'lastUpdateDate' },
    'ns1:ItemDate10': { key: 'expirationDate' },
    'ns1:ItemText308': { key: 'lastUpdateBy' },
    'ns1:ItemText20001': {
      key: 'question1',
      prepare: x => (x == null ? '' : _.unescape(x)),
    },
    'ns1:ItemText20002': {
      key: 'question2',
      prepare: x => (x == null ? '' : _.unescape(x)),
    },
    'ns1:ItemText20003': {
      key: 'comment',
      prepare: x => (x == null ? '' : _.unescape(x)),
    },
    'ns1:ItemText20004': { key: 'askedBy' },
    'ns1:ItemText20005': { key: 'askedFor' },
  };

  static getContentType() {
    return 'ACCOMPLISHMENT';
  }

  static getSectionName() {
    return `${FeedbackDecoder.getContentType()}_${FeedbackDecoder.SECTION_ID}`;
  }

  static getRequestHeader() {
    return `<pub:ContentType>${FeedbackDecoder.getContentType()}</pub:ContentType>
            <pub:SectionName>${FeedbackDecoder.getSectionName()}</pub:SectionName>`;
  }
}

export default FeedbackRequester;

export const FeedbackStatus = {
  TODO: 'TODO',
  DONE: 'DONE',
  EXPIRED: 'EXPIRED',
};

const getLastUpdateData = () => {
  const today = moment().format(DATE_FORMAT_ISO);
  const currentUserId = getCurrentUserId();
  return `
    // Last Updated by
    <pub:ItemDate9>${today}</pub:ItemDate9>
    //Last Updated by
    <pub:ItemText308>${currentUserId}</pub:ItemText308>
  `;
};

const getStatus = status => {
  const today = moment().format(DATE_FORMAT_ISO);
  return `
    //Status (NOT_STARTED, IN_PROGRESS, WRK_PREPARATION, COMPLETED)
    <pub:ItemText301>${status}</pub:ItemText301>
    //Status Date
    <pub:ItemDate7>${today}</pub:ItemDate7>`;
};

import React, { useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { Stepper, Step } from 'react-form-stepper';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { NavLink, useParams } from 'react-router-dom';
import StepWizard from 'react-step-wizard';
import 'survey-core/defaultV2.min.css';
import 'survey-core/survey.min.css';
import { StylesManager } from 'survey-core';
import { getCurrentUserId } from '../../../../api/manager';
import DocumentViewerTopBar from '../../common/DocumentViewerTopBar';
import {
  fetchEAPEligibility,
  fetchEAPsByPersonIds,
  postEAP,
  postEAPGoalsData,
  postEAPStatus,
  postMissionAndMainActivity,
} from '../../../../actions/apiPerformanceActions';
import { connect } from 'react-redux';
import {
  EAPDecoder,
  EAPManagerDecoder,
  EAPRequestData,
  EAPStatus,
} from '../../../../api/HCMProfile/requests/EAPRequester';
import { getManagerPersonId } from '../../../../model/employee';
import { DATE_FORMAT_ISO, getTodayDate } from '../../../../utils/data';
import One from './SurveySteps/StepOneMissionAndMainActivity';
import Two from './SurveySteps/StepTwoMIFID';
import Three from './SurveySteps/StepThreePerformanceGoal';
import Four from './SurveySteps/StepFourBehaviors';
import Five from './SurveySteps/StepFiveSigning';
import { useTranslation } from 'react-i18next';
import { setSelectedEmployee } from '../../../../actions/settingsAction';
import {
  MissionAndMainActivityDecoder,
  MissionAndMainActivityRequestData,
} from '../../../../api/HCMProfile/requests/MissionAndMainActivityRequester';
import { encodeBase64 } from '../../../../api/HCMProfile/helpers';
import {
  buildEAPRequestDataStatusUpdate,
  computeIsTransferredDocument,
  eapIsEmpty,
  isFunctionalManager,
  isStatusEAPCompleted,
} from '../../common/utils';
import _ from 'lodash';
import ConsultFeedbacksModal from '../actions/ConsultFeedbacksModal';
import DisplayErrorHeader from '../../common/DisplayErrorHeader';
import { NEXT_REVIEW_PERIOD } from '../../PerformanceGoals/ReviewPeriodSelector';
import moment from 'moment';

StylesManager.applyTheme('defaultV2');

const EAPDocumentViewer = ({
  employeesEAPProfileData,
  eaps,
  userLang,
  savingEAP,
  errorEAP,
  perfGoals,
  dispatchPostEap,
  dispatchfetchEAPsByPersonIds,
  dispatchfetchEAPEligibility,
  dispatchPostEAPStatus,
  selectedEmployee,
  dispatchSetSelectedEmployee,
  dispatchPostMissionAndMainActivity,
  dispatchPostEAPGoalsData,
}) => {
  const { employeeId } = useParams();
  const [stepWizard, setStepWizard] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [eapData, setEAPdata] = useState(null);
  const [isEligible, setIsEligible] = useState(false);
  const [isEligibleMifid, setIsEligibleMifid] = useState(false);
  const [isEligibleEAP, setIsEligibleEAP] = useState(true);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isTransferred, setisTransferred] = useState(false);
  const [forceReadOnly, setForceReadOnly] = useState(false);
  const [isUserFunctionalManager, setIsUserFunctionalManager] = useState(false);
  const [isUserEvaluator, setIsUserEvaluator] = useState(false);
  const [showConsultFeedbacksModal, setshowConsultFeedbacksModal] = useState(false);
  const [employeePerfGoals, setEmployeePerfGoals] = useState(null);

  const cardBodyRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (employeeId != selectedEmployee) {
      dispatchSetSelectedEmployee(employeeId);
    }
  }, [employeeId, selectedEmployee, dispatchSetSelectedEmployee]);

  useEffect(() => {
    if (!employeesEAPProfileData.fetched) {
      dispatchfetchEAPEligibility();
    }
  }, [dispatchfetchEAPEligibility, employeesEAPProfileData.fetched]);

  useEffect(() => {
    if (
      perfGoals.fetched &&
      Object.keys(perfGoals.data).indexOf(employeeId) !== -1 &&
      perfGoals.data[employeeId].PERFORMANCE_GOALS
    ) {
      setEmployeePerfGoals(
        perfGoals.data[employeeId]['PERFORMANCE_GOALS'].filter(el => {
          const periodStartDate = moment(el.periodStartDate, 'YYYY-MM-DD');
          return periodStartDate.year() == 2022;
        }),
      );
    } else {
      setEmployeePerfGoals(null);
    }
  }, [perfGoals, employeeId]);

  useEffect(() => {
    let sectionEap = findProfileSectionData(eaps, employeeId, EAPDecoder.NAME);
    const sectionEapManager = findProfileSectionData(
      eaps,
      employeeId,
      EAPManagerDecoder.NAME,
    );
    if (sectionEapManager) {
      sectionEap = mergeManagerAndEmployeeEAP(sectionEap, sectionEapManager);
      setEAPdata(sectionEap);
    } else {
      setEAPdata(sectionEap);
    }

    setForceReadOnly(false);
    const employeeData = employeesEAPProfileData.data[employeeId];
    if (employeeData) {
      const statusEmployeeData = employeeData['STATUS_CODE'];
      setIsUserFunctionalManager(isFunctionalManager(employeeData));
      setIsUserEvaluator(employeeData.EAP_EVALUATOR == getCurrentUserId());
      setForceReadOnly(isStatusEAPCompleted(statusEmployeeData));
      if (sectionEap) {
        const statusEAP = sectionEap['status'];
        if (statusEAP != statusEmployeeData) {
          console.warn(
            'ERROR: statusEAP != statusEmployeeData, status mismatch ! statusEAP=' +
              statusEAP +
              ' sectionEap=' +
              sectionEap['status'],
            ' statusEmployeeData= ',
            statusEmployeeData,
          );
        }
      }
    }
  }, [eaps, employeeId, employeesEAPProfileData]);

  useEffect(() => {
    if (employeesEAPProfileData.fetched) {
      const employeeData = employeesEAPProfileData.data[employeeId];
      if (employeeData) {
        setIsCompleted(isStatusEAPCompleted(employeeData['STATUS_CODE']));
        setIsManager(getManagerPersonId(employeeId) === getCurrentUserId());
        setisTransferred(computeIsTransferredDocument(employeeData));
        const rawEligibility = employeeData['LEADER_ELIGIBILITY'];
        if (rawEligibility && rawEligibility == 'ELIGIBLE') {
          setIsEligible(true);
        } else {
          setIsEligible(false);
        }
        const rawEligibilityMifid = employeeData['MIFID2_ELIGIBILITY'];
        if (rawEligibilityMifid && rawEligibilityMifid == 'ELIGIBLE') {
          setIsEligibleMifid(true);
          if (activeStep == 4) {
            stepWizard.goToStep(5);
          }
        } else {
          setIsEligibleMifid(false);
          // active step special case for non eligible employees
          if (activeStep >= 4) {
            setActiveStep(3);
            stepWizard.goToStep(5);
          }
        }
        const rawEligibilityEAP = employeeData['EAP_ELIGIBILITY'];
        if (rawEligibilityEAP && rawEligibilityEAP == 'ELIGIBLE') {
          setIsEligibleEAP(true);
        } else {
          setIsEligibleEAP(false);
        }
      }
    }
  }, [employeesEAPProfileData, employeeId, activeStep, stepWizard, isManager]);

  useEffect(() => {
    dispatchfetchEAPsByPersonIds(employeeId);
  }, [dispatchfetchEAPsByPersonIds, employeeId]);

  const assignStepWizard = instance => {
    setStepWizard(instance);
  };

  const handleStepChange = e => {
    setActiveStep(e.activeStep - 1);
    if (cardBodyRef) {
      cardBodyRef.current.scrollIntoView();
    }
  };

  // save performance goals content and rating
  const savePerformanceGoalsData = (
    goalsContent,
    goalsGlobalRatingContent,
    ratingValue,
    ratingType,
  ) => {
    if (savingEAP) {
      console.warn('-- savePerformanceGoalsData: already saving, aborting');
      return;
    }

    const profileItemIdEmployee = eapData['profileItemId'] || '';
    const profileItemIdManager = eapData['profileItemIdManager'] || '';

    // prepare payload
    const payloadGoalsData =
      goalsContent == null
        ? null
        : btoa(unescape(encodeURIComponent(JSON.stringify(goalsContent))));
    const payloadGoalsGlobalEvalData =
      goalsGlobalRatingContent == null
        ? null
        : btoa(unescape(encodeURIComponent(JSON.stringify(goalsGlobalRatingContent))));
    const payload = {
      goalsData: payloadGoalsData,
      goalsGlobalEvalData: payloadGoalsGlobalEvalData,
      ratingValue: ratingValue,
      ratingType: ratingType,
    };

    const requestData = new EAPRequestData(
      employeeId,
      profileItemIdEmployee,
      profileItemIdManager,
      payload,
    );
    dispatchPostEAPGoalsData(requestData);
  };

  const saveEAP = (data, managerSave = null, behaviorsGlobalRating = null) => {
    if (savingEAP) {
      console.warn('-- saveEAP: already saving, aborting');
      return;
    }

    if (managerSave == null) {
      managerSave = isUserEvaluator || isUserFunctionalManager;
    }
    const matrixSave = isUserFunctionalManager;
    const saveIsNeeded = (dataToSave, storedEAPData) => {
      // data to save is empty
      if (dataToSave == {}) {
        return false;
      }
      // no stored data
      if (
        storedEAPData == null ||
        storedEAPData == {} ||
        storedEAPData.eapPayload == null
      ) {
        return true;
      }
      // deep diff
      let needToUpdate = !_.isEqual(dataToSave, storedEAPData.eapPayload);
      if (behaviorsGlobalRating && behaviorsGlobalRating?.ratingValue != null) {
        needToUpdate = true;
      }
      return needToUpdate;
    };

    if (!saveIsNeeded(data, eapData)) {
      console.warn('-- saveEAP: no difference detected (no need to save), aborting');
      return;
    }
    if (data['missionAndMainActivity']) {
      // update mission and main activity
      const mission = data['missionAndMainActivity'].find(
        q => q.name == 'missionMainActivities',
      );
      if (mission) {
        const employeeData = eaps.data[employeeId];
        let profileItemId = '';
        const storedMission = getMissionSectionIfExists(employeeData);
        if (storedMission) {
          profileItemId = storedMission['profileItemId'];
        }
        const requestData = new MissionAndMainActivityRequestData(
          employeeId,
          profileItemId,
          encodeBase64(mission.value),
        );
        dispatchPostMissionAndMainActivity(requestData);
      }
    }
    const requestData = buildEAPRequestData({
      storedEAPData: eapData,
      dataToSave: data,
      targetEmployeeId: employeeId,
      managerSave: managerSave,
      matrixSave: matrixSave,
    });

    // append additional values to payload
    if (behaviorsGlobalRating?.ratingValue) {
      const rv = behaviorsGlobalRating?.ratingValue;
      const rt = behaviorsGlobalRating?.ratingType;
      requestData._payload = { ratingValue: rv, ratingType: rt, ...requestData._payload };
    }

    dispatchPostEap(requestData);
  };

  const updateEAPStatus = (status, managerSave = false) => {
    if (savingEAP) {
      console.warn('-- updateEAPStatus: already saving, aborting');
      return;
    }

    const requestData = buildEAPRequestDataStatusUpdate({
      storedEAPData: eapData,
      statusToSave: status,
      targetEmployeeId: employeeId,
      managerSave: managerSave,
    });
    dispatchPostEAPStatus(requestData);
  };

  const getStepClassName = step => {
    let className = '';
    switch (step) {
      case 0:
        className = 'eap_mission';
        break;
      case 1:
        className = isEligibleMifid ? 'eap_mifid2' : 'eap_perf_goal';
        break;
      case 2:
        className = isEligibleMifid ? 'eap_perf_goal' : 'eap_behavior';
        break;
      case 3:
        className = isEligibleMifid ? 'eap_behavior' : 'eap_signature';
        break;
      case 4:
        className = isEligibleMifid ? 'eap_signature' : '';
        break;
    }

    if (activeStep == step) {
      className += ' step_active';
    }

    return className;
  };

  return (
    <Card style={{ cursor: `${savingEAP ? 'wait' : ''}` }}>
      <Card.Body ref={cardBodyRef}>
        <ConsultFeedbacksModal
          show={showConsultFeedbacksModal}
          onHide={() => setshowConsultFeedbacksModal(false)}
          eapItemId={eapData ? eapData['profileItemId'] || '' : ''}
        />
        <DocumentViewerTopBar employeeId={employeeId} title={t('title_EAP_2022')} />
        {/* form encapsulation to avoid HCM button transformation */}
        <Formik
          initialValues={{ mission: '', skills: '' }}
          type="button"
          onSubmit={() => {
            return false;
          }}
        >
          <Form className="card">
            <div>
              <Row>
                <Col md={1} className="mt-3">
                  <div className="text-center">
                    <a
                      href={
                        'https://objectstorage.eu-frankfurt-1.oraclecloud.com/n/natixishr/b/HCMLibrary/o/About%20Interview.pdf'
                      }
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i className="fa fa-info-circle" style={{ fontSize: '30px' }} />
                      <br />
                      {t('About')}
                    </a>
                    {employeeId != getCurrentUserId() && (
                      <Card>
                        <Card.Text
                          style={{
                            width: '100px',
                            top: '70%',
                            zIndex: '999',
                            position: 'fixed',
                          }}
                        >
                          <div>
                            <Button
                              type="button"
                              style={{ color: 'white' }}
                              className="btn btn-link"
                              data-linkonly="true"
                              onClick={() => {
                                setshowConsultFeedbacksModal(true);
                              }}
                            >
                              {' '}
                              {t('eap_consult_feedbacks')}
                            </Button>
                          </div>
                        </Card.Text>
                      </Card>
                    )}
                  </div>
                </Col>
                {isEligibleEAP ? (
                  <Col md={11} className="zIndex0">
                    {isEligibleMifid ? (
                      <Stepper activeStep={activeStep} nonLinear={true}>
                        <Step
                          className={getStepClassName(0)}
                          onClick={() => stepWizard.goToStep(1)}
                          label={t('step_eap_missionMainActivities')}
                        />
                        <Step
                          className={getStepClassName(1)}
                          onClick={() => stepWizard.goToStep(2)}
                          label={t('step_eap_mifid2_bmr')}
                        />
                        <Step
                          className={getStepClassName(2)}
                          onClick={() => stepWizard.goToStep(3)}
                          label={t('step_eap_perf_goal')}
                        />
                        <Step
                          className={getStepClassName(3)}
                          onClick={() => stepWizard.goToStep(4)}
                          label={
                            isEligible
                              ? t('step_eap_behaviors_leader')
                              : t('step_eap_behaviors')
                          }
                        />
                        <Step
                          className={getStepClassName(4)}
                          onClick={() => stepWizard.goToStep(5)}
                          label={t('step_eap_signature')}
                        />
                      </Stepper>
                    ) : (
                      <Stepper activeStep={activeStep} nonLinear={true}>
                        <Step
                          className={getStepClassName(0)}
                          onClick={() => stepWizard.goToStep(1)}
                          label={t('step_eap_missionMainActivities')}
                        />
                        <Step
                          className={getStepClassName(1)}
                          onClick={() => stepWizard.goToStep(2)}
                          label={t('step_eap_perf_goal')}
                        />
                        <Step
                          className={getStepClassName(2)}
                          onClick={() => stepWizard.goToStep(3)}
                          label={
                            isEligible
                              ? t('step_eap_behaviors_leader')
                              : t('step_eap_behaviors')
                          }
                        />
                        <Step
                          className={getStepClassName(3)}
                          onClick={() => stepWizard.goToStep(4)}
                          label={t('step_eap_signature')}
                        />
                      </Stepper>
                    )}
                  </Col>
                ) : null}
              </Row>

              {isCompleted ? (
                <Row className=" mb-10">
                  <Col md={12} className="noteBoxes typeCloturer bandeau">
                    {t('InterviewCompleted')}
                  </Col>
                  {isManager && (
                    <>
                      <Col md={12} className="noteBoxes type4 bandeau">
                        <NavLink
                          to={`/performance/performancegoals/${employeeId}/${NEXT_REVIEW_PERIOD?.value}`}
                        >
                          {t('eap_interview_completed_add_goals')}{' '}
                          {NEXT_REVIEW_PERIOD?.label}
                        </NavLink>
                      </Col>
                    </>
                  )}
                </Row>
              ) : (
                <>
                  {isTransferred ? (
                    <Row className=" mb-10">
                      <Col md={12} className="noteBoxes typeTransfert bandeau">
                        {t('InterviewTransfered')}{' '}
                        {employeesEAPProfileData.data[employeeId].EVALUATOR_NAME}
                      </Col>
                    </Row>
                  ) : null}
                </>
              )}
              <DisplayErrorHeader error={errorEAP} label={t('eap_error_header')} />
              {!isEligibleEAP ? (
                <Row className=" mb-10">
                  <Col md={12} className="noteBoxes typeTransfert bandeau">
                    {t('InterviewNonEligible')}
                  </Col>
                </Row>
              ) : null}

              {isEligibleEAP ? (
                <StepWizard
                  id="step1"
                  instance={assignStepWizard}
                  onStepChange={handleStepChange}
                >
                  <One
                    id="step1"
                    targetEmployeeId={employeeId}
                    saveEAP={saveEAP}
                    eapData={eapData}
                    userLang={userLang}
                    additionalEmployeeData={employeesEAPProfileData.data[employeeId]}
                    forceReadOnly={forceReadOnly}
                    isFunctionalManager={isUserFunctionalManager}
                    isEvaluator={isUserEvaluator}
                  />
                  {isEligibleMifid ? (
                    <Two
                      id="step2"
                      targetEmployeeId={employeeId}
                      saveEAP={saveEAP}
                      eapData={eapData}
                      userLang={userLang}
                      additionalEmployeeData={employeesEAPProfileData.data[employeeId]}
                      forceReadOnly={forceReadOnly}
                      isFunctionalManager={isUserFunctionalManager}
                      isEvaluator={isUserEvaluator}
                    />
                  ) : null}
                  <Three
                    id="step3"
                    targetEmployeeId={employeeId}
                    forceReadOnly={forceReadOnly}
                    isFunctionalManager={isUserFunctionalManager}
                    isEvaluator={isUserEvaluator}
                    savePerformanceGoalsData={savePerformanceGoalsData}
                    eapData={eapData}
                  />
                  <Four
                    id="step4"
                    targetEmployeeId={employeeId}
                    saveEAP={saveEAP}
                    isEligibleLeader={isEligible}
                    eapData={eapData}
                    userLang={userLang}
                    forceReadOnly={forceReadOnly}
                    isFunctionalManager={isUserFunctionalManager}
                    isEvaluator={isUserEvaluator}
                  />
                  <Five
                    id="step5"
                    targetEmployeeId={employeeId}
                    saveEAP={saveEAP}
                    updateEAPStatus={updateEAPStatus}
                    isEligibleMifid2={isEligibleMifid}
                    eapData={eapData}
                    goals={employeePerfGoals}
                    userLang={userLang}
                    forceReadOnly={forceReadOnly}
                    isFunctionalManager={isUserFunctionalManager}
                    isEvaluator={isUserEvaluator}
                  />
                </StepWizard>
              ) : null}
            </div>
          </Form>
        </Formik>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = state => ({
  eaps: state.performance.eaps,
  userLang: state.settings.userLang,
  employeesEAPProfileData: state.performance.employeesEAPProfileData,
  selectedEmployee: state.settings.selectedEmployee,
  savingEAP: state.uiReducer.eaps.saving,
  errorEAP: state.uiReducer.eaps.error,
  perfGoals: state.performance.performanceGoals,
});

const mapDispatchToProps = dispatch => ({
  dispatchPostEap: eap => dispatch(postEAP(eap)),
  dispatchfetchEAPsByPersonIds: ids => dispatch(fetchEAPsByPersonIds(ids)),
  dispatchfetchEAPEligibility: () => dispatch(fetchEAPEligibility()),
  dispatchPostEAPStatus: statusPayload => dispatch(postEAPStatus(statusPayload)),
  dispatchSetSelectedEmployee: e => dispatch(setSelectedEmployee(e)),
  dispatchPostMissionAndMainActivity: requestData =>
    dispatch(postMissionAndMainActivity(requestData)),
  dispatchPostEAPGoalsData: r => dispatch(postEAPGoalsData(r)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EAPDocumentViewer);

const getMissionSectionIfExists = employeeData => {
  if (employeeData != null) {
    const section = employeeData[MissionAndMainActivityDecoder.NAME];
    if (section && section.length > 0) {
      const mission = section[0];
      if (mission) {
        return mission;
      }
    }
  }
  return null;
};

const findProfileSectionData = (data, personId, section) => {
  const getMissionSectionValueIfExists = employeeData => {
    const section = getMissionSectionIfExists(employeeData);
    if (section) {
      return section['missionAndMainActivity'];
    }
    return null;
  };

  if (data.fetched) {
    const employeeData = data.data[personId];

    if (employeeData) {
      const sectionData = employeeData[section];
      let eapData = {};
      if (sectionData && Array.isArray(sectionData) && sectionData.length > 0) {
        // TODO: take first item, maybe not the most optimal solution
        eapData = sectionData[0];
      } else {
        eapData = {};
      }
      const mission = getMissionSectionValueIfExists(employeeData);
      if (mission) {
        // create EDI data from mission section
        if (Object.keys(eapData).length === 0 || eapData['eapPayload'] == null) {
          eapData = {
            eapPayload: {
              missionAndMainActivity: [
                {
                  name: 'missionMainActivities',
                  value: mission,
                },
              ],
            },
          };
        } else {
          // merge mission section with EAP data
          const missionAndMainActivity = eapData?.eapPayload?.missionAndMainActivity;
          if (missionAndMainActivity) {
            // merge with missionAndMainActivity sub section
            const found = eapData['eapPayload']['missionAndMainActivity'].find(
              x => x['name'] == 'missionMainActivities',
            );
            if (found) {
              found.value = mission;
            }
          } else {
            // create missionAndMainActivity sub section
            eapData['eapPayload']['missionAndMainActivity'] = [
              {
                name: 'missionMainActivities',
                value: mission,
              },
            ];
          }
        }
      }
      return eapData == {} ? null : eapData;
    }
  }
  return null;
};

const buildEAPRequestData = ({
  storedEAPData,
  dataToSave,
  targetEmployeeId,
  managerSave,
  matrixSave,
}) => {
  let profileItemIdEmployee = '';
  let profileItemIdManager = '';
  let payload;

  // TODO maybe merge with mission if exists

  if (eapIsEmpty(storedEAPData)) {
    const currentDate = getTodayDate(DATE_FORMAT_ISO);

    const managerId =
      managerSave && !matrixSave
        ? getCurrentUserId()
        : matrixSave
        ? getManagerPersonId(targetEmployeeId)
        : getManagerPersonId(getCurrentUserId());

    const eapStatus =
      managerSave || matrixSave ? EAPStatus.NOT_STARTED : EAPStatus.IN_PROGRESS;
    const newEap = {
      creationDate: currentDate,
      managerId: managerId,
      eapPayload: btoa(unescape(encodeURIComponent(JSON.stringify(dataToSave)))),
      status: eapStatus,
      managerSave,
    };
    payload = newEap;
  } else {
    const creationDate = storedEAPData['date'];
    const managerId =
      managerSave && !matrixSave
        ? getCurrentUserId()
        : matrixSave
        ? getManagerPersonId(targetEmployeeId)
        : storedEAPData['managerId'];
    const currentPayload = storedEAPData['eapPayload'];
    let mergedPayload = null;
    if (currentPayload && dataToSave) {
      mergedPayload = Object.assign(currentPayload, dataToSave);
    }
    const updateEap = {
      creationDate: creationDate,
      managerId: managerId,
      eapPayload: btoa(unescape(encodeURIComponent(JSON.stringify(mergedPayload)))),
      managerSave,
    };
    const status = storedEAPData['status'];
    if (status == null || status == '') {
      updateEap['status'] =
        managerSave || matrixSave ? EAPStatus.NOT_STARTED : EAPStatus.IN_PROGRESS;
    } else {
      if (status == EAPStatus.NOT_STARTED && !managerSave && !matrixSave) {
        updateEap['status'] = EAPStatus.IN_PROGRESS;
      }
    }
    payload = updateEap;
    profileItemIdEmployee = storedEAPData['profileItemId'] || '';
    profileItemIdManager = storedEAPData['profileItemIdManager'] || '';
  }

  const requestData = new EAPRequestData(
    targetEmployeeId,
    profileItemIdEmployee,
    profileItemIdManager,
    payload,
  );
  return requestData;
};

const mergeManagerAndEmployeeEAP = (employee, manager) => {
  const result = {};
  const profileItemIdManager = manager['profileItemId'];
  manager['profileItemId'] = null;
  _.mergeWith(result, manager, employee, (a, b) => (b == '' ? a : undefined));
  employee['profileItemIdManager'] = profileItemIdManager;
  result['profileItemIdManager'] = profileItemIdManager;
  return result;
};

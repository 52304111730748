import React from 'react';
import { getCurrentUserId, isInLocalDevMode } from '../../api/manager';

const Avatar = ({ personId = getCurrentUserId(), title = 'Avatar', style = {} }) => {
  const getAvatarSrc = () => {
    const request = `/hcmUI/personImage?personId=${personId}&returnSquare=true&maxSize=80&returnInitials=Y`;
    if (isInLocalDevMode()) {
      const domain = 'https://ekez-test.fa.em2.oraclecloud.com/';
      return `${domain}${request}`;
    }
    return request;
  };
  return (
    <img
      className="avatar"
      src={getAvatarSrc()}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = '/assets/images/profile_placeholder.png';
      }}
      title={title}
      style={style}
    />
  );
};

export default Avatar;

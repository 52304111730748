import React, { useEffect, useState } from 'react';
import { Button, Modal, Card, Col } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { getCurrentEnv, getCurrentUserId } from '../../../../api/manager';
import Avatar from '../../../common/Avatar';
import { useTranslation } from 'react-i18next';
import { TextField, TextareaAutosize } from '@material-ui/core';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Stack } from '@mui/material';
import { connect } from 'react-redux';
import { postCreateNewFeedback } from '../../../../actions/apiPerformanceActions';
import { fetchEmployeesProfileData } from '../../../../api/HCMRest';
import { getEmployeeDisplayName } from '../../../../model/employee';
import { convertDate, DATE_FORMAT_ISO } from '../../../../utils/data';
import withCheckEAPExists from '../../../hoc/withCheckEAPExists';
import moment from 'moment';

const animatedComponents = makeAnimated();

const AskFeedbackModal = props => {
  const { t } = useTranslation();
  const { profileItemIdCollab, employeeId, ...rest } = props;

  return (
    <Modal
      {...rest}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop={'static'}
      dialogClassName="modal-50w"
      centered
    >
      <Modal.Header closeButton />
      <Card>
        <Card.Body>
          <Card.Title>
            {t('ask_feedback_modal')} {getEmployeeDisplayName(employeeId)}
          </Card.Title>
          <AskFeedback
            onHide={props.onHide}
            employeeId={employeeId}
            profileItemIdCollab={profileItemIdCollab}
          />
        </Card.Body>
      </Card>
    </Modal>
  );
};

export default AskFeedbackModal;

const mapStateToProps = state => ({
  savingFeedbacks: state.uiReducer.feedbacks.saving,
});

const mapDispatchToProps = dispatch => ({
  dispatchCreateNewFeedback: r => dispatch(postCreateNewFeedback(r)),
});

const AskFeedback = withCheckEAPExists(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    ({
      onHide,
      employeeId,
      profileItemIdCollab,
      dispatchCreateNewFeedback,
      savingFeedbacks,
    }) => {
      const [selectedEmployee, setSelectedEmployee] = useState(null);
      const [comment, setComment] = useState('');
      const [expirationDate, setexpirationDate] = useState(null);
      const [isExpirationDateInRange, setIsExpirationDateInRange] = useState(false);
      const { t } = useTranslation();

      const onSearchChange = result => {
        if (result != null) {
          setSelectedEmployee(result.PersonId);
        } else {
          setSelectedEmployee(null);
        }
      };

      const createNewFeedback = () => {
        if (selectedEmployee) {
          fetchEmployeesProfileData([selectedEmployee]).then(response => {
            if (response && Object.keys(response).length == 1) {
              const profileIdReceiver = Object.values(response)[0].ProfileId;
              if (profileIdReceiver) {
                const formatedDate = expirationDate
                  ? convertDate(expirationDate).format(DATE_FORMAT_ISO)
                  : null;
                const payload = {
                  personIdReceiver: selectedEmployee,
                  profileIdReceiver: profileIdReceiver,
                  personIdEmitter: getCurrentUserId(),
                  eapProfileItemId: profileItemIdCollab,
                  nameEmitter: getEmployeeDisplayName(getCurrentUserId()),
                  nameAskedFor: getEmployeeDisplayName(employeeId),
                  expirationDate: formatedDate,
                  comment: comment,
                };
                dispatchCreateNewFeedback(payload);
                onHide();
              }
            }
          });
        }
      };

      useEffect(() => {
        try {
          //Get only Today's Date without time comparaison
          const todayMoment = moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY');
          let expirationDateMoment = moment(expirationDate);
          setIsExpirationDateInRange(expirationDateMoment.isSameOrAfter(todayMoment));
        } catch (err) {
          console.error(
            'AskFeedbackModal.useEffect [expirationDate] : Date exception ',
            err,
          );
        }
      }, [expirationDate]);

      return (
        <div>
          <Col className="form-group">
            <label className="form-label">{t('ask_feedback_modal_to')}</label>
            <AsyncSelect
              isClearable
              cacheOptions
              defaultOptions
              placeholder={t('ask_feedback_modal_select_to_placeholder')}
              getOptionLabel={e => (
                <>
                  {e == null || e.WorkEmailAddress == null ? null : (
                    <>
                      <Avatar personId={e.PersonId} />
                      {`   ${e.WorkEmailAddress}`}
                    </>
                  )}
                </>
              )}
              getOptionValue={e => e.WorkEmailAddress}
              loadOptions={loadOptions}
              onChange={onSearchChange}
              components={animatedComponents}
            />
            <label className="form-label">{t('ask_feedback_modal_comment')}</label>
            <TextareaAutosize
              id="description"
              name="description"
              type="text"
              minRows={4}
              style={{ width: '100%', height: '50%' }}
              onChange={x => {
                const value = x?.target?.value;
                if (value) {
                  setComment(value);
                }
              }}
            />
            <label className="form-label">{t('ask_feedback_modal_date')}</label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={3}>
                <DesktopDatePicker
                  inputFormat="dd/MM/yyyy"
                  nullable
                  onError={(x, y) => {
                    console.error(y);
                  }}
                  renderInput={params => <TextField {...params} />}
                  onChange={x => {
                    const value = x;
                    if (value) {
                      setexpirationDate(value);
                    }
                  }}
                  disablePast={true}
                  value={expirationDate}
                />
              </Stack>
            </LocalizationProvider>
          </Col>
          <div
            className="p-10 mt-20"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button className="mr-10 w100" onClick={() => onHide()}>
              {t('Cancel')}
            </Button>
            <Button
              className="mr-10 w100"
              disabled={
                savingFeedbacks ||
                selectedEmployee == null ||
                expirationDate == null ||
                !isExpirationDateInRange
              }
              onClick={() => {
                createNewFeedback();
              }}
            >
              {savingFeedbacks ? t('save_ongoing') : t('ask_feedback_modal_send')}
            </Button>
          </div>
        </div>
      );
    },
  ),
);

const loadOptions = inputValue => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/vnd.oracle.adf.resourceitem+json');
  myHeaders.append('REST-Framework-Version', '4');
  myHeaders.append('Authorization', 'Basic bGVtcmFiZXRrYTpOYXRpeGlzMTIzKyo=');
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  return fetch(
    `https://ekez${getCurrentEnv()}.fa.em2.oraclecloud.com:443/hcmRestApi/resources/11.13.18.05/workersLov?expand=all&finder=findByWord;AssignmentType=E%20C%20N,AssignmentStatusType=ACTIVE%20SUSPENDED,DataSecurityPrivilege=PerDeferredAssignment,SearchTerms=%22${inputValue}%22,FilterAttributes=DisplayName%20WorkEmailAddress%20PersonNumber&onlyData=true`,
    requestOptions,
  )
    .then(response => response.json())
    .then(response => {
      response = response.items;
      return response;
    })
    .catch(error => console.error('ERROR: AskFeedback WorkersLov:', error));
};

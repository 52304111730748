import React, { useEffect, useState } from 'react';
import { Container, Button, Card, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import {
  deletePerformanceGoal,
  fetchPerformanceGoals,
  postPerformanceGoal,
} from '../../../../actions/apiPerformanceActions';
import {
  PerformanceGoalDecoder,
  PerformanceGoalRequestData,
} from '../../../../api/HCMProfile/requests/PerformanceGoalsRequester';
import { getCurrentUserId } from '../../../../api/manager';
import { getEmployeeDisplayName } from '../../../../model/employee';
import { DATE_FORMAT_ISO, getTodayDate } from '../../../../utils/data';
import askConfirmation from '../../common/askConfirmation';
import DocumentViewerTopBar from '../../common/DocumentViewerTopBar';
import ReviewPeriodSelector, {
  PAST_REVIEW_PERIOD,
  CURRENT_REVIEW_PERIOD,
  filterPerformanceGoalByReviewPeriod,
  REVIEW_PERIODS,
} from '../ReviewPeriodSelector';
import CreatePerfGoalModal from './CreatePerfGoalModal';
import GoalViewer from './GoalViewer';

const GoalsViewer = ({
  dispatchFetchPerformanceGoals,
  dispatchPostPerformanceGoal,
  dispatchDeletePerformanceGoal,
  performanceGoals,
  forceTargetEmployeeId,
  inEap,
  isEvaluator = false,
  isFunctionalManager = false,
  onChange = null,
  eapGoalsContent = {},
  employeesEAPProfileData,
}) => {
  const [showModal, setshowModal] = useState(false);
  const [goals, setGoals] = useState(null);
  const [selectedReviewPeriod, setSelectedReviewPeriod] = useState(CURRENT_REVIEW_PERIOD);
  const [displayActionButtons, setDisplayActionButtons] = useState(true);
  const { t } = useTranslation();
  let { targetEmployeeId, period } = useParams();

  if (forceTargetEmployeeId != null) {
    targetEmployeeId = forceTargetEmployeeId;
  }

  const eapData = employeesEAPProfileData.data[targetEmployeeId];

  useEffect(() => {
    const found = REVIEW_PERIODS.find(p => p.value == period);
    if (found) {
      setSelectedReviewPeriod(found);
    } else {
      if (inEap) {
        setSelectedReviewPeriod(PAST_REVIEW_PERIOD);
      } else {
        setSelectedReviewPeriod(CURRENT_REVIEW_PERIOD);
      }
    }
  }, [period, inEap]);

  useEffect(() => {
    let display = true;
    // TODO add status check for current EAP
    display &= !inEap && selectedReviewPeriod?.canAdd;
    setDisplayActionButtons(display);
  }, [
    employeesEAPProfileData,
    targetEmployeeId,
    selectedReviewPeriod,
    inEap,
    eapData?.STATUS_CODE,
  ]);

  const onClickAddGoal = () => {
    setshowModal(true);
  };

  const onSave = (values, goalId) => {
    let goal;
    const userName = getEmployeeDisplayName(targetEmployeeId);
    const currentDate = getTodayDate(DATE_FORMAT_ISO);
    if (goalId == '') {
      goal = {
        creationDate: currentDate,
        createdBy: userName,
        lastUpdateDate: currentDate,
        lastUpdatedBy: userName,
        title: values['title'],
        description: values['description'],
        status: values['status'],
        startDate: values['startDate'],
        realDate: values['realDate'],
        measure: values['measure'],
        inProgressComment: values['inProgressComment'],
        periodStartDate: selectedReviewPeriod?.dates.periodStartDate,
        periodEndDate: selectedReviewPeriod?.dates.periodEndDate,
      };
    } else {
      goal = {
        lastUpdateDate: currentDate,
        lastUpdatedBy: userName,
        title: values['title'],
        description: values['description'],
        status: values['status'],
        startDate: values['startDate'],
        realDate: values['realDate'],
        measure: values['measure'],
        inProgressComment: values['inProgressComment'],
      };
    }

    const request = new PerformanceGoalRequestData(targetEmployeeId, goalId, goal);
    dispatchPostPerformanceGoal(request);
    setshowModal(false);
  };

  const onDeleteGoal = profileItemId => {
    askConfirmation(
      () => {
        dispatchDeletePerformanceGoal({
          personId: targetEmployeeId,
          profileItemId: profileItemId,
        });
      },
      t,
      t('perf_goal_confirm_delete'),
      t('perf_goal_confirm_delete_sub'),
    );
  };

  useEffect(() => {
    if (performanceGoals.fetched) {
      const goals = performanceGoals.data[targetEmployeeId];
      if (goals) {
        setGoals(goals[PerformanceGoalDecoder.NAME]);
      } else {
        setGoals(null);
      }
    } else {
      setGoals(null);
      const ids = [getCurrentUserId()];
      dispatchFetchPerformanceGoals(ids);
    }
  }, [performanceGoals, targetEmployeeId, dispatchFetchPerformanceGoals]);

  return (
    <Container
      className="generic_fluid_container bg-light"
      style={{ minHeight: !inEap ? '400px' : '' }}
      fluid
    >
      {!inEap && (
        <>
          <DocumentViewerTopBar
            employeeId={targetEmployeeId}
            title={`${t('perf_tab_perf_goals')} ${selectedReviewPeriod?.label}`}
          />
          <ReviewPeriodSelector
            reviewPeriod={selectedReviewPeriod}
            setReviewPeriod={setSelectedReviewPeriod}
            employeeId={targetEmployeeId}
          />
        </>
      )}

      <div className="goals_viewer">
        {displayActionButtons ? (
          <>
            <CreatePerfGoalModal
              show={showModal}
              onHide={() => setshowModal(false)}
              onSave={onSave}
              selectedReviewPeriod={selectedReviewPeriod}
            />
            <Button className="goals_add_button" onClick={() => onClickAddGoal()}>
              <i className="fe fe-plus mr-2"></i> {t('add_perf_goal')}
            </Button>
          </>
        ) : (
          ''
        )}

        {goals != null ? (
          <>
            {goals.filter(filterPerformanceGoalByReviewPeriod(selectedReviewPeriod))
              .length > 0 ? (
              <>
                {inEap ? (
                  <Card>
                    <Card.Body>
                      <Row className="sd-html">
                        {t('infoGoal')}
                        <NavLink to={`/performance/performancegoals/${targetEmployeeId}`}>
                          <b className="text-underline my-translated-paragraph">
                            {t('eap_no_goals_goto')}
                          </b>
                        </NavLink>
                      </Row>
                      <br />
                      {targetEmployeeId == getCurrentUserId() && (
                        <Row className="sd-html">{t('infoGoalEmployee1')}</Row>
                      )}
                      {(isEvaluator || isFunctionalManager) && (
                        <Row className="sd-html">{t('infoGoalManager1')}</Row>
                      )}
                    </Card.Body>
                  </Card>
                ) : (
                  ''
                )}
                {goals
                  .filter(filterPerformanceGoalByReviewPeriod(selectedReviewPeriod))
                  .map((goal, index) => (
                    <GoalViewer
                      addMode={false}
                      key={index}
                      goal={goal}
                      onDelete={onDeleteGoal}
                      onSave={onSave}
                      inEap={inEap}
                      isEmployee={targetEmployeeId == getCurrentUserId()}
                      isEvaluator={isEvaluator}
                      isFunctionalManager={isFunctionalManager}
                      onChange={onChange}
                      eapGoalContent={eapGoalsContent[goal.profileItemId]}
                      showModal={showModal}
                      displayActionButtons={displayActionButtons}
                      eapData={eapData}
                      selectedReviewPeriod={selectedReviewPeriod}
                    />
                  ))}
              </>
            ) : (
              <NoGoals inEap={inEap} targetEmployeeId={targetEmployeeId} />
            )}
          </>
        ) : (
          <NoGoals inEap={inEap} targetEmployeeId={targetEmployeeId} />
        )}
      </div>
    </Container>
  );
};

const mapStateToProps = state => ({
  userLang: state.settings.userLang,
  savingDevelopmentGoal: state.uiReducer.developmentGoals.saving,
  performanceGoals: state.performance.performanceGoals,
  employeesEAPProfileData: state.performance.employeesEAPProfileData,
});

const mapDispatchToProps = dispatch => ({
  dispatchPostPerformanceGoal: request => dispatch(postPerformanceGoal(request)),
  dispatchDeletePerformanceGoal: request => dispatch(deletePerformanceGoal(request)),
  dispatchFetchPerformanceGoals: personId => dispatch(fetchPerformanceGoals(personId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GoalsViewer);

const NoGoals = ({ inEap, targetEmployeeId }) => {
  const { t } = useTranslation();

  return (
    <>
      {inEap && (
        <>
          <div className="text-center my-translated-paragraph">
            <p className="sd-html my-translated-paragraph">{t('eap_no_goals')}</p>
            <NavLink to={`/performance/performancegoals/${targetEmployeeId}`}>
              <b className="text-underline my-translated-paragraph">
                {t('eap_no_goals_goto')}
              </b>
            </NavLink>
          </div>
        </>
      )}
    </>
  );
};

import React from 'react';
import { connect } from 'react-redux';
import { Button, Card, Col } from 'react-bootstrap';
import { filterJobReqs, resetFilterJobReqs } from '../../../actions/settingsAction';
import Avatar from '../../common/Avatar';
import RibbonBox from '../../Dashboard/RibbonBox';

const EmployeeCard = ({ user, dispatchFilterJobReqs }) => {
  const count = user[1].count;
  const userName = user[0];
  const onClick = () => {
    dispatchFilterJobReqs(userName);
  };

  return (
    <Col>
      <Card>
        <Card.Body className="ribbon">
          <Button variant="light" className="my_sort_cut text-muted" onClick={onClick}>
            <RibbonBox number={count} fetchedData={true} />
            <Avatar
              personId={user[1].id}
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
            />
            {user[0]}
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  dispatchFilterJobReqs: e => dispatch(filterJobReqs(e)),
  dispatchResetFilterJobReqs: () => dispatch(resetFilterJobReqs()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeCard);

import moment from 'moment';
import { getCurrentUserId } from '../../../api/manager';
import { isEmployeeTrainee } from '../../../model/employee';
import { CURRENT_REVIEW_PERIOD } from '../../Performance/PerformanceGoals/ReviewPeriodSelector';

export const extractDataFromKPIsGoals = (kpis, t) => {
  const MAX_GOALS = 6;
  const title = t('dvp_kpi_title');

  const goalDateInCurrentReviewPeriod = goal => {
    if (goal == null) {
      return false;
    }
    const goalDate = moment(goal.startDate, 'YYYY-MM-DD');
    const isBetween = moment(goalDate).isBetween(
      CURRENT_REVIEW_PERIOD.dates.periodStartDate,
      CURRENT_REVIEW_PERIOD.dates.periodEndDate,
      null,
      '[]',
    );
    return isBetween || false;
  };

  const goalIsCollective = goal => {
    if (goal == null) {
      return false;
    }
    const context = goal.requestContext;
    if (context == 'FBL') {
      return true;
    } else {
      return false;
    }
  };

  const employeesGoals = Object.entries(kpis)
    .filter(([id]) => id != getCurrentUserId() && !isEmployeeTrainee(id))
    .map(([id, x]) => ({
      [id]:
        x?.PERFORMANCE_GOALS?.filter(
          g => goalDateInCurrentReviewPeriod(g) && !goalIsCollective(g),
        )?.length || 0,
    }));

  let results = {};
  for (const entry of employeesGoals) {
    const [id, goalsCount] = Object.entries(entry)[0];
    const resultIndex = goalsCount >= MAX_GOALS ? MAX_GOALS : goalsCount;
    if (results[resultIndex]) {
      results[resultIndex].push(id);
    } else {
      results[resultIndex] = [id];
    }
  }
  return { title: title, data: results };
};

export const extractDataFromKPIsPerformance = (kpis, t) => {
  const title = t('perf_kpi_title');
  const labels = [
    t('perf_kpi_not_started'),
    t('perf_kpi_in_progress'),
    t('perf_kpi_completed'),
  ];
  let series = [0, 0, 0];
  for (let i in kpis) {
    let kpi = kpis[i];
    const count = parseInt(kpi.COUNT_EVAL);
    switch (kpi.STEP_STATUS) {
      case 'NOTSTR':
      case 'READY':
        series[0] = count;
        break;
      case 'INPROG':
        series[1] = count;
        break;
      case 'COMP':
        series[2] = count;
        break;
      default:
        break;
    }
  }
  return { title: title, series: series, labels: labels };
};

import convert from 'xml-js';
import { DEFAULT_ENV, isProductionEnvironment } from '../manager';
import { getRequestAuthentication } from '../SOAPrequestMaker';
import { DevelopmentGoalDecoder } from './requests/DevelopmentGoalsRequester';
import { EDIDecoder } from './requests/EDIRequester';
import { EAPDecoder, EAPManagerDecoder } from './requests/EAPRequester';
import { MissionAndMainActivityDecoder } from './requests/MissionAndMainActivityRequester';
import { PerformanceGoalDecoder } from './requests/PerformanceGoalsRequester';
import {
  CareerPreferencesDecoder,
  PersonCareerStatementDecoder,
} from './requests/ProfessionalProjectRequests';
import { EPRDecoder } from './requests/EPRRequester';
import { DATE_FORMAT_ISO, getTodayDate } from '../../utils/data';

export const getRequestHeadersProfile = () => {
  const headers = new Headers();
  headers.append('Content-type', 'text/xml');
  headers.append('Authorization', getRequestAuthentication());
  return headers;
};

export const getRequestUrlProfile = () => {
  if (isProductionEnvironment()) {
    return '/hcmService/ProfileServiceV2';
  } else {
    return `https://ekez${DEFAULT_ENV}.fa.em2.oraclecloud.com/hcmService/ProfileServiceV2`;
  }
};

export const decodeBase64 = input => {
  try {
    return decodeURIComponent(escape(window.atob(input)));
  } catch (e) {
    return input;
  }
};

export const encodeBase64 = input => {
  try {
    return btoa(unescape(encodeURIComponent(input)));
  } catch (e) {
    return input;
  }
};

const DECODERS = [
  PersonCareerStatementDecoder,
  CareerPreferencesDecoder,
  MissionAndMainActivityDecoder,
  DevelopmentGoalDecoder,
  PerformanceGoalDecoder,
  EDIDecoder,
  EAPDecoder,
  EAPManagerDecoder,
  EPRDecoder,
];

export const prepareProfileSections = data => {
  const result = {};

  if (data == null) {
    return result;
  } else if (!Array.isArray(data)) {
    data = [data];
  }

  for (let x of data) {
    const personId = x['ns1:PersonId']._text;
    let items = x['ns1:ProfileItem'];
    const sectionAccumulator = {};
    if (items != undefined) {
      if (!Array.isArray(items)) {
        items = [items];
      }
      for (const item of items) {
        const sectionId = item['ns1:SectionId']._text;
        const decoder = matchWithDecoder(sectionId);
        if (decoder != null) {
          const decodedPayload = decoder.decode(item);
          const arr = sectionAccumulator[decoder.NAME];
          let skipItem = false;
          if (decoder == CareerPreferencesDecoder) {
            const targetContentTypeId = decoder.FORCE_CONTENT_TYPE_ID;
            const contentTypeId = decodedPayload.contentTypeId;
            if (targetContentTypeId && contentTypeId != targetContentTypeId) {
              skipItem = true;
            }
          }
          if (!skipItem) {
            if (arr == undefined) {
              sectionAccumulator[decoder.NAME] = [decodedPayload];
            } else {
              arr.push(decodedPayload);
            }
          }
        } else {
          console.error(
            `HCMProfile/API: Cant find a matching decoder, sectionId = ${sectionId}`,
          );
        }
      }
    }
    result[personId] = sectionAccumulator;
  }
  return result;
};

const matchWithDecoder = sectionId => {
  for (const decoder of DECODERS) {
    if (decoder.match(sectionId)) {
      return decoder;
    }
  }
  return null;
};

export const genericPreprocessor = response => {
  const jsonPayload = convert.xml2js(response, {
    compact: true,
    ignoreComment: true,
    trim: true,
    sanitize: true,
  });
  const results =
    jsonPayload['env:Envelope']['env:Body']['ns0:findProfileResponse']['ns2:result'][
      'ns1:Value'
    ];
  return prepareProfileSections(results);
};

export const constructFilterSectionId = sectionId => {
  return `
    <typ1:item>
        <!--Optional:-->
        <typ1:conjunction>Or</typ1:conjunction>
        <typ1:upperCaseCompare>false</typ1:upperCaseCompare>
        <typ1:attribute>SectionId</typ1:attribute>
        <typ1:operator>=</typ1:operator>
        <typ1:value>${sectionId}</typ1:value>
    </typ1:item>                            
    `;
};

export const constructFilterSectionIdSecured = sectionId => {
  return `
    <typ1:item>
        <!--Optional:-->
        <typ1:conjunction>And</typ1:conjunction>
        <typ1:upperCaseCompare>false</typ1:upperCaseCompare>
        <typ1:attribute>SectionId</typ1:attribute>
        <typ1:operator>=</typ1:operator>
        <typ1:value>${sectionId}</typ1:value>
    </typ1:item>    
    <typ1:item>
      <typ1:conjunction>And</typ1:conjunction>
      <typ1:upperCaseCompare>false</typ1:upperCaseCompare>
      <typ1:attribute>ItemText301</typ1:attribute>
      <typ1:operator>=</typ1:operator>
      <typ1:value>COMPLETED</typ1:value>
    </typ1:item>                        
    `;
};

export const constructFilterSectionIdSecuredManager = (sectionId, currentUserId) => {
  return `
    <typ1:item>
        <!--Optional:-->
        <typ1:conjunction>And</typ1:conjunction>
        <typ1:upperCaseCompare>false</typ1:upperCaseCompare>
        <typ1:attribute>SectionId</typ1:attribute>
        <typ1:operator>=</typ1:operator>
        <typ1:value>${sectionId}</typ1:value>
    </typ1:item>    
    <typ1:item>
        <typ1:conjunction>Or</typ1:conjunction>
        <typ1:upperCaseCompare>false</typ1:upperCaseCompare>
        <typ1:attribute>SourceId</typ1:attribute>
        <typ1:operator>=</typ1:operator>
        <typ1:value>${currentUserId}</typ1:value>
    </typ1:item>
    <typ1:item>
      <typ1:conjunction>Or</typ1:conjunction>
      <typ1:upperCaseCompare>false</typ1:upperCaseCompare>
      <typ1:attribute>SourceKey1</typ1:attribute>
      <typ1:operator>=</typ1:operator>
      <typ1:value>${currentUserId}</typ1:value>
    </typ1:item>
    <typ1:item>
      <typ1:conjunction>Or</typ1:conjunction>
      <typ1:upperCaseCompare>false</typ1:upperCaseCompare>
      <typ1:attribute>SourceKey2</typ1:attribute>
      <typ1:operator>=</typ1:operator>
      <typ1:value>${currentUserId}</typ1:value>
    </typ1:item>                        
    `;
};

export const constructFilterPersonId = personId => {
  return `
    <typ1:item>
        <typ1:conjunction>Or</typ1:conjunction>
        <typ1:upperCaseCompare>false</typ1:upperCaseCompare>
        <typ1:attribute>PersonId</typ1:attribute>
        <typ1:operator>=</typ1:operator>
        <typ1:value>${personId}</typ1:value>
    </typ1:item>`;
};

export const constructFilterPersonIds = personIds => {
  return personIds.map(personId => constructFilterPersonId(personId)).join('');
};

export const getDateFromEntry = () => {
  let result = '';
  const today = getTodayDate(DATE_FORMAT_ISO);
  if (today) {
    result = `<pub:DateFrom>${today}</pub:DateFrom>`;
  }
  return result;
};

export const isProfileItemIdEmpty = profileItemId => {
  return profileItemId == '' || profileItemId == null;
};

export const buildProperty = (
  propertyName,
  propertyValue,
  transformer = null,
  tag = 'pub',
) => {
  if (propertyValue) {
    if (transformer) {
      propertyValue = transformer(propertyValue);
    }
    return `<${tag}:${propertyName}>${propertyValue}</${tag}:${propertyName}>`;
  } else {
    return '';
  }
};

export const prepareBase64JsonPayload = rawPayload => {
  let decodedPayload = {};
  if (rawPayload == null || rawPayload == 'bnVsbA==') {
    decodedPayload = {}; // keep empty object if raw data null
  } else {
    try {
      decodedPayload = JSON.parse(decodeBase64(rawPayload));
    } catch (exception) {
      console.warn('ERROR: payload decoding');
    }
  }
  return decodedPayload;
};

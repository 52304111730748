import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import makeAnimated from 'react-select/animated';
import { TextField, TextareaAutosize } from '@material-ui/core';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Stack } from '@mui/material';
import { format } from 'date-fns';
import { BsFillTrash2Fill } from 'react-icons/bs';
import { getRequestAuthentication } from '../../../../api/SOAPrequestMaker';
import { getCurrentEnv } from '../../../../api/manager';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { loadPerformanceGoalRatingOptions } from '../../common/utils';

const animatedComponents = makeAnimated();

const GoalViewer = ({
  addMode = false,
  goal,
  onSave,
  onDelete,
  cancel,
  userLang,
  inEap,
  isEmployee,
  isEvaluator,
  isFunctionalManager,
  onChange,
  eapGoalContent = null,
  showModal,
  displayActionButtons,
  eapData,
  selectedReviewPeriod,
}) => {
  const { t } = useTranslation();
  const [isLock, setIsLock] = useState(true);
  const [readOnly, setReadOnly] = useState(!addMode && isLock);
  const [isCompleted] = useState(false);
  const [readOnlyStatus, setReadOnlyStatus] = useState(true);
  const [statusHasBeenUpdated, setStatusHasBeenUpdated] = useState(false);
  const [formValues, setFormValues] = useState({
    title: '',
    description: '',
    startDate: '',
    realDate: '',
    status: '',
    measure: '',
    inProgressComment: '',
  });

  useEffect(() => {
    let statusRO = !addMode && isLock;
    if (inEap && (isEmployee || isEvaluator)) {
      statusRO = false;
    }
    setReadOnlyStatus(statusRO);
  }, [addMode, isLock, isEmployee, isEvaluator, inEap, eapData, selectedReviewPeriod]);

  useEffect(() => {
    if (inEap && statusHasBeenUpdated) {
      handleSave(event, true);
    }
    setStatusHasBeenUpdated(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusHasBeenUpdated, inEap]);

  useEffect(() => {
    const assignValues = (status = null) => {
      const targetGoal = goal != null ? goal : {};
      setFormValues(
        Object.assign(
          {},
          {
            title: targetGoal.title || '',
            description: targetGoal.description || '',
            startDate:
              targetGoal?.startDate || selectedReviewPeriod?.dates.periodStartDate,
            realDate: targetGoal?.realDate || selectedReviewPeriod?.dates.periodEndDate,
            status: status || {
              Meaning: t('edi_status_not_started'),
              LookupCode: 'NOT_STARTED',
            },
            measure: targetGoal.measure || '',
            inProgressComment: targetGoal.inProgressComment || '',
          },
        ),
      );
    };
    if (goal != null) {
      if (goal.status != '' && goal.status != null) {
        loadOptionsGoalStatus(userLang)().then(response => {
          const statusValue = response.filter(x => x['LookupCode'] == goal.status);
          if (statusValue.length > 0) {
            const status = statusValue;
            assignValues(status);
          }
        });
      }
    }
    assignValues();
  }, [addMode, t, goal, userLang, selectedReviewPeriod]);

  const handleChange = property => value => {
    setFormValues({
      ...formValues,
      [property]: value.target.value,
    });
  };

  const handleChangeDate = property => value => {
    let newValue = null;
    try {
      newValue = format(value, 'yyyy-MM-dd');
    } catch {
      newValue = null;
    }
    setFormValues({
      ...formValues,
      [property]: newValue,
    });
  };

  const handleSave = (event, update = false) => {
    event?.preventDefault();
    const toSave = Object.assign({}, formValues);
    const prepareDate = date => {
      var result = new Date(new Date(date).setDate(new Date(date).getDate()));
      date = new Date(result).toISOString().split('T')[0];
      return date;
    };
    if (toSave.startDate != '' && toSave.startDate != null) {
      toSave.startDate = prepareDate(toSave.startDate);
    }
    if (toSave.realDate != '' && toSave.realDate != null) {
      toSave.realDate = prepareDate(toSave.realDate);
    }
    if (toSave.status != '' && toSave.status != null) {
      toSave.status = toSave.status['LookupCode'];
    }
    onSave(toSave, update ? goal.profileItemId : '');
  };

  const toggleLock = () => {
    const newLock = !isLock;
    setIsLock(newLock);
    setReadOnly(!addMode && newLock);
    if (newLock) {
      handleSave(event, true);
    }
  };

  const handleChangeStatus = value => {
    handleChange('status')({
      target: {
        id: 'status',
        value: value,
      },
    });
    if (inEap) {
      setStatusHasBeenUpdated(true);
    }
  };

  return (
    <div className="goal_viewer form-group">
      <form onSubmit={() => handleSave(event)}>
        <Row>
          {showModal && <h4 className="pt-3">{t('add_perf_goal')}</h4>}
          <Col md="12" className="mt-20">
            <div className="text-right">
              {displayActionButtons ? (
                <div>
                  {isLock ? (
                    <Button className="ml-10 mr-10 w50 h40" onClick={() => toggleLock()}>
                      {t('goal_modify')}
                    </Button>
                  ) : (
                    <Button className="ml-10 mr-10 w50 h40" onClick={() => toggleLock()}>
                      {t('goal_save')}
                    </Button>
                  )}
                  <Button
                    className="ml-10 mr-10 w50 h40"
                    onClick={() => onDelete(goal.profileItemId)}
                  >
                    <BsFillTrash2Fill />
                  </Button>
                </div>
              ) : (
                ''
              )}
            </div>
            <Row>
              <Col md="12">
                <TextField
                  id="title"
                  name="title"
                  type="text"
                  value={formValues.title}
                  onChange={handleChange('title')}
                  disabled={isCompleted || readOnly}
                  variant="outlined"
                  label={t('goal_name')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="w-100"
                  InputProps={{
                    style: { fontSize: 20, color: 'black', fontWeight: 500 },
                  }}
                  fullWidth
                  required
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md="12" className="mt-20">
            <TextField
              id="description"
              name="description"
              type="text"
              value={formValues.description}
              onChange={handleChange('description')}
              disabled={isCompleted || readOnly}
              variant="outlined"
              rows={4}
              label={t('goal_description')}
              InputLabelProps={{
                shrink: true,
              }}
              className="w-100 pt-10"
              InputProps={{
                inputProps: {
                  style: {
                    padding: '10px',
                    border: 'none',
                  },
                },
              }}
              multiline
            />
          </Col>
        </Row>
        <Row>
          <Col md="4" className="mt-3">
            <GoalDatePicker
              title={t('goal_start_date')}
              value={formValues.startDate}
              onChange={value => handleChangeDate('startDate')(Date.parse(value))}
              readOnly={isCompleted || readOnly}
            />
          </Col>
          <Col md="4" className="mt-3">
            <GoalDatePicker
              title={t('goal_real_date')}
              value={formValues.realDate}
              onChange={value => handleChangeDate('realDate')(Date.parse(value))}
              readOnly={isCompleted || readOnly}
            />
          </Col>
          <Col md="4" className="mt-3">
            <h6 className="font-weight-bold">{t('goal_status')}</h6>
            <AsyncSelect
              id="status"
              name="status"
              placeholder={t('my_obj_status')}
              loadOptions={loadOptionsGoalStatus(userLang)}
              closeMenuOnSelect={true}
              getOptionLabel={e => e.Meaning}
              getOptionValue={e => e.LookupCode}
              defaultOptions
              components={animatedComponents}
              value={formValues.status}
              onChange={value => handleChangeStatus(value)}
              isDisabled={isCompleted || readOnlyStatus}
            />
          </Col>
        </Row>
        <Row>
          <Col md="12 pt-2">
            <TextField
              id="indicator"
              name="indicator"
              type="text"
              value={formValues.measure}
              onChange={handleChange('measure')}
              disabled={isCompleted || readOnly}
              variant="outlined"
              rows={4}
              label={t('goal_indicator')}
              InputLabelProps={{
                shrink: true,
              }}
              className="w-100 pt-10 mt-2"
              InputProps={{
                inputProps: {
                  style: {
                    padding: '10px',
                    border: 'none',
                  },
                },
              }}
              multiline
            />
          </Col>
          <Col md="12 pt-3">
            <TextField
              id="inProgressComment"
              name="inProgressComment"
              type="text"
              value={formValues.inProgressComment}
              onChange={handleChange('inProgressComment')}
              disabled={isCompleted ? isCompleted : readOnly}
              variant="outlined"
              rows={4}
              label={t('goal_in_progress_comment')}
              InputLabelProps={{
                shrink: true,
              }}
              className="w-100 pt-10 mt-2"
              InputProps={{
                inputProps: {
                  style: {
                    padding: '10px',
                    border: 'none',
                  },
                },
              }}
              multiline
            />
          </Col>
        </Row>
        {inEap && (
          <GoalAdditionalContainer
            isEmployee={isEmployee}
            isEvaluator={isEvaluator}
            isFunctionalManager={isFunctionalManager}
            onChange={onChange}
            goalId={goal.profileItemId}
            eapGoalContent={eapGoalContent}
            isCompleted={isCompleted}
          />
        )}
        {addMode && (
          <div className="card-footer text-right">
            <Button className="mr-10 w100" onClick={() => cancel()}>
              {t('Cancel')}
            </Button>
            <Button className="mr-10 w100" type="submit">
              {t('save')}
            </Button>
          </div>
        )}
      </form>
    </div>
  );
};

const mapStateToProps = state => ({
  userLang: state.settings.userLang,
});

export default connect(mapStateToProps, null)(GoalViewer);

const GoalAdditionalContainer = ({
  isEmployee,
  isEvaluator,
  isFunctionalManager,
  onChange,
  goalId,
  eapGoalContent,
  isCompleted,
}) => {
  const [values, setValues] = useState({});
  const { t } = useTranslation();
  const localOnChange = value => {
    onChange(goalId, value);
    const fieldId = value.target.id;
    const fieldValue = value.target.value;
    setValues({ ...values, [fieldId]: fieldValue });
  };

  useEffect(() => {
    if (eapGoalContent == null) {
      setValues({});
    } else {
      setValues({ ...eapGoalContent });
    }
  }, [eapGoalContent, goalId]);

  return (
    <Row>
      <Col md="6" className="pr-5">
        <Row>
          <h6 className="mt-3 sv-string-viewer">{t('goal_employee_comment')}</h6>
          <TextareaAutosize
            id="goal_employee_comment"
            name="goal_employee_comment"
            type="text"
            minRows={4}
            className="w-100 h-50 p-10 mt-2 sd-input"
            value={values.goal_employee_comment || ''}
            onChange={x => {
              localOnChange(x);
            }}
            disabled={isCompleted ? isCompleted : !isEmployee}
          />
          <h6 className="mt-3 sv-string-viewer">{t('goal_funct_manager_comment')}</h6>
          <TextareaAutosize
            id="goal_funct_manager_comment"
            name="goal_funct_manager_comment"
            type="text"
            minRows={4}
            className="w-100 h-50 p-10 mt-2 sd-input"
            value={values.goal_funct_manager_comment || ''}
            onChange={x => {
              localOnChange(x);
            }}
            disabled={
              isCompleted ? isCompleted : isEvaluator ? isEvaluator : !isFunctionalManager
            }
          />
        </Row>
      </Col>
      <Col md="6">
        <Row>
          <Col md="12">
            <h6 className="mt-3 sv-string-viewer">{t('goal_manager_eval')}</h6>
            <Select
              id="goal_manager_eval"
              name="goal_manager_eval"
              className="goal_manager_eval mt-3"
              placeholder={t('goal_eval_placeholder')}
              options={loadPerformanceGoalRatingOptions(t)}
              closeMenuOnSelect={true}
              defaultOptions
              components={animatedComponents}
              isDisabled={isCompleted ? isCompleted : !isEvaluator}
              value={
                values.goal_manager_eval
                  ? {
                      value: values.goal_manager_eval,
                      label: t(
                        `perf_goal_rating_${
                          values.goal_manager_eval ? values.goal_manager_eval : 0
                        }`,
                      ),
                    }
                  : null
              }
              onChange={x => {
                localOnChange({
                  target: {
                    id: 'goal_manager_eval',
                    value: x.value,
                  },
                });
              }}
            />
          </Col>
          <Col md="12">
            <h6 className="mt-4 sv-string-viewer">{t('goal_manager_global_comment')}</h6>
            <TextareaAutosize
              id="goal_manager_comment"
              name="goal_manager_comment"
              type="text"
              minRows={4}
              className="w-100 h-100 p-10 mt-2 sd-input"
              value={values.goal_manager_comment || ''}
              onChange={x => {
                localOnChange(x);
              }}
              disabled={isCompleted ? isCompleted : !isEvaluator}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const GoalDatePicker = ({ title, value, onChange, readOnly, defaultValue }) => {
  return (
    <div className="goal_display_container">
      <h6 className="font-weight-bold">{title}</h6>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack spacing={3}>
          <DesktopDatePicker
            inputFormat="dd/MM/yyyy"
            nullable
            value={value !== '' ? value : defaultValue}
            disabled={readOnly}
            onError={(x, y) => {
              console.error(y);
            }}
            onChange={x => {
              onChange(x);
            }}
            renderInput={params => <TextField {...params} />}
          />
        </Stack>
      </LocalizationProvider>
    </div>
  );
};

const loadOptionsGoalStatus = userLang => () => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/vnd.oracle.adf.resourceitem+json');
  myHeaders.append('REST-Framework-Version', '4');
  myHeaders.append('Authorization', getRequestAuthentication());
  myHeaders.append('Accept-Language', userLang);
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  return fetch(
    `https://ekez${getCurrentEnv()}.fa.em2.oraclecloud.com:443/fscmRestApi/resources/11.13.18.05/commonLookupsLOV?q=LookupType = 'NATIXIS_HRG_GOAL_STATUS'&fields=Meaning,LookupCode&onlyData=true`,
    requestOptions,
  )
    .then(response => response.json())
    .then(response => {
      response = response.items;
      return response;
    })
    .catch(error => console.error('LookupType (error): ', error));
};

export const uiLoadingProfessionalProject = isLoading => dispatch => {
  dispatch({
    type: 'LOADING_PROFESSIONAL_PROJECT',
    payload: isLoading,
  });
};

export const uiLoadingEPR = isLoading => dispatch => {
  dispatch({
    type: 'LOADING_EPR',
    payload: isLoading,
  });
};

export const uiSavingDevelopmentGoal = isSaving => dispatch => {
  dispatch({
    type: 'SAVING_DEVELOPMENT_GOAL',
    payload: isSaving,
  });
};

export const uiSavingEDI = isSaving => dispatch => {
  dispatch({
    type: 'SAVING_EDI',
    payload: isSaving,
  });
};

export const uiSavingEAP = isSaving => dispatch => {
  dispatch({
    type: 'SAVING_EAP',
    payload: isSaving,
  });
};

export const uiNeedToSynchroniseEDI = needToSync => dispatch => {
  dispatch({
    type: 'NEED_TO_SYNCHRONISE_EDI',
    payload: needToSync,
  });
};

export const setIsEPRViewerLoading = e => disptach => {
  disptach({
    type: 'LOADING_EPR',
    payload: e,
  });
};

export const uiSavingFeedback = isSaving => dispatch => {
  dispatch({
    type: 'SAVING_FEEDBACK',
    payload: isSaving,
  });
};

export const uiSavingEPR = isSaving => dispatch => {
  dispatch({
    type: 'SAVING_EPR',
    data: isSaving,
  });
};

export const errorEAP = message => dispatch => {
  dispatch({
    type: 'ERROR_EAP',
    data: message,
  });
};

export const approveRecruitingPendingRequests = isSaving => dispatch => {
  dispatch({
    type: 'APPROVE_RECRUITING_PENDING_REQUESTS',
    payload: isSaving,
  });
};

export const rejectRecruitingPendingRequests = isSaving => dispatch => {
  dispatch({
    type: 'REJECT_RECRUITING_PENDING_REQUESTS',
    payload: isSaving,
  });
};

export const uiLoadingJobReqs = isLoading => dispatch => {
  dispatch({
    type: 'RECRUITING_LOADING',
    payload: isLoading,
  });
};

export const uiLoadingJobReqsOutcomes = isLoading => dispatch => {
  dispatch({
    type: 'RECRUITING_OUTCOMES_LOADING',
    payload: isLoading,
  });
};

import { getEmployeeProfileId } from '../../../model/employee';
import { getCurrentEnv } from '../../manager';
import { GenericDecoder } from '../common/GenericDecoder';
import {
  CAREER_PREFERENCES_SECTION_IDS,
  PERSON_CAREER_STATEMENT_SECTION_IDS,
} from '../common/section_ids_configuration';
import { decodeBase64 } from '../helpers';

const createOrUpdate = requestData => {
  let [
    personId,
    profileId,
    itemIdCareerPref,
    itemIdCareerStatement,
    professionalProject,
  ] = requestData.destructure();
  const {
    careerStatement,
    desiredTimeFrame,
    careerChoice,
    whatjob,
    NationalgeographicalMobility,
    InternationalgeographicalMobility,
    primaryLocation,
  } = professionalProject;

  console.info('--- Debug IDP POST data');

  if (profileId == null) {
    const targetProfileId = getEmployeeProfileId(personId);
    profileId = targetProfileId;
    console.info(`- IDP profileId null, replacing with: ${targetProfileId}`);
  }

  console.info(`- PersonId: ${personId}`);
  console.info(`- ProfileId: ${profileId}`);
  console.info(`- itemId Career Pref: ${itemIdCareerPref}`);
  console.info(`- itemId Career Statement: ${itemIdCareerStatement}`);

  const requestBody = `<soapenv:Envelope
    xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
    xmlns:typ="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/types/"
    xmlns:pub="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/"
    xmlns:prof="http://xmlns.oracle.com/apps/hcm/profiles/core/flex/profileItem/"
    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
    <soapenv:Header/>
    <soapenv:Body>
      <typ:mergeProfile>
        <typ:profile>
          <pub:ProfileId>${profileId}</pub:ProfileId>
          <pub:ProfileItem>
            <pub:ProfileId>${profileId}</pub:ProfileId>
            ${PersonCareerStatementDecoder.getRequestHeader()}
            <pub:DateFrom>2021-01-01</pub:DateFrom>
            <pub:ProfileItemId>${itemIdCareerStatement}</pub:ProfileItemId>
            <pub:ItemClob1File>${careerStatement}</pub:ItemClob1File>
          </pub:ProfileItem>
          <pub:ProfileItem>
            <pub:ProfileId>${profileId}</pub:ProfileId>
            ${CareerPreferencesDecoder.getRequestHeader()}
            <pub:DateFrom>2021-01-01</pub:DateFrom>
            <pub:ProfileItemId>${itemIdCareerPref}</pub:ProfileItemId>
            ${
              desiredTimeFrame
                ? `<pub:ItemText301>${desiredTimeFrame}</pub:ItemText301>`
                : `<pub:ItemText301 xsi:nil="true"/>`
            }
            ${
              careerChoice
                ? `<pub:ItemText303>${careerChoice}</pub:ItemText303>`
                : `<pub:ItemText303 xsi:nil="true"/>`
            }
            ${
              whatjob
                ? `<pub:ItemText304>${whatjob}</pub:ItemText304>`
                : `<pub:ItemText304 xsi:nil="true"/>`
            }
            <pub:ItemText305>${NationalgeographicalMobility}</pub:ItemText305>
            <pub:ItemText306>${InternationalgeographicalMobility}</pub:ItemText306>
            ${
              primaryLocation
                ? `<pub:ItemText24010>${primaryLocation}</pub:ItemText24010>`
                : `<pub:ItemText24010 xsi:nil="true"/>`
            }
          </pub:ProfileItem>
        </typ:profile>
      </typ:mergeProfile>
    </soapenv:Body>
  </soapenv:Envelope>`;
  return requestBody;
};

const ProfessionalProject = {
  createOrUpdate: createOrUpdate,
};

export default ProfessionalProject;

export class PersonCareerStatementDecoder extends GenericDecoder {
  static SECTION_ID = PERSON_CAREER_STATEMENT_SECTION_IDS[getCurrentEnv()];
  static NAME = 'PERSON_CAREER_STATEMENT';
  static ATTRIBUTES = {
    'ns1:ProfileId': { key: 'profileId' },
    'ns1:ProfileItemId': { key: 'profileItemId' },
    'ns1:DateFrom': { key: 'date' },
    'ns1:ItemClob1File': { key: 'careerStatement', prepare: x => decodeBase64(x) },
  };

  static getContentType() {
    return 'CAREER_STATEMENT';
  }

  static getSectionName() {
    return PersonCareerStatementDecoder.NAME;
  }

  static getRequestHeader() {
    return `<pub:ContentType>${PersonCareerStatementDecoder.getContentType()}</pub:ContentType>
            <pub:SectionName>${PersonCareerStatementDecoder.getSectionName()}</pub:SectionName>`;
  }
}

export class CareerPreferencesDecoder extends GenericDecoder {
  static FORCE_CONTENT_TYPE_ID = '112';
  static SECTION_ID = CAREER_PREFERENCES_SECTION_IDS[getCurrentEnv()];
  static NAME = 'CAREER_PREFERENCES';
  static ATTRIBUTES = {
    'ns1:ProfileId': { key: 'profileId' },
    'ns1:ProfileItemId': { key: 'profileItemId' },
    'ns1:DateFrom': { key: 'date' },
    'ns1:ItemText301': { key: 'desiredTimeFrame' },
    'ns1:ItemText303': { key: 'careerChoice' },
    'ns1:ItemText304': { key: 'whatjob' },
    'ns1:ItemText305': { key: 'NationalgeographicalMobility' },
    'ns1:ItemText306': { key: 'InternationalgeographicalMobility' },
    'ns1:ItemText24010': { key: 'primaryLocation' },
    'ns1:ContentTypeId': { key: 'contentTypeId' },
  };

  static getContentType() {
    return 'CAREER_PREFERENCES';
  }

  static getSectionName() {
    return `${CareerPreferencesDecoder.getContentType()}_${
      CareerPreferencesDecoder.SECTION_ID
    }`;
  }

  static getRequestHeader() {
    return `<pub:ContentType>${CareerPreferencesDecoder.getContentType()}</pub:ContentType>
            <pub:SectionName>${CareerPreferencesDecoder.getSectionName()}</pub:SectionName>`;
  }
}

export class ProfessionalProjectRequestData {
  constructor(personId, profileId, itemIdCareerPref, itemIdCareerStatement, payload) {
    this._personId = personId;
    this._profileId = profileId;
    this._itemIdCareerPref = itemIdCareerPref;
    this._itemIdCareerStatement = itemIdCareerStatement;
    this._payload = payload;
  }

  destructure() {
    return [
      this._personId,
      this._profileId,
      this._itemIdCareerPref,
      this._itemIdCareerStatement,
      this._payload,
    ];
  }
}

import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Survey } from 'survey-react-ui';
import { Model } from 'survey-core';
import ActionButtons from './ActionButtons';
import { getCurrentUserId } from '../../../../../api/manager';
import OutsideClickHandler from 'react-outside-click-handler';
import { isClickOnScrollBar, isStatusEAPCompleted } from '../../../common/utils';

var myCss = {
  question: {
    description: 'sd-title',
    titleOnAnswer: 'question-title-answered',
  },
};

const MANAGER_OVERALL_ASSESSMENT = 'managerOverallAssessment';
const Four = props => {
  const [isManager, setIsManager] = useState(false);
  const [isMatrixManager, setIsMatrixManager] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [survey, setSurvey] = useState(null);
  const [ratingComp, setRatingComp] = useState(null);

  useEffect(() => {
    let isEmployee = false;
    let isManager = false;
    let isMatrixManager = false;
    if (props.targetEmployeeId) {
      isEmployee = getCurrentUserId() === props.targetEmployeeId;
    }
    isManager = props.isEvaluator;
    isMatrixManager =
      props.isEvaluator == props.isFunctionalManager ? false : props.isFunctionalManager;
    setIsMatrixManager(isMatrixManager);
    setIsManager(isManager);
    setIsReadOnly(
      (isEmployee == false && isManager == false && isMatrixManager == false) ||
        props.forceReadOnly,
    );

    let localSurvey = null;
    if (props.isEligibleLeader) {
      localSurvey = new Model(behaviorsLeader);
    } else {
      localSurvey = new Model(behaviors);
    }

    localSurvey.locale = props.userLang;
    localSurvey.focusFirstQuestionAutomatic = true;
    localSurvey.onTextMarkdown.add(function (survey, options) {
      var str = options.text;
      options.html = str;
    });
    const setFetchedValues = () => {
      let hasValue = false;
      if (props.eapData?.ratingComp) {
        hasValue = true;
        localSurvey.setValue('managerOverallAssessment', props.eapData.ratingComp);
      }
      if (
        props.eapData &&
        props.eapData.eapPayload &&
        props.eapData.eapPayload.behaviors
      ) {
        hasValue = true;
        const behaviors = props.eapData.eapPayload.behaviors;
        behaviors.forEach(x => {
          if (x.name !== 'managerOverallAssessment') {
            localSurvey.setValue(x.name, x.value);
          }
          if (x.comment != undefined && x.comment != '') {
            localSurvey.setComment(x.name, x.comment);
          }
        });
      } else {
        !hasValue && localSurvey.clear();
      }
    };

    const setQuestionReadonlyness = () => {
      localSurvey.getAllQuestions().forEach(q => {
        if (isStatusEAPCompleted(props?.eapData?.status)) {
          q.setPropertyValue('readOnly', true);
        } else {
          if (q.name.startsWith('employee')) {
            q.setPropertyValue('readOnly', !isEmployee || props.forceReadOnly);
          }
          if (q.name.startsWith('manager')) {
            q.setPropertyValue('readOnly', !isManager);
            q.setPropertyValue('readOnly', !isManager);
          }
          if (q.name.startsWith('matrix')) {
            q.setPropertyValue('readOnly', !isMatrixManager);
          }
        }
      });
    };

    setQuestionReadonlyness();
    setFetchedValues();
    setSurvey(localSurvey);
  }, [
    props.eapData,
    props.targetEmployeeId,
    props.userLang,
    isReadOnly,
    props.forceReadOnly,
    props.isEligibleLeader,
    props.isEvaluator,
    props.isFunctionalManager,
  ]);

  const onChangeSurvey = (_, update) => {
    if (update['name'] === MANAGER_OVERALL_ASSESSMENT) {
      setRatingComp(update.value);
    }
  };

  const prepareData = plainData => {
    return plainData.map(x => {
      const questionName = x.name;
      const comment = survey.getComment(questionName);
      return { name: x.name, title: x.title, value: x.value ?? '', comment: comment };
    });
  };

  const handleChangeStep = () => {
    if (!isReadOnly) {
      const data = prepareData(survey.getPlainData());
      const behaviorsGlobalRating = { ratingType: 'behaviors', ratingValue: ratingComp };
      props.saveEAP(
        { behaviors: data },
        isManager || isMatrixManager,
        behaviorsGlobalRating,
      );
    }
  };

  const handleNext = handleChangeStep;
  const handlePrev = handleChangeStep;

  return (
    <>
      <OutsideClickHandler
        disabled={!props.isActive || props.forceReadOnly}
        onOutsideClick={event => {
          if (!isClickOnScrollBar(event)) {
            console.warn('-- Auto save trigger: SAVING STEP 4');
            handleNext();
          }
        }}
        useCapture={false}
      >
        <Row>
          <Col>
            <div>
              {survey != null ? (
                <Survey
                  model={survey}
                  css={myCss}
                  onValueChanged={(a, b) => onChangeSurvey(a, b)}
                />
              ) : null}
            </div>
          </Col>
        </Row>
        <ActionButtons {...props} nextCallback={handleNext} prevCallback={handlePrev} />
      </OutsideClickHandler>
    </>
  );
};

export default Four;

const behaviors = {
  logoPosition: 'right',
  pages: [
    {
      name: 'page1',
      elements: [
        {
          type: 'panel',
          name: 'globalPanel',
          elements: [
            {
              type: 'panel',
              name: 'panel_1',
              elements: [
                {
                  type: 'panel',
                  name: 'subPanel_1',
                  elements: [
                    {
                      type: 'comment',
                      name: 'employeeComment_1',
                      title: {
                        default: 'Employee comment',
                        fr: 'Commentaire collaborateur',
                      },
                      readOnly: true,
                    },
                    {
                      type: 'comment',
                      name: 'matrixComment_1',
                      title: {
                        default: 'Functional Manager comment',
                        fr: 'Commentaire manager fonctionnel',
                      },
                      readOnly: true,
                    },
                  ],
                },
                {
                  type: 'panel',
                  name: 'managerPanel_1',
                  elements: [
                    {
                      type: 'dropdown',
                      name: 'managerAssessment_1',
                      title: {
                        default: 'Manager reviewer assessment',
                        fr: 'Evaluation manager évaluateur',
                      },
                      isRequired: true,
                      readOnly: true,
                      showOptionsCaption: false,
                      choices: [
                        {
                          value: 'Area for development',
                          text: {
                            fr: 'Axe de développement',
                            default: 'Area for development',
                          },
                        },
                        {
                          value: 'Strength',
                          text: {
                            fr: "Point d'appui",
                            default: 'Strength',
                          },
                        },
                        {
                          value: 'NA',
                          text: '-',
                        },
                      ],
                      placeholder: {
                        default: 'Select...',
                        fr: 'Selectionner...',
                      },
                    },
                    {
                      type: 'comment',
                      name: 'managerComment_1',
                      title: {
                        default: 'Manager reviewer comment',
                        fr: 'Commentaire manager évaluateur',
                      },
                      readOnly: true,
                      rows: 6,
                    },
                  ],
                  startWithNewLine: false,
                },
              ],
              title: {
                default: '1 - Collective Intelligence',
                fr: '1 - Intelligence collective ',
              },
              description: {
                default:
                  'Team work, openness to diverse viewpoints, ability to foster trusting relationships',
                fr: 'Esprit d’équipe, ouverture à la diversité des points de vue, attitude de confiance dans les relations',
              },
            },
            {
              type: 'panel',
              name: 'panel_2',
              elements: [
                {
                  type: 'panel',
                  name: 'subPanel_2',
                  elements: [
                    {
                      type: 'comment',
                      name: 'employeeComment_2',
                      title: {
                        default: 'Employee comment',
                        fr: 'Commentaire collaborateur',
                      },
                    },
                    {
                      type: 'comment',
                      name: 'matrixComment_2',
                      title: {
                        default: 'Functional Manager comment',
                        fr: 'Commentaire manager fonctionnel',
                      },
                    },
                  ],
                },
                {
                  type: 'panel',
                  name: 'managerPanel_2',
                  elements: [
                    {
                      type: 'dropdown',
                      name: 'managerAssessment_2',
                      title: {
                        default: 'Manager reviewer assessment',
                        fr: 'Evaluation manager évaluateur',
                      },
                      isRequired: true,
                      showOptionsCaption: false,
                      choices: [
                        {
                          value: 'Area for development',
                          text: {
                            fr: 'Axe de développement',
                            default: 'Area for development',
                          },
                        },
                        {
                          value: 'Strength',
                          text: {
                            fr: "Point d'appui",
                            default: 'Strength',
                          },
                        },
                        {
                          value: 'NA',
                          text: '-',
                        },
                      ],
                    },
                    {
                      type: 'comment',
                      name: 'managerComment_2',
                      title: {
                        default: 'Manager reviewer comment',
                        fr: 'Commentaire manager évaluateur',
                      },
                      rows: 6,
                    },
                  ],
                  startWithNewLine: false,
                },
              ],
              title: {
                default: '2 - Entrepreneurial Mindset',
                fr: '2 - Esprit entrepreneurial',
              },
              description: {
                fr: 'Agilité, engagement, initiative',
                default: 'Agility, commitment, initiative',
              },
            },
            {
              type: 'panel',
              name: 'panel_3',
              elements: [
                {
                  type: 'panel',
                  name: 'subPanel_3',
                  elements: [
                    {
                      type: 'comment',
                      name: 'employeeComment_3',
                      title: {
                        default: 'Employee comment',
                        fr: 'Commentaire collaborateur',
                      },
                    },
                    {
                      type: 'comment',
                      name: 'matrixComment_3',
                      title: {
                        default: 'Functional Manager comment',
                        fr: 'Commentaire manager fonctionnel',
                      },
                    },
                  ],
                },
                {
                  type: 'panel',
                  name: 'managerPanel_3',
                  elements: [
                    {
                      type: 'dropdown',
                      name: 'managerAssessment_3',
                      title: {
                        default: 'Manager reviewer assessment',
                        fr: 'Evaluation manager évaluateur',
                      },
                      isRequired: true,
                      showOptionsCaption: false,
                      choices: [
                        {
                          value: 'Area for development',
                          text: {
                            fr: 'Axe de développement',
                            default: 'Area for development',
                          },
                        },
                        {
                          value: 'Strength',
                          text: {
                            fr: "Point d'appui",
                            default: 'Strength',
                          },
                        },
                        {
                          value: 'NA',
                          text: '-',
                        },
                      ],
                    },
                    {
                      type: 'comment',
                      name: 'managerComment_3',
                      title: {
                        default: 'Manager reviewer comment',
                        fr: 'Commentaire manager évaluateur',
                      },
                      rows: 6,
                    },
                  ],
                  startWithNewLine: false,
                },
              ],
              title: {
                default: '3 - Sustainable Impact',
                fr: '3 -  Impact durable',
              },
              description: {
                fr: 'Développement de relations durables avec les clients, apport de valeur ajoutée de manière responsable',
                default:
                  'Ability to establish sustainable relationships with clients, capacity to create added value in a responsible way',
              },
            },
          ],
          title: {
            default:
              'This step aims to assess the behaviours used to achieve the results, in relation to the three Purple Way values.',
            fr: 'Cette étape vise à évaluer les comportements adoptés pour atteindre les résultats, en lien avec les trois valeurs de la Purple Way.',
          },
          description: {
            fr: 'En tant que collaborateur, pour préparer votre entretien, indiquez de manière factuelle les comportements que vous avez mis en œuvre.<br><br>Revoir les comportements de la <a class="font-weight-bold" style="text-decoration: underline;" target="_blank" href="https://objectstorage.eu-frankfurt-1.oraclecloud.com/n/natixishr/b/HCMLibrary/o/2022%2009%20MustHave%20Behaviors.pdf">Purple Way</a>',
            default:
              'As an employee, to prepare for your meeting, provide a factual description of the behaviours you have put into action.<br><br>To review the <a class="font-weight-bold" style="text-decoration: underline;" target="_blank" href="https://objectstorage.eu-frankfurt-1.oraclecloud.com/n/natixishr/b/HCMLibrary/o/2022%2009%20MustHave%20Behaviors.pdf">Purple Way behaviors</a>',
          },
        },
        {
          type: 'panel',
          name: 'overallBehaviorsRating',
          elements: [
            {
              type: 'panel',
              name: 'subPanel_4',
              elements: [
                {
                  type: 'comment',
                  name: 'employeeOverallComment',
                  title: {
                    default: 'Employee comment',
                    fr: 'Commentaire collaborateur',
                  },
                },
                {
                  type: 'comment',
                  name: 'matrixOverallComment',
                  title: {
                    default: 'Functional Manager comment',
                    fr: 'Commentaire manager fonctionnel',
                  },
                },
              ],
            },
            {
              type: 'panel',
              name: 'panel4',
              elements: [
                {
                  type: 'dropdown',
                  name: 'managerOverallAssessment',
                  title: {
                    default: 'Manager reviewer assessment',
                    fr: 'Evaluation manager évaluateur',
                  },
                  isRequired: true,
                  showOptionsCaption: false,
                  choices: [
                    {
                      value: '0',
                      text: {
                        default: '0 - Not measured',
                        fr: '0 - Non mesuré',
                      },
                    },
                    {
                      value: '1',
                      text: {
                        default: '1 - Poor',
                        fr: '1 - Insuffisant',
                      },
                    },
                    {
                      value: '2',
                      text: {
                        default: '2 - Satisfactory',
                        fr: '2 - Correct',
                      },
                    },
                    {
                      value: '3',
                      text: {
                        default: '3 - Good',
                        fr: '3 - Bon',
                      },
                    },
                    {
                      value: '4',
                      text: {
                        default: '4 - Very good',
                        fr: '4 - Très bon',
                      },
                    },
                    {
                      value: '5',
                      text: {
                        default: '5 - Exceptional',
                        fr: '5 - Exceptionnel',
                      },
                    },
                  ],
                },
                {
                  type: 'comment',
                  name: 'managerOverallComment',
                  title: {
                    default: 'Manager reviewer comment',
                    fr: 'Commentaire manager évaluateur',
                  },
                  isRequired: true,
                },
              ],
              startWithNewLine: false,
            },
          ],
          title: {
            default: "Overall Behavior's rating",
            fr: 'Evaluation globale des comportements',
          },
        },
      ],
    },
  ],
  showNavigationButtons: 'none',
  showCompletedPage: false,
  showQuestionNumbers: 'off',
  widthMode: 'responsive',
};

const behaviorsLeader = {
  logoPosition: 'right',
  pages: [
    {
      name: 'page1',
      elements: [
        {
          type: 'panel',
          name: 'panel1',
          elements: [
            {
              type: 'panel',
              name: 'panel2',
              elements: [
                {
                  type: 'panel',
                  name: 'panel4',
                  elements: [
                    {
                      type: 'comment',
                      name: 'employeeComment_1',
                      title: {
                        default: 'Employee comment',
                        fr: 'Commentaire collaborateur',
                      },
                    },
                    {
                      type: 'comment',
                      name: 'matrixComment_1',
                      title: {
                        default: 'Functional Manager comment',
                        fr: 'Commentaire manager fonctionnel',
                      },
                    },
                  ],
                },
                {
                  type: 'panel',
                  name: 'panel3',
                  elements: [
                    {
                      type: 'dropdown',
                      name: 'managerAssessment_1',
                      title: {
                        default: 'Manager reviewer assessment',
                        fr: 'Evaluation manager évaluateur',
                      },
                      isRequired: true,
                      showOptionsCaption: false,
                      choices: [
                        {
                          value: 'Area for development',
                          text: {
                            fr: 'Axe de développement',
                            default: 'Area for development',
                          },
                        },
                        {
                          value: 'Strength',
                          text: {
                            fr: "Point d'appui",
                            default: 'Strength',
                          },
                        },
                        {
                          value: 'NA',
                          text: '-',
                        },
                      ],
                    },
                    {
                      type: 'comment',
                      name: 'managerComment_1',
                      title: {
                        default: 'Manager reviewer comment',
                        fr: 'Commentaire manager évaluateur',
                      },
                      rows: 6,
                    },
                  ],
                  startWithNewLine: false,
                },
              ],
              title: {
                default: '1 - Collective Intelligence',
                fr: '1 - Intelligence collective ',
              },
              description: {
                default:
                  'Team work, openness to diverse viewpoints, ability to foster trusting relationships',
                fr: 'Esprit d’équipe, ouverture à la diversité des points de vue, attitude de confiance dans les relations',
              },
            },
            {
              type: 'panel',
              name: 'panel5',
              elements: [
                {
                  type: 'panel',
                  name: 'panel6',
                  elements: [
                    {
                      type: 'comment',
                      name: 'employeeComment_2',
                      title: {
                        default: 'Employee comment',
                        fr: 'Commentaire collaborateur',
                      },
                    },
                    {
                      type: 'comment',
                      name: 'matrixComment_2',
                      title: {
                        default: 'Functional Manager comment',
                        fr: 'Commentaire manager fonctionnel',
                      },
                    },
                  ],
                },
                {
                  type: 'panel',
                  name: 'panel7',
                  elements: [
                    {
                      type: 'dropdown',
                      name: 'managerAssessment_2',
                      title: {
                        default: 'Manager reviewer assessment',
                        fr: 'Evaluation manager évaluateur',
                      },
                      isRequired: true,
                      showOptionsCaption: false,
                      choices: [
                        {
                          value: 'Area for development',
                          text: {
                            fr: 'Axe de développement',
                            default: 'Area for development',
                          },
                        },
                        {
                          value: 'Strength',
                          text: {
                            fr: "Point d'appui",
                            default: 'Strength',
                          },
                        },
                        {
                          value: 'NA',
                          text: '-',
                        },
                      ],
                    },
                    {
                      type: 'comment',
                      name: 'managerComment_2',
                      title: {
                        default: 'Manager reviewer comment',
                        fr: 'Commentaire manager évaluateur',
                      },
                      rows: 6,
                    },
                  ],
                  startWithNewLine: false,
                },
              ],
              title: {
                default: '2 - Entrepreneurial Mindset',
                fr: '2 - Esprit entrepreneurial',
              },
              description: {
                fr: 'Agilité, engagement, initiative',
                default: 'Agility, commitment, initiative',
              },
            },
            {
              type: 'panel',
              name: 'panel8',
              elements: [
                {
                  type: 'panel',
                  name: 'panel9',
                  elements: [
                    {
                      type: 'comment',
                      name: 'employeeComment_3',
                      title: {
                        default: 'Employee comment',
                        fr: 'Commentaire collaborateur',
                      },
                    },
                    {
                      type: 'comment',
                      name: 'matrixComment_3',
                      title: {
                        default: 'Functional Manager comment',
                        fr: 'Commentaire manager fonctionnel',
                      },
                    },
                  ],
                },
                {
                  type: 'panel',
                  name: 'panel10',
                  elements: [
                    {
                      type: 'dropdown',
                      name: 'managerAssessment_3',
                      title: {
                        default: 'Manager reviewer assessment',
                        fr: 'Evaluation manager évaluateur',
                      },
                      isRequired: true,
                      showOptionsCaption: false,
                      choices: [
                        {
                          value: 'Area for development',
                          text: {
                            fr: 'Axe de développement',
                            default: 'Area for development',
                          },
                        },
                        {
                          value: 'Strength',
                          text: {
                            fr: "Point d'appui",
                            default: 'Strength',
                          },
                        },
                        {
                          value: 'NA',
                          text: '-',
                        },
                      ],
                    },
                    {
                      type: 'comment',
                      name: 'managerComment_3',
                      title: {
                        default: 'Manager reviewer comment',
                        fr: 'Commentaire manager évaluateur',
                      },
                      rows: 6,
                    },
                  ],
                  startWithNewLine: false,
                },
              ],
              title: {
                default: '3 - Sustainable Impact',
                fr: '3 -  Impact durable',
              },
              description: {
                fr: 'Développement de relations durables avec les clients, apport de valeur ajoutée de manière responsable',
                default:
                  'Ability to establish sustainable relationships with clients, capacity to create added value in a responsible way',
              },
            },
            {
              type: 'panel',
              name: 'panel11',
              elements: [
                {
                  type: 'panel',
                  name: 'panel12',
                  elements: [
                    {
                      type: 'comment',
                      name: 'employeeComment_4',
                      title: {
                        default: 'Employee comment',
                        fr: 'Commentaire collaborateur',
                      },
                    },
                    {
                      type: 'comment',
                      name: 'matrixComment_4',
                      title: {
                        default: 'Functional Manager comment',
                        fr: 'Commentaire manager fonctionnel',
                      },
                    },
                  ],
                },
                {
                  type: 'panel',
                  name: 'panel13',
                  elements: [
                    {
                      type: 'dropdown',
                      name: 'managerAssessment_4',
                      title: {
                        default: 'Manager reviewer assessment',
                        fr: 'Evaluation manager évaluateur',
                      },
                      isRequired: true,
                      showOptionsCaption: false,
                      choices: [
                        {
                          value: 'Area for development',
                          text: {
                            fr: 'Axe de développement',
                            default: 'Area for development',
                          },
                        },
                        {
                          value: 'Strength',
                          text: {
                            fr: "Point d'appui",
                            default: 'Strength',
                          },
                        },
                        {
                          value: 'NA',
                          text: '-',
                        },
                      ],
                    },
                    {
                      type: 'comment',
                      name: 'managerComment_4',
                      title: {
                        default: 'Manager reviewer comment',
                        fr: 'Commentaire manager évaluateur',
                      },
                      rows: 6,
                    },
                  ],
                  startWithNewLine: false,
                },
              ],
              title: {
                default: '4 - Caring & Demanding',
                fr: '4 - Bienveillance et Exigence',
              },
              description: {
                fr: 'Favorise les échanges avec un a priori positif ; Écoute et interagit avec empathie ; Développe une culture de l’excellence et du résultat ; Se montre exigeant dans le pilotage et l’évaluation de la performance de chacun',
                default:
                  'Listens and interacts with empathy; Is demanding in directing and assessing each person’s performance; Encourages discussions with an open mind; Develops a culture of excellence and results',
              },
            },
            {
              type: 'panel',
              name: 'panel14',
              elements: [
                {
                  type: 'panel',
                  name: 'panel15',
                  elements: [
                    {
                      type: 'comment',
                      name: 'employeeComment_5',
                      title: {
                        default: 'Employee comment',
                        fr: 'Commentaire collaborateur',
                      },
                    },
                    {
                      type: 'comment',
                      name: 'matrixComment_5',
                      title: {
                        default: 'Functional Manager comment',
                        fr: 'Commentaire manager fonctionnel',
                      },
                    },
                  ],
                },
                {
                  type: 'panel',
                  name: 'panel16',
                  elements: [
                    {
                      type: 'dropdown',
                      name: 'managerAssessment_5',
                      title: {
                        default: 'Manager reviewer assessment',
                        fr: 'Evaluation manager évaluateur',
                      },
                      isRequired: true,
                      showOptionsCaption: false,
                      choices: [
                        {
                          value: 'Area for development',
                          text: {
                            fr: 'Axe de développement',
                            default: 'Area for development',
                          },
                        },
                        {
                          value: 'Strength',
                          text: {
                            fr: "Point d'appui",
                            default: 'Strength',
                          },
                        },
                        {
                          value: 'NA',
                          text: '-',
                        },
                      ],
                    },
                    {
                      type: 'comment',
                      name: 'managerComment_5',
                      title: {
                        default: 'Manager reviewer comment',
                        fr: 'Commentaire manager évaluateur',
                      },
                      rows: 6,
                    },
                  ],
                  startWithNewLine: false,
                },
              ],
              title: {
                default: '5 - Unleash Talent',
                fr: '5 - Libère les talents',
              },
              description: {
                fr: 'Fait grandir les collaborateurs et les équipes au quotidien ; Promeut la mobilité professionnelle au-delà de son propre domaine de responsabilité, dans l’intérêt du collaborateur et de l’entreprise ; Capitalise sur la diversité pour réussir',
                default:
                  'Promotes employee and team development on a daily basis; Encourages professional mobility beyond his/her own field of responsibility, in the interests of the employee and the company; Capitalizes on diversity in order to succeed',
              },
            },
            {
              type: 'panel',
              name: 'panel17',
              elements: [
                {
                  type: 'panel',
                  name: 'panel18',
                  elements: [
                    {
                      type: 'comment',
                      name: 'employeeComment_6',
                      title: {
                        default: 'Employee comment',
                        fr: 'Commentaire collaborateur',
                      },
                    },
                    {
                      type: 'comment',
                      name: 'matrixComment_6',
                      title: {
                        default: 'Functional Manager comment',
                        fr: 'Commentaire manager fonctionnel',
                      },
                    },
                  ],
                },
                {
                  type: 'panel',
                  name: 'panel19',
                  elements: [
                    {
                      type: 'dropdown',
                      name: 'managerAssessment_6',
                      title: {
                        default: 'Manager reviewer assessment',
                        fr: 'Evaluation manager évaluateur',
                      },
                      isRequired: true,
                      showOptionsCaption: false,
                      choices: [
                        {
                          value: 'Area for development',
                          text: {
                            fr: 'Axe de développement',
                            default: 'Area for development',
                          },
                        },
                        {
                          value: 'Strength',
                          text: {
                            fr: "Point d'appui",
                            default: 'Strength',
                          },
                        },
                        {
                          value: 'NA',
                          text: '-',
                        },
                      ],
                    },
                    {
                      type: 'comment',
                      name: 'managerComment_6',
                      title: {
                        default: 'Manager reviewer comment',
                        fr: 'Commentaire manager évaluateur',
                      },
                      rows: 6,
                    },
                  ],
                  startWithNewLine: false,
                },
              ],
              title: {
                default: '6 - Play as a Team',
                fr: '6 - Joue collectif',
              },
              description: {
                fr: 'Se rend accessible dans la relation et travaille aisément avec tous les niveaux de l’entreprise ; Favorise le « faire ensemble » et le travail en équipe ; Promeut les initiatives transverses, au service de GFS',
                default:
                  'Is accessible in relationships and at ease working at all levels of the activity; Encourages working together and teamwork; Promotes cross-functional initiatives serving GFS',
              },
            },
            {
              type: 'panel',
              name: 'panel20',
              elements: [
                {
                  type: 'panel',
                  name: 'panel21',
                  elements: [
                    {
                      type: 'comment',
                      name: 'employeeComment_7',
                      title: {
                        default: 'Employee comment',
                        fr: 'Commentaire collaborateur',
                      },
                    },
                    {
                      type: 'comment',
                      name: 'matrixComment_7',
                      title: {
                        default: 'Functional Manager comment',
                        fr: 'Commentaire manager fonctionnel',
                      },
                    },
                  ],
                },
                {
                  type: 'panel',
                  name: 'panel22',
                  elements: [
                    {
                      type: 'dropdown',
                      name: 'managerAssessment_7',
                      title: {
                        default: 'Manager reviewer assessment',
                        fr: 'Evaluation manager évaluateur',
                      },
                      isRequired: true,
                      showOptionsCaption: false,
                      choices: [
                        {
                          value: 'Area for development',
                          text: {
                            fr: 'Axe de développement',
                            default: 'Area for development',
                          },
                        },
                        {
                          value: 'Strength',
                          text: {
                            fr: "Point d'appui",
                            default: 'Strength',
                          },
                        },
                        {
                          value: 'NA',
                          text: '-',
                        },
                      ],
                    },
                    {
                      type: 'comment',
                      name: 'managerComment_7',
                      title: {
                        default: 'Manager reviewer comment',
                        fr: 'Commentaire manager évaluateur',
                      },
                      rows: 6,
                    },
                  ],
                  startWithNewLine: false,
                },
              ],
              title: {
                default: '7 - Challenge Status Quo',
                fr: '7 - Entreprend et sait remettre en cause',
              },
              description: {
                fr: 'Identifie et met en œuvre des solutions innovantes ; Maîtrise la complexité et recherche la simplicité ; Se montre ouvert et sait remettre en question ses pratiques ; Sait prendre des décisions difficiles lorsque cela est nécessaire',
                default:
                  'Identifies and implements innovative solutions; Masters complexity and looks for simplicity ; Is open and knows how to question their practices; Knows how to take difficult decisions whenever necessary',
              },
            },
            {
              type: 'panel',
              name: 'panel23',
              elements: [
                {
                  type: 'panel',
                  name: 'panel24',
                  elements: [
                    {
                      type: 'comment',
                      name: 'employeeComment_8',
                      title: {
                        default: 'Employee comment',
                        fr: 'Commentaire collaborateur',
                      },
                    },
                    {
                      type: 'comment',
                      name: 'matrixComment_8',
                      title: {
                        default: 'Functional Manager comment',
                        fr: 'Commentaire manager fonctionnel',
                      },
                    },
                  ],
                },
                {
                  type: 'panel',
                  name: 'panel25',
                  elements: [
                    {
                      type: 'dropdown',
                      name: 'managerAssessment_8',
                      title: {
                        default: 'Manager reviewer assessment',
                        fr: 'Evaluation manager évaluateur',
                      },
                      isRequired: true,
                      showOptionsCaption: false,
                      choices: [
                        {
                          value: 'Area for development',
                          text: {
                            fr: 'Axe de développement',
                            default: 'Area for development',
                          },
                        },
                        {
                          value: 'Strength',
                          text: {
                            fr: "Point d'appui",
                            default: 'Strength',
                          },
                        },
                        {
                          value: 'NA',
                          text: '-',
                        },
                      ],
                    },
                    {
                      type: 'comment',
                      name: 'managerComment_8',
                      title: {
                        default: 'Manager reviewer comment',
                        fr: 'Commentaire manager évaluateur',
                      },
                      rows: 6,
                    },
                  ],
                  startWithNewLine: false,
                },
              ],
              title: {
                default: '8 - Inspire to Engage',
                fr: "8 - Inspire pour créer l'engagement",
              },
              description: {
                fr: 'Donne du sens et porte une vision claire ; Met en cohérence ses paroles et ses actes ; Fixe et maintient le cap en pratiquant les ajustements nécessaires ; Embarque et suscite l’adhésion',
                default:
                  'Provides direction and brings a clear vision; Ensures consistency in words and actions; Sets and stays on course by making the necessary adjustments; Fosters and promotes a sense of belonging',
              },
            },
            {
              type: 'panel',
              name: 'panel26',
              elements: [
                {
                  type: 'panel',
                  name: 'panel27',
                  elements: [
                    {
                      type: 'comment',
                      name: 'employeeComment_9',
                      title: {
                        default: 'Employee comment',
                        fr: 'Commentaire collaborateur',
                      },
                    },
                    {
                      type: 'comment',
                      name: 'matrixComment_9',
                      title: {
                        default: 'Functional Manager comment',
                        fr: 'Commentaire manager fonctionnel',
                      },
                    },
                  ],
                },
                {
                  type: 'panel',
                  name: 'panel28',
                  elements: [
                    {
                      type: 'dropdown',
                      name: 'managerAssessment_9',
                      title: {
                        default: 'Manager reviewer assessment',
                        fr: 'Evaluation manager évaluateur',
                      },
                      isRequired: true,
                      showOptionsCaption: false,
                      choices: [
                        {
                          value: 'Area for development',
                          text: {
                            fr: 'Axe de développement',
                            default: 'Area for development',
                          },
                        },
                        {
                          value: 'Strength',
                          text: {
                            fr: "Point d'appui",
                            default: 'Strength',
                          },
                        },
                        {
                          value: 'NA',
                          text: '-',
                        },
                      ],
                    },
                    {
                      type: 'comment',
                      name: 'managerComment_9',
                      title: {
                        default: 'Manager reviewer comment',
                        fr: 'Commentaire manager évaluateur',
                      },
                      rows: 6,
                    },
                  ],
                  startWithNewLine: false,
                },
              ],
              title: {
                default: '9 - Build Trust and Empower',
                fr: '9 - Construit la confiance et responsabilise',
              },
              description: {
                fr: 'Construit et développe un climat de confiance avec ses interlocuteurs ; Accélère la performance par la responsabilisation de chacun ; Pratique et encourage un feedback régulier, précis et constructif ; Incite à faire et à apprendre de l’action',
                default:
                  'Builds and develops a climate of trust with others ; Accelerates performance by empowering everyone ; Practices and encourages regular, specific and constructive feedback ; Encourages action and learning by doing',
              },
            },
          ],
          title: {
            default:
              'This step aims to assess the behaviours used to achieve the results, in relation to the three Purple Way values and to our Leadership Model.',
            fr: 'Cette étape vise à évaluer les comportements adoptés pour atteindre les résultats, en lien avec les trois valeurs de la Purple Way et avec notre modèle de Leadership.',
          },
          description: {
            fr: 'En tant que collaborateur, pour préparer votre entretien, indiquez de manière factuelle les comportements que vous avez mis en œuvre.<br><br> Revoir les comportements de la <a class="font-weight-bold" style="text-decoration: underline;" target="_blank" href="https://objectstorage.eu-frankfurt-1.oraclecloud.com/n/natixishr/b/HCMLibrary/o/2022%2009%20MustHave%20Behaviors.pdf">Purple Way</a> ou ceux du <a class="font-weight-bold" style="text-decoration: underline;" target="_blank" href="https://objectstorage.eu-frankfurt-1.oraclecloud.com/n/natixishr/b/HCMLibrary/o/2022%2009%20Leadership%20Model.pdf">modèle de Leadership</a>',
            default:
              'As an employee, to prepare for your meeting, provide a factual description of the behaviours you have put into action.<br><br>To review the <a class="font-weight-bold" style="text-decoration: underline;" target="_blank" href="https://objectstorage.eu-frankfurt-1.oraclecloud.com/n/natixishr/b/HCMLibrary/o/2022%2009%20MustHave%20Behaviors.pdf">Purple Way behaviors</a> or those from the <a class="font-weight-bold" style="text-decoration: underline;" target="_blank" href="https://objectstorage.eu-frankfurt-1.oraclecloud.com/n/natixishr/b/HCMLibrary/o/2022%2009%20Leadership%20Model.pdf">Leadership Model</a>',
          },
        },
        {
          type: 'panel',
          name: 'overallBehaviorsRating',
          elements: [
            {
              type: 'panel',
              name: 'subPanel_4',
              elements: [
                {
                  type: 'comment',
                  name: 'employeeOverallComment',
                  title: {
                    default: 'Employee comment',
                    fr: 'Commentaire collaborateur',
                  },
                },
                {
                  type: 'comment',
                  name: 'matrixOverallComment',
                  title: {
                    default: 'Functional Manager comment',
                    fr: 'Commentaire manager fonctionnel',
                  },
                },
              ],
            },
            {
              type: 'panel',
              name: 'managerOverallPanel',
              elements: [
                {
                  type: 'dropdown',
                  name: 'managerOverallAssessment',
                  title: {
                    default: 'Manager reviewer assessment',
                    fr: 'Evaluation manager évaluateur',
                  },
                  isRequired: true,
                  showOptionsCaption: false,
                  choices: [
                    {
                      value: '0',
                      text: {
                        default: '0 - Not measured',
                        fr: '0 - Non mesuré',
                      },
                    },
                    {
                      value: '1',
                      text: {
                        default: '1 - Poor',
                        fr: '1 - Insuffisant',
                      },
                    },
                    {
                      value: '2',
                      text: {
                        default: '2 - Satisfactory',
                        fr: '2 - Correct',
                      },
                    },
                    {
                      value: '3',
                      text: {
                        default: '3 - Good',
                        fr: '3 - Bon',
                      },
                    },
                    {
                      value: '4',
                      text: {
                        default: '4 - Very good',
                        fr: '4 - Très bon',
                      },
                    },
                    {
                      value: '5',
                      text: {
                        default: '5 - Exceptional',
                        fr: '5 - Exceptionnel',
                      },
                    },
                  ],
                },
                {
                  type: 'comment',
                  name: 'managerOverallComment',
                  title: {
                    default: 'Manager reviewer comment',
                    fr: 'Commentaire manager évaluateur',
                  },
                  isRequired: true,
                },
              ],
              startWithNewLine: false,
            },
          ],
          title: {
            default: "Overall Behavior's rating",
            fr: 'Evaluation globale des comportements',
          },
        },
      ],
    },
  ],
  showNavigationButtons: 'none',
  showCompletedPage: false,
  showQuestionNumbers: 'off',
  widthMode: 'responsive',
};

import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams, Switch, Route } from 'react-router-dom';
import withCloseMenu from '../hoc/withCloseMenu';
import TeamMenu from './TeamMenu';
import AppraisalDocuments from './AppraisalDocuments';
import IndividualDevelopmentInterviews from './IndividualDevelopmentInterviews';
import EAPDocumentViewer from './AppraisalDocuments/EAPDocumentViewer';
import { connect } from 'react-redux';
import { getCurrentUserId, getCurrentUserManagerRole } from '../../api/manager';
import {
  fetchEDIEligibility,
  fetchEAPEligibility,
  fetchEmployeesProfileData,
} from '../../actions/apiPerformanceActions';
import PerformanceGoals from './PerformanceGoals';
import GoalsViewer from './PerformanceGoals/GoalsViewer';
import Feedbacks from './Feedbacks';
// import OthersInterviews from './OthersInterviews';

const Performance = () => {
  return <PerformanceContainer />;
};

export default withCloseMenu(Performance);

const PerformanceContainer = () => {
  const isManager = getCurrentUserManagerRole();

  return (
    <>
      {isManager ? <TeamMenu /> : null}
      <Switch>
        <Route exact path="/performance/:tab?">
          <PerformanceDocumentListing />
        </Route>
        <Route path="/performance/eapdocument/:employeeId?">
          <EAPDocumentViewer />
        </Route>
        <Route path="/performance/performancegoals/:targetEmployeeId?/:period?">
          <GoalsViewer />
        </Route>
      </Switch>
    </>
  );
};

const mapStateToProps = state => ({
  selectedEmployee: state.settings.selectedEmployee,
  employeesProfileData: state.performance.employeesProfileData,
});

const mapDispatchToProps = dispatch => ({
  dispatchFetchEDIEligibility: () => dispatch(fetchEDIEligibility()),
  dispatchFetchEAPEligibility: () => dispatch(fetchEAPEligibility()),
  dispatchFetchEmployeesProfileData: personIds =>
    dispatch(fetchEmployeesProfileData(personIds)),
});

const PerformanceDocumentListing = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  ({
    selectedEmployee,
    employeesProfileData,
    dispatchFetchEDIEligibility,
    dispatchFetchEAPEligibility,
    dispatchFetchEmployeesProfileData,
  }) => {
    const { tab } = useParams();
    const [tabKey, setTabKey] = useState(tab || 'appraisals');
    const classes = 'card-title font-weight-bold mr-15';
    const isManager = getCurrentUserManagerRole();
    const { t } = useTranslation();

    useEffect(() => {
      if (!isManager) {
        const ids = [getCurrentUserId()];
        dispatchFetchEmployeesProfileData(ids);
      }
    }, [dispatchFetchEmployeesProfileData, isManager]);

    useEffect(() => {
      if (!employeesProfileData.fetched) {
        dispatchFetchEDIEligibility();
        dispatchFetchEAPEligibility();
      }
    }, [
      dispatchFetchEDIEligibility,
      dispatchFetchEAPEligibility,

      dispatchFetchEmployeesProfileData,
      employeesProfileData,
      isManager,
    ]);

    const onSelectTab = key => {
      setTabKey(key);
    };

    return (
      <div style={{ padding: '15px' }}>
        <Tabs
          defaultActiveKey="appraisals"
          activeKey={tabKey}
          onSelect={onSelectTab}
          className="pl-2 mb-4 ml-4 page-header-tab nav-tabs"
        >
          <Tab
            eventKey="feedbacks"
            title={t('tabFeedbacks')}
            tabClassName={classes}
            disabled={true}
          >
            <Feedbacks />
          </Tab>
          <Tab eventKey="appraisals" title={t('tabAPR')} tabClassName={classes}>
            <AppraisalDocuments targetEmployeeId={selectedEmployee} />
          </Tab>
          <Tab eventKey="perf_goals" title={t('tabPerfGoal')} tabClassName={classes}>
            <PerformanceGoals targetEmployeeId={selectedEmployee} />
          </Tab>
          <Tab eventKey="edi_documents" title={t('tabIDI')} tabClassName={classes}>
            <IndividualDevelopmentInterviews targetEmployeeId={selectedEmployee} />
          </Tab>
          {/* <Tab eventKey="others_interviews" title={t('tabOI')} tabClassName={classes}>
            <OthersInterviews targetEmployeeId={selectedEmployee} />
          </Tab> */}
        </Tabs>
      </div>
    );
  },
);

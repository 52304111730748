const initialState = {
  isToggleLeftMenu: false,
  filterJobReqs: { filer: false, name: '' },
  recruitingModal: { isOpen: false, id: 0, isLinkOnly: false },
  userLang: 'en',
  selectedEmployee: 0,
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case 'IS_TOGGLE_LEFTMENU':
      return {
        ...state,
        isToggleLeftMenu: action.payload,
      };

    case 'FILTER_JOB_REQS':
      return {
        ...state,
        filterJobReqs: {
          ...state.filterJobReqs,
          filter: true,
          name: action.payload,
        },
      };

    case 'RESET_FILTER_JOB_REQS':
      return {
        ...state,
        filterJobReqs: {
          ...state.filterJobReqs,
          filter: false,
          name: '',
        },
      };

    case 'OPEN_RECRUITING_MODAL_LINK_ONLY':
      return {
        ...state,
        recruitingModal: {
          ...state.recruitingModal,
          isOpen: true,
          isLinkOnly: true,
          id: action.payload,
        },
      };

    case 'OPEN_RECRUITING_MODAL':
      return {
        ...state,
        recruitingModal: {
          ...state.recruitingModal,
          isOpen: true,
          isLinkOnly: false,
          id: action.payload,
        },
      };

    case 'CLOSE_RECRUITING_MODAL':
      return {
        ...state,
        recruitingModal: {
          ...state.recruitingModal,
          isOpen: false,
          isLinkOnly: false,
          id: 0,
        },
      };

    case 'SET_USER_LANG':
      return {
        ...state,
        userLang: action.payload,
      };

    case 'SET_SELECTED_EMPLOYEE':
      return {
        ...state,
        selectedEmployee: action.payload,
      };

    default:
      return state;
  }
};

export default settings;

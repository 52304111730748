import { getEmployeeProfileId } from '../../../model/employee';
import { getCurrentEnv } from '../../manager';
import { GenericDecoder } from '../common/GenericDecoder';
import { DEV_GOALS_SECTION_IDS } from '../common/section_ids_configuration';
import {
  constructFilterPersonIds,
  constructFilterSectionId,
  decodeBase64,
  encodeBase64,
} from '../helpers';

const createOrUpdate = requestData => {
  const [personId, profileItemId, devGoal] = requestData.destructure();
  const {
    creationDate,
    createdBy,
    lastUpdateDate,
    lastUpdatedBy,
    completionDate,
    goalName,
    status,
    description,
    actionPlan,
  } = devGoal;

  const profileId = getEmployeeProfileId(personId);

  console.info('--- Debug Development Goal POST data');
  console.info(`- PersonId: ${personId}`);
  console.info(`- ProfileId: ${profileId}`);
  console.info(`- ProfileItemId: ${profileItemId}`);

  const requestBody = `<soapenv:Envelope
  xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
  xmlns:typ="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/types/"
  xmlns:pub="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/"
  xmlns:prof="http://xmlns.oracle.com/apps/hcm/profiles/core/flex/profileItem/">
  <soapenv:Header/>
  <soapenv:Body>
    <typ:mergeProfile>
      <typ:profile>
        <pub:ProfileId>${profileId}</pub:ProfileId>
        <pub:ProfileItem>
          <pub:ProfileId>${profileId}</pub:ProfileId>
          ${DevelopmentGoalDecoder.getRequestHeader()}
          <pub:ProfileItemId>${profileItemId}</pub:ProfileItemId>
          // Creation Date
          ${creationDate ? `<pub:DateFrom>${creationDate}</pub:DateFrom>` : ''}
          //Created by
          <pub:ItemText307>${createdBy.slice(0, 30)}</pub:ItemText307> 
          //Last Updated Date
          <pub:ItemDate9>${lastUpdateDate}</pub:ItemDate9>
          //Last Updated by
          <pub:ItemText308>${lastUpdatedBy.slice(0, 30)}</pub:ItemText308>
          // Target Completion Date 
          <pub:ItemDate1>${completionDate}</pub:ItemDate1>
          //Goal Name
          <pub:ItemText20001>${encodeURIComponent(goalName)}</pub:ItemText20001>
          //Status (NOT_STARTED, IN_PROGRESS, COMPLETED) => NATIXIS_HRG_GOAL_STATUS
          <pub:ItemText301>${status}</pub:ItemText301>
          //Description
          <pub:ItemClob1File>${encodeBase64(description)}</pub:ItemClob1File>
          //Action plan
          <pub:ItemClob2File>${encodeBase64(actionPlan)}</pub:ItemClob2File>
        </pub:ProfileItem>
      </typ:profile>
    </typ:mergeProfile>
  </soapenv:Body>
</soapenv:Envelope>`;
  return requestBody;
};

export class DevelopmentGoalDecoder extends GenericDecoder {
  static SECTION_ID = DEV_GOALS_SECTION_IDS[getCurrentEnv()];
  static NAME = 'DEVELOPMENT_GOALS';
  static ATTRIBUTES = {
    'ns1:ItemText20001': { key: 'goalName', prepare: x => decodeURIComponent(x) },
    'ns1:ItemClob1File': { key: 'description', prepare: x => decodeBase64(x) },
    'ns1:ItemText301': { key: 'status' },
    'ns1:ItemDate1': { key: 'completionDate' },
    'ns1:ProfileItemId': { key: 'profileItemId' },
    'ns1:DateFrom': { key: 'date' },
    'ns1:ItemText307': { key: 'createdBy' },
    'ns1:ItemDate9': { key: 'lastUpdateDate' },
    'ns1:ItemText308': { key: 'lastUpdatedBy' },
    'ns1:ItemClob2File': { key: 'actionPlan', prepare: x => decodeBase64(x) },
  };

  static getContentType() {
    return 'ACCOMPLISHMENT';
  }

  static getSectionName() {
    return `${DevelopmentGoalDecoder.getContentType()}_${
      DevelopmentGoalDecoder.SECTION_ID
    }`;
  }

  static getRequestHeader() {
    return `<pub:ContentType>${DevelopmentGoalDecoder.getContentType()}</pub:ContentType>
            <pub:SectionName>${DevelopmentGoalDecoder.getSectionName()}</pub:SectionName>`;
  }
}

export class DevelopmentGoalRequestData {
  constructor(personId, profileItemId, payload) {
    this._personId = personId;
    this._profileItemId = profileItemId;
    this._payload = payload;
  }

  get personId() {
    return this._personId;
  }

  destructure() {
    return [this._personId, this._profileItemId, this._payload];
  }
}

const getByPersonIds = personIds => {
  if (!Array.isArray(personIds)) {
    personIds = [personIds];
  }

  const requestBody = `<soapenv:Envelope
  xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
  xmlns:typ="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/types/"
  xmlns:typ1="http://xmlns.oracle.com/adf/svc/types/">
  <soapenv:Header/>
  <soapenv:Body>
      <typ:findProfile>
          <typ:findCriteria>
              <typ1:fetchStart>0</typ1:fetchStart>
              <typ1:fetchSize>-1</typ1:fetchSize>
              <typ1:filter>
                  <typ1:group>
                      <typ1:conjunction>And</typ1:conjunction>
                      <typ1:upperCaseCompare>true</typ1:upperCaseCompare>
                      ${constructFilterPersonIds(personIds)}
                  </typ1:group>
              </typ1:filter>
              <typ1:childFindCriteria>
                  <typ1:fetchStart>0</typ1:fetchStart>
                  <typ1:fetchSize>-1</typ1:fetchSize>
                  <typ1:filter>
                      <typ1:conjunction>And</typ1:conjunction>
                      <typ1:group>
                          <typ1:conjunction>And</typ1:conjunction>
                          <typ1:upperCaseCompare>false</typ1:upperCaseCompare>
                          ${constructFilterSectionId(DevelopmentGoalDecoder.SECTION_ID)}
                      </typ1:group>
                  </typ1:filter>
                  <typ1:childAttrName>ProfileItem</typ1:childAttrName>
              </typ1:childFindCriteria>
              <typ1:findAttribute>ProfileId</typ1:findAttribute>
              <typ1:findAttribute>ProfileItem</typ1:findAttribute>
              <typ1:findAttribute>PersonId</typ1:findAttribute>
          </typ:findCriteria>
          <typ:findControl>
              <typ1:retrieveAllTranslations>false</typ1:retrieveAllTranslations>
          </typ:findControl>
      </typ:findProfile>
  </soapenv:Body>
</soapenv:Envelope>`;
  return requestBody;
};

const deleteByProfileItemId = profileItemId => {
  console.info('--- Debug Development Goal DELETE data');
  console.info(`- profileItemId: ${profileItemId}`);

  const requestBody = `
<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" 
                  xmlns:typ="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/types/" 
                  xmlns:pub="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/" 
                  xmlns:prof="http://xmlns.oracle.com/apps/hcm/profiles/core/flex/profileItem/">
<soapenv:Header/>
<soapenv:Body>
   <typ:deleteProfileItem>
      <typ:profileItem>
         <pub:ProfileItemId>${profileItemId}</pub:ProfileItemId>
      </typ:profileItem>
   </typ:deleteProfileItem>
</soapenv:Body>
</soapenv:Envelope>`;
  return requestBody;
};

const DevelopmentGoalsRequester = {
  createOrUpdate: createOrUpdate,
  getByPersonIds: getByPersonIds,
  deleteByProfileItemId: deleteByProfileItemId,
};

export default DevelopmentGoalsRequester;

import React from 'react';
import IframeComponent from './IframeComponent';
const CreateFeedback = () => (
  <>
    <IframeComponent
      src="https://integrationprd-natixishr.integration.ocp.oraclecloud.com/ic/builder/rt/FEEDBACK_1/live/webApps/fb/?userLang=en"
      height="100%"
      width="100%"
    />
  </>
);

export default CreateFeedback;

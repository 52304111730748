import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Nav, Button, Row, Col } from 'react-bootstrap';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { getCurrentUserId } from '../../../api/manager';
import { fetchEmployeeTeam } from '../../../actions/apiActions';
import { useTranslation } from 'react-i18next';
import { getEmployeeDisplayName, getManagerDisplayName } from '../../../model/employee';
import { fetchEmployeesProfileData } from '../../../actions/apiPerformanceActions';
import { Form, Formik } from 'formik';
import { displayStatus, getDocumentViewerMode, getTargetTab } from '../common/utils';
import { setSelectedEmployee } from '../../../actions/settingsAction';
import EDIStatusBadge from '../common/EDIStatusBadge';
import { getSliderProperties } from './SliderProperties';
import { isFunctionalWorker } from '../../../utils/data';
import FunctionalWorkerIcon from '../../common/FunctionalWorkerIcon';

const TeamMenu = ({
  employees,
  employeesProfileData,
  employeesEAPProfileData,
  selectedEmployee,
  dispatchFetchEmployeeTeam,
  dispatchFetchEmployeesProfileData,
  dispatchSetSelectedEmployee,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [employeeName, setEmployeeName] = useState('');
  const [managerName, setManagerName] = useState('');
  const [fetchingProfileData, setFetchingProfileData] = useState(false);
  const slideRef = useRef();

  useEffect(() => {
    dispatchFetchEmployeeTeam();
  }, [dispatchFetchEmployeeTeam]);

  useEffect(() => {
    const getActiveSlidePage = () => {
      const index = employees.data.findIndex(p => p['PERSON_ID'] === selectedEmployee);
      return index < 0 ? 0 : index;
    };
    const sliderActivePage = getActiveSlidePage() || 0;
    slideRef.current && slideRef.current.goTo(sliderActivePage);
  }, [employees.data, selectedEmployee]);

  useEffect(() => {
    if (!fetchingProfileData && employees.fetched && !employeesProfileData.fetched) {
      setFetchingProfileData(true);
      const ids = employees.data.map(e => e['PERSON_ID']);
      ids.push(getCurrentUserId());
      dispatchFetchEmployeesProfileData(ids);
    }
  }, [
    fetchingProfileData,
    employees,
    dispatchFetchEmployeesProfileData,
    employeesProfileData.fetched,
  ]);

  useEffect(() => {
    setManagerName(getManagerDisplayName(getCurrentUserId()));
    setEmployeeName(getEmployeeDisplayName(getCurrentUserId()));
  }, [employeesProfileData]);

  const { url } = useRouteMatch();
  const documentViewerMode = getDocumentViewerMode(url);

  const style = {
    textAlign: 'center',
    background: 'white',
    marginLeft: '36px',
    fontSize: '14px',
    width: '200px',
    color: 'black',
  };

  const redirectToDocumentListing = () => {
    const targetTab = getTargetTab(documentViewerMode);
    history.replace({
      pathname: `/performance/${targetTab}`,
    });
  };

  const redirectToDocumentViewerIfNeeded = id => {
    if (documentViewerMode != null) {
      history.replace({
        pathname: `/performance/${documentViewerMode}/${id}`,
      });
    }
  };

  const handleTeamEmployeeClick = id => () => {
    const reclick = selectedEmployee === id;
    if (reclick) {
      dispatchSetSelectedEmployee(0);
      redirectToDocumentListing();
    } else {
      dispatchSetSelectedEmployee(id);
      if (id == 0) {
        redirectToDocumentListing();
      } else {
        redirectToDocumentViewerIfNeeded(id);
      }
    }
  };

  return (
    <>
      <Formik
        initialValues={{ mission: '', skills: '' }}
        onSubmit={() => {
          return false;
        }}
      >
        <Form>
          <div style={{ background: 'white' }}>
            <Row>
              <Col xl={2} style={{ marginLeft: '20px' }}>
                <Nav defaultActiveKey="/home" className="metismenu" as="li">
                  <div>
                    <a
                      className={
                        selectedEmployee === getCurrentUserId()
                          ? 'active btn btn-primary'
                          : 'inactive'
                      }
                      onClick={handleTeamEmployeeClick(getCurrentUserId())}
                    >
                      {t('perf_menu_my')}
                    </a>
                  </div>
                  <div>
                    <a
                      className={
                        selectedEmployee === 0 ? 'active btn btn-primary' : 'inactive'
                      }
                      onClick={handleTeamEmployeeClick(0)}
                    >
                      {t('perf_menu_team')}
                    </a>
                  </div>
                  <div>
                    <div className="navigation">
                      <div className="inlines">
                        <a>{t('perf_menu_reporting')}</a>
                        <img
                          className="reporting_icon"
                          src="https://objectstorage.eu-frankfurt-1.oraclecloud.com/n/natixishr/b/HCMLibrary/o/perfReporting.png"
                        />
                      </div>
                      <div className="navigation-content">
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href="/analytics/saw.dll?bipublisherEntry&Action=open&itemType=.xdo&bipPath=%2FCustom%2FHuman%20Capital%20Management%2FNatixis%20Custom%2FTalent%202021%2FReact%20-%20EAP%2FRating%20-%20Performance%20Behaviours%20and%20Conduct%20For%20Manager.xdo&path=%2Fshared%2FCustom%2FHuman%20Capital%20Management%2FNatixis%20Custom%2FTalent%202021%2FReact%20-%20EAP%2FRating%20-%20Performance%20Behaviours%20and%20Conduct%20For%20Manager.xdo"
                        >
                          {t('perf_menu_reporting_1')}
                        </a>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href="/xmlpserver/Custom/Human%20Capital%20Management/Natixis%20Custom/Talent%20management/KPIs%20Talent/Follow%20up%20Performance%20Goals.xdo?_xiasynch=&_xpf=&_xpt=0&_dFlag=false&_edIndex=0&_dIndex=0&_rToken=&_ranDiag=false&_xmode=2"
                        >
                          {t('perf_menu_reporting_2')}
                        </a>
                      </div>
                    </div>
                  </div>
                </Nav>
              </Col>

              <Col xl={9}>
                <Button style={{ ...style, ...{ marginBottom: '-20px' } }} disabled>
                  {managerName}
                </Button>
                <Button
                  style={{ ...style, ...{ marginBottom: '-20px' } }}
                  className={
                    selectedEmployee === getCurrentUserId() ? 'active closed' : 'inactive'
                  }
                  onClick={handleTeamEmployeeClick(getCurrentUserId())}
                >
                  {employeeName}
                </Button>
                <hr />
                {employees != undefined && employees.data.length > 0 ? (
                  <Slide ref={slideRef} {...getSliderProperties(employees.data.length)}>
                    {employees.data.map((p, index) => {
                      const key = index + 1;
                      return (
                        <Button
                          style={style}
                          className={
                            selectedEmployee === p['PERSON_ID']
                              ? 'm-2 active closed'
                              : 'inactive'
                          }
                          key={key}
                          onClick={handleTeamEmployeeClick(p['PERSON_ID'])}
                        >
                          <div className="p-2 text-center menuClosed">
                            {isFunctionalWorker(p) ? <FunctionalWorkerIcon /> : null}
                            {p['LAST_NAME']} {p['FIRST_NAME']}
                          </div>
                          {displayStatus(documentViewerMode) ? (
                            <>
                              <DisplayDocumentStatus
                                documentViewerMode={documentViewerMode}
                                employeeId={p?.PERSON_ID}
                                employeesProfileData={employeesProfileData}
                                employeesEAPProfileData={employeesEAPProfileData}
                              />
                            </>
                          ) : null}
                        </Button>
                      );
                    })}
                  </Slide>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </div>
        </Form>
      </Formik>
    </>
  );
};

const mapStateToProps = state => {
  return {
    employees: state.hcm.employeeTeam,
    employeesProfileData: state.performance.employeesProfileData,
    employeesEAPProfileData: state.performance.employeesEAPProfileData,
    selectedEmployee: state.settings.selectedEmployee,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchFetchEmployeeTeam: () => dispatch(fetchEmployeeTeam()),
  dispatchFetchEmployeesProfileData: personIds =>
    dispatch(fetchEmployeesProfileData(personIds)),
  dispatchSetSelectedEmployee: e => dispatch(setSelectedEmployee(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamMenu);

const DisplayDocumentStatus = ({
  documentViewerMode,
  employeeId,
  employeesProfileData,
  employeesEAPProfileData,
}) => {
  const display = displayStatus(documentViewerMode);
  return (
    <>
      {display ? (
        <>
          {documentViewerMode == 'edidocument' && (
            <>{getEmployeeEDIStatus(employeesProfileData, employeeId)}</>
          )}
          {documentViewerMode == 'eapdocument' && (
            <>{getEmployeeEAPStatus(employeesEAPProfileData, employeeId)}</>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const getEmployeeEDIStatus = (employeesProfileData, employeeId) => {
  let status = 'INELIGIBLE';
  if (employeesProfileData.fetched) {
    const profile = employeesProfileData.data[employeeId];
    if (profile && profile['EDI_ELIGIBILITY'] == 'ELIGIBLE') {
      status = profile['STATUS_CODE'];
    }
  }
  return <EDIStatusBadge status={status} />;
};

const getEmployeeEAPStatus = (employeesEAPProfileData, employeeId) => {
  let status = 'INELIGIBLE';
  if (employeesEAPProfileData.fetched) {
    const profile = employeesEAPProfileData.data[employeeId];
    if (profile && profile['EAP_ELIGIBILITY'] == 'ELIGIBLE') {
      status = profile['STATUS_CODE'];
    }
  }
  return <EDIStatusBadge status={status} />;
};

import React, { useEffect, useState } from 'react';
import { Chip } from '@material-ui/core';
import { Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Avatar from '../../common/Avatar';
import moment from 'moment';
import EDIStatusBadge from '../common/EDIStatusBadge';
import { getCurrentEnv, getCurrentUserId } from '../../../api/manager';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { loadPerformanceGoalRatingOptions } from '../common/utils';
import FunctionalWorkerIcon from '../../common/FunctionalWorkerIcon';
import { TableCell } from '@mui/material';
import {
  getEmployeeEAPEvaluatorId,
  getEmployeeEAPMatrixManagerId,
  getManagerPersonId,
} from '../../../model/employee';

export const getColumnsArchive = (handleOpenArchive, reopenEAP, t) => {
  const columnsArchive = [
    {
      name: 'person_id',
      options: {
        display: false,
      },
    },
    {
      name: t('table_EmployeeName'),
      options: {
        sortOrder: 'asc',
        customBodyRender: (_, tableMeta) => {
          const employeeId = getEmployeeId(tableMeta);
          const employeeName = getEmployeeName(tableMeta);
          return (
            <>
              <Avatar personId={employeeId} /> {employeeName}
            </>
          );
        },
      },
    },
    {
      name: t('table_DocumentName'),
      options: {
        customBodyRender: (_, tableMeta) => {
          const isCompletedEAPArchive = tableMeta.rowData[8] === 'COMPLETED_EAP';
          const documentName = getDocumentName(tableMeta);
          let url = '';
          if (isCompletedEAPArchive) {
            const employeeId = getEmployeeId(tableMeta);
            url = `/performance/eapdocument/${employeeId}`;
          } else {
            url =
              `https://ekez${getCurrentEnv()}.fa.em2.oraclecloud.com/` +
              getDocumentURL(tableMeta);
          }

          return (
            <>
              {isCompletedEAPArchive ? (
                <Row>
                  <Link
                    className="buttonLink documentName trigger btn btn-link"
                    to={`${url}`}
                  >
                    {documentName}
                  </Link>
                </Row>
              ) : (
                <Row>
                  <Button
                    href="#"
                    type="button"
                    className="buttonLink documentName trigger btn btn-link"
                    onClick={() => handleOpenArchive(url)}
                    data-linkonly="true"
                  >
                    {documentName}
                  </Button>
                </Row>
              )}
            </>
          );
        },
      },
    },
    {
      name: t('table_CurrentStep'),
      options: {
        display: false,
        customBodyRender: value => {
          return <>{value != null && <EDIStatusBadge status={value} />}</>;
        },
      },
    },
    {
      name: t('table_InterviewDate'),
      options: {
        customBodyRender: value => moment(value).format('DD/MM/YYYY'),
      },
    },
    {
      name: 'EVALUATOR',
      options: {
        display: false,
      },
    },
    {
      name: t('table_Actions'),
      options: {
        customBodyRender: (_, tableMeta) => {
          const isCompletedEAPArchive = tableMeta.rowData[8] === 'COMPLETED_EAP';
          const allowReopen = false;
          let url = '';
          if (isCompletedEAPArchive) {
            const profileItemId = tableMeta?.rowData[10];
            url = `/xmlpserver/Custom/Human Capital Management/Career/Performance/Gabarit Impression EAP 2022 Report.xdo?_xpt=0&_xdo=%2FCustom%2FHuman%20Capital%20Management%2FCareer%2FPerformance%2FGabarit%20Impression%20EAP%202022%20Report.xdo&_xmode=4&_paramsp_profileitemid=${profileItemId}&_xt=Master%20Template&_xf=pdf&_xautorun=true&_linkToReport=true`;
          } else {
            url =
              `https://ekez${getCurrentEnv()}.fa.em2.oraclecloud.com/` +
              getDocumentURL(tableMeta);
          }
          return (
            <>
              {isCompletedEAPArchive ? (
                <>
                  {allowReopen && (
                    <Row>
                      <Button
                        type="button"
                        className="buttonLink documentName trigger btn btn-link"
                        data-linkonly="true"
                        onClick={() => reopenEAP(getEmployeeId(tableMeta))}
                      >
                        {t('Reopen')}
                      </Button>
                    </Row>
                  )}
                  <Row>
                    <Button
                      type="button"
                      className="buttonLink documentName trigger btn btn-link"
                      data-linkonly="true"
                      onClick={() => handleOpenArchive(url)}
                    >
                      {t('Print')}
                    </Button>
                  </Row>
                </>
              ) : (
                <Row>
                  <Button
                    type="button"
                    className="buttonLink documentName trigger btn btn-link"
                    data-linkonly="true"
                    onClick={() => handleOpenArchive(url)}
                  >
                    {t('Print')}
                  </Button>
                </Row>
              )}
            </>
          );
        },
      },
    },
    {
      name: 'URL',
      options: {
        display: false,
      },
    },
    {
      name: 'DOCUMENT_TYPE',
      options: {
        display: false,
      },
    },
    {
      name: 'ALLOW_REOPEN',
      options: {
        display: false,
      },
    },
    {
      name: 'ITEM_ID',
      options: {
        display: false,
      },
    },
  ];
  return columnsArchive;
};

export const getColumns = (
  handleOpenArchive,
  onClickTransferEAP,
  onClickAskFeedback,
  dispatchSetSelectedEmployee,
  canDisplayRating,
  onClickSetRating,
  t,
) => {
  const defaultColumns = [
    {
      name: 'person_id',
      options: {
        display: false,
      },
    },
    {
      name: t('table_EmployeeName'),
      options: {
        sortOrder: 'asc',
        customBodyRender: (_, tableMeta) => {
          const employeeId = getEmployeeId(tableMeta);
          const employeeName = getEmployeeName(tableMeta);
          return (
            <>
              <Avatar personId={employeeId} /> {employeeName}
              {getIsFunctionalManager(tableMeta) && <FunctionalWorkerIcon />}
            </>
          );
        },
      },
    },
    {
      name: t('table_DocumentName'),
      options: {
        customBodyRender: (_, tableMeta) => {
          const employeeId = getEmployeeId(tableMeta);
          const documentName = getDocumentName(tableMeta);
          const url = `/performance/eapdocument/${employeeId}`;
          return (
            <>
              <Link
                className="documentName"
                to={`${url}`}
                onClick={() => dispatchSetSelectedEmployee(employeeId)}
              >
                {documentName}
              </Link>
              {getIsTransferredDocument(tableMeta) ? (
                <div style={{ marginTop: '2px' }}>
                  <Chip
                    label={getTransferedBy(tableMeta)}
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                </div>
              ) : null}
            </>
          );
        },
      },
    },
    {
      name: t('table_Evaluator'),
    },
    {
      name: t('table_CurrentStep'),
      options: {
        sort: true,
        customBodyRender: value => {
          return <EDIStatusBadge status={value} />;
        },
      },
    },
    {
      name: t('table_LastUpdateDate'),
      options: {
        customBodyRender: value => (value ? moment(value).format('DD/MM/YYYY') : value),
        display: false,
      },
    },
    {
      name: t('table_perfEvaluation'),
      options: {
        sort: true,
        customHeadRender: ({ index, ...column }) => {
          return (
            <TableCell key={index} className="tableheaderCenter">
              {column.name}
            </TableCell>
          );
        },
        customBodyRender: (_, tableMeta) => {
          const employeeId = getEmployeeId(tableMeta);
          const profileItemId = getProfileItemIdManager(tableMeta) || '';
          const previousPerfRating = getPreviousPerfRating(tableMeta);
          const previousCompRating = getPreviousCompRating(tableMeta);
          const perfRating = getPerfRating(tableMeta);
          const compRating = getCompRating(tableMeta);
          const readOnly =
            getCurrentUserId(tableMeta) == getEvaluatorId(tableMeta) ? false : true;
          return (
            <EAPManagerRating
              employeeId={employeeId}
              profileItemId={profileItemId}
              onClickSetRating={onClickSetRating}
              previousPerfRating={previousPerfRating}
              previousCompRating={previousCompRating}
              perfRating={perfRating}
              compRating={compRating}
              readOnly={readOnly}
            />
          );
        },
        display: canDisplayRating,
      },
    },
    {
      name: t('table_Actions'),
      options: {
        customBodyRender: (_, tableMeta) => {
          const employeeId = getEmployeeId(tableMeta);
          const profileItemIdCollab = getProfileItemIdCollab(tableMeta);
          const profileItemIdManager = getProfileItemIdManager(tableMeta);
          const url = `/performance/eapdocument/${employeeId}`;
          const disableTransfer =
            getCurrentUserId(tableMeta) == getEvaluatorId(tableMeta) ? false : true;

          return (
            <>
              {disableTransfer ? null : (
                <>
                  <Row>
                    <Button
                      href="#"
                      type="button"
                      className="buttonLink documentName trigger btn btn-link"
                      onClick={() =>
                        onClickTransferEAP(
                          employeeId,
                          profileItemIdCollab,
                          profileItemIdManager,
                        )
                      }
                      disabled={disableTransfer}
                      style={{ textAlign: 'left' }}
                      data-linkonly="true"
                    >
                      {t('edi_transfer')}
                    </Button>
                  </Row>
                  <Row>
                    <Button
                      href="#"
                      type="button"
                      className="buttonLink documentName trigger btn btn-link"
                      onClick={() => onClickAskFeedback(employeeId, profileItemIdCollab)}
                      disabled={disableTransfer}
                      style={{ textAlign: 'left' }}
                      data-linkonly="true"
                    >
                      {t('eap_ask_feedback')}
                    </Button>
                  </Row>
                </>
              )}
              <Row>
                <Link
                  className="buttonLink documentName trigger btn btn-link"
                  to={`${url}`}
                  onClick={() => dispatchSetSelectedEmployee(employeeId)}
                >
                  {t('edi_modify')}
                </Link>
              </Row>
            </>
          );
        },
      },
    },
    {
      name: 'DISABLE_TRANSFER',
      options: {
        display: false,
      },
    },
    {
      name: 'IS_TRANSFERRED',
      options: {
        display: false,
      },
    },
    {
      name: 'TRANSFERRED_BY',
      options: {
        display: false,
      },
    },
    {
      name: 'IS_FUNCTIONAL_MANAGER',
      options: {
        display: false,
      },
    },
    {
      name: 'EAP_ITEM_ID',
      options: {
        display: false,
      },
    },
    {
      name: 'EAP_MANAGER_ITEM_ID',
      options: {
        display: false,
      },
    },
    {
      name: 'EAP_EVALUATOR',
      options: {
        display: false,
      },
    },
  ];
  return defaultColumns;
};

const getEmployeeId = tableMeta => tableMeta.rowData[0];

const getEmployeeName = tableMeta => tableMeta.rowData[1];

const getDocumentName = tableMeta => tableMeta.rowData[2];

//const getDisableTransfer = tableMeta => tableMeta.rowData[8];

const getIsTransferredDocument = tableMeta => tableMeta.rowData[9];

const getTransferedBy = tableMeta => tableMeta.rowData[10];

const getIsFunctionalManager = tableMeta => tableMeta.rowData[11];

const getDocumentURL = tableMeta => tableMeta.rowData[7];

const getProfileItemIdCollab = tableMeta => tableMeta.rowData[12];

const getProfileItemIdManager = tableMeta => tableMeta.rowData[13];

const getPreviousPerfRating = tableMeta => tableMeta.rowData[6][0];
const getPreviousCompRating = tableMeta => tableMeta.rowData[6][1];
const getPerfRating = tableMeta => tableMeta.rowData[6][2];
const getCompRating = tableMeta => tableMeta.rowData[6][3];

const getEvaluatorId = tableMeta => tableMeta.rowData[14];

const EAPManagerRating = ({
  onClickSetRating,
  employeeId,
  profileItemId,
  previousPerfRating,
  previousCompRating,
  perfRating,
  compRating,
  readOnly,
}) => {
  const [displayRating, setDisplayRating] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setDisplayRating(getDisplayRatingForEmployeeEAP(employeeId));
  }, [employeeId]);

  return (
    <>
      {!displayRating ? (
        <></>
      ) : (
        <>
          <Row>
            <Col md="4"></Col>
            <Col md="4" className="text-center">
              2021
            </Col>
            <Col md="4" className="text-center">
              2022
            </Col>
          </Row>
          <Row>
            <Col md="4" className="font-weight-bold">
              {t('rating_goal')}
            </Col>
            <Col md="4">
              <SelectRating
                disabled={true}
                previousRating={previousPerfRating}
                preRating={false}
              />
            </Col>
            <Col md="4">
              <SelectRating
                employeeId={employeeId}
                profileItemId={profileItemId}
                onClickSetRating={onClickSetRating}
                rating={perfRating}
                type={'performanceGoal'}
                disabled={readOnly}
                preRating={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md="4" className="font-weight-bold">
              {t('rating_behaviors')}
            </Col>
            <Col md="4">
              <SelectRating
                disabled={true}
                previousRating={previousCompRating}
                preRating={false}
              />
            </Col>
            <Col md="4">
              <SelectRating
                employeeId={employeeId}
                profileItemId={profileItemId}
                onClickSetRating={onClickSetRating}
                rating={compRating}
                type={'behavior'}
                disabled={readOnly}
                preRating={true}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

const animatedComponents = makeAnimated();

const SelectRating = props => {
  const { t } = useTranslation();
  return (
    <>
      {props.disabled && !props.preRating ? (
        <Select
          placeholder={t('goal_eval')}
          options={loadPerformanceGoalRatingOptions(t)}
          defaultOptions
          components={animatedComponents}
          isDisabled={props.disabled}
          value={{
            value: props.previousRating,
            label: t(
              `perf_goal_rating_${props.previousRating ? props.previousRating : 0}`,
            ),
          }}
        />
      ) : (
        <Select
          placeholder={t('goal_eval_placeholder')}
          options={loadPerformanceGoalRatingOptions(t)}
          defaultOptions
          components={animatedComponents}
          isDisabled={props.disabled}
          value={
            props.rating
              ? {
                  value: props.rating,
                  label: t(`perf_goal_rating_${props.rating}`),
                }
              : null
          }
          onChange={value => {
            props.onClickSetRating(
              props.employeeId,
              props.profileItemId,
              value,
              props.type,
            );
          }}
          menuPortalTarget={document.body}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        />
      )}
    </>
  );
};

const getDisplayRatingForEmployeeEAP = employeeId => {
  let display = false;
  if (getEmployeeEAPEvaluatorId(employeeId) == getCurrentUserId()) {
    display = true;
  } else if (getEmployeeEAPMatrixManagerId(employeeId) == getCurrentUserId()) {
    display = true;
  } else if (getManagerPersonId(employeeId) == getCurrentUserId()) {
    display = true;
  }
  return display;
};

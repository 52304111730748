import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import DataTableCard from '../../common/DataTableCard';
import {
  getOngoingRecruitments,
  getFinishedRecruitments,
  columns,
  options,
  constructJobReqsMap,
} from './dataTableConfig';
import { useTranslation } from 'react-i18next';

const JobReqs = ({ recruitingJobReqs, openModal, filter = undefined }) => {
  const [jobReqsMap, setJobReqsMap] = useState(new Map());
  const { t } = useTranslation();

  useEffect(() => {
    setJobReqsMap(constructJobReqsMap(recruitingJobReqs));
  }, [recruitingJobReqs]);

  const getJobReq = id => {
    return jobReqsMap[id];
  };

  return (
    <>
      <Row>
        <DataTableCard
          data={getOngoingRecruitments(recruitingJobReqs, t)}
          columns={columns(openModal, filter, getJobReq, t)}
          options={options(t)}
          title={t('recruiting_ongoing')}
        />
      </Row>
      <Row>
        <DataTableCard
          data={getFinishedRecruitments(recruitingJobReqs, t)}
          columns={columns(openModal, filter, getJobReq, t)}
          options={options(t)}
          title={t('recruiting_over')}
        />
      </Row>
    </>
  );
};

export default JobReqs;

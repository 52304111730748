import React from 'react';

const titleStyle = {
  boxSizing: 'border-box',
  color: 'rgb(102, 106, 109)',
  direction: 'ltr',
  fontFeatureSettings: 'liga 0',
  fontSize: '13px',
  fontWeight: '800',
  lineHeight: '19.5px',
  marginTop: '20px',
  paddingLeft: '0px',
  textAlign: 'left',
  textTransform: 'uppercase',
};
const Title = ({ text }) => {
  return <span style={titleStyle}>{text}</span>;
};

export default Title;

export const setKPILearning = e => dispatch => {
  dispatch({
    type: 'SET_KPI_LEARNING',
    payload: e,
  });
};

export const setEmployeeTeam = e => dispatch => {
  dispatch({
    type: 'SET_EMPLOYEE_TEAM',
    payload: e,
  });
};

export const setEmployeeSubTeam = e => dispatch => {
  dispatch({
    type: 'SET_EMPLOYEE_SUB_TEAM',
    payload: e,
  });
};

export const setFeedbacks = e => dispatch => {
  dispatch({
    type: 'SET_FEEDBACKS',
    payload: e,
  });
};

export const setAnnouncements = e => dispatch => {
  dispatch({
    type: 'SET_ANNOUNCEMENTS',
    payload: e,
  });
};

export const setAnnouncementsRecruitment = e => dispatch => {
  dispatch({
    type: 'SET_ANNOUNCEMENTS_RECRUITMENT',
    payload: e,
  });
};

export const setKPIsGoals = e => dispatch => {
  dispatch({
    type: 'SET_KPIS_GOALS',
    payload: e,
  });
};

export const setKPIsPerformance = e => dispatch => {
  dispatch({
    type: 'SET_KPIS_PERFORMANCE',
    payload: e,
  });
};

export const setTutorials = e => dispatch => {
  dispatch({
    type: 'SET_TUTORIALS',
    payload: e,
  });
};

export const setGoalsList = e => dispatch => {
  dispatch({
    type: 'SET_GOALS_LIST',
    payload: e,
  });
};

export const setRecruitingJobReqList = e => dispatch => {
  dispatch({
    type: 'SET_RECRUITING_JOB_REQ_LIST',
    payload: e,
  });
};

export const setRecruitingJobReqOutcomes = e => dispatch => {
  dispatch({
    type: 'SET_RECRUITING_JOB_REQ_OUTCOMES',
    payload: e,
  });
};

export const setCandidatesList = e => dispatch => {
  dispatch({
    type: 'SET_RECRUITING_CANDIDATES_LIST',
    payload: e,
  });
};

export const setActions = e => dispatch => {
  dispatch({
    type: 'SET_ACTIONS',
    payload: e,
  });
};

export const setEmployeeArchive = e => dispatch => {
  dispatch({
    type: 'SET_EMPLOYEE_ARCHIVE',
    payload: e,
  });
};

import React from 'react';
import LoaderInCard from '../common/LoaderInCard';
export default class IframeComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      name: 'React',
      loading: true,
    };
  }
  hideSpinner = () => {
    this.setState({
      loading: false,
    });
    var iframe = document.getElementById('CreateFeedback');
    let iframeWin = iframe.contentWindow || iframe.contentDocument.parentWindow;
    if (iframeWin.document.body)
      iframe.height =
        iframeWin.document.documentElement.scrollHeight ||
        iframeWin.document.body.scrollHeight;
  };
  render() {
    return (
      <div>
        {this.state.loading ? <LoaderInCard /> : null}
        <iframe
          id="CreateFeedback"
          src={this.props.src}
          height={this.props.height}
          width={this.props.width}
          onLoad={this.hideSpinner}
          className="fullheight"
          frameBorder="0"
        />
      </div>
    );
  }
}

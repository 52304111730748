import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { customRenderStatusJobReq } from './dataTableConfig';

const JobReqSLiderItem = ({ jobReq, handleClick }) => {
  const { t } = useTranslation();

  return (
    <div className="slider_item">
      <Button
        style={{ minHeight: '86px' }}
        variant="outline-secondary"
        onClick={handleClick}
        value={jobReq.REQUISITION_ID}>
        {jobReq.TITLE} <br />
        {getStatus(jobReq, t)}
      </Button>
    </div>
  );
};

export default JobReqSLiderItem;

const getStatus = (jobReq, t) => {
  return customRenderStatusJobReq(jobReq, t);
};

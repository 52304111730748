const initialState = {
  employeeTeam: {
    fetched: false,
    data: [],
  },
  employeeSubTeams: {},
  candidates: {
    fetched: false,
    data: [],
  },
  feedbacks: {
    fetched: false,
    data: [],
  },
  announcements: {
    fetched: false,
    data: [],
  },
  announcementsRecruitment: {
    fetched: false,
    data: [],
  },
  kpisGoals: {
    fetched: false,
    data: [],
  },
  kpisPerformance: {
    fetched: false,
    data: [],
  },
  tutorials: {
    fetched: false,
    data: [],
  },
  goalsList: {
    fetched: false,
    data: [],
  },
  recruitingJobReqs: {
    fetched: false,
    data: [],
  },
  recruitingJobReqOutcomes: {
    fetched: false,
    data: [],
  },
  actions: {
    fetched: false,
    data: [],
  },
  kpiLearning: {
    fetched: false,
    data: [],
  },
  employeeArchiveDocument: {
    fetched: false,
    data: [],
  },
};

const hcm = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_KPI_LEARNING':
      return {
        ...state,
        kpiLearning: {
          ...state.kpiLearning,
          fetched: true,
          data: action.payload,
        },
      };

    case 'SET_GOALS_LIST':
      return {
        ...state,
        goalsList: {
          ...state.goalsList,
          fetched: true,
          data: action.payload,
        },
      };

    case 'SET_EMPLOYEE_TEAM':
      return {
        ...state,
        employeeTeam: {
          ...state.employeeTeam,
          fetched: true,
          data: action.payload,
        },
      };

    case 'SET_EMPLOYEE_SUB_TEAM':
      return {
        ...state,
        employeeSubTeams: {
          ...state.employeeSubTeams,
          [action.payload.personId]: action.payload.payload,
        },
      };

    case 'SET_FEEDBACKS':
      return {
        ...state,
        feedbacks: {
          ...state.feedbacks,
          fetched: true,
          data: action.payload,
        },
      };

    case 'SET_ANNOUNCEMENTS':
      return {
        ...state,
        announcements: {
          ...state.announcements,
          fetched: true,
          data: action.payload,
        },
      };

    case 'SET_ANNOUNCEMENTS_RECRUITMENT':
      return {
        ...state,
        announcementsRecruitment: {
          ...state.announcementsRecruitment,
          fetched: true,
          data: action.payload,
        },
      };

    case 'SET_KPIS_GOALS':
      return {
        ...state,
        kpisGoals: {
          ...state.kpisGoals,
          fetched: true,
          data: action.payload,
        },
      };

    case 'SET_KPIS_PERFORMANCE':
      return {
        ...state,
        kpisPerformance: {
          ...state.kpisPerformance,
          fetched: true,
          data: action.payload,
        },
      };

    case 'SET_TUTORIALS':
      return {
        ...state,
        tutorials: {
          ...state.tutorials,
          fetched: true,
          data: action.payload,
        },
      };

    // TODO add fetched status
    case 'SET_RECRUITING_JOB_REQ_LIST':
      return {
        ...state,
        recruitingJobReqs: {
          ...state.recruitingJobReqs,
          fetched: true,
          data: action.payload,
        },
      };

    case 'SET_ACTIONS':
      return {
        ...state,
        actions: {
          ...state.actions,
          fetched: true,
          data: action.payload,
        },
      };

    case 'SET_RECRUITING_CANDIDATES_LIST':
      return {
        ...state,
        candidates: {
          ...state.actions,
          fetched: true,
          data: action.payload,
        },
      };

    case 'SET_EMPLOYEE_ARCHIVE':
      return {
        ...state,
        employeeArchiveDocument: {
          ...state.employeeArchiveDocument,
          fetched: true,
          data: action.payload,
        },
      };
    case 'SET_RECRUITING_JOB_REQ_OUTCOMES':
      return {
        ...state,
        recruitingJobReqOutcomes: {
          ...state.recruitingJobReqOutcomes,
          fetched: true,
          data: action.payload,
        },
      };
    default:
      return state;
  }
};

export default hcm;

import React from 'react';
import Avatar from '../../common/Avatar';
import moment from 'moment';
import TableSearchWithoutAutofocus from '../../common/TableSearchWithoutAutofocus';

export const buildColumns = (getFeedback, t) => [
  {
    name: t('recruiting_job_req_id'),
  },
  { name: 'Technical id', options: { display: false } },
  {
    name: t('recruiting_title'),
  },
  {
    name: t('recruiting_hr_team'),
    options: {
      customBodyRender: customRenderHRTeam(getFeedback),
    },
  },
  {
    name: t('recruiting_feedback_ask_date'),
    options: {
      customBodyRender: customRenderDate(),
      sortCompare: order => {
        return (obj1, obj2) => {
          const l = convertDate(obj1.data);
          const r = convertDate(obj2.data);
          return order === 'asc' ? l > r : l < r;
        };
      },
    },
  },
  {
    name: t('recruiting_expiration_date'),
    options: {
      customBodyRender: customRenderDate(),
      sortCompare: order => {
        return (obj1, obj2) => {
          const l = convertDate(obj1.data);
          const r = convertDate(obj2.data);
          return order === 'asc' ? l > r : l < r;
        };
      },
    },
  },
  {
    name: t('recruiting_candidate_name'),
  },
  {
    name: t('recruiting_actions'),
  },
];

export const options = {
  filter: true,
  filterType: 'dropdown',
  searchOpen: true,
  selectableRows: 'none',
  customSearchRender: (searchText, handleSearch, hideSearch, options) => (
    <TableSearchWithoutAutofocus
      options={options}
      searchText={searchText}
      onSearch={handleSearch}
      onHide={hideSearch}
    />
  ),
};

const customRenderDate = () => {
  return value => {
    const date = moment(new Date(value));
    return date.format('DD/MM/YYYY');
  };
};

const customRenderHRTeam = getFeedback => {
  // eslint-disable-next-line react/display-name
  return value => {
    const feedback = getFeedback(value);
    const recruiterName = (feedback != undefined && feedback.RECRUITER_NAME) || '';
    const recruiterId = (feedback != undefined && feedback.RECRUITED_ID) || '';
    const hiringName = (feedback != undefined && feedback.HIRING_NAME) || '';
    const hiringId = (feedback != undefined && feedback.HIRING_MANAGER_ID) || '';

    return (
      <>
        <Avatar
          personId={recruiterId}
          style={{ marginRight: '5px', marginBottom: '5px' }}
        />
        {recruiterName}
        <br />
        <Avatar personId={hiringId} style={{ marginRight: '5px' }} />
        {hiringName}
      </>
    );
  };
};

export const constructFeedbacksMap = feedbacks => {
  return feedbacks.reduce(function (map, obj) {
    map[obj.FEEDBACK_ID] = obj;
    return map;
  }, {});
};

const convertDate = rawDate => moment(rawDate, 'DD/MM/YYYY');

import React from 'react';

const Checkbox = ({ label, checked, handleChange }) => {
  return (
    <div id="team_filter">
      <label>
        <input
          className="team_filter_input"
          type="checkbox"
          checked={checked}
          onChange={handleChange}
        />
        {label}
      </label>
    </div>
  );
};

export default Checkbox;

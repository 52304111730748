import React from 'react';
import { connect } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { toggleLeftMenuAction } from '../../actions/settingsAction';
import { isCurrentUserHiringManager, isCurrentUserHR } from '../../api/manager';
import LeftSideBar from './LeftSideBar';
import { useTranslation } from 'react-i18next';
import { openHCMMenu } from '../../utils/hcmMenu';

const Menu = ({ istoggleLeftMenu, dispatchToggleLeftMenu }) => {
  const url = useLocation();
  const isHR = isCurrentUserHR();
  const isHiringManager = isCurrentUserHiringManager();
  const { t } = useTranslation();

  return (
    <div style={{ display: 'block' }}>
      <div id="header_top" className="header_top">
        <div className="container">
          <div className="hleft">
            <NavLink to="/" className="header-brand" title="Home">
              <i className="fa fa-home brand-logo menu-icon-brand" />
            </NavLink>
            <NavLink to="/performance/" className="menu-link" title={t('menu_perf')}>
              <i className="fa fa-group menu-icon" />
            </NavLink>
            {isHiringManager ? (
              <NavLink
                to="/recruiting"
                className="menu-link"
                title={t('menu_recruiting')}
              >
                <i className="fa fa-drivers-license-o menu-icon" />
              </NavLink>
            ) : null}
            <a
              href="https://natixis.e-tipi.com"
              className="menu-link"
              title={t('menu_learning')}
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-graduation-cap menu-icon" />
            </a>
            {isHR ? (
              <div
                className="menu-link"
                title={t('menu_hr')}
                style={{ cursor: 'pointer' }}
                onClick={() => openHCMMenu({ displayHROnly: true })}
              >
                <i className="fa fa-folder-open menu-icon" />
              </div>
            ) : null}
          </div>
          <div className="hright">
            {displayToggleArrow(url.pathname) ? (
              <div className="dropdown">
                <p
                  className="nav-link icon menu_toggle"
                  onClick={() => dispatchToggleLeftMenu(!istoggleLeftMenu)}
                >
                  <i
                    className={`fa  ${
                      istoggleLeftMenu ? 'fa-arrow-right' : 'fa-arrow-left'
                    }`}
                  />
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <LeftSideBar />
    </div>
  );
};

const mapStateToProps = state => ({
  istoggleLeftMenu: state.settings.isToggleLeftMenu,
});

const mapDispatchToProps = dispatch => ({
  dispatchToggleLeftMenu: e => dispatch(toggleLeftMenuAction(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);

const displayToggleArrow = url => {
  const match = url.includes('performance') || url.includes('recruiting');
  return !match;
};

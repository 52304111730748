import React from 'react';
import moment from 'moment';
import TableSearchWithoutAutofocus from '../../common/TableSearchWithoutAutofocus';
import { Button } from 'react-bootstrap';
import Avatar from '../../common/Avatar';
import TextField from '@mui/material/TextField';
import { TASK_OUTCOMES } from '../../../api/BPMTask/types';
import {
  Cancel,
  CheckCircle,
  Error,
  Check,
  HourglassEmpty,
  SubdirectoryArrowRight,
} from '@material-ui/icons';
import { Skeleton } from '@mui/lab';
import { Tooltip } from '@material-ui/core';
import slugify from 'slugify';

// -----------------------------------------------
// Data Manipulation

export const prepareFinishedData = inputRows => {
  return inputRows.map(el => {
    let lastApprover = [];
    try {
      lastApprover = JSON.parse(el.HISTORY_OBJ);
    } catch (err) {
      console.error(
        'RecruitingMassValidation/DocumentTableStructure prepareFinishedData() : ',
        err,
      );
    }
    return {
      REQUISITION_NUMBER: el.REQUISITION_NUMBER,
      TITLE: {
        TITLE: el.TITLE,
        REQUISITION_ID: el.REQUISITION_ID,
      },
      HIRING_NAME: el.HIRING_NAME,
      RECRUITING_TEAM: {
        RECRUITER_NAME: el.RECRUITER_NAME,
        HIRING_NAME: el.HIRING_NAME,
      },
      CREATEDDATE: el.CREATEDDATE,
      UPDATE_DATE: el.UPDATEDDATE,
      STATUS: el.OUTCOME,
      COMMENTS: el.WF_COMMENT,
      LAST_APPROVER: lastApprover,
    };
  });
};

export const preparePendingData = (inputRows, jobReqsComments, t) => {
  return inputRows.map(inProgressJobReq => {
    let history = [];
    try {
      history = JSON.parse(inProgressJobReq.HISTORY);
    } catch (err) {
      console.error(
        'RecruitingMassValidation/DocumentTableStructure preparePendingData() : ',
        err,
      );
    }
    return {
      REQUISITION_NUMBER: inProgressJobReq.REQUISITION_NUMBER,
      REQUISITION_ID: inProgressJobReq.REQUISITION_ID,
      TITLE: {
        TITLE: inProgressJobReq.TITLE,
        REQUISITION_ID: inProgressJobReq.REQUISITION_ID,
      },
      RECRUITING_TEAM: {
        RECRUITER_NAME: inProgressJobReq.RECRUITER_NAME,
        HIRING_NAME: inProgressJobReq.HIRING_NAME,
      },
      CREATION_DATE: inProgressJobReq.CREATION_DATE,
      STATUS: t(`recruiting_${inProgressJobReq.JOB_REQ_STATUS.toLowerCase()}`),
      GLOBAL_STATUS_DETAILS: inProgressJobReq.GLOBAL_STATUS_DETAILS,
      LAST_UPDATE_DATE: inProgressJobReq.LAST_UPDATE_DATE,
      COMMENTS: jobReqsComments[inProgressJobReq.REQUISITION_NUMBER] || '',
      NEXT_APPROVER: inProgressJobReq.NEXT_APPROVER,
      GLOBAL_HISTORY: {
        HISTORY: history,
        APPROVAL_GROUP: inProgressJobReq.APPROVAL_GROUP,
      },
      TASKID: inProgressJobReq.TASKID,
    };
  });
};

const customRenderHRTeam = getJobReq => {
  // eslint-disable-next-line react/display-name
  return (value, tableMeta) => {
    const jobReq = getJobReq(tableMeta.rowData[0]);
    const recruiterName = value.RECRUITER_NAME;
    const recruiterId = (jobReq != undefined && jobReq.RECRUITED_ID) || '';
    const hiringName = value.HIRING_NAME;
    const hiringId = (jobReq != undefined && jobReq.HIRING_MANAGER_ID) || '';

    return (
      <div>
        <Avatar
          personId={recruiterId}
          style={{ marginRight: '5px', marginBottom: '5px' }}
        />
        {recruiterName}
        <br />
        <Avatar personId={hiringId} style={{ marginRight: '5px' }} />
        {hiringName}
      </div>
    );
  };
};

export const constructJobReqsMap = jobReqs => {
  return jobReqs.reduce(function (map, obj) {
    map[obj.REQUISITION_NUMBER] = obj;
    return map;
  }, {});
};

const dateComparator = (a, b, format) => {
  const aDate = moment(a, format);
  const bDate = moment(b, format);
  if (aDate.isAfter(bDate)) return -1;
  if (aDate.isBefore(bDate)) return 1;
  return 0;
};

const getHiringTeamFilterLabel = el => el.RECRUITER_NAME + ' / ' + el.HIRING_NAME;

const PENDING_WORKFLOW_STATUS = {
  PENDING: 'PENDING',
  APPROVED_OR_REJECTED: 'APPROVED_OR_REJECTED',
  NEXT_STEP: 'NEXT_STEP',
};

const getWorkflowPendingDetails = allHistory => {
  const getHistoryStatus = status => {
    switch (status) {
      case 'TASK_VERSION_REASON_INITIATED':
        return PENDING_WORKFLOW_STATUS.PENDING;
      case 'TASK_VERSION_REASON_OUTCOME_UPDATED':
        return PENDING_WORKFLOW_STATUS.APPROVED_OR_REJECTED;
      case 'TASK_VERSION_REASON_ROUTED':
        return PENDING_WORKFLOW_STATUS.PENDING;
      default:
        return PENDING_WORKFLOW_STATUS.NEXT_STEP;
    }
  };

  return allHistory.HISTORY.filter((el, i) => {
    const isReasonRouted = el.status === 'TASK_VERSION_REASON_ROUTED';
    const isLastOrFirst = i === 0 || i === allHistory.HISTORY.length - 1;
    return isLastOrFirst || !isReasonRouted;
  })
    .filter(el => {
      const isInitiatedNotInteresting =
        el.status === 'TASK_VERSION_REASON_INITIATED' && allHistory.HISTORY.length > 1;
      return !isInitiatedNotInteresting;
    })
    .map(el => ({
      assigneeDisplayName: decodeURIComponent(
        escape(
          el.assigneeDisplayName
            ? el.assigneeDisplayName
            : 'Vital MARTINS (validation externe)',
        ),
      ),
      index: el.index,
      status: getHistoryStatus(el.status),
      updatedDate: moment(el.updatedDate),
    }))
    .sort((a, b) => {
      return parseInt(a.index) - parseInt(b.index);
    });
};

const getWorkflowFinishedDetails = inputHistory => {
  let history = inputHistory.filter((el, i) => {
    const isPendingButApproveAfter =
      el.status === 'TASK_VERSION_REASON_ROUTED' && i < inputHistory.length - 1;
    return !isPendingButApproveAfter;
  });
  return history
    .map(el => ({
      index: el.index,
      status:
        el.status === 'TASK_VERSION_REASON_OUTCOME_UPDATED'
          ? 'APPROVED_OR_REJECTED'
          : 'PENDING',
      assigneeDisplayName: el.assigneeDisplayName,
      updatedDate: moment(el.updatedDate),
    }))
    .sort((a, b) => {
      return parseInt(a.index) - parseInt(b.index);
    });
};

const customRenderStatusJobReq = status => {
  switch (status) {
    case TASK_OUTCOMES.APPROVE:
      return (
        <Tooltip title="APPROVE">
          <CheckCircle fontSize="medium" style={{ color: '#038F3C' }} />
        </Tooltip>
      );
    case TASK_OUTCOMES.REJECT:
      return (
        <Tooltip title="REJECT">
          <Cancel fontSize="medium" style={{ color: '#DE1E1F' }} />
        </Tooltip>
      );
    default:
      return (
        <Tooltip title="ERROR">
          <Error fontSize="medium" style={{ color: '#DE1E1F' }} />
        </Tooltip>
      );
  }
};

const customRenderTitle = handler => {
  // eslint-disable-next-line react/display-name
  return value => {
    const requisitionId = value.REQUISITION_ID;
    const url = `/fscmUI/faces/deeplink?objType=IRC_RECRUITING&action=REQUISITION_DETAIL_MY_TEAM_RESP&objKey=RequisitionId=${requisitionId};pHideNavigation=true;pCloseMainTaskOnDone=Y`;
    return (
      <a
        href={url}
        className="trigger"
        onClick={handler}
        id={requisitionId}
        data-linkonly="false"
      >
        {value.TITLE}
      </a>
    );
  };
};

const customSearch = payload => {
  const { searchQuery, currentRow, columns, config } = payload;
  const getQueryEscaped = query => query.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  const searchRegexp = new RegExp('(.*)' + getQueryEscaped(searchQuery) + '(.*)', 'i');

  const getRowValue = colName => {
    const index = columns.map(el => el.name).indexOf(colName);
    if (index === -1) return null;
    return currentRow[index];
  };

  const isFieldSearchable = fieldName => config.indexOf(fieldName) !== -1;

  //Search in column TITLE
  let titleMatch = false;
  if (isFieldSearchable('TITLE')) {
    const titleValue = getRowValue('TITLE');
    const titleSubValue = titleValue ? titleValue?.TITLE : undefined;
    if (titleSubValue) {
      titleMatch = searchRegexp.test(titleSubValue);
    } else {
      console.error(
        'RecruitingMassValidation/DocumentTableStructure customSearch : Wrong format for column TITLE',
      );
    }
  }

  //Search in column REQUISITION_NUMBER
  let requisitionNumberMatch = false;
  if (isFieldSearchable('REQUISITION_NUMBER')) {
    const requisitionNumberValue = getRowValue('REQUISITION_NUMBER');
    requisitionNumberMatch = requisitionNumberValue
      ? searchRegexp.test(requisitionNumberValue)
      : requisitionNumberMatch;
  }

  //Search in column RECRUITING_TEAM
  let recruitingTeamMatch = false;
  if (isFieldSearchable('RECRUITING_TEAM')) {
    const recruitingTeamValue = getRowValue('RECRUITING_TEAM');
    const recruitingTeamRecruiterValue = recruitingTeamValue
      ? recruitingTeamValue?.RECRUITER_NAME
      : null;
    const recruitingTeamHiringValue = recruitingTeamValue
      ? recruitingTeamValue?.HIRING_NAME
      : null;
    if (recruitingTeamRecruiterValue && recruitingTeamHiringValue) {
      recruitingTeamMatch =
        searchRegexp.test(recruitingTeamRecruiterValue) ||
        searchRegexp.test(recruitingTeamHiringValue);
    } else {
      console.error(
        'RecruitingMassValidation/DocumentTableStructure customSearch : Wrong format for column RECRUITING_TEAM',
      );
    }
  }

  //Search in column CREATION_DATE
  let creationDateMatch = false;
  if (isFieldSearchable('CREATION_DATE')) {
    const creationDateValue = getRowValue('CREATION_DATE');
    if (creationDateValue) creationDateMatch = searchRegexp.test(creationDateValue);
  }

  //Search in column LAST_UPDATE_DATE
  let lastUpdateDateMatch = false;
  if (isFieldSearchable('LAST_UPDATE_DATE')) {
    const lastUpdateDateValue = getRowValue('LAST_UPDATE_DATE');
    if (lastUpdateDateValue) lastUpdateDateMatch = searchRegexp.test(lastUpdateDateValue);
  }

  //Search in column GLOBAL_HISTORY
  let historyMatch = false;
  if (isFieldSearchable('GLOBAL_HISTORY')) {
    const globalHistoryValue = getRowValue('GLOBAL_HISTORY');
    const historyValue = globalHistoryValue ? globalHistoryValue?.APPROVAL_GROUP : null;
    if (historyValue) {
      const matchHistoryResults = historyValue.filter(el => searchRegexp.test(el));
      if (matchHistoryResults.length > 0) historyMatch = true;
    } else {
      console.error(
        'RecruitingMassValidation/DocumentTableStructure customSearch : Wrong format for column GLOBAL_HISTORY',
      );
    }
  }

  //Search in column CREATEDDATE
  let createdDateMatch = false;
  if (isFieldSearchable('CREATEDDATE')) {
    let createdDateValue = getRowValue('CREATEDDATE');
    createdDateValue = moment(createdDateValue, 'YYYY-MM-DD').format('DD/MM/YYYY');
    if (createdDateValue) createdDateMatch = searchRegexp.test(createdDateValue);
  }

  //Search in column UPDATE_DATE
  let updateDateMatch = false;
  if (isFieldSearchable('UPDATE_DATE')) {
    let updateDateValue = getRowValue('UPDATE_DATE');
    updateDateValue = moment(updateDateValue, 'YYYY-MM-DD').format('DD/MM/YYYY');
    if (updateDateValue) updateDateMatch = searchRegexp.test(updateDateValue);
  }

  //Search in column LAST_APPROVER
  let lastApproverMatch = false;
  if (isFieldSearchable('LAST_APPROVER')) {
    let lastApproverValue = getRowValue('LAST_APPROVER');
    if (lastApproverValue) {
      const lastApproverDisplayName = lastApproverValue.map(el => el.assigneeDisplayName);
      const lastApproverMatchCount = lastApproverDisplayName.filter(el =>
        searchRegexp.test(el),
      );
      if (lastApproverMatchCount.length > 0) lastApproverMatch = true;
    } else {
      console.error(
        'RecruitingMassValidation/DocumentTableStructure customSearch : Wrong format for column LAST_APPROVER',
      );
    }
  }
  //Search in column COMMENTS
  let commentsMatch = false;
  if (isFieldSearchable('COMMENTS')) {
    const commentsValue = getRowValue('COMMENTS');
    if (commentsValue) commentsMatch = searchRegexp.test(commentsValue);
  }

  return (
    titleMatch ||
    requisitionNumberMatch ||
    recruitingTeamMatch ||
    creationDateMatch ||
    lastUpdateDateMatch ||
    historyMatch ||
    commentsMatch ||
    updateDateMatch ||
    createdDateMatch ||
    lastApproverMatch
  );
};
const CUSTOM_SEARCH_FIELDS = {
  //PENDING
  TITLE: 'TITLE',
  REQUISITION_NUMBER: 'REQUISITION_NUMBER',
  RECRUITING_TEAM: 'RECRUITING_TEAM',
  CREATION_DATE: 'CREATION_DATE',
  LAST_UPDATE_DATE: 'LAST_UPDATE_DATE',
  GLOBAL_HISTORY: 'GLOBAL_HISTORY',
  //FINISHED
  CREATEDDATE: 'CREATEDDATE',
  UPDATE_DATE: 'UPDATE_DATE',
  LAST_APPROVER: 'LAST_APPROVER',
  COMMENTS: 'COMMENTS',
};
const CUSTOM_SEARCH_PENDING_ENABLE_FIELDS = [
  CUSTOM_SEARCH_FIELDS.TITLE,
  CUSTOM_SEARCH_FIELDS.REQUISITION_NUMBER,
  CUSTOM_SEARCH_FIELDS.RECRUITING_TEAM,
  CUSTOM_SEARCH_FIELDS.CREATION_DATE,
  CUSTOM_SEARCH_FIELDS.LAST_UPDATE_DATE,
  CUSTOM_SEARCH_FIELDS.GLOBAL_HISTORY,
];

const CUSTOM_SEARCH_FINISHED_ENABLE_FIELDS = [
  CUSTOM_SEARCH_FIELDS.TITLE,
  CUSTOM_SEARCH_FIELDS.REQUISITION_NUMBER,
  CUSTOM_SEARCH_FIELDS.RECRUITING_TEAM,
  CUSTOM_SEARCH_FIELDS.CREATEDDATE,
  CUSTOM_SEARCH_FIELDS.UPDATE_DATE,
  CUSTOM_SEARCH_FIELDS.LAST_APPROVER,
  CUSTOM_SEARCH_FIELDS.COMMENTS,
];

export const getColumnsPending = (
  getJobReq,
  handlerCommentChange,
  t,
  openModal,
  data,
) => {
  return [
    {
      name: 'REQUISITION_NUMBER',
      label: t('recruiting_job_req_id'),
    },
    {
      name: 'TITLE',
      label: t('recruiting_title'),
      options: {
        customBodyRender: customRenderTitle(openModal),
        filterOptions: {
          names: [...new Set(data.map(el => el.TITLE.TITLE))],
          logic: (title, filters) => {
            if (filters.length) return filters.indexOf(title.TITLE) === -1;
            return false;
          },
        },
        sortCompare: order => {
          return (obj1, obj2) => {
            const title1 = obj1?.data?.TITLE;
            const title2 = obj2?.data?.TITLE;
            const compare = title1 && title2 ? title1.localeCompare(title2) : 0;
            return order === 'desc' ? compare : -compare;
          };
        },
      },
    },
    {
      name: 'RECRUITING_TEAM',
      label: t('recruiting_hr_team'),
      options: {
        customBodyRender: customRenderHRTeam(getJobReq),
        filterOptions: {
          names: [
            ...new Set(data.map(el => getHiringTeamFilterLabel(el.RECRUITING_TEAM))),
          ],
          logic: (recruitingTeam, filters) => {
            const recruitingTeamSearch = getHiringTeamFilterLabel(recruitingTeam);
            if (filters.length) return filters.indexOf(recruitingTeamSearch) === -1;
            return false;
          },
        },
        sort: false,
        // sortCompare: order => {
        //   return (obj1, obj2) => {
        //     const recruiter1 = obj1?.data?.RECRUITER_NAME;
        //     const hiring1 = obj1?.data?.HIRING_NAME;
        //     const team1 = recruiter1 && hiring1 ? recruiter1 + ' - ' + hiring1 : '';
        //     const recruiter2 = obj2?.data?.RECRUITER_NAME;
        //     const hiring2 = obj2?.data?.HIRING_NAME;
        //     const team2 = recruiter2 && hiring2 ? recruiter2 + ' - ' + hiring2 : '';
        //     const compare = team1.localeCompare(team2);
        //     return order === 'desc' ? compare : -compare;
        //   };
        // },
      },
    },
    {
      name: 'CREATION_DATE',
      label: t('recruiting_date_create'),
      options: {
        sort: true,
        filter: false,
        sortCompare: order => {
          return (obj1, obj2) => {
            const compare = dateComparator(obj1.data, obj2.data, 'DD/MM/YYYY');
            return order === 'desc' ? compare : -compare;
          };
        },
      },
    },
    {
      name: 'LAST_UPDATE_DATE',
      label: t('recruiting_pending_date'),
      options: {
        sort: true,
        filter: false,
        sortCompare: order => {
          return (obj1, obj2) => {
            const compare = dateComparator(obj1.data, obj2.data, 'DD/MM/YYYY');
            return order === 'desc' ? compare : -compare;
          };
        },
      },
    },
    {
      name: 'COMMENTS',
      label: t('recruiting_comments'),
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => (
          <div className="col-md-12">
            <div className="form-group">
              <TextField
                id="comment"
                name="comment"
                label={t('actionComment')}
                multiline
                rows={2}
                type="text"
                fullWidth
                variant="outlined"
                value={value}
                onChange={event => {
                  handlerCommentChange(tableMeta.rowData[0], event.target.value);
                }}
                InputProps={{
                  inputProps: {
                    style: {
                      border: 'none',
                    },
                  },
                }}
                style={{ backgroundColor: 'white' }}
              />
            </div>
          </div>
        ),
      },
    },
    {
      name: 'GLOBAL_HISTORY',
      label: t('recruiting_approuve'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          const history = getWorkflowPendingDetails(value);
          return (
            <div
              style={{
                display: 'flex',
                width: '200px',
                flexDirection: 'column',
              }}
            >
              {history.map((el, i) => (
                <span key={i}>
                  {el.status === PENDING_WORKFLOW_STATUS.APPROVED_OR_REJECTED ? (
                    <Check
                      fontSize="small"
                      style={{ color: '#038F3C', marginRight: '5px' }}
                    />
                  ) : el.status === PENDING_WORKFLOW_STATUS.PENDING ? (
                    <HourglassEmpty
                      fontSize="small"
                      style={{ color: '#ffb74d', marginRight: '5px' }}
                    />
                  ) : (
                    <SubdirectoryArrowRight fontSize="small" />
                  )}
                  {el.assigneeDisplayName}
                </span>
              ))}
            </div>
          );
        },
      },
    },
    {
      name: 'TITLE',
      options: {
        display: false,
        filter: false,
        sort: false,
        viewColumns: false,
        download: false,
      },
    },
    {
      name: 'TASKID',
      label: t('recruiting_actions'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          const taskId = value;
          const urlApproveJobReq = `/hcmUI/faces/adf.task-flow?tz=Europe%2FBelgrade&df=medium&dt=both&tf=short&lg=en&cy=&bpmWorklistTaskId=${taskId}&bpmBrowserWindowStatus=taskFlowReturn&bpmClientType=&sf=Natixis_logo6alta&adf.tfDoc=%2FWEB-INF%2Foracle%2Fapps%2Ffinancials%2FcommonModules%2Fshared%2FpublicUi%2FonlineNotification%2Fflow%2FUnifiedNotificationFlow.xml&adf.tfId=UnifiedNotificationFlow`;
          return (
            <div style={{ fontWeight: '700' }}>
              {buildOpenModalLink(openModal, urlApproveJobReq, t('recruiting_details'))}
            </div>
          );
        },
      },
    },
  ];
};

export const getColumnsFinished = (getJobReq, t, handleOpenDetailsModal, data) => {
  return [
    {
      name: 'REQUISITION_NUMBER',
      label: t('recruiting_job_req_id'),
    },
    {
      name: 'TITLE',
      label: t('recruiting_title'),
      options: {
        customBodyRender: customRenderTitle(handleOpenDetailsModal),
        filterOptions: {
          names: [...new Set(data.map(el => el.TITLE.TITLE))],
          logic: (title, filters) => {
            if (filters.length) return filters.indexOf(title.TITLE) === -1;
            return false;
          },
        },
        sortCompare: order => {
          return (obj1, obj2) => {
            const title1 = obj1?.data?.TITLE;
            const title2 = obj2?.data?.TITLE;
            const compare = title1 && title2 ? title1.localeCompare(title2) : 0;
            return order === 'desc' ? compare : -compare;
          };
        },
      },
    },
    {
      name: 'RECRUITING_TEAM',
      label: t('recruiting_hr_team'),
      options: {
        customBodyRender: customRenderHRTeam(getJobReq),
        filterOptions: {
          names: [
            ...new Set(data.map(el => getHiringTeamFilterLabel(el.RECRUITING_TEAM))),
          ],
          logic: (recruitingTeam, filters) => {
            const recruitingTeamSearch = getHiringTeamFilterLabel(recruitingTeam);
            if (filters.length) return filters.indexOf(recruitingTeamSearch) === -1;
            return false;
          },
        },
        sort: false,
        // sortCompare: order => {
        //   return (obj1, obj2) => {
        //     const recruiter1 = obj1?.data?.RECRUITER_NAME;
        //     const hiring1 = obj1?.data?.HIRING_NAME;
        //     const team1 = recruiter1 && hiring1 ? recruiter1 + ' - ' + hiring1 : '';
        //     const recruiter2 = obj2?.data?.RECRUITER_NAME;
        //     const hiring2 = obj2?.data?.HIRING_NAME;
        //     const team2 = recruiter2 && hiring2 ? recruiter2 + ' - ' + hiring2 : '';
        //     const compare = team1.localeCompare(team2);
        //     return order === 'desc' ? compare : -compare;
        //   };
        // },
      },
    },
    {
      name: 'CREATEDDATE',
      label: t('recruiting_date_create'),
      options: {
        sort: true,
        filter: false,
        sortCompare: order => {
          return (obj1, obj2) => {
            const compare = dateComparator(obj1.data, obj2.data, 'YYYY-MM-DD');
            return order === 'desc' ? compare : -compare;
          };
        },
        customBodyRender: value => (
          <>{moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')}</>
        ),
      },
    },
    {
      name: 'UPDATE_DATE',
      label: t('recruiting_validation_date'),
      options: {
        sort: true,
        filter: false,
        sortCompare: order => {
          return (obj1, obj2) => {
            const compare = dateComparator(obj1.data, obj2.data, 'YYYY-MM-DD');
            return order === 'desc' ? compare : -compare;
          };
        },
        customBodyRender: value => (
          <>{moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')}</>
        ),
      },
    },
    {
      name: 'STATUS',
      label: t('recruiting_status'),
      options: {
        customBodyRender: value => customRenderStatusJobReq(value),
      },
    },
    {
      name: 'COMMENTS',
      label: t('recruiting_comments'),
      options: {
        customBodyRender: value => {
          let comments = [];
          if (value) comments = value.split('<br>');
          return (
            <div style={{ maxHeight: '100px', overflowY: 'auto' }}>
              {comments.map((el, i) => {
                if (i < comments.length - 1) {
                  return (
                    <span key={i}>
                      {el}
                      <br />
                    </span>
                  );
                } else {
                  return <span key={i}>{el}</span>;
                }
              })}
            </div>
          );
        },
      },
    },
    {
      name: 'LAST_APPROVER',
      label: t('recruiting_last_validator'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          const history = getWorkflowFinishedDetails(value);
          return (
            <div
              style={{
                display: 'flex',
                width: '200px',
                flexDirection: 'column',
              }}
            >
              {history.map((el, i) => (
                <span key={i}>
                  {el.status === 'APPROVED_OR_REJECTED' ? (
                    <Check
                      fontSize="small"
                      style={{ color: '#038F3C', marginRight: '5px' }}
                    />
                  ) : (
                    <HourglassEmpty
                      fontSize="small"
                      style={{ color: '#ffb74d', marginRight: '5px' }}
                    />
                  )}
                  {el.assigneeDisplayName}
                </span>
              ))}
            </div>
          );
        },
      },
    },
  ];
};

export const optionsPending = (handlerOpenModal, isLoading, t) => {
  return {
    filter: true,
    filterType: 'dropdown',
    searchOpen: true,
    selectableRows: 'multiple',
    sortOrder: {
      name: 'CREATION_DATE',
      direction: 'asc',
    },
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <>
            <Skeleton height={70} />
            <Skeleton height={70} />
            <Skeleton height={70} />
            <Skeleton height={70} />
          </>
        ) : (
          <span>{t('empty_table')}</span>
        ),
      },
    },
    downloadOptions: {
      filename: slugify(t('recruiting_pending'), { lower: true }) + '.csv',
      separator: ';',
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      let dataToDownload = [...data];
      dataToDownload.map(columnData => {
        columnData.data[1] = columnData.data[1]?.TITLE;
        columnData.data[2] =
          columnData.data[2]?.HIRING_NAME + ', ' + columnData.data[2]?.RECRUITER_NAME;
        columnData.data[6] = JSON.stringify(
          getWorkflowPendingDetails({
            APPROVAL_GROUP: columnData.data[6]?.APPROVAL_GROUP,
            HISTORY: columnData.data[6]?.HISTORY,
          }),
        );
      });
      return '\uFEFF' + buildHead(columns) + buildBody(dataToDownload);
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => (
      <TableSearchWithoutAutofocus
        options={options}
        searchText={searchText}
        onSearch={handleSearch}
        onHide={hideSearch}
      />
    ),
    customSearch: (searchQuery, currentRow, columns) =>
      customSearch({
        searchQuery,
        currentRow,
        columns,
        config: CUSTOM_SEARCH_PENDING_ENABLE_FIELDS,
      }),
    customToolbar: () => (
      <div>
        <Button variant="success" className="mr-3" disabled>
          {t('mass_validation_modal_approve')}
        </Button>
        <Button variant="danger active" className="mr-3" disabled>
          {t('mass_validation_modal_reject')}
        </Button>
      </div>
    ),
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <div style={{}}>
        <Button
          variant="success"
          className="mr-3"
          onClick={() => {
            handlerOpenModal(
              true,
              TASK_OUTCOMES.APPROVE,
              selectedRows,
              displayData,
              setSelectedRows,
            );
          }}
        >
          {t('mass_validation_modal_approve')}
        </Button>
        <Button
          variant="danger active"
          className="mr-3"
          onClick={() => {
            handlerOpenModal(
              true,
              TASK_OUTCOMES.REJECT,
              selectedRows,
              displayData,
              setSelectedRows,
            );
          }}
        >
          {t('mass_validation_modal_reject')}
        </Button>
      </div>
    ),
  };
};

export const optionsFinished = (isLoading, t) => {
  return {
    filter: true,
    filterType: 'dropdown',
    searchOpen: true,
    selectableRows: 'none',
    sortOrder: {
      name: 'CREATION_DATE',
      direction: 'desc',
    },
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <>
            <Skeleton height={70} />
            <Skeleton height={70} />
            <Skeleton height={70} />
            <Skeleton height={70} />
          </>
        ) : (
          <span>{t('empty_table')}</span>
        ),
      },
    },
    downloadOptions: {
      filename: slugify(t('recruiting_pending_finished'), { lower: true }) + '.csv',
      separator: ';',
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      let dataToDownload = [...data];
      dataToDownload.map(columnData => {
        columnData.data[1] = columnData.data[1]?.TITLE;
        columnData.data[2] =
          columnData.data[2]?.HIRING_NAME + ', ' + columnData.data[2]?.RECRUITER_NAME;
        columnData.data[7] = JSON.stringify(
          getWorkflowFinishedDetails(columnData.data[7]),
        );
      });
      return '\uFEFF' + buildHead(columns) + buildBody(data);
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => (
      <TableSearchWithoutAutofocus
        options={options}
        searchText={searchText}
        onSearch={handleSearch}
        onHide={hideSearch}
      />
    ),
    customSearch: (searchQuery, currentRow, columns) =>
      customSearch({
        searchQuery,
        currentRow,
        columns,
        config: CUSTOM_SEARCH_FINISHED_ENABLE_FIELDS,
      }),
  };
};

const buildOpenModalLink = (openModal, url, text) => {
  return (
    <a href={url} className="trigger" onClick={openModal} id={url} data-linkonly="true">
      <i className="dropdown-icon fe fe-link" />
      {text}
    </a>
  );
};

import React, { useState } from 'react';
import { Button, Modal, Card, Row, Col } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { getCurrentEnv } from '../../../api/manager';
import Avatar from '../../common/Avatar';
import { useTranslation } from 'react-i18next';

const animatedComponents = makeAnimated();

const TransferDocumentModal = props => {
  const { t } = useTranslation();

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop={'static'}
      dialogClassName="modal-50w"
      centered
    >
      <Card>
        <Card.Body>
          <Card.Title> {t('TransferTo')}</Card.Title>
          <TransferDocument onSave={props.onSave} onHide={props.onHide} />
        </Card.Body>
      </Card>
    </Modal>
  );
};

export default TransferDocumentModal;

const TransferDocument = ({ onSave, onHide }) => {
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isNotAManager, setIsNotAManager] = useState(null);
  const { t } = useTranslation();

  const onSearchChange = result => {
    if (result != null && result.PersonId) {
      isEmployeeManager(result.PersonId).then(isTargetManager => {
        if (isTargetManager) {
          setIsNotAManager(false);
          setSelectedEmployee(result.PersonId);
        } else {
          setIsNotAManager(true);
          setSelectedEmployee(null);
        }
      });
    } else {
      setSelectedEmployee(null);
    }
  };

  return (
    <div>
      <Row>
        <Col md={12} className="form-group">
          <label className="form-label">{t('TransferSearch')}</label>
          <AsyncSelect
            isClearable
            cacheOptions
            defaultOptions
            getOptionLabel={e => (
              <>
                {e == null || e.WorkEmailAddress == null ? null : (
                  <>
                    <Avatar personId={e.PersonId} />
                    {`   ${e.WorkEmailAddress}`}
                  </>
                )}
              </>
            )}
            getOptionValue={e => e.WorkEmailAddress}
            loadOptions={loadOptions}
            onChange={onSearchChange}
            components={animatedComponents}
          />
        </Col>
      </Row>
      <>
        {isNotAManager && (
          <>
            <div className="text-center mb-10">
              <i
                className="bi bi-exclamation-triangle-fill h1"
                style={{ color: 'red' }}
              ></i>
              <p className="font-weight-bold">{t('InterviewTransferedNotToManager')}</p>
              <p>{t('InterviewTransferedNotToManagerInfo')}</p>
            </div>
          </>
        )}
      </>
      <div className="p-10 mt-20" style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button className="mr-10 w160" onClick={() => onHide()}>
          {t('Cancel')}
        </Button>
        <Button
          className="mr-10 w160"
          onClick={() => onSave(selectedEmployee)}
          disabled={selectedEmployee == null || isNotAManager}
        >
          {t('save')}
        </Button>
      </div>
    </div>
  );
};

const loadOptions = inputValue => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/vnd.oracle.adf.resourceitem+json');
  myHeaders.append('REST-Framework-Version', '4');
  myHeaders.append('Authorization', 'Basic bGVtcmFiZXRrYTpOYXRpeGlzMTIzKyo=');
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  return fetch(
    `https://ekez${getCurrentEnv()}.fa.em2.oraclecloud.com:443/hcmRestApi/resources/11.13.18.05/workersLov?expand=all&finder=findByWord;AssignmentType=E%20C%20N,AssignmentStatusType=ACTIVE%20SUSPENDED,DataSecurityPrivilege=PerDeferredAssignment,SearchTerms=%22${inputValue}%22,FilterAttributes=DisplayName%20WorkEmailAddress%20PersonNumber&onlyData=true`,
    requestOptions,
  )
    .then(response => response.json())
    .then(response => {
      response = response.items;
      if (response) {
        response = response.filter(e => e.AssignmentPrimaryFlag == true);
      }
      return response;
    })
    .catch(error => console.error('[ERROR] Transfer Document, workerLov error: ', error));
};

const isEmployeeManager = async personId => {
  const myHeaders = new Headers();
  myHeaders.append('REST-Framework-Version', '4');
  myHeaders.append('Authorization', 'Basic bGVtcmFiZXRrYTpOYXRpeGlzMTIzKyo=');
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  return fetch(
    `https://ekez${getCurrentEnv()}.fa.em2.oraclecloud.com:443/hcmRestApi/resources/11.13.18.05/publicWorkers?finder=findReports;PersonId=${personId},DirectReportsFlag=true&onlyData=true&fields=PersonId`,
    requestOptions,
  )
    .then(response => response.json())
    .then(response => {
      response = response.items;
      return response?.length > 0;
    })
    .catch(error => console.warn('[ERROR] api publicWorkers (isEmployeeManager)', error));
};

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import MainContainer from './MainContainer';
import Menu from './Menu';
import { useTranslation } from 'react-i18next';
import { setUserLang } from '../../actions/settingsAction';
import { updateCurrentUserLang } from '../../api/manager';

const Main = ({ istoggleLeftMenu, dispatchSetUserLang }) => {
  const [, i18n] = useTranslation();
  window.document.title = 'HR One';

  useEffect(() => {
    dispatchSetUserLang(updateCurrentUserLang(i18n));
  }, [i18n, dispatchSetUserLang]);

  return (
    <>
      <div className={`${istoggleLeftMenu ? 'offcanvas-active' : ''}`}>
        <Menu />
        <MainContainer />
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  istoggleLeftMenu: state.settings.isToggleLeftMenu,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetUserLang: e => dispatch(setUserLang(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);

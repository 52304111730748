import React, { useState } from 'react';
import Loader from '../common/Loader';

const IframeContainer = ({
  id,
  src,
  height,
  width,
  onLoad = () => {},
  className,
  frameBorder,
  dontResize = false,
  dontShowLoader = false,
}) => {
  const [loading, setLoading] = useState(true);

  const wrappedOnLoad = () => {
    const iframe = getIframe(id);
    setLoading(false);
    if (!dontResize) {
      resizeIframe(iframe);
    }
    onLoad(iframe);
  };

  return (
    <div>
      {loading && !dontShowLoader ? (
        <Loader style={{ margin: 'auto', marginTop: '50px', scale: '2' }} />
      ) : null}
      <iframe
        id={id}
        src={src}
        height={height}
        width={width}
        onLoad={wrappedOnLoad}
        className={className}
        frameBorder={frameBorder}
      />
    </div>
  );
};

export default IframeContainer;

const getIframe = id => {
  return document.getElementById(id);
};

const resizeIframe = iframe => {
  if (iframe) {
    let iframeWin = iframe.contentWindow || iframe.contentDocument.parentWindow;
    if (iframeWin.document.body)
      iframe.height =
        iframeWin.document.body.scrollHeight ||
        iframeWin.document.documentElement.scrollHeight;
  }
};

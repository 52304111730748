import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Announcements from './Announcements';
import KPIs from './KPIs';
import MyActions from './MyActions';
import withOpenMenu from '../hoc/withOpenMenu';
import { Container, Row, Col } from 'react-bootstrap';
import {
  getCurrentUserManagerRole,
  isCurrentUserHiringManager,
  isCurrentUserHR,
} from '../../api/manager';
import OngoingRecruitments from '../Recruiting/OngoingRecruitments';
import { fetchRecruitingJobReqs } from '../../actions/apiActions';

const Dashboard = ({ recruitingJobReqs, dispatchRecruitingJobReqs }) => {
  const isManager = getCurrentUserManagerRole();
  const isHiringManger = isCurrentUserHiringManager() || isCurrentUserHR();

  useEffect(() => {
    dispatchRecruitingJobReqs();
  }, [dispatchRecruitingJobReqs]);

  return (
    <Container fluid>
      <Row className="clearfix row-deck">
        <MyActions />
      </Row>
      {isManager ? (
        <>
          <Row>
            <Col xl={3} lg={3} md={3}>
              <ColFullSize>
                <KPIs />
              </ColFullSize>
            </Col>

            {isHiringManger ? (
              <Col xl={3} lg={3} md={3}>
                <ColFullSize>
                  <OngoingRecruitments recruitingJobReqs={recruitingJobReqs} />
                </ColFullSize>
              </Col>
            ) : null}

            <Col xl={6} lg={6} md={6}>
              <ColFullSize>
                <Announcements />
              </ColFullSize>
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col xl={5} lg={6} md={6}>
            <ColFullSize>
              <Announcements />
            </ColFullSize>
          </Col>
        </Row>
      )}
    </Container>
  );
};

const ColFullSize = ({ children }) => {
  return (
    <Col xl={12} lg={12} md={12}>
      {children}
    </Col>
  );
};

const mapStateToProps = state => {
  return {
    recruitingJobReqs: state.hcm.recruitingJobReqs,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchRecruitingJobReqs: () => dispatch(fetchRecruitingJobReqs()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withOpenMenu(Dashboard));

export class HCMEndpoint {
  static EmployeeTeam = new HCMEndpoint('EmployeeTeam', 'N-1 WS.xdo');
  static Tutorials = new HCMEndpoint('Tutorials', 'TUTORIALS WS.xdo');
  static Announcements = new HCMEndpoint('Announcements', 'ANNOUNCEMENTS WS.xdo');
  static Feedbacks = new HCMEndpoint('Feedbacks', 'FEEDBACK WS.xdo');
  static KPIsGoals = new HCMEndpoint('KPIsGoals', 'KPIS GOAL WS.xdo');
  static KPIsPerformance = new HCMEndpoint('KPIsPerformance', 'KPIS PERFORMANCE WS.xdo');
  static GoalsList = new HCMEndpoint('GoalsList', 'GOALS LIST WS.xdo');
  static Actions = new HCMEndpoint('Actions', 'ACTIONSKEL WS.xdo');
  static RecruitingJobReqList = new HCMEndpoint(
    'RecruitingJobReqList',
    'JOBREQ LIST WS.xdo',
  );
  static RecruitingJobReqOutcomes = new HCMEndpoint(
    'RecruitingJobReqOutcomes',
    'MY_JOB_REQ_OUTCOMES.xdo',
  );
  static RecruitingCandidatesList = new HCMEndpoint(
    'RecruitingCandidatesList',
    'CandidatesByJobReqWS.xdo',
  );
  static KPILearning = new HCMEndpoint('KPILearning', 'KPIS_LEARNING_WS.xdo');
  static EmployeeArchive = new HCMEndpoint(
    'EmployeeArchive',
    'Performance/EmployeeArchive WS.xdo',
  );
  static EDIEligibility = new HCMEndpoint('EDIEligibility ', 'Performance/EDI WS.xdo');
  static EAPEligibility = new HCMEndpoint('EAPEligibility ', 'Performance/EAP WS.xdo');
  static GetProfileItemLastUpdateBy = new HCMEndpoint(
    'GetProfileItemLastUpdateBy',
    'Performance/GET_PROFILE_ITEM_LAST_UPDATE_BY_WS.xdo',
  );
  static GetFeedbacksByPersonId = new HCMEndpoint(
    'GetFeedbacksByPersonId',
    'Performance/GET_FEEDBACKS_BY_PERSON_ID_WS.xdo',
  );
  static GetFeedbacksByEAPItemId = new HCMEndpoint(
    'GetFeedbacksByEAPItemId',
    'Performance/GET_FEEDBACKS_BY_EAP_ITEM_ID_WS.xdo',
  );
  static GetMyTeamEAPFeedbacks = new HCMEndpoint(
    'GetMyTeamEAPFeedbacks',
    'Performance/GET_MY_TEAM_FEEDBACKS_WS.xdo',
  );
  // TODO: update with real report path
  static JimmyEligibility = new HCMEndpoint(
    'JIMMY_ELIGIBILITY',
    'Performance/JIMMY_ELIGIBILITY_WS.xdo',
  );

  static EPRDetail = new HCMEndpoint('EPRDetail', 'Performance/EPR COL WS.xdo');
  static EmployeeProfile = new HCMEndpoint('EmployeeProfile', '');

  constructor(name, value) {
    this.name = name;
    this.value = value;
  }

  toString() {
    return `HCMEndpoint.${this.name} = ${this.value}`;
  }
}

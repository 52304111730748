import { getCurrentUserId } from '../api/manager';

export const OBJECTIVE_TYPE_FILTER = {
  performance: 'PERFORMANCE',
  development: 'DEVELOPMENT',
  none: 'none',
};

export const HELP_PHONE = '01 58 19 23 03 (82303)';

export const HELP_MAIL = 'hrone-hotline@natixis.com';

export const HELP_WEBSITE = 'https://groupebpce.sharepoint.com/sites/FAQHRONE/';

export const MODAL_OVERLAY_COLOR = 'rgba(0, 0, 0, 0.6)';

export const NATIXIS_LOGO_URL =
  '/cs/groups/ucm_spaces/documents/document/b19u/yxrp/~edisp/logo_natixis.jpg';

export const getNatixisInterviewsAndTrainingsHistory = () => {
  const personId = getCurrentUserId();
  const url = `/xmlpserver/Custom/Human%20Capital%20Management/Natixis%20Custom/Embedded%20Report/Historique%20Entretiens%20et%20Formations/Historique%20Entretiens%20et%20Formations.xdo?_xiasynch=&_xpf=&_xpt=1&_dFlag=false&_edIndex=0&_dIndex=0&_rToken=&_ranDiag=false&_xdo=%2FCustom%2FHuman%20Capital%20Management%2FNatixis%20Custom%2FEmbedded%20Report%2FHistorique%20Entretiens%20et%20Formations%2FHistorique%20Entretiens%20et%20Formations.xdo&_xmode=4&_paramsp_person_id=${personId}&_xf=html&_xautorun=true&_linkToReport=true`;
  return url;
};

export const NATIXIS_VAE_PDF_URL =
  '/cs/groups/faauthpubcontent/documents/document/zxbf/dmfl/~edisp/edi_cpf_cep_vae_bc.pdf';

import { combineReducers } from 'redux';
import settings from './settings';
import hcm from './hcm';
import performance from './performance';
import uiReducer from './uiReducer';

export default combineReducers({
  settings,
  hcm,
  performance,
  uiReducer,
});

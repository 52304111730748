import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getCurrentUserManagerRole } from '../../api/manager';

import {
  fetchRecruitingJobReqs,
  fetchRecruitingCandidates,
  fetchRecruitingJobReqOutcomes,
} from '../../actions/apiActions';
import { Container, Row, Col } from 'react-bootstrap';
import OngoingRecruitments from './OngoingRecruitments';
import RecruitingAnnouncement from './RecruitingAnnouncement';
import RecruitingMainContainer from './RecruitingMainContainer';
import MyTeamSlicker from './MyTeamSlicker';
import withCloseMenu from '../hoc/withCloseMenu';

const Recruiting = ({
  recruitingJobReqs,
  recruitingJobReqOutcomes,
  candidates,
  dispatchRecruitingJobReqs,
  dispatchRecruitingJobReqOutcomes,
  dispatchJobReqCandidates,
}) => {
  useEffect(() => {
    dispatchRecruitingJobReqs();
    dispatchJobReqCandidates();
    dispatchRecruitingJobReqOutcomes();
  }, [
    dispatchRecruitingJobReqs,
    dispatchJobReqCandidates,
    dispatchRecruitingJobReqOutcomes,
  ]);
  const isManager = getCurrentUserManagerRole();

  return (
    <Container className="generic_fluid_container" fluid>
      <Row>
        <Col md="8">
          <RecruitingAnnouncement />
          {isManager ? <MyTeamSlicker recruitingJobReqs={recruitingJobReqs} /> : null}
        </Col>
        <Col md="4">
          <OngoingRecruitments recruitingJobReqs={recruitingJobReqs} />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <RecruitingMainContainer
            recruitingJobReqs={recruitingJobReqs}
            recruitingJobReqOutcomes={recruitingJobReqOutcomes}
            candidates={candidates}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    recruitingJobReqs: state.hcm.recruitingJobReqs,
    recruitingJobReqOutcomes: state.hcm.recruitingJobReqOutcomes,
    candidates: state.hcm.candidates,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchRecruitingJobReqs: () => dispatch(fetchRecruitingJobReqs()),
  dispatchRecruitingJobReqOutcomes: () => dispatch(fetchRecruitingJobReqOutcomes()),
  dispatchJobReqCandidates: () => dispatch(fetchRecruitingCandidates()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withCloseMenu(Recruiting));

import { ENVs } from '../../manager';

export const PERSON_CAREER_STATEMENT_SECTION_IDS = {};
PERSON_CAREER_STATEMENT_SECTION_IDS[ENVs.DEV] = '901';
PERSON_CAREER_STATEMENT_SECTION_IDS[ENVs.TEST] = '901';
PERSON_CAREER_STATEMENT_SECTION_IDS[ENVs.PROD] = '901';

export const CAREER_PREFERENCES_SECTION_IDS = {};
CAREER_PREFERENCES_SECTION_IDS[ENVs.DEV] = '300000004937462';
CAREER_PREFERENCES_SECTION_IDS[ENVs.TEST] = '300000004937462';
CAREER_PREFERENCES_SECTION_IDS[ENVs.PROD] = '300000004937462';

export const DEV_GOALS_SECTION_IDS = {};
DEV_GOALS_SECTION_IDS[ENVs.DEV] = '300002308156421';
DEV_GOALS_SECTION_IDS[ENVs.TEST] = '300002308156421';
DEV_GOALS_SECTION_IDS[ENVs.PROD] = '300002308156421';

export const EDI_SECTION_IDS = {};
EDI_SECTION_IDS[ENVs.DEV] = '300002308156366';
EDI_SECTION_IDS[ENVs.TEST] = '300002308156366';
EDI_SECTION_IDS[ENVs.PROD] = '300002308156366';

export const EAP_SECTION_IDS = {};
EAP_SECTION_IDS[ENVs.DEV] = '300002454737377';
EAP_SECTION_IDS[ENVs.TEST] = '300002454737377';
EAP_SECTION_IDS[ENVs.PROD] = '300002454737377';

export const EAP_MANAGER_SECTION_IDS = {};
EAP_MANAGER_SECTION_IDS[ENVs.DEV] = '300002706863636';
EAP_MANAGER_SECTION_IDS[ENVs.TEST] = '300002706863636';
EAP_MANAGER_SECTION_IDS[ENVs.PROD] = '300002706863636';

export const MISSION_MAIN_SECTION_IDS = {};
MISSION_MAIN_SECTION_IDS[ENVs.DEV] = '300000002439001';
MISSION_MAIN_SECTION_IDS[ENVs.TEST] = '300000002439001';
MISSION_MAIN_SECTION_IDS[ENVs.PROD] = '300000002439001';

export const PERF_GOALS_SECTION_IDS = {};
PERF_GOALS_SECTION_IDS[ENVs.DEV] = '300002454737285';
PERF_GOALS_SECTION_IDS[ENVs.TEST] = '300002454737285';
PERF_GOALS_SECTION_IDS[ENVs.PROD] = '300002454737285';

export const PERF_ANNUAL_INTERVIEW = {};
PERF_ANNUAL_INTERVIEW[ENVs.DEV] = '300002454737377';
PERF_ANNUAL_INTERVIEW[ENVs.TEST] = '300002454737377';
PERF_ANNUAL_INTERVIEW[ENVs.PROD] = '300002454737377';

export const EPR_COLLAB_SECTION_IDS = {};
EPR_COLLAB_SECTION_IDS[ENVs.DEV] = '300002468480030';
EPR_COLLAB_SECTION_IDS[ENVs.TEST] = '300002468480030';
EPR_COLLAB_SECTION_IDS[ENVs.PROD] = '300002468480030';

export const FEEDBACK_IDS = {};
FEEDBACK_IDS[ENVs.DEV] = '300002706863581';
FEEDBACK_IDS[ENVs.TEST] = '300002706863581';
FEEDBACK_IDS[ENVs.PROD] = '300002706863581';

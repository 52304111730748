import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCurrentUserId } from '../../api/manager';
import IframeContainer from '../common/IframeContainer';
import { setSelectedEmployee } from '../../actions/settingsAction';

const Profile = ({ dispatchSetSelectedEmployee }) => {
  const { personId } = useParams();
  const targetPersonId = (personId != undefined && personId) || getCurrentUserId();
  var iframeUrl = '';

  useEffect(() => {
    return () => {
      dispatchSetSelectedEmployee(null);
    };
  }, [dispatchSetSelectedEmployee]);
  if (personId == undefined) {
    iframeUrl = `/xmlpserver/Custom/Human Capital Management/Natixis Custom/Embedded Report/Career Card/Career Card.xdo?_xiasynch=&_xpf=&_xpt=1&_dFlag=false&_edIndex=0&_dIndex=0&_rToken=&_ranDiag=false&_xdo=%2FCustom%2FHuman%20Capital%20Management%2FNatixis%20Custom%2FEmbedded%20Report%2FCareer%20Card%2FCareer%20Card.xdo&_xmode=4&_paramsp_personid=${targetPersonId}&_xt=TemplateEN`;
  } else {
    iframeUrl = `/xmlpserver/Custom/Human Capital Management/Natixis Custom/Embedded Report/Performance/Performance_Employee rp.xdo?_xiasynch=&_xpf=&_xpt=1&_dFlag=false&_edIndex=0&_dIndex=0&_rToken=&_ranDiag=false&_xdo=%2FCustom%2FHuman%20Capital%20Management%2FNatixis%20Custom%2FEmbedded%20Report%2FPerformance%2FPerformance_Employee%20rp.xdo&_xmode=4&_paramspWorkerId=${targetPersonId}`;
  }

  return (
    <IframeContainer
      id="performance_iframe"
      src={iframeUrl}
      height="100%"
      width="100%"
      frameBorder={0}
      className="fullheight"
    />
  );
};

const mapDispatchToProps = dispatch => ({
  dispatchSetSelectedEmployee: e => dispatch(setSelectedEmployee(e)),
});

export default connect(null, mapDispatchToProps)(Profile);

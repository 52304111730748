import React from 'react';
import { Card } from 'react-bootstrap';
import MUIDataTable from 'mui-datatables';

const DataTableCard = ({ data, title = '', columns, options }) => {
  return (
    <Card>
      <Card.Body>
        {title != '' ? <Card.Title>{title}</Card.Title> : <></>}
        <MUIDataTable
          data={data}
          columns={columns}
          options={options}
          className="table table-hover table-striped table-vcenter mb-0"
        />
      </Card.Body>
    </Card>
  );
};

export default DataTableCard;

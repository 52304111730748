import csv from 'csvtojson';
import { fetchEmployeesProfileData } from '../../actions/apiPerformanceActions';
import {
  setFeedbacks,
  setEmployeeTeam,
  setEmployeeSubTeam,
  setTutorials,
  setAnnouncements,
  setKPIsGoals,
  setKPIsPerformance,
  setGoalsList,
  setRecruitingJobReqList,
  setCandidatesList,
  setActions,
  setAnnouncementsRecruitment,
  setKPILearning,
  setEmployeeArchive,
  setRecruitingJobReqOutcomes,
} from '../../actions/hcmActions';
import {
  setEmployeesEDIData,
  setEmployeesEAPData,
  setEPRs,
  setEAPFeedbacks,
  setJimmyEligibility,
} from '../../actions/performanceActions';
import { JobReqsSource } from '../../utils/data';
import { HCMEndpoint } from '../endpoints';
import {
  autoDispatcher,
  makeRequest,
  withCSVdecode,
  withXMLdecode,
  cleanText,
  defaultPreprocessor,
  makeRequestPromise,
} from '../SOAPrequestMaker';
import { uiLoadingJobReqs, uiLoadingJobReqsOutcomes } from '../../actions/uiActions';

export const doFetchFeedbacks = dispatch => {
  const endpoint = HCMEndpoint.Feedbacks;
  makeRequest(
    endpoint,
    withXMLdecode(
      autoDispatcher({
        selector: payload => payload.FEEDBACK,
        cleaner: cleanFeedbacks,
        dispatch: dispatch,
        action: setFeedbacks,
      }),
    ),
    { format: 'xml' },
  );
};
export const doFetchEmployeeArchive = dispatch => {
  const endpoint = HCMEndpoint.EmployeeArchive;
  const onSuccess = payload => {
    dispatch(setEmployeeArchive(payload));
  };
  const preprocessor = response => cleanText(defaultPreprocessor(response));
  makeRequest(endpoint, withCSVdecode(onSuccess), { preprocessor: preprocessor });
};

export const doFetchRecruitingJobReqs = dispatch => {
  dispatch(uiLoadingJobReqs(true));
  const endpoint = HCMEndpoint.RecruitingJobReqList;

  const extractJobReqs = (payload, selector, cleaner, source) => {
    let target = selector(payload);
    if (target == undefined) {
      return undefined;
    } else {
      if (!Array.isArray(target)) {
        target = [target];
      }
      const cleanedData = cleaner(target, source);
      return cleanedData;
    }
  };

  const onSuccess = payload => {
    const cleaner = cleanRecruitingJobReqs;
    const action = setRecruitingJobReqList;
    const sources = [
      {
        selector: payload => payload.JOBREQS,
        source: JobReqsSource.FromMe,
      },
      {
        selector: payload => payload.JOBREQ_TEAMS,
        source: JobReqsSource.FromMyTeam,
      },
    ];

    let data = [];
    sources.forEach(source => {
      const dataSource = extractJobReqs(payload, source.selector, cleaner, source.source);
      if (dataSource != undefined) {
        data = data.concat(dataSource);
      }
    });
    dispatch(uiLoadingJobReqs(false));
    dispatch(action(data));
  };
  makeRequest(endpoint, withXMLdecode(onSuccess), { format: 'xml' });
};

export const doFetchRecruitingCandidates = dispatch => {
  const endpoint = HCMEndpoint.RecruitingCandidatesList;
  const onSuccess = payload => dispatch(setCandidatesList(payload));
  const preprocessor = response => cleanText(defaultPreprocessor(response));
  makeRequest(endpoint, withCSVdecode(onSuccess), { preprocessor: preprocessor });
};

export const doFetchEmployeeTeam = dispatch => {
  const endpoint = HCMEndpoint.EmployeeTeam;
  const onSuccess = payload => dispatch(setEmployeeTeam(payload));
  const preprocessor = response => cleanText(defaultPreprocessor(response));
  makeRequest(endpoint, withCSVdecode(onSuccess), { preprocessor: preprocessor });
};

export const doFetchEmployeeSubTeam = (dispatch, personId) => {
  const endpoint = HCMEndpoint.EmployeeTeam;
  const onSuccess = payload =>
    dispatch(setEmployeeSubTeam({ personId: personId, payload: payload }));
  const preprocessor = response => cleanText(defaultPreprocessor(response));
  makeRequest(endpoint, withCSVdecode(onSuccess), {
    personId: personId,
    preprocessor: preprocessor,
  });
};

export const doFetchTutorials = dispatch => {
  const endpoint = HCMEndpoint.Tutorials;
  makeRequest(
    endpoint,
    withXMLdecode(
      autoDispatcher({
        selector: payload => payload.TUTORIALS,
        cleaner: cleanTutorials,
        dispatch: dispatch,
        action: setTutorials,
      }),
    ),
    { format: 'xml' },
  );
};

export const doFetchAnnouncements = (dispatch, announcementContext) => {
  const endpoint = HCMEndpoint.Announcements;
  const action =
    announcementContext == 'RECRUITMENT' ? setAnnouncementsRecruitment : setAnnouncements;
  makeRequest(
    endpoint,
    withXMLdecode(
      autoDispatcher({
        selector: payload => payload.G_1,
        cleaner: cleanAnnouncements,
        dispatch: dispatch,
        action: action,
      }),
    ),
    { format: 'xml', context: announcementContext },
  );
};

export const doFetchKPIsGoals = dispatch => {
  const endpoint = HCMEndpoint.KPIsGoals;
  const onSuccess = payload => {
    dispatch(setKPIsGoals(payload));
  };
  makeRequest(endpoint, withCSVdecode(onSuccess));
};

export const doFetchKPIsPerformance = dispatch => {
  const endpoint = HCMEndpoint.KPIsPerformance;
  const onSuccess = payload => {
    dispatch(setKPIsPerformance(payload));
  };
  makeRequest(endpoint, withCSVdecode(onSuccess));
};

export const doFetchGoalsList = dispatch => {
  const endpoint = HCMEndpoint.GoalsList;
  const onSuccess = payload => {
    dispatch(setGoalsList(cleanGoalsList(payload)));
  };
  makeRequest(endpoint, withCSVdecode(onSuccess));
};

export const doFetchActions = dispatch => {
  const endpoint = HCMEndpoint.Actions;
  const onSuccess = payload => {
    dispatch(setActions(payload));
  };
  makeRequest(endpoint, withCSVdecode(onSuccess));
};

export const doFetchKPILearning = dispatch => {
  const endpoint = HCMEndpoint.KPILearning;
  makeRequest(
    endpoint,
    withXMLdecode(
      autoDispatcher({
        selector: payload => payload.G_1,
        cleaner: cleanKPILearning,
        dispatch: dispatch,
        action: setKPILearning,
      }),
    ),
    { format: 'xml' },
  );
};

export const doFetchEDIEligibility = dispatch => {
  const prepareData = payload => {
    const items = payload;
    const result = items.reduce((acc, item) => {
      const personId = item['PERSON_ID'];
      acc[personId] = item;
      return acc;
    }, {});
    return result;
  };
  const endpoint = HCMEndpoint.EDIEligibility;
  const onSuccess = payload => {
    const transferedEmployees = payload
      .filter(x => x['EDI_EVALUATOR'] != '' && x['MANAGER_ID'] != x['EDI_EVALUATOR'])
      .map(x => x['PERSON_ID']);
    dispatch(setEmployeesEDIData(prepareData(payload)));
    if (transferedEmployees.length > 0) {
      dispatch(fetchEmployeesProfileData(transferedEmployees, false));
    }
  };
  const preprocessor = response => cleanText(defaultPreprocessor(response));
  makeRequest(endpoint, withCSVdecode(onSuccess), { preprocessor: preprocessor });
};

export const doFetchEAPEligibility = dispatch => {
  const prepareData = payload => {
    const items = payload;
    const result = items.reduce((acc, item) => {
      const personId = item['PERSON_ID'];
      acc[personId] = item;
      return acc;
    }, {});
    return result;
  };
  const endpoint = HCMEndpoint.EAPEligibility;
  const onSuccess = payload => {
    const transferedEmployees = payload
      .filter(x => x['EAP_EVALUATOR'] != '' && x['MANAGER_ID'] != x['EAP_EVALUATOR'])
      .map(x => x['PERSON_ID']);
    dispatch(setEmployeesEAPData(prepareData(payload)));
    if (transferedEmployees.length > 0) {
      dispatch(fetchEmployeesProfileData(transferedEmployees, false));
    }
  };
  const preprocessor = response => cleanText(defaultPreprocessor(response));
  makeRequest(endpoint, withCSVdecode(onSuccess), { preprocessor: preprocessor });
};

const cleanKPILearning = learnings => {
  return learnings.map(learning => {
    const item = {};
    for (const key in learning) {
      item[key] = learning[key]._text;
    }
    return item;
  });
};

const cleanFeedbacks = feedbacks => {
  return feedbacks.map(feedback => ({
    CONTENT: cleanText(feedback.CONTENT._text),
    CDATE: feedback.CDATE._text,
    TYPE: feedback.TYPE._text,
    LANG_CODE: feedback.LANG_CODE._text,
    RECEIVER_NAME: cleanText(feedback.RECEIVER_NAME._text),
    AUTHOR_NAME: cleanText(feedback.AUTHOR_NAME._text),
    MAIL_SUBJECT: cleanText(feedback.MAIL_SUBJECT._text),
    STATUS: feedback.STATUS._text,
    PERSON_ID: feedback.PERSON_ID._text,
  }));
};

const cleanTutorials = tutorials => {
  return tutorials.map(tutorial => ({
    SUBJECT: tutorial.SUBJECT._text,
    IMAGE_URL: tutorial.IMAGE_URL._text,
    LAST_UPDATE_DATE: tutorial.LAST_UPDATE_DATE._text,
    DESCRIPTION: cleanText(tutorial.DESCRIPTION._text),
  }));
};

const cleanAnnouncements = announcements => {
  return announcements.map(announcement => ({
    SUBJECT: cleanText(announcement.SUBJECT._text),
    LAST_UPDATE_DATE: announcement.LAST_UPDATE_DATE._text,
    DESCRIPTION: cleanText(announcement.DESCRIPTION._text),
  }));
};

const cleanGoalsList = goals => {
  return goals.map(g => ({
    GOAL_NAME: cleanText(g.GOAL_NAME),
    GOAL_SOURCE_CODE: cleanText(g.GOAL_SOURCE_CODE),
    GOAL_START_DATE: cleanText(g.GOAL_START_DATE),
    GOAL_TYPE_CODE: cleanText(g.GOAL_TYPE_CODE),
    PERCENT_COMPLETE_CODE: cleanText(g.PERCENT_COMPLETE_CODE),
    STATUS_CODE: cleanText(g.STATUS_CODE),
  }));
};

const cleanRecruitingJobReqs = (recruitingJobReqs, source) => {
  return recruitingJobReqs.map(recruitingJobReq => ({
    TITLE: cleanText(recruitingJobReq.TITLE._text),
    REQUISITION_ID: recruitingJobReq.REQUISITION_ID._text,
    GLOBAL_TYPE: cleanText(recruitingJobReq.GLOBAL_TYPE._text),
    REQUISITION_NUMBER: cleanText(recruitingJobReq.REQUISITION_NUMBER._text),
    JOB_REQ_STATUS: cleanText(recruitingJobReq.JOB_REQ_STATUS._text),
    CREATION_DATE: cleanText(recruitingJobReq.CREATION_DATE._text),
    LAST_UPDATE_DATE: cleanText(recruitingJobReq.LAST_UPDATE_DATE._text),
    RECRUITER_NAME: cleanText(recruitingJobReq.RECRUITER_NAME._text),
    RECRUITER_ID: cleanText(recruitingJobReq.RECRUITER_ID._text),
    REQUISITION_STATE_PHASE: cleanText(recruitingJobReq.REQUISITION_STATE_PHASE._text),
    OBJECT_STATUS: cleanText(recruitingJobReq.OBJECT_STATUS._text),
    HIRING_NAME: cleanText(recruitingJobReq.HIRING_NAME._text),
    HIRING_MANAGER_ID: cleanText(recruitingJobReq.HIRING_MANAGER_ID._text),
    PERSON_ID: cleanText(recruitingJobReq.PERSON_ID._text),
    GLOBAL_STATUS_DETAILS: cleanText(recruitingJobReq.GLOBAL_STATUS_DETAILS._text),
    ACTION_BY: recruitingJobReq.ACTION_BY._text,
    FINANCE_NAME: cleanText(recruitingJobReq.FINANCE_NAME._text),
    OPEN_DATE: recruitingJobReq.OPEN_DATE._text,
    NEXT_APPROVER: cleanText(recruitingJobReq.NEXT_APPROVER._text),
    DATE_START: recruitingJobReq.DATE_START._text,
    TASKID: recruitingJobReq.TASKID._text,
    NEXT_APPROVER_ID: recruitingJobReq.NEXT_APPROVER_ID._text,
    OBJECT_ID: recruitingJobReq.OBJECT_ID._text,
    HISTORY: recruitingJobReq?.HIST_OBJ ? recruitingJobReq.HIST_OBJ._text : {},
    APPROVAL_GROUP: [
      recruitingJobReq?.NAME_LV1 ? recruitingJobReq?.NAME_LV1._text : {},
      recruitingJobReq?.NAME_LV2 ? recruitingJobReq?.NAME_LV2._text : {},
      recruitingJobReq?.NAME_LV3 ? recruitingJobReq?.NAME_LV3._text : {},
    ],
    SOURCE: source,
  }));
};

export const doFetchProfileItemLastUpdateBy = async profileItemId => {
  const endpoint = HCMEndpoint.GetProfileItemLastUpdateBy;
  const onSuccess = payload => {
    const json = csv().fromString(payload);
    return json;
  };
  const preprocessor = response => cleanText(defaultPreprocessor(response));
  return makeRequestPromise(endpoint, onSuccess, {
    preprocessor: preprocessor,
    forceBodyRequest: buildRequestBody(endpoint, 'csv', profileItemId),
  });
};

const buildRequestBody = (endpoint, format = 'csv', profileItemId) => {
  const body = `
    <soapenv:Envelope xmlns:soapenv="http://www.w3.org/2003/05/soap-envelope" xmlns:pub="http://xmlns.oracle.com/oxp/service/PublicReportService">
      <soapenv:Body>
          <pub:runReport>
              <pub:reportRequest>
                  <pub:attributeFormat>${format}</pub:attributeFormat>
                  <pub:reportAbsolutePath>/Custom/Human Capital Management/Natixis Custom/Embedded Report/Home Page/WS/${endpoint.value}</pub:reportAbsolutePath>
                  <pub:sizeOfDataChunkDownload>-1</pub:sizeOfDataChunkDownload>
                  <pub:parameterNameValues>
                    <pub:item>
                      <pub:name>pProfileItemId</pub:name>
                      <pub:values>
                          <pub:item>${profileItemId}</pub:item>
                        </pub:values>
                    </pub:item>                
                  </pub:parameterNameValues>
              </pub:reportRequest>
          </pub:runReport>
      </soapenv:Body>
  </soapenv:Envelope>
`;
  return body;
};

export const doFecthEPRList = (dispatch, personId) => {
  const endpoint = HCMEndpoint.EPRDetail;
  const onSuccess = payload => {
    const data = payload.map(el => ({
      ...el,
      PERSON_ID: personId,
    }));
    dispatch(setEPRs(data));
  };
  const preprocessor = response => cleanText(defaultPreprocessor(response));
  makeRequest(endpoint, withCSVdecode(onSuccess), {
    preprocessor: preprocessor,
  });
};

export const doFecthEAPFeedbacks = (dispatch, personId) => {
  const endpoint = HCMEndpoint.GetFeedbacksByPersonId;
  const onSuccess = payload => {
    dispatch(setEAPFeedbacks(payload));
  };
  const preprocessor = response => cleanText(defaultPreprocessor(response));
  makeRequest(endpoint, withCSVdecode(onSuccess), {
    preprocessor: preprocessor,
    personId: personId,
  });
};

export const doFetchEAPFeedbacksByEAPItemId = (dispatch, eapItemId) => {
  const endpoint = HCMEndpoint.GetFeedbacksByEAPItemId;
  const onSuccess = payload => {
    dispatch(setEAPFeedbacks(payload));
  };
  const preprocessor = response => cleanText(defaultPreprocessor(response));
  makeRequest(endpoint, withCSVdecode(onSuccess), {
    preprocessor: preprocessor,
    forceBodyRequest: buildRequestBodyFeedbacksByEAPId(endpoint, 'csv', eapItemId),
  });
};

const buildRequestBodyFeedbacksByEAPId = (endpoint, format = 'csv', eapItemId) => {
  const body = `
    <soapenv:Envelope xmlns:soapenv="http://www.w3.org/2003/05/soap-envelope" xmlns:pub="http://xmlns.oracle.com/oxp/service/PublicReportService">
      <soapenv:Body>
          <pub:runReport>
              <pub:reportRequest>
                  <pub:attributeFormat>${format}</pub:attributeFormat>
                  <pub:reportAbsolutePath>/Custom/Human Capital Management/Natixis Custom/Embedded Report/Home Page/WS/${endpoint.value}</pub:reportAbsolutePath>
                  <pub:sizeOfDataChunkDownload>-1</pub:sizeOfDataChunkDownload>
                  <pub:parameterNameValues>
                    <pub:item>
                      <pub:name>pEAPItemId</pub:name>
                      <pub:values>
                          <pub:item>${eapItemId}</pub:item>
                        </pub:values>
                    </pub:item>                
                  </pub:parameterNameValues>
              </pub:reportRequest>
          </pub:runReport>
      </soapenv:Body>
  </soapenv:Envelope>
`;
  return body;
};

export const doFecthMyTeamEAPFeedbacks = dispatch => {
  const endpoint = HCMEndpoint.GetMyTeamEAPFeedbacks;
  const onSuccess = payload => {
    dispatch(setEAPFeedbacks(payload));
  };
  const preprocessor = response => cleanText(defaultPreprocessor(response));
  makeRequest(endpoint, withCSVdecode(onSuccess), {
    preprocessor: preprocessor,
  });
};

export const doFetchJobReqOutcomes = dispatch => {
  dispatch(uiLoadingJobReqsOutcomes(true));
  const endpoint = HCMEndpoint.RecruitingJobReqOutcomes;
  const onSuccess = payload => {
    dispatch(uiLoadingJobReqsOutcomes(false));
    dispatch(setRecruitingJobReqOutcomes(payload));
  };
  const preprocessor = response => cleanText(defaultPreprocessor(response));
  makeRequest(endpoint, withCSVdecode(onSuccess), {
    preprocessor: preprocessor,
  });
};

export const doFetchJimmyEligibility = dispatch => {
  const isEligibleJimmy = payload => {
    let isEligible = false;
    if (payload && Array.isArray(payload) && payload.length >= 1) {
      const iup = payload[0]['IUP'];
      isEligible = iup != null && iup != '';
    }
    return isEligible;
  };
  const endpoint = HCMEndpoint.JimmyEligibility;
  const onSuccess = payload => {
    dispatch(setJimmyEligibility(isEligibleJimmy(payload)));
  };
  const preprocessor = response => cleanText(defaultPreprocessor(response));
  makeRequest(endpoint, withCSVdecode(onSuccess), {
    preprocessor: preprocessor,
  });
};

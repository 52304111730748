import React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getCurrentUserId, getCurrentUserManagerRole } from '../../api/manager';
import { setSelectedEmployee } from '../../actions/settingsAction';
import { connect } from 'react-redux';

const TileKPIPosition = {
  LEFT: 'l',
  RIGHT: 'r',
  MIDDLE: 'm',
};

export const TileKPIOnly = {
  NO: 'Jimmy',
  ME: 'me',
  TEAM: 'team',
};

const TileKPI = ({ actions, tileData }) => {
  const [me, team] = extractDataFromActions(actions, tileData.selecter);
  let [linkMe, linkTeam] = tileData.links;
  const [dispatchCurrentUser] = tileData.onClickDispatchCurrentUser || [false, false];
  const isManager = getCurrentUserManagerRole();

  if (tileData.linkIfZeroForMe) {
    if (me == 0) {
      linkMe = tileData.linkIfZeroForMe;
    }
  }

  const ComponentMe = (
    position = isManager ? TileKPIPosition.LEFT : TileKPIPosition.MIDDLE,
  ) => (
    <DisplayOneKPI
      value={me}
      label="me"
      link={linkMe}
      linkType={tileData.linkType}
      position={position}
      isManager={isManager}
      dispatchCurrentUser={dispatchCurrentUser}
    />
  );

  const ComponentTeam = position => (
    <DisplayOneKPI
      value={team}
      label="team"
      link={linkTeam}
      linkType={tileData.linkType}
      position={position}
      isManager={isManager}
    />
  );

  return (
    <>
      <Row>
        {tileData.keepOnlyOneKPI == TileKPIOnly.TEAM ? (
          ComponentTeam(TileKPIPosition.MIDDLE)
        ) : (
          <>
            {tileData.keepOnlyOneKPI == TileKPIOnly.ME ? (
              ComponentMe(TileKPIPosition.MIDDLE)
            ) : (
              <>
                {/* TODO deactive JIMMY link for now */}
                {tileData.keepOnlyOneKPI == TileKPIOnly.NO ? null : (
                  <>
                    {ComponentMe()}
                    {isManager ? ComponentTeam(TileKPIPosition.RIGHT) : null}
                  </>
                )}
              </>
            )}
          </>
        )}
      </Row>
      {tileData.AdditionalRow != null ? (
        <tileData.AdditionalRow actions={actions} />
      ) : null}
    </>
  );
};

export default TileKPI;

export const extractDataFromActions = (actions, selecter) => {
  if (actions == null) {
    return [null, null];
  } else {
    return selecter(actions);
  }
};

const mapDispatchToProps = dispatch => ({
  dispatchSetSelectedEmployee: e => dispatch(setSelectedEmployee(e)),
});

const TileLink = connect(
  null,
  mapDispatchToProps,
)(({ link, linkType, dispatchCurrentUser, dispatchSetSelectedEmployee, children }) => {
  let attributeLink;
  if (linkType === 'internal') {
    attributeLink = link ? { to: link } : {};
  } else {
    attributeLink = link ? { href: link } : {};
  }

  const onClick = () => {
    if (dispatchCurrentUser) {
      dispatchSetSelectedEmployee(getCurrentUserId());
    }
  };

  return (
    <>
      {linkType === 'internal' ? (
        <NavLink {...attributeLink} onClick={() => onClick()}>
          {children}
        </NavLink>
      ) : (
        <a {...attributeLink} target="_blank" rel="noreferrer">
          {children}
        </a>
      )}
    </>
  );
});

const DisplayOneKPI = ({
  value,
  label,
  link,
  linkType,
  position = TileKPIPosition.RIGHT,
  isManager = true,
  dispatchCurrentUser,
}) => {
  const { t } = useTranslation();
  let colSize = '6';
  let additionalClassName = '';
  switch (position) {
    case TileKPIPosition.LEFT:
      additionalClassName = 'border-right mb-10';
      break;
    case TileKPIPosition.MIDDLE:
      additionalClassName = 'mb-10';
      colSize = '12';
      break;
  }
  return (
    <Col lg={colSize} xs={colSize} className={`tile_me ${additionalClassName}`}>
      {value !== null ? (
        <TileLink
          link={link}
          linkType={linkType}
          dispatchCurrentUser={dispatchCurrentUser}
        >
          <div>
            {isManager ? (
              <>
                <label>{t(label)}</label>
                <h4 className="font-16">{value}</h4>
              </>
            ) : (
              <h3 className="font-24">{value}</h3>
            )}
          </div>
        </TileLink>
      ) : (
        <div className="spinner-border" role="status"></div>
      )}
    </Col>
  );
};

import React from 'react';
import { Button } from 'react-bootstrap';
import { BsWrench as Wrench } from 'react-icons/bs';
import { openHCMMenu } from '../../../utils/hcmMenu';

const AdminMenu = () => {
  return (
    <div className="notification d-flex" style={{ marginLeft: '5px' }}>
      <div className="dropdown d-flex">
        <Button variant="outline-secondary" data-toggle="dropdown">
          Admin <Wrench size={20} />
        </Button>

        <div
          className="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
          style={{ zIndex: 99999999 }}
        >
          <a
            title="Admin Menu"
            className="dropdown-item"
            href="#"
            onClick={e => {
              e.preventDefault();
              openHCMMenu({ displayHROnly: false });
            }}
          >
            <i className={`dropdown-icon fe fe-link`} />
            Access Admin Menu
          </a>
          <div className="dropdown-divider" />
          {adminLinks.map((link, index) => {
            return (
              <a
                rel="noreferrer"
                target="_blank"
                href={link.url}
                className="dropdown-item"
                key={index}
              >
                <i className={`dropdown-icon fe ${link.icon}`} />
                {link.name}
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AdminMenu;

const adminLinks = [
  {
    url: '/fscmUI/faces/FuseTaskListManagerTop',
    icon: 'fe-external-link',
    name: 'Setup and Maintenance',
  },
  {
    url: '/fscmUI/faces/FuseOverview?fndGlobalItemNodeId=itemNode_tools_sandboxes',
    icon: 'fe-external-link',
    name: 'Sandboxes',
  },
  {
    url: '/analytics',
    icon: 'fe-external-link',
    name: 'BI Publisher',
  },
  {
    url: '/hcmUI/faces/FuseOverview?fnd=%252FWEB-INF%252Foracle%252Fapps%252Fhcm%252FhomePage%252FpublicUi%252FquickAction%252Fflow%252FQuickActionSearchFlow.xml%2523QuickActionSearchFlow%3BfndWarnChanges%253D%2523%257BProfile.values.HCM_SAVE_CHANGES_WARNING_ENABLED%2Beq%2B%2527Y%2527%257D%253BqueryMyOrg%253D%2523%257B%2527Y%2527%257D%253BrootMenuGroupNodeId%253D%2523%257B%2527groupNode_workforce_management%2527%257D%253BquickActionItemNodeId%253D%2523%257B%2527my_org_manage_allocated_checklists_1%2527%257D%253Bheader%253D%2523%257BadfBundle%255B%2527oracle.apps.hcm.people.resource.HcmPeopleTopGenBundle%2527%255D%255B%2527Action.ManageAllocatedChecklists%2527%255D%257D%253B%3B%3BAllocate%2BChecklists%3Bfalse%3B256%3B%3B%3B&pageParams=fndDetailsMode%3Dtrue',
    icon: 'fe-external-link',
    name: 'Allocate Checklists',
  },
  {
    url: '/hcmUI/faces/FuseOverview?fnd=%252FWEB-INF%252Foracle%252Fapps%252Fhcm%252Fchecklists%252Fui%252Fflow%252FChecklistsMainAreaFlow.xml%2523ChecklistsMainAreaFlow%3BfndWarnChanges%253D%2523%257BProfile.values.HCM_SAVE_CHANGES_WARNING_ENABLED%2Beq%2B%2527Y%2527%257D%253BcalledFrom%253D%2523%257B%2527QuickAction%2527%257D%253B%3B%3BChecklist%2BTemplates%3Bfalse%3B256%3B%3B%3B&pageParams=fndDetailsMode%3Dtrue',
    icon: 'fe-external-link',
    name: 'Checklist Templates',
  },
  {
    url: '/hcmUI/faces/FndOverview?fnd=%3B%3B%3B%3Bfalse%3B256%3B%3B%3B&fndGlobalItemNodeId=itemNode_workforce_management_data_exchange',
    icon: 'fe-external-link',
    name: 'Data Exchange',
  },
  {
    url: '/hcmUI/faces/FndOverview?fnd=%3B%3B%3B%3Bfalse%3B256%3B%3B%3B&fndGlobalItemNodeId=PER_HCMPEOPLETOP_FUSE_DIRECTORY',
    icon: 'fe-external-link',
    name: 'Directory',
  },
  {
    url: '/hcmUI/faces/FuseOverview?fnd=%252FWEB-INF%252Foracle%252Fapps%252Ffnd%252Fapplcore%252Flookups%252FpublicUi%252Fflow%252FManageCommonLookupsTF.xml%2523ManageCommonLookupsTF%3BfndWarnChanges%253D%2523%257BProfile.values.HCM_SAVE_CHANGES_WARNING_ENABLED%2Beq%2B%2527Y%2527%257D%253Bmode%253Dsearch%253B%3B%3BLookups%3Bfalse%3B256%3B%3B%3B&pageParams=fndDetailsMode%3Dtrue',
    icon: 'fe-external-link',
    name: 'Manage Common Lookups',
  },
  {
    url: '/hcmUI/faces/FuseOverview?fnd=%252FWEB-INF%252Foracle%252Fapps%252Fhcm%252Fprofiles%252Fquestionnaire%252Flibrary%252FpublicUi%252Fflow%252FManageQuestionnairesFlow.xml%2523ManageQuestionnairesFlow%3BfndWarnChanges%253D%2523%257BProfile.values.HCM_SAVE_CHANGES_WARNING_ENABLED%2Beq%2B%2527Y%2527%257D%253B%3B%3BQuestionnaires%3Bfalse%3B256%3B%3B%3B&pageParams=fndDetailsMode%3Dtrue',
    icon: 'fe-external-link',
    name: 'Questionnaire',
  },
  {
    url: '/hcmUI/faces/FuseOverview?fnd=%252FWEB-INF%252Foracle%252Fapps%252Fhcm%252Fprofiles%252Fquestionnaire%252Flibrary%252FpublicUi%252Fflow%252FManageTemplatesFlow.xml%2523ManageTemplatesFlow%3BfndWarnChanges%253D%2523%257BProfile.values.HCM_SAVE_CHANGES_WARNING_ENABLED%2Beq%2B%2527Y%2527%257D%253B%3B%3BQuestionnaire%2BTemplates%3Bfalse%3B256%3B%3B%3B&pageParams=fndDetailsMode%3Dtrue',
    icon: 'fe-external-link',
    name: 'Questionnaire Templates',
  },
  {
    url: '/fscmUI/faces/FuseOverview?fnd=%3B%3B%3B%3Bfalse%3B256%3B%3B%3B&fndGlobalItemNodeId=itemNode_tools_scheduled_processes_fuse_plus',
    icon: 'fe-external-link',
    name: 'Scheduled processes',
  },
  {
    url: '/hcmUI/faces/FndOverview?fnd=%3B%3B%3B%3Bfalse%3B256%3B%3B%3B&fndGlobalItemNodeId=ASE_FUSE_SECURITY_CONSOLE',
    icon: 'fe-external-link',
    name: 'Security console',
  },
  {
    url: '/hcmUI/faces/FndOverview?fnd=%3B%3B%3B%3Bfalse%3B256%3B%3B%3B&fndGlobalItemNodeId=itemNode_Tools_TransactionConsole_TransactionAdministratorConsol',
    icon: 'fe-external-link',
    name: 'Transaction console',
  },
  {
    url: '/cs',
    icon: 'fe-external-link',
    name: 'Web Server Content',
  },
  {
    url: '/fscmUI/faces/FuseOverview?fndGlobalItemNodeId=itemNode_tools_DiagnosticDashboard&fnd=%3B%3B%3B%3Bfalse%3B256%3B%3B%3B',
    icon: 'fe-external-link',
    name: 'Run Diagnostics Tests',
  },
];

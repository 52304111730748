import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Carousel } from 'react-bootstrap';
import { fetchAnnouncements } from '../../../actions/apiActions';
import LoaderInCard from '../../common/LoaderInCard';
import NoData from '../../common/NoData';
import Announcement from './Announcement';
import { useTranslation } from 'react-i18next';

const Announcements = ({ announcements, dispatchFetchAnnouncements }) => {
  const { t } = useTranslation();
  useEffect(() => {
    dispatchFetchAnnouncements();
  }, [dispatchFetchAnnouncements]);

  return (
    <Card className="card_dashboard min_height">
      <Card.Header>
        <Card.Title>{t('last_com_title')}</Card.Title>
      </Card.Header>
      <Card.Body>
        {announcements.fetched > 0 ? (
          <>
            {announcements.data.length > 0 ? (
              <Carousel variant="dark">
                {announcements.data.map((announcement, index) => {
                  return (
                    <Carousel.Item key={index}>
                      <Announcement announcement={announcement} />
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            ) : (
              <NoData />
            )}
          </>
        ) : (
          <LoaderInCard loaderMarginTop="20%" />
        )}
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    announcements: state.hcm.announcements,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchFetchAnnouncements: () => dispatch(fetchAnnouncements()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Announcements);

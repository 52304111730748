import React from 'react';
import { Button } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const askConfirmation = (onYes, t, title = '', subtitle = '', midtitle = null) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div>
          <h3 style={{ textAlign: 'center' }}>{title}</h3>
          <br />
          <h3 style={{ textAlign: 'center' }}>{subtitle}</h3>
          <br />
          {midtitle ? (
            <>
              <i style={{ textAlign: 'center' }}>{midtitle}</i>
              <br />
              <br />
            </>
          ) : null}
          <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
            <br></br>
            <Button
              style={{ fontSize: '20px', width: '100px' }}
              onClick={() => onClose()}
            >
              {t('no')}
            </Button>
            <Button
              style={{ fontSize: '20px', width: '100px' }}
              onClick={() => {
                onYes();
                onClose();
              }}
            >
              {t('yes')}
            </Button>
          </div>
        </div>
      );
    },
  });
};

export default askConfirmation;

const initialState = {
  professionalProject: {
    loading: false,
    error: false,
  },
  developmentGoals: {
    saving: false,
    loading: false,
    error: false,
  },
  edis: {
    saving: false,
    loading: false,
    error: false,
    needToSynchronise: false,
  },
  eaps: {
    saving: false,
    loading: false,
    error: false,
  },
  epr: {
    saving: false,
    loading: false,
    error: false,
  },
  feedbacks: {
    saving: false,
    loading: false,
    error: false,
  },
  recruiting: {
    pendingRequests: {
      isApproving: false,
      isRejecting: false,
      isLoading: false,
    },
    finishedRequest: {
      isLoading: false,
    },
  },
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING_PROFESSIONAL_PROJECT':
      return {
        ...state,
        professionalProject: {
          ...state.professionalProject,
          loading: action.payload,
        },
      };

    case 'SAVING_DEVELOPMENT_GOAL':
      return {
        ...state,
        developmentGoals: {
          ...state.developmentGoals,
          saving: action.payload,
        },
      };

    case 'SAVING_EDI':
      return {
        ...state,
        edis: {
          ...state.edis,
          saving: action.payload,
        },
      };

    case 'NEED_TO_SYNCHRONISE_EDI':
      return {
        ...state,
        edis: {
          ...state.edis,
          needToSynchronise: action.payload,
        },
      };

    case 'SAVING_EAP':
      return {
        ...state,
        eaps: {
          ...state.eaps,
          saving: action.payload,
        },
      };
    case 'LOADING_EPR':
      return {
        ...state,
        epr: {
          ...state.epr,
          loading: action.data,
        },
      };
    case 'SAVING_EPR':
      return {
        ...state,
        epr: {
          ...state.epr,
          saving: action.data,
        },
      };
    case 'SAVING_FEEDBACK':
      return {
        ...state,
        feedbacks: {
          ...state.feedbacks,
          saving: action.payload,
        },
      };
    case 'ERROR_EAP':
      return {
        ...state,
        eaps: {
          ...state.eaps,
          error: action.data,
        },
      };
    case 'APPROVE_RECRUITING_PENDING_REQUESTS':
      return {
        ...state,
        recruiting: {
          ...state.recruiting,
          pendingRequests: {
            ...state.recruiting.pendingRequests,
            isApproving: action.payload,
          },
        },
      };
    case 'REJECT_RECRUITING_PENDING_REQUESTS':
      return {
        ...state,
        recruiting: {
          ...state.recruiting,
          pendingRequests: {
            ...state.recruiting.pendingRequests,
            isRejecting: action.payload,
          },
        },
      };
    case 'RECRUITING_LOADING':
      return {
        ...state,
        recruiting: {
          ...state.recruiting,
          pendingRequests: {
            ...state.recruiting.pendingRequests,
            isLoading: action.payload,
          },
        },
      };
    case 'RECRUITING_OUTCOMES_LOADING':
      return {
        ...state,
        recruiting: {
          ...state.recruiting,
          finishedRequest: {
            ...state.recruiting.finishedRequest,
            isLoading: action.payload,
          },
        },
      };
    default:
      return state;
  }
};

export default uiReducer;

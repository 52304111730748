import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import DataTableCard from '../../common/DataTableCard';
import {
  constructJobReqsMap,
  getColumnsPending,
  getColumnsFinished,
  optionsFinished,
  optionsPending,
  preparePendingData,
  prepareFinishedData,
} from './DocumentTableStructure';
import { useTranslation } from 'react-i18next';
import MassValidationModal from './MassValidationModal';
import { connect } from 'react-redux';
import { isCurrentUserAllowedMassValidation } from '../../../api/manager';

const RecruitingValidation = ({
  recruitingJobReqs,
  recruitingJobReqOutcomes,
  isLoadingPending,
  isLoadingFinished,
  handleOpenDetailsModal,
}) => {
  const [jobReqsMap, setJobReqsMap] = useState(new Map());
  const [jobReqsComments, setJobReqsComments] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedJobs, setSelectedJobs] = useState(false);
  const [actionModal, setActionModal] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    setJobReqsMap(constructJobReqsMap(recruitingJobReqs));
  }, [recruitingJobReqs]);

  const getJobReq = id => {
    return jobReqsMap[id];
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handlerCommentChange = (requisitionNumber, comment) => {
    const data = {};
    data[requisitionNumber] = comment;
    setJobReqsComments({ ...jobReqsComments, ...data });
  };

  const handlerOpenModal = (value, type, selectedRows, displayData) => {
    if (value) {
      let jobs = [];
      let indexOfSelectedRows = [];

      selectedRows.data.map(rowIndex => {
        indexOfSelectedRows.push(rowIndex.dataIndex);
      });
      displayData.map(job => {
        if (indexOfSelectedRows.includes(job.dataIndex)) {
          job['LOADING_STATUS'] = JOB_REQ_LOADING_STATUS.QUEUED;
          jobs.push(job);
        }
      });
      setSelectedJobs(jobs);
      setOpenModal(value);
      setActionModal(type);
    }
  };

  // temporary disable feature for non allowed user
  if (!isCurrentUserAllowedMassValidation()) {
    return null;
  }

  return (
    <>
      <MassValidationModal
        open={openModal}
        handleCloseModal={handleCloseModal}
        selectedJobs={selectedJobs}
        setSelectedJobs={setSelectedJobs}
        recruitingJobReqs={recruitingJobReqs}
        action={actionModal}
        jobReqsComments={jobReqsComments}
      />
      <Row>
        <DataTableCard
          data={
            !isLoadingPending
              ? preparePendingData(recruitingJobReqs, jobReqsComments, t)
              : []
          }
          columns={getColumnsPending(
            getJobReq,
            handlerCommentChange,
            t,
            handleOpenDetailsModal,
            !isLoadingPending
              ? preparePendingData(recruitingJobReqs, jobReqsComments, t)
              : [],
          )}
          options={optionsPending(handlerOpenModal, isLoadingPending, t)}
          title={t('recruiting_pending')}
        />
      </Row>
      <Row>
        <DataTableCard
          data={
            !isLoadingFinished ? prepareFinishedData(recruitingJobReqOutcomes.data) : []
          }
          columns={getColumnsFinished(
            getJobReq,
            t,
            handleOpenDetailsModal,
            !isLoadingFinished ? prepareFinishedData(recruitingJobReqOutcomes.data) : [],
          )}
          options={optionsFinished(isLoadingFinished, t)}
          title={t('recruiting_pending_finished')}
        />
      </Row>
    </>
  );
};

export const JOB_REQ_LOADING_STATUS = {
  QUEUED: 'QUEUED',
  APPROVING: 'APPROVING',
  REJECTING: 'REJECTING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  FAILED: 'FAILED',
};

const mapStateToProps = state => {
  return {
    isLoadingPending: state.uiReducer.recruiting.pendingRequests.isLoading,
    isLoadingFinished: state.uiReducer.recruiting.finishedRequest.isLoading,
  };
};

export default connect(mapStateToProps)(RecruitingValidation);

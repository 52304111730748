import React from 'react';
import ReactApexChart from 'react-apexcharts';

const Chart = ({ title, labels, series, tooltips = [] }) => {
  const state = constructChartState(title, labels, series, tooltips);

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="donut"
          height="auto"
          autoselected="zoom"
        />
      </div>
    </div>
  );
};

export default Chart;

const constructChartState = (title, labels, series, tooltips = []) => {
  const state = {
    series: series,
    options: {
      plotOptions: {
        pie: {
          donut: {
            size: '50%',
            labels: {
              show: true,
              name: {
                show: true,
              },
              total: {
                show: false,
              },
            },
          },
          customScale: 1,
        },
      },
      noData: {
        text: 'No data',
        align: 'center',
        verticalAlign: 'middle',
      },
      colors: ['#551F72', '#D170A6', '#029CBA', '#DC3545', '#511DA5'],
      chart: {
        type: 'donut',
        redrawOnParentResize: true,
      },
      title: {
        text: title,
        align: 'bottom',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#263238',
        },
      },
      labels: labels,
      legend: {
        position: 'bottom',
      },
      ...(tooltips.length != 0 && {
        tooltip: {
          custom: function ({ seriesIndex, w }) {
            const color = w.globals.colors[seriesIndex].split('#')[1];
            const divClassNames = `charts_tooltip charts_tooltip_color${color.toLowerCase()}`;
            const employeesList = tooltips[seriesIndex];
            const employees = employeesList
              .split(';')
              .map(e => `<li>${e}</li>`)
              .join(' ');
            const html = `<div class="${divClassNames}"><ul>` + employees + '<ul></div>';
            return html;
          },
        },
      }),
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex];
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  };
  return state;
};

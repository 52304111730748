import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import DocumentTable from '../common/DocumentTable';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { fetchEmployeeArchive } from '../../../actions/apiActions';
import LoaderInCard from '../../common/LoaderInCard';
import { getColumnsArchive } from './DocumentTableStructure';
import {
  fetchEDIEligibility,
  postEDIStatus,
  transferEDI,
  fetchJimmyEligibility,
} from '../../../actions/apiPerformanceActions';
import { useTranslation } from 'react-i18next';
import { getCurrentUserId, getCurrentUserManagerRole } from '../../../api/manager';
import { setSelectedEmployee } from '../../../actions/settingsAction';
import { isEDICompleted, isFunctionalManager } from '../common/utils';
import ViewEDIArchiveModal from '../AppraisalDocuments/actions/ViewEDIArchiveModal';

const IndividualDevelopmentInterviews = ({
  employeeArchiveDocument,
  employeesProfileData,
  targetEmployeeId,
  dispatchFetchEmployeeArchive,
  dispatchFetchEDIEligibility,
  dispatchFetchJimmyEligibility,
  isEligibleJimmy,
}) => {
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [archiveUrl, setArchiveUrl] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    dispatchFetchEmployeeArchive();
    dispatchFetchEDIEligibility();
    dispatchFetchJimmyEligibility();
  }, [
    dispatchFetchEmployeeArchive,
    dispatchFetchEDIEligibility,
    dispatchFetchJimmyEligibility,
  ]);

  const prepareEmployeeArchive = (archiveDocuments, completedEDIs, t) => {
    const filterArchiveDocuments = doc => {
      return (
        doc['DOCUMENT_TYPE'] == 'NATIXIS Professional Development Review' ||
        doc['DOCUMENT_TYPE'] == 'Career and Development Interview'
      );
    };

    const preparedArchiveDocuments = archiveDocuments
      .filter(filterArchiveDocuments)
      .map(g => {
        return {
          PERSON_ID: g.PERS_ID,
          EMPLOYEE_NAME: g.FIRST_NAME + ' ' + g.LAST_NAME,
          DOCUMENT_NAME: g.DOCUMENT_NAME,
          CURRENT_STEP: null,
          EVALUATION_DATE: g.EVALUATION_DATE,
          EVALUATOR: g.EVALUATOR,
          ACTIONS: '',
          DOCUMENT_URL: g.URL,
          DOCUMENT_TYPE: g.DOCUMENT_TYPE,
          ALLOW_REOPEN: false,
          EDI_ITEM_ID: null,
        };
      });

    const keepEDI = edi => {
      const isManager = edi['MANAGER_ID'] == getCurrentUserId();
      const isEmployee = edi['PERSON_ID'] == getCurrentUserId();
      return isFunctionalManager(edi) || isManager || isEmployee;
    };

    const preparedCompletedEDIs = Object.values(completedEDIs)
      .filter(isEDICompleted)
      .filter(keepEDI)
      .map(e => ({
        PERSON_ID: e['PERSON_ID'],
        EMPLOYEE_NAME: e['DisplayName'],
        DOCUMENT_NAME: t('title_IDP_2022'),
        CURRENT_STEP: e['STATUS_CODE'],
        EVALUATION_DATE: e['LAST_UPDATE_DATE'],
        EVALUATOR: getEvaluatorName(e),
        ACTIONS: '',
        DOCUMENT_URL: null,
        DOCUMENT_TYPE: 'COMPLETED_EDI',
        ALLOW_REOPEN: e['MANAGER_ID'] == getCurrentUserId(),
        EDI_ITEM_ID: e['EDI_EXIST'],
      }));

    return preparedArchiveDocuments.concat(preparedCompletedEDIs);
  };

  const handleOpenArchive = documentUrl => {
    setArchiveUrl(documentUrl);
    setShowArchiveModal(true);
  };

  const isManager =
    targetEmployeeId !== getCurrentUserId() && getCurrentUserManagerRole() == true;

  return (
    <Container fluid id="objectives_container">
      <Row>
        <Annoncement />
        <ViewEDIArchiveModal
          show={showArchiveModal}
          onHide={() => setShowArchiveModal(false)}
          documentUrl={archiveUrl}
        />

        <Col lg="12" xs="12">
          <Card style={{ minHeight: '220px' }} className="card_dashboard">
            <Card.Body>
              <Card.Title>{t('currentInterview')}</Card.Title>
              <LinkToJimmy isManager={isManager} isEligible={isEligibleJimmy} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="12" xs="12">
          <Card style={{ minHeight: '220px' }} className="card_dashboard">
            <Card.Body>
              <Card.Title>
                {t('PreviousInterview')}
                <div style={{ textAlign: 'right' }}></div>
              </Card.Title>
              {employeeArchiveDocument.fetched == true &&
              employeesProfileData.fetched == true ? (
                <DocumentTable
                  columns={getColumnsArchive(handleOpenArchive, t)}
                  data={prepareEmployeeArchive(
                    employeeArchiveDocument.data,
                    employeesProfileData.data,
                    t,
                  )}
                  targetEmployeeId={targetEmployeeId}
                />
              ) : (
                <LoaderInCard />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    employeeArchiveDocument: state.hcm.employeeArchiveDocument,
    employees: state.hcm.employeeTeam,
    employeesProfileData: state.performance.employeesProfileData,
    isEligibleJimmy: state.performance.jimmyEligibility.isEligible,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchFetchEmployeeArchive: () => dispatch(fetchEmployeeArchive()),
  dispatchFetchEDIEligibility: () => dispatch(fetchEDIEligibility()),
  dispatchTransferEDI: edi => dispatch(transferEDI(edi)),
  dispatchSetSelectedEmployee: e => dispatch(setSelectedEmployee(e)),
  dispatchPostEDIStatus: statusPayload => dispatch(postEDIStatus(statusPayload)),
  dispatchFetchJimmyEligibility: () => dispatch(fetchJimmyEligibility()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IndividualDevelopmentInterviews);

const getEvaluatorName = e => {
  let ediEvaluator = e['EVALUATOR_NAME'];
  if (ediEvaluator == '') {
    ediEvaluator = e['MGR_NAME'];
  }
  return ediEvaluator;
};

const LinkToJimmy = ({ isManager, isEligible }) => {
  const { t } = useTranslation();
  let link =
    'https://smartmobility-natixis.com/plateforme/profil/#exampleTabsLineEvaluation';
  if (isManager) {
    link = 'https://smartmobility-natixis.com/company/interview/';
  }

  let label = '';
  if (isManager) {
    label = 'link_to_jimmy_manager';
  } else {
    if (isEligible != null) {
      label = `link_to_jimmy_employee_${isEligible ? 'eligible' : 'noneligible'}`;
    }
  }

  return (
    <>
      {!isManager && isEligible === null ? (
        <LoaderInCard />
      ) : (
        <>
          <div style={{ textAlign: 'center', marginTop: '5%' }}>
            {!isManager && !isEligible ? (
              <a
                style={{
                  fontWeight: 'bold',
                  pointerEvents: 'none',
                  cursor: 'default',
                  margin: '5%',
                }}
                href=""
              >
                {t(label)}
              </a>
            ) : (
              <a href={link} style={{ fontWeight: 'bold' }} target="blank">
                {t(label)}
              </a>
            )}
          </div>
        </>
      )}
    </>
  );
};

const Annoncement = () => {
  const { t } = useTranslation();
  const link =
    'https://smartmobility-natixis.com/plateforme/profil/#exampleTabsLineEvaluation';

  return (
    <Col>
      <div
        className="performance_annoncement"
        style={{
          fontWeight: 'bold',
          color: 'rgb(0 126 194)',
          padding: '1%',
          marginBottom: '1%',
        }}
      >
        {t('announcement_jimmy')}{' '}
        <a href={link} target="blank">
          {t('link_to_jimmy_employee_eligible')}
        </a>
      </div>
    </Col>
  );
};

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { extractDataFromKPIsGoals } from './utils';
import { useTranslation } from 'react-i18next';
import DashboardKPI from '../../common/DashboardKPI';
import { fetchPerformanceGoals } from '../../../actions/apiPerformanceActions';
import { getCurrentUserId } from '../../../api/manager';
import { getEmployeeDisplayName } from '../../../model/employee';

const KPIPerformanceGoalsTable = ({
  performanceGoals,
  employees,
  dispatchFetchPerformanceGoals,
}) => {
  const [dataKPI, setDataKPI] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!performanceGoals.fetched) {
      if (employees.fetched) {
        const ids = employees.data.map(e => e['PERSON_ID']);
        ids.push(getCurrentUserId());
        dispatchFetchPerformanceGoals(ids);
      }
    } else {
      if (performanceGoals.data) {
        setDataKPI(extractDataFromKPIsGoals(performanceGoals.data, t));
      }
    }
  }, [employees, performanceGoals, dispatchFetchPerformanceGoals, setDataKPI, t]);

  const getTableKPI = () => (
    <table id="kpis_goals_table">
      <tbody>
        <tr>
          <th scope="col">{t('dvp_kpi_col_objectives')}</th>
          <th scope="col">{t('dvp_kpi_col_employees')}</th>
        </tr>
        {Array.from(Array(7).keys()).map(item => {
          return (
            <tr key={item}>
              <td>{item == 6 ? `${item}+` : item}</td>
              <td>
                {dataKPI?.data[item] == undefined ? (
                  0
                ) : (
                  <div className="kpis_goals_tooltip">
                    {dataKPI.data[item].length}
                    <span className="kpis_goals_tooltiptext">
                      {displayTooltip(dataKPI.data[item])}
                    </span>
                  </div>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  return (
    <DashboardKPI
      title={t('dvp_kpi_title')}
      Component={getTableKPI}
      loadingKPI={!performanceGoals.fetched}
      hideKPI={false}
    />
  );
};

const mapStateToProps = state => {
  return {
    employees: state.hcm.employeeTeam,
    performanceGoals: state.performance.performanceGoals,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchFetchPerformanceGoals: personId => dispatch(fetchPerformanceGoals(personId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(KPIPerformanceGoalsTable);

const displayTooltip = listEmployees => (
  <>
    {listEmployees.map((employeeId, index) => {
      return (
        <div key={index}>
          {getEmployeeDisplayName(employeeId) || ''}
          <br />
        </div>
      );
    })}
  </>
);

import csv from 'csvtojson';
import base64 from 'base-64';
import convert from 'xml-js';
import { HCMEndpoint } from './endpoints';
import { DEFAULT_ENV, getCurrentUserId, isProductionEnvironment } from './manager';

export const getRequestAuthentication = () => {
  if (isProductionEnvironment()) {
    const jwtToken = window.parent.itk;
    const authentication = `Bearer ${jwtToken}`;
    return authentication;
  } else {
    const devAPIToken = process.env.REACT_APP_HCM_TESTING_API_KEY;
    const authentication = `Basic ${devAPIToken}`;
    return authentication;
  }
};
const getRequestUrl = () => {
  if (isProductionEnvironment()) {
    return '/xmlpserver/services/ExternalReportWSSService';
  } else {
    return `https://ekez${DEFAULT_ENV}.fa.em2.oraclecloud.com/xmlpserver/services/ExternalReportWSSService`;
  }
};
const getRequestHeaders = () => {
  const headers = new Headers();
  headers.append('Content-type', 'application/soap+xml');
  headers.append('Authorization', getRequestAuthentication());
  return headers;
};

const getRequestBody = (endpoint, format = 'csv', personId, context) => {
  const body = context
    ? `<soapenv:Envelope xmlns:soapenv="http://www.w3.org/2003/05/soap-envelope" xmlns:pub="http://xmlns.oracle.com/oxp/service/PublicReportService">\r\n      <soapenv:Body>\r\n          <pub:runReport>\r\n              <pub:reportRequest>\r\n                  <pub:attributeFormat>${format}</pub:attributeFormat>\r\n                  <pub:reportAbsolutePath>/Custom/Human Capital Management/Natixis Custom/Embedded Report/Home Page/WS/${endpoint.value}</pub:reportAbsolutePath>\r\n                  <pub:sizeOfDataChunkDownload>-1</pub:sizeOfDataChunkDownload>\r\n                  <pub:parameterNameValues>\r\n                  <pub:item>\r\n                    <pub:name>pConnectedUserId</pub:name>\r\n                     <pub:values>\r\n                        <pub:item>${personId}</pub:item>\r\n                      </pub:values>\r\n                  </pub:item>                \r\n   <pub:item>
						<pub:name>pAnnouncementCategory</pub:name>						<pub:values>							<pub:item>${context}</pub:item>						</pub:values>					</pub:item>               </pub:parameterNameValues>\r\n              </pub:reportRequest>\r\n          </pub:runReport>\r\n      </soapenv:Body>\r\n  </soapenv:Envelope>\r\n`
    : `<soapenv:Envelope xmlns:soapenv="http://www.w3.org/2003/05/soap-envelope" xmlns:pub="http://xmlns.oracle.com/oxp/service/PublicReportService">\r\n      <soapenv:Body>\r\n          <pub:runReport>\r\n              <pub:reportRequest>\r\n                  <pub:attributeFormat>${format}</pub:attributeFormat>\r\n                  <pub:reportAbsolutePath>/Custom/Human Capital Management/Natixis Custom/Embedded Report/Home Page/WS/${endpoint.value}</pub:reportAbsolutePath>\r\n                  <pub:sizeOfDataChunkDownload>-1</pub:sizeOfDataChunkDownload>\r\n                  <pub:parameterNameValues>\r\n                  <pub:item>\r\n                    <pub:name>pConnectedUserId</pub:name>\r\n                     <pub:values>\r\n                        <pub:item>${personId}</pub:item>\r\n                      </pub:values>\r\n                  </pub:item>                \r\n                  </pub:parameterNameValues>\r\n              </pub:reportRequest>\r\n          </pub:runReport>\r\n      </soapenv:Body>\r\n  </soapenv:Envelope>\r\n`;
  return body;
};

export const makeRequest = (
  endpoint,
  onSuccess,
  {
    onError = defaultOnError,
    preprocessor = defaultPreprocessor,
    personId = getCurrentUserId(),
    format = 'csv',
    context = '',
    verbose = false,
    requestUrl = getRequestUrl(),
    requestHeaders = getRequestHeaders(),
    forceBodyRequest = null,
  } = {},
) => {
  if (!(endpoint instanceof HCMEndpoint)) {
    onError(`Bad Endpoint ${endpoint}`);
    return;
  }

  const bodyRequest =
    forceBodyRequest != null
      ? forceBodyRequest
      : getRequestBody(endpoint, format, personId, context);

  const requestOptions = {
    method: 'POST',
    headers: requestHeaders,
    body: bodyRequest,
    redirect: 'follow',
  };

  if (verbose === true) {
    console.warn('------------ verbose mode');
    console.warn('- HCM API Manager:');
    console.warn('--- endpoint: ', endpoint);
    console.warn('--- requestOptions: ', requestOptions);
  }

  fetch(requestUrl, requestOptions)
    .then(response => checkAPIResponse(response, endpoint))
    .then(response => preprocessor(response))
    .then(response => onSuccess(response))
    .catch(error => onError(error));
};

const defaultOnError = response => {
  buildError(response).then(error => {
    console.error('- HCM API Manager: onError => ', error.basic);
    if (error.details) {
      console.error('- HCM error details: ', error.details);
    }
  });
};

export const buildError = async response => {
  const basicMessage = `Endpoint = ${response.endpoint} || Status = ${response.status} | ${response.statusText}`;
  const error = { basic: basicMessage, details: null, status: response.status };
  if (response.status == 500) {
    const decoded = await response.text();
    error['raw'] = decoded;
    const result = new window.DOMParser().parseFromString(decoded, 'text/xml');
    const errorMessage =
      result.getElementsByTagName('faultstring')[0].firstChild.textContent;
    error['details'] = errorMessage;
  }
  return error;
};

export const buildAndDispatchError = (dispatch, dispatcher, response) => {
  buildError(response).then(error => {
    dispatch(dispatcher(error));
  });
};

const checkAPIResponse = (response, endpoint) => {
  if (!response.ok) {
    response['endpoint'] = endpoint;
    throw response;
  } else {
    return response.text();
  }
};

export const defaultPreprocessor = response => {
  const result = new window.DOMParser().parseFromString(response, 'text/xml');
  const payload = base64.decode(
    result.getElementsByTagName('ns2:reportBytes')[0].firstChild.textContent,
  );
  return payload;
};

export const withCSVdecode = onSuccess => {
  return payload => {
    csv()
      .fromString(payload)
      .then(jsonPayload => {
        onSuccess(jsonPayload);
      });
  };
};

export const withXMLdecode = onSuccess => {
  return payload => {
    const jsonPayload = convert.xml2js(payload, { compact: true, ignoreComment: true });
    let data = jsonPayload.DATA_DS;
    onSuccess(data);
  };
};

export const onSuccessLogger = payload => {
  console.error('***** [API/Manager] (onSuccessLogger) Do not use in production !');
  console.warn(payload);
};

export const autoDispatcher = ({ selector, cleaner, dispatch, action }) => {
  return payload => {
    let target = selector(payload);
    if (target == undefined) {
      dispatch(action([]));
    } else {
      if (!Array.isArray(target)) {
        target = [target];
      }
      const cleanedData = cleaner(target);
      dispatch(action(cleanedData));
    }
  };
};

export const cleanText = text => decodeURIComponent(escape(text));

export const makeRequestPromise = (
  endpoint,
  onSuccess,
  {
    onError = defaultOnError,
    preprocessor = defaultPreprocessor,
    personId = getCurrentUserId(),
    format = 'csv',
    context = '',
    verbose = false,
    requestUrl = getRequestUrl(),
    requestHeaders = getRequestHeaders(),
    forceBodyRequest = null,
  } = {},
) => {
  if (!(endpoint instanceof HCMEndpoint)) {
    onError(`Bad Endpoint ${endpoint}`);
    return;
  }

  const bodyRequest =
    forceBodyRequest != null
      ? forceBodyRequest
      : getRequestBody(endpoint, format, personId, context);

  const requestOptions = {
    method: 'POST',
    headers: requestHeaders,
    body: bodyRequest,
    redirect: 'follow',
  };

  if (verbose === true) {
    console.warn('------------ verbose mode');
    console.warn('- HCM API Manager:');
    console.warn('--- endpoint: ', endpoint);
    console.warn('--- requestOptions: ', requestOptions);
  }

  return fetch(requestUrl, requestOptions)
    .then(response => checkAPIResponse(response, endpoint))
    .then(response => preprocessor(response))
    .then(response => onSuccess(response))
    .catch(error => onError(error));
};

import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FeedbackStatus } from '../../../api/HCMProfile/requests/FeedbackRequester';
import { convertDate, DATE_FORMAT_FR, DATE_FORMAT_ISO } from '../../../utils/data';
import { isStatusEAPCompleted } from '../common/utils';

export const getColumns = (t, answerMode, onAnswerFeedback, onViewFeedback) => {
  const defaultColumns = [
    {
      name: 'FEEDBACK_ITEM_ID',
      options: {
        display: false,
      },
    },
    {
      name: 'EAP_ITEM_ID',
      options: {
        display: false,
      },
    },
    {
      name: t('table_feedbacks_asked_by'),
      options: {
        display: answerMode,
        sortOrder: 'asc',
        customBodyRender: value => {
          return <>{value}</>;
        },
      },
    },
    {
      name: t('table_feedbacks_asked_for'),
      options: {
        sortOrder: 'asc',
        customBodyRender: value => {
          return <>{value}</>;
        },
      },
    },
    {
      name: t('table_feedbacks_asked_to'),
      options: {
        display: !answerMode,
        sortOrder: 'asc',
        customBodyRender: value => {
          return <>{value}</>;
        },
      },
    },
    {
      name: t('table_feedbacks_expected_date'),
      options: {
        customBodyRender: value => {
          const date = value
            ? convertDate(value, DATE_FORMAT_ISO).format(DATE_FORMAT_FR)
            : '';
          return <>{date}</>;
        },
      },
    },
    {
      name: t('table_feedbacks_status'),
      options: {
        customBodyRender: (value, tableMeta) => {
          const eapStatusCode = tableMeta.rowData[8];
          return <FeedbackStatusBadge status={value} eapStatusCode={eapStatusCode} />;
        },
      },
    },
    {
      name: t('table_Actions'),
      options: {
        customBodyRender: (status, tableMeta) => {
          const feedbackItemId = tableMeta.rowData[0];
          const eapStatusCode = tableMeta.rowData[8];
          return (
            <>
              {(status == FeedbackStatus.DONE || !answerMode) && (
                <FeedbackActionButton
                  label={t('feedback_action_view')}
                  callback={() => onViewFeedback(feedbackItemId)}
                />
              )}
              {status == FeedbackStatus.TODO &&
                !isFeedbackExpired(status, eapStatusCode) &&
                answerMode && (
                  <FeedbackActionButton
                    label={t('feedback_action_answer')}
                    callback={() => onAnswerFeedback(feedbackItemId)}
                  />
                )}
            </>
          );
        },
      },
    },
    {
      name: 'EAP_STATUS_CODE',
      options: {
        display: false,
      },
    },
  ];
  return defaultColumns;
};

const FeedbackStatusBadge = ({ status, eapStatusCode }) => {
  const { t } = useTranslation();

  let label = 'feedback_status_unknown';
  if (isFeedbackExpired(status, eapStatusCode)) {
    status = FeedbackStatus.EXPIRED;
  }

  if (status != '') {
    label = `feedback_status_${status.toLowerCase()}`;
  }

  let badge = 'badge-secondary';
  switch (status) {
    case FeedbackStatus.TODO:
      badge = 'badge-edi-in-progress';
      break;
    case FeedbackStatus.DONE:
      badge = 'badge-edi-completed';
      break;
    default:
      badge = 'badge-edi-wrk-preparation';
      break;
  }
  return <span className={`p-2 badge ${badge}`}>{t(label)}</span>;
};

const FeedbackActionButton = ({ label, callback }) => {
  return (
    <Button
      type="button"
      className="buttonLink documentName trigger btn btn-link"
      data-linkonly="true"
      onClick={() => callback()}
    >
      {label}
    </Button>
  );
};

const isFeedbackExpired = (feedbackStatus, eapStatusCode) => {
  if (feedbackStatus == FeedbackStatus.DONE) {
    return false;
  } else {
    if (isStatusEAPCompleted(eapStatusCode)) {
      return true;
    } else {
      return false;
    }
  }
};

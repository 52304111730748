import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import ActionButtons from './ActionButtons';
import 'survey-core/defaultV2.min.css';
import 'survey-core/survey.min.css';
import { Survey } from 'survey-react-ui';
import { Model } from 'survey-core';
import { getCurrentUserId } from '../../../../../api/manager';
import SigningModal from './SigningModal';
import OutsideClickHandler from 'react-outside-click-handler';
import { isClickOnScrollBar, isStatusEAPCompleted } from '../../../common/utils';
import { useTranslation } from 'react-i18next';

const MANAGER_NO_EVAL = 'managerNoEval';

const Five = props => {
  const [survey] = useState(new Model(signingSurvey));
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isManager, setIsManager] = useState(false);
  const [isMatrixManager, setIsMatrixManager] = useState(false);
  const [isEmployee, setIsEmployee] = useState(false);
  const [showSigningModal, setShowSigningModal] = useState(false);
  const [missingFields, setMissingFields] = useState([]);
  const [wasEmployeeAbsent, setWasEmployeeAbsent] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    let isEmployee = false;
    let isManager = false;
    let isMatrixManager = false;

    if (props.targetEmployeeId) {
      isEmployee = getCurrentUserId() === props.targetEmployeeId;
    }
    isManager = props.isEvaluator;
    isMatrixManager =
      props.isEvaluator == props.isFunctionalManager ? false : props.isFunctionalManager;

    setIsMatrixManager(isMatrixManager);
    setIsManager(isManager);
    setIsEmployee(isEmployee);
    setIsReadOnly(
      (isEmployee == false && isManager == false && isMatrixManager == false) ||
        props.forceReadOnly,
    );

    survey.locale = props.userLang;
    survey.focusFirstQuestionAutomatic = true;
    survey.onTextMarkdown.add(function (survey, options) {
      var str = options.text;
      options.html = str;
    });
    const setFetchedValues = () => {
      if (props.eapData && props.eapData.eapPayload && props.eapData.eapPayload.signing) {
        const signing = props.eapData.eapPayload.signing;

        signing.forEach(x => survey.setValue(x.name, x.value));
      } else {
        survey.clear();
      }
    };

    const setQuestionReadonlyness = () => {
      survey.getAllQuestions().forEach(q => {
        if (isStatusEAPCompleted(props?.eapData?.status)) {
          q.setPropertyValue('readOnly', true);
        } else {
          if (q.name.startsWith('employee')) {
            q.setPropertyValue('readOnly', !isEmployee || props.forceReadOnly);
          }
          if (q.name.startsWith('manager')) {
            q.setPropertyValue('readOnly', !isManager);
          }
          if (q.name.startsWith('matrix')) {
            q.setPropertyValue('readOnly', !isMatrixManager);
          }
        }
      });
    };

    setFetchedValues();
    setQuestionReadonlyness();
  }, [
    props.eapData,
    props.targetEmployeeId,
    props.userLang,
    props.forceReadOnly,
    survey,
    props.isEvaluator,
    props.isFunctionalManager,
  ]);

  const prepareData = plainData => {
    return plainData.map(x => ({ name: x.name, title: x.title, value: x.value ?? '' }));
  };

  const handleSigningModal = missingFields => {
    setShowSigningModal(true);
    setMissingFields(missingFields);
  };

  const handleNext = (saveOnly = false) => {
    if (!isReadOnly) {
      const data = prepareData(survey.getPlainData());
      props.saveEAP({ signing: data }, isManager);
      if (!saveOnly) {
        checkEAPMandatoryFields(props, isManager, handleSigningModal, t, props.goals);
      }
    }
  };

  const onChangeSurvey = (_, update) => {
    if (update['name'] === MANAGER_NO_EVAL) {
      setWasEmployeeAbsent(update.value.length);
    }
  };

  return (
    <>
      <SigningModal
        show={showSigningModal}
        onHide={() => setShowSigningModal(false)}
        wasEmployeeAbsent={wasEmployeeAbsent}
        updateEAPStatus={props.updateEAPStatus}
        isManager={isManager}
        isMatrixManager={isMatrixManager}
        missingFields={missingFields}
      />
      <OutsideClickHandler
        disabled={!props.isActive || props.forceReadOnly}
        onOutsideClick={event => {
          if (!showSigningModal) {
            if (!isClickOnScrollBar(event)) {
              console.warn('-- Auto save trigger: SAVING STEP 5');
              handleNext(true);
            }
          }
        }}
        useCapture={false}
      >
        <Row>
          <Col>
            <Survey
              model={survey}
              {...props}
              onValueChanged={(a, b) => onChangeSurvey(a, b)}
            />
          </Col>
        </Row>
        <ActionButtons
          {...props}
          isEmployee={isEmployee}
          isMatrixManager={isMatrixManager}
          managerCanClickValidate={isManager}
          finishCallback={handleNext}
          prevCallback={() => handleNext(true)}
        />
      </OutsideClickHandler>
    </>
  );
};

const signingSurvey = {
  logoPosition: 'right',
  pages: [
    {
      name: 'signing',
      elements: [
        {
          type: 'panel',
          name: 'Employeepanel',
          elements: [
            {
              type: 'comment',
              name: 'employeeFinalComment',
              title: {
                default:
                  'Summarise here what you want to share with your manager.<br>To inform your manager that you have prepared your discussion, use the "Share my preparation with my manager" button.<br>This action will not prevent you from modifying or completing your comments until your manager has validated the interview.',
                fr: 'Récapitulez ici une synthèse de ce que vous souhaitez partager à votre manager.<br>Pour informer votre manager que vous avez préparé votre échange, utilisez le bouton "Partager ma préparation à mon manager".<br>Cette action ne vous empêchera pas de pouvoir encore modifier ou compléter vos commentaires, jusqu\'à ce que votre manager ait validé l\'entretien.',
              },
              readOnly: true,
            },
          ],
          title: {
            default: "Employee's final comment",
            fr: 'Synthèse collaborateur',
          },
        },
        {
          type: 'panel',
          name: 'Matrixpanel',
          elements: [
            {
              type: 'comment',
              name: 'matrixFinalComment',
              title: {
                default: 'Comment',
                fr: 'Commentaire',
              },
              readOnly: true,
            },
          ],
          title: {
            fr: 'Synthèse manager fonctionnel',
            default: 'Functional manager final comment',
          },
        },
        {
          type: 'panel',
          name: 'Managerpanel',
          elements: [
            {
              type: 'checkbox',
              name: 'managerNoEval',
              titleLocation: 'hidden',
              choices: [
                {
                  value: 'item3',
                  text: {
                    default: 'Employee away from work or not assessed - specify below',
                    fr: 'Collaborateur absent ou non évalué (précisez ci-dessous le cas échéant)',
                  },
                },
              ],
              readOnly: true,
            },
            {
              type: 'comment',
              name: 'managerFinalComment',
              title: {
                default: 'Comment',
                fr: 'Commentaire',
              },
              readOnly: true,
            },
          ],
          title: {
            default: 'Reviewer manager final comment',
            fr: 'Synthèse manager évaluateur',
          },
        },
      ],
    },
  ],
  showNavigationButtons: 'none',
  showCompletedPage: false,
  showQuestionNumbers: 'off',
  widthMode: 'responsive',
};

export default Five;

const checkEAPMandatoryFields = (props, isManager, handleSigningModal, t, goals) => {
  if (isManager) {
    handleSigningModal(
      checkEAPMandatoryFieldsManager(props.eapData, props.isEligibleMifid2, t, goals),
    );
  } else {
    handleSigningModal(checkEAPMandatoryFieldsEmployee(props.eapData));
  }
};

const checkEAPMandatoryFieldsEmployee = () => {
  // no fields to check for employee (yet)
  return [];
};

const checkEAPMandatoryFieldsManager = (eapData, isEligibleMifid2, t, goals) => {
  const missingFields = [];

  //Don't check form if collab is absent
  const managerNoEval = eapData?.eapPayload?.signing?.find(
    el => el.name === 'managerNoEval',
  )?.value;

  if (managerNoEval !== undefined && managerNoEval.length > 0) {
    return missingFields;
  }

  // behaviors
  {
    let behaviorInfoMissing = false;
    if (eapData?.eapPayload?.behaviors) {
      for (const field of eapData?.eapPayload?.behaviors) {
        if (field.name.startsWith('managerAssessment') && field.value == '') {
          behaviorInfoMissing = true;
          break;
        }
        if (field.name == 'managerOverallAssessment' && field.value == '') {
          behaviorInfoMissing = true;
          break;
        }
        if (field.name == 'managerOverallComment' && field.value == '') {
          behaviorInfoMissing = true;
          break;
        }
      }
    } else {
      behaviorInfoMissing = true;
    }
    if (behaviorInfoMissing) {
      const behaviors = {
        section_name: t('eap_validate_popup_missing_evaluator_fields_behavior'),
        fields: [],
      };
      missingFields.push(behaviors);
    }
  }

  // mifid 2
  {
    let mifidInfoMissing = false;
    if (isEligibleMifid2) {
      if (eapData?.eapPayload?.mifid2) {
        for (const field of eapData?.eapPayload?.mifid2) {
          if (field.name == 'managerquestion' && field.value == '') {
            mifidInfoMissing = true;
            break;
          }
        }
      } else {
        mifidInfoMissing = true;
      }
      if (mifidInfoMissing) {
        const mifid = {
          section_name: t('eap_validate_popup_missing_evaluator_fields_mifid'),
          fields: [],
        };
        missingFields.push(mifid);
      }
    }
  }

  // goals
  {
    let goalsInfoMissing = false;
    if (goals !== null) {
      for (const goal of goals) {
        const goalEvalContent = eapData?.eapGoalsContent[goal.profileItemId];
        if (goalEvalContent) {
          const goalEvalContentKeys = Object.keys(goalEvalContent);
          if (goalEvalContentKeys.indexOf('goal_manager_eval') === -1) {
            goalsInfoMissing = true;
            break;
          }
        } else {
          goalsInfoMissing = true;
          break;
        }
      }
    }
    // goals global rating
    if (!eapData?.rating) {
      goalsInfoMissing = true;
    }
    // goals global comment
    if (!eapData?.eapGoalsGlobalEvalContent?.goal_manager_global_comment) {
      goalsInfoMissing = true;
    }

    if (goalsInfoMissing) {
      const perfGoalSection = {
        section_name: t('eap_validate_popup_missing_evaluator_fields_goals'),
        fields: [],
      };
      missingFields.push(perfGoalSection);
    }
  }
  return missingFields;
};

import React from 'react';
import Avatar from '../../common/Avatar';
import { useTranslation } from 'react-i18next';

const UserMenu = () => {
  const { t } = useTranslation();
  const location = window.location.host;
  const signOutUrl = `https://${location.replace(
    'fa',
    'login',
  )}/fusion_apps/pages/logoutConsent.jsp?logout_done_url=https%3A%2F%2F${location}%3A443%2FfscmUI%2FadfAuthentication%3F_adf.authenticate%3Dtrue`;

  return (
    <div className="notification d-flex">
      <div className="dropdown d-flex">
        <a
          className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
          data-toggle="dropdown"
        >
          <Avatar title="User Menu" />
        </a>
        <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
          <a
            href="https://smartmobility-natixis.com/plateforme/profil/"
            rel="noreferrer"
            className="dropdown-item"
            target="_blank"
          >
            <i className="dropdown-icon fe fe-user" />
            {t('profile_link')}
          </a>
          <div className="dropdown-divider" />
          <a href={signOutUrl} className="dropdown-item" rel="noreferrer">
            <i className="dropdown-icon fe fe-log-out" />
            {t('signout')}
          </a>
        </div>
      </div>
    </div>
  );
};
export default UserMenu;

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import DocumentTable from '../common/DocumentTable';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { getColumnsArchive, getColumns } from './DocumentTableStructure';
import { useTranslation } from 'react-i18next';
import { fetchEmployeeArchive } from '../../../actions/apiActions';
import LoaderInCard from '../../common/LoaderInCard';
import ViewEDIArchiveModal from './actions/ViewEDIArchiveModal';
import { getCurrentUserId, getCurrentUserManagerRole } from '../../../api/manager';
import { setSelectedEmployee } from '../../../actions/settingsAction';

import {
  fetchEAPEligibility,
  postEAPRating,
  transferEAP,
  postEAPStatus,
} from '../../../actions/apiPerformanceActions';
import {
  computeIsTransferredDocument,
  getEvaluatorId,
  isEAPCompleted,
  isFunctionalManager,
  prepareDisplayName,
} from '../common/utils';
import AskFeedbackModal from './actions/AskFeedbackModal';
import TransferDocumentModal from '../common/TransferDocumentModal';
import askConfirmation from '../common/askConfirmation';
import { EAPRequestData, EAPStatus } from '../../../api/HCMProfile/requests/EAPRequester';
import { DATE_FORMAT_ISO, getTodayDate } from '../../../utils/data';

const AppraisalDocuments = ({
  employeeArchiveDocument,
  employeesEAPProfileData,
  targetEmployeeId,
  dispatchFetchEmployeeArchive,
  dispatchFetchEAPEligibility,
  dispatchSetSelectedEmployee,
  dispatchPostEAPRating,
  dispatchTransferEAP,
  dispatchPostEAPStatus,
}) => {
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showAskFeedbackModal, setShowAskFeedbackModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [actionEmployeeId, setActionEmployeeId] = useState(null);
  const [profileItemIdCollab, setProfileItemIdCollab] = useState(null);
  const [profileItemIdManager, setProfileItemIdManager] = useState(null);
  const [archiveUrl, setArchiveUrl] = useState('');
  const [canDisplayRating, setcanDisplayRating] = useState();

  useEffect(() => {
    let result = false;
    if (getCurrentUserManagerRole()) {
      result = targetEmployeeId != getCurrentUserId();
    } else {
      result = targetEmployeeId != getCurrentUserId() && targetEmployeeId != 0;
    }
    setcanDisplayRating(result);
  }, [targetEmployeeId]);

  useEffect(() => {
    dispatchFetchEmployeeArchive();
    dispatchFetchEAPEligibility();
  }, [dispatchFetchEmployeeArchive, dispatchFetchEAPEligibility]);

  const { t } = useTranslation();

  const prepareEmployeeArchive = (archiveDocuments, completedEAPs, t) => {
    const filterArchiveDocuments = doc => {
      return (
        doc['DOCUMENT_TYPE'] == 'Annual Performance Review' ||
        doc['DOCUMENT_TYPE'] == 'NATIXIS Assessment'
      );
    };

    const preparedArchiveDocuments = archiveDocuments
      .filter(filterArchiveDocuments)
      .map(g => {
        return {
          PERSON_ID: g.PERS_ID,
          EMPLOYEE_NAME: g.FIRST_NAME + ' ' + g.LAST_NAME,
          DOCUMENT_NAME: g.DOCUMENT_NAME,
          CURRENT_STEP: null,
          EVALUATION_DATE: g.EVALUATION_DATE,
          EVALUATOR: g.EVALUATOR,
          ACTIONS: '',
          DOCUMENT_URL: g.URL,
          DOCUMENT_TYPE: g.DOCUMENT_TYPE,
          ALLOW_REOPEN: false,
          ITEM_ID: null,
        };
      });

    const keepEAP = eap => {
      const isManager = eap['MANAGER_ID'] == getCurrentUserId();
      const isEmployee = eap['PERSON_ID'] == getCurrentUserId();
      return isFunctionalManager(eap) || isManager || isEmployee;
    };

    const preparedCompletedEAPs = Object.values(completedEAPs)
      .filter(isEAPCompleted)
      .filter(keepEAP)
      .map(e => ({
        PERSON_ID: e['PERSON_ID'],
        EMPLOYEE_NAME: e['DisplayName'],
        DOCUMENT_NAME: t('title_EAP_2022'),
        CURRENT_STEP: e['STATUS_CODE'],
        EVALUATION_DATE: e['LAST_UPDATE_DATE'],
        EVALUATOR: getEvaluatorName(e),
        ACTIONS: '',
        DOCUMENT_URL: null,
        DOCUMENT_TYPE: 'COMPLETED_EAP',
        ALLOW_REOPEN: false,
        ITEM_ID: e['EAP_EXIST'],
      }));

    return preparedArchiveDocuments.concat(preparedCompletedEAPs);
  };
  const handleOpenArchive = documentUrl => {
    setArchiveUrl(documentUrl);
    setShowArchiveModal(true);
  };

  const saveTransferEAP = newManagerId => {
    const payload = {
      managerId: newManagerId,
    };

    const requestData = new EAPRequestData(
      actionEmployeeId,
      profileItemIdCollab,
      profileItemIdManager,
      payload,
    );

    dispatchTransferEAP(requestData);
    setShowTransferModal(false);
    setActionEmployeeId(null);
    setProfileItemIdCollab(null);
    setProfileItemIdManager(null);
  };

  const onClickTransferEAP = (employeeId, profileItemIdCollab, profileItemIdManager) => {
    setShowTransferModal(true);
    setActionEmployeeId(employeeId);
    setProfileItemIdCollab(profileItemIdCollab);
    setProfileItemIdManager(profileItemIdManager);
  };

  const reopenEAP = employeeId => {
    let error = true;
    if (employeesEAPProfileData.fetched && employeesEAPProfileData.data) {
      const employeeData = employeesEAPProfileData.data[employeeId];
      if (employeeData) {
        const profileItemId = employeeData['EAP_EXIST'];
        if (profileItemId) {
          const updateEap = {
            creationDate: null,
            managerId: getCurrentUserId(),
            status: EAPStatus.IN_PROGRESS,
            action: 'REOPEN',
          };
          const requestData = new EAPRequestData(
            employeeId,
            profileItemId,
            null,
            updateEap,
          );
          error = false;
          dispatchPostEAPStatus(requestData);
        }
      }
    }
    if (error) {
      console.error('ERROR: cannot reopen EAP');
    }
  };

  const onClickAskFeedback = (employeeId, profileItemIdCollab) => {
    setShowAskFeedbackModal(true);
    setActionEmployeeId(employeeId);
    setProfileItemIdCollab(profileItemIdCollab);
  };

  const onClickSetRating = (employeeId, profileItemIdManager, value, type) => {
    if (value != null) {
      const managerId = getCurrentUserId();
      const currentDate = getTodayDate(DATE_FORMAT_ISO);
      const payload = {
        managerId: managerId,
        rating: value.value,
        creationDate: currentDate,
        type: type,
      };
      askConfirmation(
        () => {
          const requestData = new EAPRequestData(
            employeeId,
            '',
            profileItemIdManager,
            payload,
          );
          dispatchPostEAPRating(requestData);
        },
        t,
        t('eap_set_perf_rating'),
        `${t('eap_set_perf_rating_sub')} ${value.label}`,
        t('eap_set_perf_info'),
      );
    }
  };

  return (
    <>
      <Container fluid id="objectives_container">
        <Row>
          <ViewEDIArchiveModal
            show={showArchiveModal}
            onHide={() => setShowArchiveModal(false)}
            documentUrl={archiveUrl}
          />
          <AskFeedbackModal
            show={showAskFeedbackModal}
            onHide={() => setShowAskFeedbackModal(false)}
            employeeId={actionEmployeeId}
            profileItemIdCollab={profileItemIdCollab}
          />
          <TransferDocumentModal
            show={showTransferModal}
            onHide={() => setShowTransferModal(false)}
            onSave={saveTransferEAP}
          />
          <Col lg="12" xs="12">
            <Card style={{ minHeight: '220px' }} className="card_dashboard">
              <Card.Body>
                <Card.Title>
                  {t('currentInterview')}
                  <div style={{ textAlign: 'right' }}></div>
                </Card.Title>
                {employeesEAPProfileData.fetched == true ? (
                  <DocumentTable
                    columns={getColumns(
                      handleOpenArchive,
                      onClickTransferEAP,
                      onClickAskFeedback,
                      dispatchSetSelectedEmployee,
                      canDisplayRating,
                      onClickSetRating,
                      t,
                    )}
                    data={prepareEAPs(employeesEAPProfileData.data, t)}
                    targetEmployeeId={targetEmployeeId}
                  />
                ) : (
                  <LoaderInCard />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="12" xs="12">
            <Card style={{ minHeight: '220px' }} className="card_dashboard">
              <Card.Body>
                <Card.Title>
                  {t('PreviousInterview')}
                  <div style={{ textAlign: 'right' }}></div>
                </Card.Title>
                {employeeArchiveDocument.fetched == true &&
                employeesEAPProfileData.fetched == true ? (
                  <DocumentTable
                    columns={getColumnsArchive(handleOpenArchive, reopenEAP, t)}
                    data={prepareEmployeeArchive(
                      employeeArchiveDocument.data,
                      employeesEAPProfileData.data,
                      t,
                    )}
                    targetEmployeeId={targetEmployeeId}
                  />
                ) : (
                  <LoaderInCard />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = state => {
  return {
    employeeArchiveDocument: state.hcm.employeeArchiveDocument,
    employees: state.hcm.employeeTeam,
    employeesEAPProfileData: state.performance.employeesEAPProfileData,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchFetchEmployeeArchive: () => dispatch(fetchEmployeeArchive()),
  dispatchFetchEAPEligibility: () => dispatch(fetchEAPEligibility()),
  dispatchSetSelectedEmployee: e => dispatch(setSelectedEmployee(e)),
  dispatchPostEAPRating: r => dispatch(postEAPRating(r)),
  dispatchTransferEAP: r => dispatch(transferEAP(r)),
  dispatchPostEAPStatus: statusPayload => dispatch(postEAPStatus(statusPayload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppraisalDocuments);

const prepareEAPs = (employees, t) => {
  const isEligible = e => e['EAP_ELIGIBILITY'] == 'ELIGIBLE';
  const eaps = Object.values(employees)
    .filter(isEligible)
    .filter(x => !isEAPCompleted(x))
    .map(e => ({
      PERSON_ID: e['PERSON_ID'],
      FULL_NAME: prepareDisplayName(e['EMP_NAME']),
      DOC_NAME: t('title_EAP_2022'),
      EVALUATOR: prepareDisplayName(getEvaluatorName(e)),
      CURRENT_STEP: e['STATUS_CODE'],
      LAST_UPDATE: e['LAST_UPDATE_DATE'],
      RATING: [
        e['PERFORMANCE_NOTE'],
        e['COMPETENCY_NOTE'],
        e['PERFORMANCE_NOTE_NEW'],
        e['COMPETENCY_NOTE_NEW'],
      ],
      ACTIONS: '',
      DISABLE_TRANSFER: computeDisableTransfer(e),
      IS_TRANSFERRED: computeIsTransferredDocument(e),
      TRANSFERRED_BY: computeIsTranferedBy(e, t),
      IS_FUNCTIONAL_MANAGER: isFunctionalManager(e),
      EAP_ITEM_ID: e['EAP_EXIST'],
      EAP_MANAGER_ITEM_ID: e['EAP_MANAGER_EXIST'],
      EAP_EVALUATOR: e['EAP_EVALUATOR'],
    }));
  return eaps;
};

const computeDisableTransfer = e => {
  const evalutorId = getEvaluatorId(e);
  const disableTransfer = evalutorId != getCurrentUserId();
  return disableTransfer;
};

const computeIsTranferedBy = (e, t) => {
  const isTransferred = computeIsTransferredDocument(e);
  if (!isTransferred) {
    return '';
  } else {
    const managerName = e['MGR_NAME'];
    const evaluatorName = getEvaluatorName(e);
    const evalutorId = getEvaluatorId(e);
    if (evalutorId == getCurrentUserId()) {
      return `${t('DocumentTransferedBy')} ${managerName}`;
    } else {
      return `${t('DocumentTransferedTo')} ${evaluatorName}`;
    }
  }
};

const getEvaluatorName = e => {
  let ediEvaluator = e['EVALUATOR_NAME'];
  if (ediEvaluator == '') {
    ediEvaluator = e['MGR_NAME'];
  }
  return ediEvaluator;
};

import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import 'survey-core/defaultV2.min.css';
import 'survey-core/survey.min.css';
import { Survey } from 'survey-react-ui';
import showdown from 'showdown';
import { Model } from 'survey-core';
import ActionButtons from './ActionButtons';
import { getCurrentUserId, isInLocalDevMode } from '../../../../../api/manager';
import * as SurveyCore from 'survey-core';
import * as widgets from 'surveyjs-widgets';
import OutsideClickHandler from 'react-outside-click-handler';
import { isClickOnScrollBar, isStatusEAPCompleted } from '../../../common/utils';

widgets.ckeditor(SurveyCore);

const myCss = {
  question: {
    content: 'sd-question__content question_content_custom',
    description: 'sd-title',
    titleOnAnswer: 'question-title-answered',
  },
};

const One = props => {
  const [survey, setSurvey] = useState(null);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isManager, setIsManager] = useState(false);
  const [isMatrixManager, setIsMatrixManager] = useState(false);

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  }, []);

  useEffect(() => {
    let isEmployee = false;
    let isManager = false;
    let isMatrixManager = false;

    if (props.targetEmployeeId) {
      isEmployee = getCurrentUserId() === props.targetEmployeeId;
    }
    isManager = props.isEvaluator;
    isMatrixManager =
      props.isEvaluator == props.isFunctionalManager ? false : props.isFunctionalManager;
    setIsMatrixManager(isMatrixManager);
    setIsManager(isManager);
    setIsReadOnly(
      (isEmployee == false && isManager == false && isMatrixManager == false) ||
        props.forceReadOnly,
    );
    const localSurvey = new Model(buildModel(props));
    localSurvey.locale = props.userLang;
    localSurvey.focusFirstQuestionAutomatic = true;
    var converter = new showdown.Converter();

    localSurvey.onTextMarkdown.add(function (survey, options) {
      var str = options.text;
      str = converter.makeHtml(options.text);
      str = str.substring(3);
      str = str.substring(0, str.length - 4);
      options.html = str;
    });

    const setFetchedValues = () => {
      if (
        props.eapData &&
        props.eapData.eapPayload &&
        props.eapData.eapPayload.missionAndMainActivity
      ) {
        const missionAndMainActivity = props.eapData.eapPayload.missionAndMainActivity;
        missionAndMainActivity.forEach(x => localSurvey.setValue(x.name, x.value));
      } else {
        localSurvey.clear();
      }
    };

    const setQuestionReadonlyness = () => {
      localSurvey.getAllQuestions().forEach(q => {
        if (isStatusEAPCompleted(props?.eapData?.status)) {
          q.setPropertyValue('readOnly', true);
        } else {
          if (q.name.startsWith('employee')) {
            q.setPropertyValue('readOnly', !isEmployee || props.forceReadOnly);
          }
          if (q.name.startsWith('manager')) {
            q.setPropertyValue('readOnly', !isManager);
          }
          if (q.name.startsWith('matrix')) {
            q.setPropertyValue('readOnly', !isMatrixManager);
          }
        }
      });
    };
    if (getCurrentUserId() != props.targetEmployeeId || props.forceReadOnly) {
      window.CKEDITOR.on('instanceReady', function (e) {
        if (e.editor.document) {
          e.editor.document.getBody().setStyle('background-color', '#8080802e');
          e.editor.on('contentDom', function () {
            e.editor.document.getBody().setStyle('background-color', '#8080802e');
          });
        }
      });
    } else {
      window.CKEDITOR.on('instanceReady', function (e) {
        if (e.editor.document) {
          e.editor.document.getBody().setStyle('background-color', '#fff');
          e.editor.on('contentDom', function () {
            e.editor.document.getBody().setStyle('background-color', '#fff');
          });
        }
      });
    }
    setFetchedValues();
    setQuestionReadonlyness();
    setSurvey(localSurvey);
  }, [props.eapData, props.targetEmployeeId, props.userLang, props.forceReadOnly, props]);

  const prepareData = plainData => {
    return plainData.map(x => ({ name: x.name, title: x.title, value: x.value ?? '' }));
  };

  const handleChangeStep = () => {
    if (!isReadOnly) {
      const data = prepareData(survey.getPlainData());
      props.saveEAP(
        { missionAndMainActivity: data },
        isManager || isMatrixManager,
        isMatrixManager,
      );
    }
  };

  const handleNext = handleChangeStep;
  const handlePrev = handleChangeStep;

  return (
    <>
      <OutsideClickHandler
        disabled={!props.isActive || props.forceReadOnly}
        onOutsideClick={event => {
          if (!isClickOnScrollBar(event)) {
            console.warn('-- Auto save trigger: SAVING STEP 1');
            handleNext();
          }
        }}
        useCapture={false}
      >
        <Row>
          <Col>
            <div>{survey != null ? <Survey model={survey} css={myCss} /> : null}</div>
          </Col>
        </Row>
        <ActionButtons {...props} nextCallback={handleNext} prevCallback={handlePrev} />
      </OutsideClickHandler>
    </>
  );
};

export default One;

const buildModel = props => {
  const missionAndMainActivity = {
    pages: [
      {
        elements: [
          {
            name: 'missionMainActivities',
            title: {
              default: 'Missions and main activities',
              fr: 'Missions et principales activités',
            },
            description: {
              default:
                '<br>Briefly describe the missions and main activities of your current position.<br>Feel free to update them if they have evolved since your last entry.<br>The interview is an opportunity to discuss with your manager the tasks, the workload and the organisation of the work, as well as the balance between work and personal and family life.<br>If you want to share specific comment on this topic, please use the comment boxes available to you.',
              fr: "<br>Décrivez les missions et principales activités de votre poste actuel, de manière synthétique.<br>N'hésitez pas à les mettre à jour si elles ont évolué depuis votre dernière saisie.<br>L’entretien est l’occasion d’échanger avec votre manager sur les missions, la charge et l’organisation du travail ainsi que l’articulation entre activité professionnelle et vie personnelle et familiale.<br>Si vous souhaitez partager des éléments spécifiques sur ce sujet, utilisez les zones de commentaires à votre disposition.",
            },
            type: 'editor',
          },
          {
            type: 'comment',
            name: 'employeeComment',
            title: {
              default: 'Employee comment',
              fr: 'Commentaire collaborateur',
            },
            autoGrow: true,
            readOnly: true,
          },
          {
            type: 'comment',
            name: 'matrixComment',
            title: {
              default: 'Functional manager comment',
              fr: 'Commentaire manager fonctionnel',
            },
            autoGrow: true,
            readOnly: true,
          },
          {
            type: 'comment',
            name: 'managerComment',
            title: {
              default: 'Reviewer comment',
              fr: 'Commentaire évaluateur',
            },
            autoGrow: true,
            readOnly: true,
          },
          {
            type: 'panel',
            name: 'CodeOfConduct',
            elements: [
              {
                type: 'html',
                name: 'CodeOfConduct',
                html: {
                  default: `<br><b>Code of Conduct</b> <br><br>Global Financial Services expects all employees to behave ethically and to abide by the principles of the Global Financial Services Code of Conduct.<br>In case of positive behavior or breach of conduct validated by a local or global conduct committee, a statement will appear below.<br><br>Without such a statement, there is nothing to report from a Code of conduct point of view.<br><br>For any further information, you may contact your manager and/or your HRBP.<br><br> ${getCodeOfConductIframe(
                    props.targetEmployeeId,
                  )}`,
                  fr: `<br><b>Code de Conduite</b> <br><br>Global Financial Services attend de ses collaborateurs qu’ils se comportent de manière éthique et respectent les principes du Code de conduite de Global Financial Services.<br>En cas de comportement positif ou de manquement de conduite validé en comité de conduite local ou global, une mention apparaîtra ci-desssous. <br><br>A défaut d’une telle mention, il n’y a rien à signaler du point de vue du Code de conduite.<br><br>Pour toute précision, vous pouvez vous rapprocher de votre manager et/ou de votre responsable RH.<br><br> ${getCodeOfConductIframe(
                    props.targetEmployeeId,
                  )}`,
                },
              },
            ],
          },
        ],
      },
    ],
    showNavigationButtons: 'none',
    showCompletedPage: false,
    showQuestionNumbers: 'off',
    widthMode: 'responsive',
  };
  return missionAndMainActivity;
};

const getCodeOfConductIframe = employeeId => {
  if (isInLocalDevMode()) {
    return `IFrame in local dev mode <iframe width='100%' height='300' frameBorder="0" src='http://www.blankwebsite.com/'></iframe>`;
  } else {
    return `<iframe id="iframe_missconduct" width='100%' height='300' frameBorder="0" src='/xmlpserver/Custom/Natixis Reporting/Code of Conduct/Misconduct_Report_V3.xdo?_xmode=4&_xf=html&_xpt=1&p_personid=${employeeId}'></iframe>`;
  }
};

import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { JobReqsSource, filterOngoingJobReqsBySource } from '../../../utils/data';

const DonutChart = ({ title, labels, series, labels2 }) => {
  const state = constructChartState(title, labels, series, labels2);
  return (
    <div id="chart">
      <ReactApexChart
        height="auto"
        options={state.options}
        series={state.series}
        type="donut"
      />
    </div>
  );
};

export default DonutChart;

const constructChartState = (title, labels, series, labels2) => {
  const colors = [];
  labels2.forEach(function (element) {
    switch (element.toUpperCase()) {
      case 'JOB_REQUISITION':
        return colors.push('#17a2b8');
      case 'SELECTION':
        return colors.push('#dc3545');
      case 'JOB_OFFER':
        return colors.push('#ffc107');
      case 'ONBOARDING':
        return colors.push('#e8769f');
      case 'CANDIDATE_ARRIVE':
        return colors.push('#3f3d56');
      default:
        return colors.push('#3f3d56');
    }
  });

  const state = {
    series: series,
    options: {
      plotOptions: {
        pie: {
          donut: {
            size: '50%',
          },
          customScale: 1,
        },
      },
      noData: {
        text: 'No data',
        align: 'center',
        verticalAlign: 'middle',
      },
      colors: colors,
      chart: {
        type: 'donut',
        redrawOnParentResize: true,
      },
      title: {
        text: title,
        align: 'bottom',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#263238',
        },
      },
      labels: labels,
      legend: {
        position: 'bottom',
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex];
        },
      },
    },
  };
  return state;
};

export const extractDataFromRecruitments = (jobReqs, t) => {
  const values = new Map([]);
  const values2 = new Map([]);

  if (jobReqs != undefined) {
    filterOngoingJobReqsBySource(jobReqs, JobReqsSource.FromMe).forEach(jobReq => {
      const s = t(`recruiting_${jobReq.GLOBAL_TYPE.toLowerCase()}`);
      values.set(s, values.get(s) + 1 || 1);
      const s2 = jobReq.GLOBAL_TYPE.toLowerCase(); //t(`recruiting_${jobReq.GLOBAL_TYPE.toLowerCase()}`);
      values2.set(s2, values2.get(s2) + 1 || 1);
    });
  }
  const series = Array.from(values.values());
  const labels = Array.from(values.keys());
  const labels2 = Array.from(values2.keys());

  return {
    series: series,
    labels: labels,
    labels2: labels2,
  };
};

import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { fetchRecruitingJobReqs } from '../../actions/apiActions';
import {
  filterJobReqsBySource,
  filterJobReqsMyApprovalsWaiting,
  JobReqsSource,
} from '../../utils/data';
import { isCurrentUserAllowedMassValidation } from '../../api/manager';

const ApproveRecruitingAdditionalRow = ({
  recruitingJobReqs,
  dispatchRecruitingJobReqs,
}) => {
  const [nbRecruitingToApprove, setNbRecruitingToApprove] = useState(null);
  const link = `/recruiting/approve`;
  const { t } = useTranslation();

  useEffect(() => {
    // temporary disable feature for non allowed user
    if (isCurrentUserAllowedMassValidation()) {
      if (!recruitingJobReqs.fetched) {
        dispatchRecruitingJobReqs();
        setNbRecruitingToApprove(null);
      } else {
        const pendingJobReqs = filterJobReqsMyApprovalsWaiting(
          filterJobReqsBySource(recruitingJobReqs, JobReqsSource.FromMyTeam),
        );
        setNbRecruitingToApprove(pendingJobReqs?.length);
      }
    }
  }, [recruitingJobReqs, dispatchRecruitingJobReqs]);

  // temporary disable feature for non allowed user
  if (!isCurrentUserAllowedMassValidation()) {
    return null;
  }

  return (
    <>
      {nbRecruitingToApprove != null ? (
        <div id="tile_jobReqOutcomes">
          <NavLink to={link}>
            {t('tile_jobReqOutcomes')} : {nbRecruitingToApprove}
          </NavLink>
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = state => {
  return {
    recruitingJobReqs: state.hcm.recruitingJobReqs,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchRecruitingJobReqs: () => dispatch(fetchRecruitingJobReqs()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApproveRecruitingAdditionalRow);

import { getBPMTaskEndpoint, getBPMTaskHeaders } from './helpers';
import { getAddCommentToTaskBody, getUpdateTaskOutcomeBody } from './requests';

/**
 *
 * @param {string} taskId ID of the task (example: 2b0ca0d1-764b-49ba-829f-471900cb61e1)
 * @param {string} outcome Task decision must be APPROVE or REJECT
 * @returns {Promise<ResponseBody>} Response of the SOAP API
 */
export const updateTaskOutcome = (taskId, outcome) => {
  const requestOptions = {
    method: 'POST',
    headers: getBPMTaskHeaders(),
    redirect: 'follow',
    body: getUpdateTaskOutcomeBody(taskId, outcome),
  };
  return fetch(getBPMTaskEndpoint(), requestOptions)
    .then(result => result)
    .catch(error =>
      console.error('Error in BPMTask/index => updateTaskOutcome() : ', error),
    );
};

/**
 *
 * @param {string} taskId ID of the task (example: 2b0ca0d1-764b-49ba-829f-471900cb61e1)
 * @param {string} comment Outcome comment for the task
 * @returns {Promise<ResponseBody>} Response of the SOAP API
 */
export const addCommentToTask = (taskId, comment) => {
  const requestOptions = {
    method: 'POST',
    headers: getBPMTaskHeaders(),
    redirect: 'follow',
    body: getAddCommentToTaskBody(taskId, comment),
  };
  return fetch(getBPMTaskEndpoint(), requestOptions)
    .then(result => result)
    .catch(error =>
      console.error('Error in BPMTask/index => addCommentToTask() : ', error),
    );
};

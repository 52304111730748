import React from 'react';
import { useTranslation } from 'react-i18next';
import { EDIStatus } from '../../../api/HCMProfile/requests/EDIRequester';

const EDIStatusBadge = ({ status }) => {
  const { t } = useTranslation();
  let label = 'edi_status_not_started';
  if (status != '') {
    label = `edi_status_${status.toLowerCase()}`;
  }

  let badge = 'badge-secondary';
  switch (status) {
    case EDIStatus.INELIGIBLE:
      badge = 'badge-dark';
      break;
    case EDIStatus.IN_PROGRESS:
      badge = 'badge-edi-in-progress';
      break;
    case EDIStatus.WRK_PREPARATION:
      badge = 'badge-edi-wrk-preparation';
      break;
    case EDIStatus.COMPLETED:
      badge = 'badge-edi-completed';
      break;
    case EDIStatus.COMPLETED_ABSENT:
      badge = 'badge-edi-completed-absent';
      break;
  }

  return <span className={`p-2 badge ${badge}`}>{t(label)}</span>;
};

export default EDIStatusBadge;

import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Avatar from '../../common/Avatar';
import { getDocumentViewerMode, getTargetTab } from './utils';

const DocumentViewerHeader = ({ employeeId }) => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const previous = () => {
    const documentViewerMode = getDocumentViewerMode(url);
    const targetTab = getTargetTab(documentViewerMode);
    history.replace({
      pathname: `/performance/${targetTab}`,
    });
  };

  return (
    <table>
      <tbody>
        <tr style={{ verticalAlign: 'initial' }}>
          <td onClick={previous}>
            <a className="card text-center bg-blue" style={{ cursor: 'pointer' }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.0"
                width="30.000000pt"
                height="30.000000pt"
                viewBox="0 0 64.000000 64.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g
                  transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                  fill="#FFFFFF"
                  stroke="none"
                >
                  <path d="M300 393 c-36 -31 -65 -64 -65 -73 0 -16 121 -130 137 -130 23 0 1 34 -49 80 l-58 51 58 50 c50 45 72 79 49 79 -4 0 -36 -25 -72 -57z" />{' '}
                </g>
              </svg>
            </a>
          </td>
          {employeeId != null ? (
            <td style={{ paddingLeft: '15px' }}>
              <Avatar personId={employeeId} style={{ height: '60px', width: '60px' }} />
            </td>
          ) : (
            <null />
          )}
        </tr>
      </tbody>
    </table>
  );
};

export default DocumentViewerHeader;

import { getCurrentEnv } from '../manager';
import { getRequestAuthentication } from '../SOAPrequestMaker';

export const getBPMTaskEndpoint = () =>
  `https://ekez${getCurrentEnv()}.fa.em2.oraclecloud.com/integration/services/TaskService/TaskServicePort`;

export const getBPMTaskHeaders = () => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'text/xml');
  myHeaders.append('Authorization', getRequestAuthentication());
  return myHeaders;
};

import React from 'react';
import { connect } from 'react-redux';
import LanguageChoice from './Header/LanguageChoice';
import UserMenu from './Header/UserMenu';
import { getDate } from '../../utils/data';
import { BsCollectionPlay as Tutorials } from 'react-icons/bs';
import { Button } from 'react-bootstrap';
import HelpMenu from './Header/HelpMenu';
import { isCurrentUserAdmin } from '../../api/manager';
import AdminMenu from './Header/AdminMenu';
import { useTranslation } from 'react-i18next';

const Header = ({ userLang }) => {
  const isUserAdmin = isCurrentUserAdmin();
  const { t } = useTranslation();

  return (
    <div>
      <div id="page_top" className="section-body">
        <div className="container-fluid">
          <div className="page-header">
            <div className="left">
              <div>
                <h4>{t('welcome')}</h4>
                <span>
                  {t('date')} {getDate(userLang)}.
                </span>
              </div>
            </div>
            <div className="right">
              <LanguageChoice />
              <a
                href={t('tutorials_link')}
                target="_blank"
                rel="noreferrer"
                style={{ marginRight: '5px' }}
              >
                <Button variant="outline-secondary">
                  {t('tutorials')} <Tutorials size={20} />
                </Button>
              </a>
              <HelpMenu />
              {isUserAdmin ? <AdminMenu /> : null}
              <UserMenu />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userLang: state.settings.userLang,
  };
};

export default connect(mapStateToProps)(Header);

export const toggleLeftMenuAction = e => dispatch => {
  dispatch({
    type: 'IS_TOGGLE_LEFTMENU',
    payload: e,
  });
};

export const closeLeftMenuAction = () => dispatch => {
  dispatch({
    type: 'IS_TOGGLE_LEFTMENU',
    payload: true,
  });
};

export const openLeftMenuAction = () => dispatch => {
  dispatch({
    type: 'IS_TOGGLE_LEFTMENU',
    payload: false,
  });
};

export const filterJobReqs = e => dispatch => {
  dispatch({
    type: 'FILTER_JOB_REQS',
    payload: e,
  });
};

export const resetFilterJobReqs = () => dispatch => {
  dispatch({
    type: 'RESET_FILTER_JOB_REQS',
    payload: undefined,
  });
};

export const openRecruitingModal = e => dispatch => {
  dispatch({
    type: 'OPEN_RECRUITING_MODAL',
    payload: e,
  });
};

export const openRecruitingModalLinkOnly = e => dispatch => {
  dispatch({
    type: 'OPEN_RECRUITING_MODAL_LINK_ONLY',
    payload: e,
  });
};

export const closeRecruitingModal = e => dispatch => {
  dispatch({
    type: 'CLOSE_RECRUITING_MODAL',
    payload: e,
  });
};

export const setUserLang = e => dispatch => {
  dispatch({
    type: 'SET_USER_LANG',
    payload: e,
  });
};

export const setSelectedEmployee = e => dispatch => {
  dispatch({
    type: 'SET_SELECTED_EMPLOYEE',
    payload: e,
  });
};

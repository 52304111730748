import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { closeLeftMenuAction } from '../../actions/settingsAction';

const withCloseMenu = WrappedComponent => {
  const WithCloseMenu = ({ dispatchCloseLeftMenuAction, ...rest }) => {
    useEffect(() => {
      dispatchCloseLeftMenuAction();
    }, [dispatchCloseLeftMenuAction]);

    return <WrappedComponent {...rest} />;
  };

  const mapDispatchToProps = dispatch => ({
    dispatchCloseLeftMenuAction: () => dispatch(closeLeftMenuAction()),
  });

  return connect(null, mapDispatchToProps)(WithCloseMenu);
};

export default withCloseMenu;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchKPIsPerformance } from '../../../actions/apiActions';
import Chart from './Chart';
import { extractDataFromKPIsPerformance } from './utils';
import { useTranslation } from 'react-i18next';
import DashboardKPI from '../../common/DashboardKPI';

const PerformanceGoals = ({ kpisPerformance, dispatchFetchKPIsPerformance }) => {
  const { t } = useTranslation();

  useEffect(() => {
    dispatchFetchKPIsPerformance();
  }, [dispatchFetchKPIsPerformance]);

  let dataPerformance = null;
  let loadingKPI = true;
  let hideKPI = false;

  if (kpisPerformance.fetched) {
    dataPerformance = extractDataFromKPIsPerformance(kpisPerformance.data, t);
    loadingKPI = false;
    hideKPI = kpisPerformance.data.length <= 0;
  }

  const getChart = () => (
    <Chart series={dataPerformance.series} labels={dataPerformance.labels} />
  );

  return (
    <DashboardKPI
      title={t('kpis_title_idp')}
      Component={getChart}
      loadingKPI={loadingKPI}
      hideKPI={hideKPI}
    />
  );
};

const mapStateToProps = state => {
  return {
    kpisPerformance: state.hcm.kpisPerformance,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchFetchKPIsPerformance: () => dispatch(fetchKPIsPerformance()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceGoals);

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Header from './Header';
import Dashboard from '../Dashboard/Dashboard';
import Profile from '../Profile/Profile';
import CreateFeedback from '../CreateFeedback/CreateFeedback';
import Recruiting from '../Recruiting';
import Objectives from '../Objectives';
import Performance from '../Performance';

const MainContainer = () => {
  return (
    <div className="page">
      <Header />
      <Switch>
        <Route path="/" component={Dashboard} exact={true} />
        <Route path="/profile/:personId?" component={Profile} exact={true} />
        <Route path="/performance/*" component={Performance} exact={true} />
        <Route path="/createFeedback" component={CreateFeedback} exact={true} />
        <Route path="/recruiting/:defaultTab?" component={Recruiting} exact={true} />
        <Route path="/objectives/:filter?" component={Objectives} exact={true} />
        <Redirect to="/" />
      </Switch>
    </div>
  );
};

export default MainContainer;

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { closeRecruitingModal } from '../../../actions/settingsAction';
import IframeContainer from '../../common/IframeContainer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import JobReqSLiderItem from './JobReqSliderItem';
import { fetchActions, fetchRecruitingJobReqs } from '../../../actions/apiActions';

const getJobReqUrl = requisitionId => {
  return `/fscmUI/faces/deeplink?objType=IRC_RECRUITING&action=REQUISITION_DETAIL_MY_TEAM_RESP&objKey=RequisitionId=${requisitionId};pHideNavigation=true;pCloseMainTaskOnDone=Y;pCalledFrom=DEEPLINK`;
};

const JobReqModal = ({
  recruitingJobReqs,
  recruitingModal,
  dispatchCloseRecruitingModal,
  dispatchFetchJobReqs,
  dispatchFetchActions,
}) => {
  const [jobReqUrl, setJobReqUrl] = useState(null);

  useEffect(() => {
    if (!recruitingModal.isLinkOnly) {
      setJobReqUrl(getJobReqUrl(recruitingModal.id));
    } else {
      setJobReqUrl(recruitingModal.id);
    }
  }, [recruitingModal]);

  const handleCloseModal = () => {
    dispatchCloseRecruitingModal();
    dispatchFetchJobReqs();
    dispatchFetchActions();
  };

  const handleClick = e => {
    setJobReqUrl(getJobReqUrl(e.currentTarget.value));
  };

  const jobReqsCount = recruitingJobReqs != undefined ? recruitingJobReqs.length : 0;

  return (
    <Modal
      dialogClassName="jobreq_modal"
      show={recruitingModal.isOpen}
      onHide={handleCloseModal}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body style={{ padding: '0px' }}>
        <div>
          {!recruitingModal.isLinkOnly && jobReqsCount > 0 ? (
            <div
              style={{
                padding: '0px 20px 0px 20px',
                marginLeft: '20px',
                marginRight: '20px',
              }}>
              <Slider {...modalSettings} style={{ height: '100px' }}>
                {recruitingJobReqs.map((jobReq, index) => {
                  return (
                    <JobReqSLiderItem
                      key={index}
                      jobReq={jobReq}
                      handleClick={handleClick}
                    />
                  );
                })}
              </Slider>
            </div>
          ) : null}
          <IframeContainer
            id="jobreq_iframe"
            onLoad={
              recruitingJobReqs.isLinkOnly ? () => {} : onLoadWrapper(handleCloseModal)
            }
            src={jobReqUrl}
            height={800}
            width={'100%'}
            frameBorder={0}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    recruitingModal: state.settings.recruitingModal,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchCloseRecruitingModal: () => dispatch(closeRecruitingModal()),
  dispatchFetchJobReqs: () => dispatch(fetchRecruitingJobReqs()),
  dispatchFetchActions: () => dispatch(fetchActions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobReqModal);

const onLoadWrapper = handleCloseModal => iframe => {
  if (iframe) {
    let style = document.createElement('style');
    style.textContent =
      '#_FOpt1\\:_UISpgl1 { display: none; } ' +
      '#_FOpt1\\:_UISpbl1 { display: none; } ' +
      '#_FOpt1\\:_UISpgl33 { display: none; } ';
    iframe.contentWindow.document.body.style.display = 'block';
    iframe.contentWindow.document.body.style.overflow = 'auto';
    iframe.contentDocument.head.appendChild(style);

    window.redirectPage = setInterval(function () {
      console.warn('redirectPage' + iframe.contentWindow.AdfPage.PAGE);
      let ids = [];
      iframe.contentWindow.AdfPage.PAGE.getComponentsByType(
        'oracle.adf.RichRegion',
      ).forEach(function (r) {
        ids.push(r.getProperty('viewId'));
        let saveActivate = ids.includes('/RequisitionListFlow/RequisitionList');
        if (saveActivate) {
          console.warn('close modal');
          handleCloseModal();
        }
      });
    }, 200);
  }
};

const modalSettings = {
  dots: false,
  infinite: false,
  autoplay: false,
  slidesToScroll: 1,
  slidesToShow: 5,
};

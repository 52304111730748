import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isProductionEnvironment, updateCurrentUserLang } from '../../../api/manager';
import { setUserLang } from '../../../actions/settingsAction';
import IframeContainer from '../../common/IframeContainer';

const languageImageSrc = {
  fr: '/cs/groups/ucm_spaces/documents/document/mdaw/mdaw/~edisp/bl.svg',
  en: '/cs/groups/ucm_spaces/documents/document/mdaw/mdaw/~edisp/us.svg',
};

const LanguageChoice = ({ userLang, dispatchSetUserLang }) => {
  const [, i18n] = useTranslation();

  const deactiveLanguageChange = false;
  const setLang = isEnglish => {
    const language = isEnglish ? 'en' : 'fr';
    if (!deactiveLanguageChange && isProductionEnvironment()) {
      selectLanguageOnIFrame(language);
      dispatchSetUserLang(updateCurrentUserLang(i18n, language));
      window.location.reload();
    } else {
      console.warn('DEBUG: deactived feature language change');
      dispatchSetUserLang(updateCurrentUserLang(i18n, language));
    }
  };

  const setFrench = () => {
    setLang(false);
  };
  const setEnglish = () => {
    setLang(true);
  };

  const iframeUrl =
    '/fscmUI/faces/FuseOverview?fndGlobalItemNodeId=itemNode_Tools_Preferences&fnd=%252FWEB-INF%252Foracle%252Fapps%252Ffnd%252Fapplcore%252Fpref%252Fui%252Fflow%252FLanguageFlow.xml%2523LanguageFlow%3B%3B%3B%3Bfalse%3B256%3B%3B%3B';

  return (
    <>
      <IframeContainer
        id="language_iframe"
        src={iframeUrl}
        height="0%"
        width="0%"
        frameBorder={0}
        dontResize
        dontShowLoader
      />

      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false">
          <img
            className="w20 mr-2"
            src={userLang == 'en' ? languageImageSrc.en : languageImageSrc.fr}
          />
        </a>
        <div className="dropdown-menu">
          <a className="dropdown-item" onClick={setEnglish}>
            <img
              className="w20 mr-2"
              src="/cs/groups/ucm_spaces/documents/document/mdaw/mdaw/~edisp/us.svg"
            />
            English
          </a>
          <div className="dropdown-divider" />
          <a className="dropdown-item" onClick={setFrench}>
            <img
              className="w20 mr-2"
              src="/cs/groups/ucm_spaces/documents/document/mdaw/mdaw/~edisp/bl.svg"
            />
            Français
          </a>
        </div>
      </li>
    </>
  );
};

const mapStateToProps = state => ({
  userLang: state.settings.userLang,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetUserLang: e => dispatch(setUserLang(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageChoice);

const getElement = id => {
  const element = document.getElementById(id);
  if (!element) {
    console.warn('DEBUG: cannot find element ', id);
  }
  return element;
};

const getElementFromIframe = (id, iframeId) => {
  const iframe = getElement(iframeId);
  if (iframe) {
    const element =
      iframe.contentWindow.document.getElementById(id) ||
      iframe.contentDocument.getElementById(id);
    if (!element) {
      console.warn('DEBUG: cannot find element within iframe ', id);
    }
    return element;
  } else {
    console.warn('DEBUG: iframe is not accessible ', iframeId);
  }
  return iframe;
};

const selectLanguageOnIFrame = language => {
  const iframeId = 'language_iframe';
  const defaultId = '_FOpt1:_FOr1:0:_FONSr2:0:MAnt2:0:AP2:soc2::content';
  const ongoingSessionId = '_FOpt1:_FOr1:0:_FONSr2:0:MAnt2:0:AP2:soc1::content';
  const setLanguage = id => {
    const element = getElementFromIframe(id, iframeId);
    if (element) {
      element.selectedIndex = language == 'en' ? 0 : '1';
    } else {
      console.warn('DEBUG: cannot find element: ', id);
    }
  };
  setLanguage(defaultId);
  setLanguage(ongoingSessionId);

  const saveId = '_FOpt1:_FOr1:0:_FONSr2:0:MAnt2:0:AP2:APsv';
  const saveButton = getElementFromIframe(saveId, iframeId);
  if (saveButton) {
    saveButton.click();
  }
};

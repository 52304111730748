import { getEmployeeProfileId } from '../../../model/employee';
import { getCurrentEnv } from '../../manager';
import { GenericDecoder } from '../common/GenericDecoder';
import { PERF_GOALS_SECTION_IDS } from '../common/section_ids_configuration';
import {
  buildProperty,
  constructFilterPersonIds,
  constructFilterSectionId,
  decodeBase64,
  encodeBase64,
} from '../helpers';

const createOrUpdate = requestData => {
  const [personId, profileItemId, devGoal] = requestData.destructure();
  const {
    createdBy,
    creationDate,
    lastUpdateDate,
    lastUpdatedBy,
    title,
    description,
    startDate,
    realDate,
    status,
    measure,
    inProgressComment,
    periodStartDate,
    periodEndDate,
  } = devGoal;

  const profileId = getEmployeeProfileId(personId);

  console.info('--- Debug Performance Goal POST data ');
  console.info(`- PersonId: ${personId}`);
  console.info(`- ProfileId: ${profileId}`);
  console.info(`- ProfileItemId: ${profileItemId}`);

  const requestBody = `<soapenv:Envelope
  xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
  xmlns:typ="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/types/"
  xmlns:pub="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/"
  xmlns:prof="http://xmlns.oracle.com/apps/hcm/profiles/core/flex/profileItem/">
  <soapenv:Header/>
  <soapenv:Body>
    <typ:mergeProfile>
      <typ:profile>
        <pub:ProfileId>${profileId}</pub:ProfileId>
        <pub:ProfileItem>
          <pub:ProfileId>${profileId}</pub:ProfileId>
          ${PerformanceGoalDecoder.getRequestHeader()}
          <pub:ProfileItemId>${profileItemId}</pub:ProfileItemId>
          ${creationDate ? `<pub:DateFrom>${creationDate}</pub:DateFrom>` : ''}
          ${
            createdBy
              ? `<pub:ItemText307>${createdBy.slice(0, 30)}</pub:ItemText307> `
              : ''
          }
          <pub:ItemDate9>${lastUpdateDate}</pub:ItemDate9>
          ${
            lastUpdateDate
              ? `<pub:ItemText308>${lastUpdatedBy.slice(0, 30)}</pub:ItemText308>`
              : ''
          }
          <pub:ItemText20001>${encodeURIComponent(title)}</pub:ItemText20001>
          <pub:ItemClob1File>${encodeBase64(description)}</pub:ItemClob1File>
          ${startDate ? `<pub:ItemDate1>${startDate}</pub:ItemDate1>` : ''}
          ${realDate ? `<pub:ItemDate2>${realDate}</pub:ItemDate2>` : ''}
          ${status ? `<pub:ItemText301>${status}</pub:ItemText301>` : ''}
          ${
            measure
              ? `<pub:ItemClob2File>${encodeBase64(measure)}</pub:ItemClob2File>`
              : ''
          }
          ${
            inProgressComment
              ? `<pub:ItemClob3File>${encodeBase64(
                  inProgressComment,
                )}</pub:ItemClob3File>`
              : ''
          }
          ${buildProperty('ItemDate7', periodStartDate)}
          ${buildProperty('ItemDate8', periodEndDate)}
        </pub:ProfileItem>
      </typ:profile>
    </typ:mergeProfile>
  </soapenv:Body>
</soapenv:Envelope>`;
  return requestBody;
};

export class PerformanceGoalDecoder extends GenericDecoder {
  static SECTION_ID = PERF_GOALS_SECTION_IDS[getCurrentEnv()];
  static NAME = 'PERFORMANCE_GOALS';
  static ATTRIBUTES = {
    'ns1:ProfileItemId': { key: 'profileItemId' },
    'ns1:DateFrom': { key: 'date' },
    'ns1:ItemText307': { key: 'createdBy' },
    'ns1:ItemDate9': { key: 'lastUpdateDate' },
    'ns1:ItemText308': { key: 'lastUpdatedBy' },
    'ns1:ItemText20001': {
      key: 'title',
      prepare: x => {
        let result = '';
        if (x != null) {
          try {
            let tmp = decodeURIComponent(x);
            tmp = tmp?.replace(/&amp;/g, '&') || '';
            result = tmp;
          } catch (error) {
            result = x;
          }
        }
        return result;
      },
    },
    'ns1:ItemClob1File': { key: 'description', prepare: x => decodeBase64(x) },
    'ns1:ItemDate1': { key: 'startDate' },
    'ns1:ItemDate2': { key: 'realDate' },
    'ns1:ItemText301': { key: 'status' },
    'ns1:ItemClob2File': { key: 'measure', prepare: x => decodeBase64(x) },
    'ns1:ItemClob3File': { key: 'inProgressComment', prepare: x => decodeBase64(x) },
    'ns1:ItemDate7': { key: 'periodStartDate' },
    'ns1:ItemDate8': { key: 'periodEndDate' },
    'ns1:ItemText3010': { key: 'requestContext' },
  };

  static getContentType() {
    return 'ACCOMPLISHMENT';
  }

  static getSectionName() {
    return `${PerformanceGoalDecoder.getContentType()}_${
      PerformanceGoalDecoder.SECTION_ID
    }`;
  }

  static getRequestHeader() {
    return `<pub:ContentType>${PerformanceGoalDecoder.getContentType()}</pub:ContentType>
            <pub:SectionName>${PerformanceGoalDecoder.getSectionName()}</pub:SectionName>`;
  }
}

export class PerformanceGoalRequestData {
  constructor(personId, profileItemId, payload) {
    this._personId = personId;
    this._profileItemId = profileItemId;
    this._payload = payload;
  }

  get personId() {
    return this._personId;
  }

  destructure() {
    return [this._personId, this._profileItemId, this._payload];
  }
}

const getByPersonIds = personIds => {
  if (!Array.isArray(personIds)) {
    personIds = [personIds];
  }

  const requestBody = `<soapenv:Envelope
  xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
  xmlns:typ="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/types/"
  xmlns:typ1="http://xmlns.oracle.com/adf/svc/types/">
  <soapenv:Header/>
  <soapenv:Body>
      <typ:findProfile>
          <typ:findCriteria>
              <typ1:fetchStart>0</typ1:fetchStart>
              <typ1:fetchSize>-1</typ1:fetchSize>
              <typ1:filter>
                  <typ1:group>
                      <typ1:conjunction>And</typ1:conjunction>
                      <typ1:upperCaseCompare>true</typ1:upperCaseCompare>
                      ${constructFilterPersonIds(personIds)}
                  </typ1:group>
              </typ1:filter>
              <typ1:childFindCriteria>
                  <typ1:fetchStart>0</typ1:fetchStart>
                  <typ1:fetchSize>-1</typ1:fetchSize>
                  <typ1:filter>
                      <typ1:conjunction>And</typ1:conjunction>
                      <typ1:group>
                          <typ1:conjunction>And</typ1:conjunction>
                          <typ1:upperCaseCompare>false</typ1:upperCaseCompare>
                          ${constructFilterSectionId(PerformanceGoalDecoder.SECTION_ID)}
                      </typ1:group>
                  </typ1:filter>
                  <typ1:childAttrName>ProfileItem</typ1:childAttrName>
              </typ1:childFindCriteria>
              <typ1:findAttribute>ProfileId</typ1:findAttribute>
              <typ1:findAttribute>ProfileItem</typ1:findAttribute>
              <typ1:findAttribute>PersonId</typ1:findAttribute>
          </typ:findCriteria>
          <typ:findControl>
              <typ1:retrieveAllTranslations>false</typ1:retrieveAllTranslations>
          </typ:findControl>
      </typ:findProfile>
  </soapenv:Body>
</soapenv:Envelope>`;
  return requestBody;
};

const deleteByProfileItemId = profileItemId => {
  console.info('--- Debug Performance Goal DELETE data');
  console.info(`- profileItemId: ${profileItemId}`);

  const requestBody = `
<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" 
                  xmlns:typ="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/types/" 
                  xmlns:pub="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/" 
                  xmlns:prof="http://xmlns.oracle.com/apps/hcm/profiles/core/flex/profileItem/">
<soapenv:Header/>
<soapenv:Body>
   <typ:deleteProfileItem>
      <typ:profileItem>
         <pub:ProfileItemId>${profileItemId}</pub:ProfileItemId>
      </typ:profileItem>
   </typ:deleteProfileItem>
</soapenv:Body>
</soapenv:Envelope>`;
  return requestBody;
};

const PerformanceGoalsRequester = {
  createOrUpdate: createOrUpdate,
  getByPersonIds: getByPersonIds,
  deleteByProfileItemId: deleteByProfileItemId,
};

export default PerformanceGoalsRequester;

import React from 'react';
import { Modal } from 'react-bootstrap';
import GoalViewer from './GoalViewer';

const CreatePerfGoalModal = ({ ...props }) => {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop={'static'}
      dialogClassName="modal-50w"
      centered
    >
      <Modal.Body className="show-grid">
        <AddPerfGoal
          onHide={props.onHide}
          onSave={props.onSave}
          show={props.show}
          selectedReviewPeriod={props.selectedReviewPeriod}
        />
      </Modal.Body>
    </Modal>
  );
};

export default CreatePerfGoalModal;

const AddPerfGoal = ({ onHide, onSave, show, selectedReviewPeriod }) => {
  return (
    <GoalViewer
      addMode={true}
      goal={null}
      cancel={onHide}
      onSave={onSave}
      showModal={show}
      selectedReviewPeriod={selectedReviewPeriod}
    />
  );
};

export const ENVs = {
  TEST: '-test',
  DEV: '-dev1',
  PROD: '',
};

const getDefaultEnv = () => {
  const env = process.env.REACT_APP_DEFAULT_ENV;
  let result;
  switch (env) {
    case 'TEST':
      result = ENVs.TEST;
      break;
    case 'DEV':
      result = ENVs.DEV;
      break;
    case 'PROD':
      result = ENVs.PROD;
      break;
    default:
      result = ENVs.DEV;
  }
  return result;
};

export const DEFAULT_ENV = getDefaultEnv();

export const isInLocalDevMode = () => {
  const hostname = window.location.host;
  if (!hostname.includes('ekez')) {
    return true;
  }
  return false;
};

export const getCurrentEnv = () => {
  const hostname = window.location.host;
  if (hostname.includes('ekez')) {
    for (let v of [ENVs.DEV, ENVs.TEST]) {
      if (hostname.includes(v)) {
        return v;
      }
    }
    return ENVs.PROD;
  }
  return DEFAULT_ENV;
};

export const getCurrentUserLang = () => {
  if (isProductionEnvironment()) {
    // TODO check lang validity
    return window.parent.lang || 'en';
  } else {
    return process.env.REACT_APP_HCM_TESTING_USER_LANG;
  }
};

export const getCurrentUserId = () => {
  if (isProductionEnvironment()) {
    return window.parent.userPersonId;
  } else {
    return process.env.REACT_APP_HCM_TESTING_USER_ID;
  }
};

export const getCurrentUserManagerRole = () => {
  if (isProductionEnvironment()) {
    return window.parent.lineManager || false;
  } else {
    return process.env.REACT_APP_HCM_TESTING_USER_LINEMANAGER == 'true';
  }
};

export const isCurrentUserHiringManager = () => {
  if (isProductionEnvironment()) {
    return window.parent.hiringManager || false;
  } else {
    return process.env.REACT_APP_HCM_TESTING_USER_HIRINGMANAGER;
  }
};

export const isCurrentUserP1 = () => {
  if (isProductionEnvironment()) {
    return window.parent.p1Employee || false;
  } else {
    return process.env.REACT_APP_HCM_TESTING_USER_IS_P1 || false;
  }
};

export const isCurrentUserAllowedMassValidation = () => {
  return isCurrentUserHiringManager();
};

export const isCurrentUserAdmin = () => {
  if (isProductionEnvironment()) {
    return window.parent.isUserAdmin || false;
  } else {
    return process.env.REACT_APP_HCM_TESTING_USER_IS_ADMIN || false;
  }
};

export const isCurrentUserHR = () => {
  if (isProductionEnvironment()) {
    return window.parent.hrSpecialist || false;
  } else {
    return process.env.REACT_APP_HCM_TESTING_USER_IS_HR == 'true';
  }
};

export const getDisableCreateJobReq = () => {
  if (isProductionEnvironment()) {
    return window.parent.disableCreateJobReq || false;
  } else {
    return process.env.REACT_APP_HCM_TESTING_DISABLE_CREATE_JOB_REQ || false;
  }
};

export const getPVRDashboardAccess = () => {
  return isCurrentUserHiringManager();
};

export const isProductionEnvironment = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return false;
  } else {
    return true;
  }
};

export const updateCurrentUserLang = (i18n, lang = getCurrentUserLang()) => {
  i18n.changeLanguage(lang, err => {
    if (err) {
      console.error('***** [UserLang] (error) ', err);
    }
  });
  return lang;
};

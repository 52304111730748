import { EDIRequestData, EDIStatus } from '../../../api/HCMProfile/requests/EDIRequester';
import { EAPRequestData, EAPStatus } from '../../../api/HCMProfile/requests/EAPRequester';
import { getCurrentUserId } from '../../../api/manager';
import { getManagerPersonId } from '../../../model/employee';
import { DATE_FORMAT_ISO, getTodayDate } from '../../../utils/data';

export const getDocumentViewerMode = url => {
  const targets = ['edidocument', 'idpdocument', 'performancegoals', 'eapdocument'];
  let documentViewerMode = null;
  for (const target of targets) {
    if (url.includes(target)) {
      documentViewerMode = target;
    }
  }
  return documentViewerMode;
};

export const getTargetTab = documentViewerMode => {
  switch (documentViewerMode) {
    case 'edidocument':
      return 'edi_documents';
    case 'idpdocument':
      return 'idp_documents';
    case 'eapdocument':
      return 'appraisals';
    case 'performancegoals':
      return 'perf_goals';
    case 'feedbacks':
      return 'feedbacks';
  }
  return '';
};

export const displayStatus = documentViewerMode => {
  const targets = ['edidocument', 'eapdocument'];
  return targets.includes(documentViewerMode);
};

export const buildEDIRequestDataStatusUpdate = ({
  storedEDIData,
  statusToSave,
  targetEmployeeId,
  managerSave,
}) => {
  let profileItemId = '';
  let payload;
  let lastUpdateBy = null;

  if (ediIsEmpty(storedEDIData)) {
    const currentDate = getTodayDate(DATE_FORMAT_ISO);
    const managerId = managerSave
      ? getCurrentUserId()
      : getManagerPersonId(getCurrentUserId());
    const newEdi = {
      creationDate: currentDate,
      managerId: managerId,
      status: statusToSave,
    };
    payload = newEdi;
  } else {
    const creationDate = storedEDIData['date'];
    const managerId = managerSave ? getCurrentUserId() : storedEDIData['managerId'];
    const updateEdi = {
      creationDate: creationDate,
      managerId: managerId,
      status: statusToSave,
    };
    payload = updateEdi;
    profileItemId = storedEDIData['profileItemId'];
    lastUpdateBy = storedEDIData['lastUpdateBy'];
  }

  const requestData = new EDIRequestData(
    targetEmployeeId,
    profileItemId,
    lastUpdateBy,
    payload,
  );
  return requestData;
};

export const ediIsEmpty = edi => {
  return edi == null || Object.keys(edi).length === 0 || edi['profileItemId'] == null;
};

export const isEDICompleted = e => isStatusEDICompleted(e['STATUS_CODE']);

export const isStatusEDICompleted = status =>
  status == EDIStatus.COMPLETED || status == EDIStatus.COMPLETED_ABSENT;

export const buildEAPRequestDataStatusUpdate = ({
  storedEAPData,
  statusToSave,
  targetEmployeeId,
  managerSave,
}) => {
  let profileItemIdEmployee = '';
  let profileItemIdManager = '';
  let payload;
  if (eapIsEmpty(storedEAPData)) {
    const currentDate = getTodayDate(DATE_FORMAT_ISO);
    const managerId = managerSave
      ? getCurrentUserId()
      : getManagerPersonId(getCurrentUserId());
    const newEap = {
      creationDate: currentDate,
      managerId: managerId,
      status: statusToSave,
    };
    payload = newEap;
  } else {
    const creationDate = storedEAPData['date'];
    const managerId = managerSave ? getCurrentUserId() : storedEAPData['managerId'];
    const updateEap = {
      creationDate: creationDate,
      managerId: managerId,
      status: statusToSave,
    };
    payload = updateEap;
    profileItemIdEmployee = storedEAPData['profileItemId'];
    profileItemIdManager = storedEAPData['profileItemIdManager'];
  }

  const requestData = new EAPRequestData(
    targetEmployeeId,
    profileItemIdEmployee,
    profileItemIdManager,
    payload,
  );
  return requestData;
};

export const eapIsEmpty = eap => {
  return eap == null || Object.keys(eap).length === 0;
};

export const isEAPCompleted = e => isStatusEAPCompleted(e['STATUS_CODE']);

export const isStatusEAPCompleted = status =>
  status === EAPStatus.COMPLETED ||
  status === EAPStatus.COMPLETED_ABSENT ||
  status === EAPStatus.NOT_COMPLETED;

let isWordCapitalized = word => {
  let result = true;
  for (const c of [...word]) {
    if (c != c.toUpperCase()) {
      result = false;
      break;
    }
  }
  return result;
};

export const prepareDisplayName = displayName => {
  if (displayName == null || displayName.length == 0) {
    return '';
  } else {
    const fullName = displayName.split(' ');
    let firstName = '';
    let lastName = '';
    fullName.forEach(subName => {
      if (isWordCapitalized(subName)) {
        lastName += subName + ' ';
      } else {
        firstName += subName + ' ';
      }
    });
    return `${lastName} ${firstName} `;
  }
};

export const isFunctionalManager = e => {
  const isFunctionalManager = e['MANAGER_TYPE'] == 'NA_FUNCTIONAL_MGR';
  return isFunctionalManager;
};

export const computeIsTransferredDocument = e => {
  const evalutorId = getEvaluatorId(e);
  const managerId = e['MANAGER_ID'];
  const employeeId = e['PERSON_ID'];
  return evalutorId != managerId && employeeId != getCurrentUserId();
};

export const getEvaluatorId = e => {
  let ediEvaluator = e['EDI_EVALUATOR'] || e['EAP_EVALUATOR'];
  if (ediEvaluator == '' || ediEvaluator == null) {
    ediEvaluator = e['MANAGER_ID'];
  }
  return ediEvaluator;
};

export const isClickOnScrollBar = event => {
  // avoid click on scroll bar (for auto save)
  const offset = 50;
  if (event.clientX >= window.outerWidth - offset) {
    return true;
  }
  return false;
};

export const getEDI2022PrintLink = ediItemId => {
  const url = `/xmlpserver/Custom/Human Capital Management/Career/Performance/Gabarit Impression EDI 2022 Report.xdo?_xpt=0&_xdo=%2FCustom%2FHuman%20Capital%20Management%2FCareer%2FPerformance%2FGabarit%20Impression%20EDI%202022%20Report.xdo&_xmode=4&_paramsp_profileitemid=${ediItemId}&_xt=Master%20Template&_xf=pdf&_xautorun=true&_linkToReport=true`;
  return url;
};

export const loadPerformanceGoalRatingOptions = t => {
  return [
    { value: '0', label: t('perf_goal_rating_0') },
    { value: '1', label: t('perf_goal_rating_1') },
    { value: '2', label: t('perf_goal_rating_2') },
    { value: '3', label: t('perf_goal_rating_3') },
    { value: '4', label: t('perf_goal_rating_4') },
    { value: '5', label: t('perf_goal_rating_5') },
  ];
};

import { HCMEndpoint } from '../endpoints';
import { buildAndDispatchError, makeRequest } from '../SOAPrequestMaker';
import {
  genericPreprocessor,
  getRequestHeadersProfile,
  getRequestUrlProfile,
} from './helpers';
import HCMProfileRequests from './requests';
import {
  setDevelopmentGoals,
  setEDIs,
  setPerformanceGoals,
  setEAPs,
  setProfessionalProjects,
  setEPRs,
} from '../../actions/performanceActions';
import {
  fetchDevelopmentGoals,
  fetchEDIEligibility,
  fetchEDIsByPersonIds,
  fetchPerformanceGoals,
  fetchEAPEligibility,
  fetchEAPsByPersonIds,
  fetchEAPFeedbacks,
  fetchMyTeamEAPFeedbacks,
} from '../../actions/apiPerformanceActions';
import {
  uiLoadingProfessionalProject,
  uiSavingDevelopmentGoal,
  uiSavingEDI,
  uiNeedToSynchroniseEDI,
  uiSavingEAP,
  uiSavingFeedback,
  errorEAP,
} from '../../actions/uiActions';
import { doFetchProfileItemLastUpdateBy } from '../OracleBIP/api';

export const doPostProfessionalProject = (dispatch, requestData) => {
  dispatch(uiLoadingProfessionalProject(true));
  dispatch(uiSavingEDI(true));
  const endpoint = HCMEndpoint.EmployeeProfile;
  const preprocessor = response => response;
  const onSuccess = payload => {
    dispatch(uiLoadingProfessionalProject(false));
    dispatch(uiSavingEDI(false));
    return payload;
  };
  const customBody = HCMProfileRequests.ProfessionalProject.createOrUpdate(requestData);
  makeRequest(endpoint, onSuccess, {
    preprocessor: preprocessor,
    forceBodyRequest: customBody,
    requestUrl: getRequestUrlProfile(),
    requestHeaders: getRequestHeadersProfile(),
  });
};

export const doFetchAllProfileSections = (dispatch, personIds) => {
  const endpoint = HCMEndpoint.EmployeeProfile;
  const preprocessor = payload => genericPreprocessor(payload);
  const onSuccess = payload => {
    dispatch(setProfessionalProjects(payload));
  };
  const customBody = HCMProfileRequests.FetchAllSections.getByPersonIds(personIds);
  makeRequest(endpoint, onSuccess, {
    preprocessor: preprocessor,
    forceBodyRequest: customBody,
    requestUrl: getRequestUrlProfile(),
    requestHeaders: getRequestHeadersProfile(),
  });
};

export const doPostDevelopmentGoal = (dispatch, requestData) => {
  dispatch(uiSavingDevelopmentGoal(true));
  const endpoint = HCMEndpoint.EmployeeProfile;
  const preprocessor = response => response;
  const onSuccess = () => {
    dispatch(fetchDevelopmentGoals(requestData.personId));
    dispatch(uiSavingDevelopmentGoal(false));
  };
  const customBody =
    HCMProfileRequests.DevelopmentGoalsRequester.createOrUpdate(requestData);
  makeRequest(endpoint, onSuccess, {
    preprocessor: preprocessor,
    forceBodyRequest: customBody,
    requestUrl: getRequestUrlProfile(),
    requestHeaders: getRequestHeadersProfile(),
  });
};

export const doFetchDevelopmentGoals = (dispatch, personIds) => {
  const endpoint = HCMEndpoint.EmployeeProfile;
  const preprocessor = payload => genericPreprocessor(payload);
  const onSuccess = payload => {
    dispatch(setDevelopmentGoals(payload));
  };
  const customBody =
    HCMProfileRequests.DevelopmentGoalsRequester.getByPersonIds(personIds);
  makeRequest(endpoint, onSuccess, {
    preprocessor: preprocessor,
    forceBodyRequest: customBody,
    requestUrl: getRequestUrlProfile(),
    requestHeaders: getRequestHeadersProfile(),
  });
};

export const doDeleteDevelopmentGoal = (dispatch, { personId, profileItemId }) => {
  const endpoint = HCMEndpoint.EmployeeProfile;
  const preprocessor = response => response;
  const onSuccess = () => {
    dispatch(fetchDevelopmentGoals(personId));
  };
  const customBody =
    HCMProfileRequests.DevelopmentGoalsRequester.deleteByProfileItemId(profileItemId);
  makeRequest(endpoint, onSuccess, {
    preprocessor: preprocessor,
    forceBodyRequest: customBody,
    requestUrl: getRequestUrlProfile(),
    requestHeaders: getRequestHeadersProfile(),
  });
};

export const doPostEDI = (dispatch, requestData) => {
  dispatch(uiSavingEDI(true));
  checkIfEDIHasBeenUpdated(requestData).then(hasBeenUpdated => {
    if (hasBeenUpdated) {
      console.warn('--- EDI has been updated, abort post EDI');
      dispatch(uiNeedToSynchroniseEDI(true));
      dispatch(uiSavingEDI(false));
    } else {
      dispatch(uiNeedToSynchroniseEDI(false));
      const endpoint = HCMEndpoint.EmployeeProfile;
      const preprocessor = response => response;
      const onSuccess = payload => {
        dispatch(fetchEDIsByPersonIds(requestData.personId));
        dispatch(fetchEDIEligibility());
        dispatch(uiSavingEDI(false));
        return payload;
      };
      const customBody = HCMProfileRequests.EDIRequester.createOrUpdate(requestData);
      makeRequest(endpoint, onSuccess, {
        preprocessor: preprocessor,
        forceBodyRequest: customBody,
        requestUrl: getRequestUrlProfile(),
        requestHeaders: getRequestHeadersProfile(),
      });
    }
  });
};

export const doFetchEDIsByPersonIds = (dispatch, personIds) => {
  const endpoint = HCMEndpoint.EmployeeProfile;
  const preprocessor = payload => genericPreprocessor(payload);
  const onSuccess = payload => {
    dispatch(setEDIs(payload));
    dispatch(uiNeedToSynchroniseEDI(false));
  };
  const customBody = HCMProfileRequests.EDIRequester.getByPersonIds(personIds);
  makeRequest(endpoint, onSuccess, {
    preprocessor: preprocessor,
    forceBodyRequest: customBody,
    requestUrl: getRequestUrlProfile(),
    requestHeaders: getRequestHeadersProfile(),
  });
};

export const doFetchEDIsByManagerId = (dispatch, managerId) => {
  const endpoint = HCMEndpoint.EmployeeProfile;
  const preprocessor = payload => genericPreprocessor(payload);
  const onSuccess = payload => {
    dispatch(setEDIs(payload));
    dispatch(uiNeedToSynchroniseEDI(false));
  };
  const customBody = HCMProfileRequests.EDIRequester.getByManagerId(managerId);
  makeRequest(endpoint, onSuccess, {
    preprocessor: preprocessor,
    forceBodyRequest: customBody,
    requestUrl: getRequestUrlProfile(),
    requestHeaders: getRequestHeadersProfile(),
  });
};

export const doTransferEDI = (dispatch, requestData) => {
  const endpoint = HCMEndpoint.EmployeeProfile;
  const preprocessor = response => response;
  const onSuccess = payload => {
    dispatch(fetchEDIsByPersonIds(requestData.personId));
    dispatch(fetchEDIEligibility());
    return payload;
  };
  const customBody = HCMProfileRequests.EDIRequester.transferDocument(requestData);
  makeRequest(endpoint, onSuccess, {
    preprocessor: preprocessor,
    forceBodyRequest: customBody,
    requestUrl: getRequestUrlProfile(),
    requestHeaders: getRequestHeadersProfile(),
  });
};

export const doUpdateEDIStatus = (dispatch, requestData) => {
  dispatch(uiSavingEDI(true));
  checkIfEDIHasBeenUpdated(requestData).then(hasBeenUpdated => {
    if (hasBeenUpdated) {
      console.warn('--- EDI has been updated, abort update EDI status');
      dispatch(uiNeedToSynchroniseEDI(true));
      dispatch(uiSavingEDI(false));
    } else {
      dispatch(uiNeedToSynchroniseEDI(false));
      const endpoint = HCMEndpoint.EmployeeProfile;
      const preprocessor = response => response;
      const onSuccess = payload => {
        dispatch(fetchEDIsByPersonIds(requestData.personId));
        dispatch(fetchEDIEligibility());
        dispatch(uiSavingEDI(false));
        return payload;
      };
      const customBody = HCMProfileRequests.EDIRequester.updateStatus(requestData);
      makeRequest(endpoint, onSuccess, {
        preprocessor: preprocessor,
        forceBodyRequest: customBody,
        requestUrl: getRequestUrlProfile(),
        requestHeaders: getRequestHeadersProfile(),
      });
    }
  });
};

export const doUpdateEAPStatus = (dispatch, requestData) => {
  dispatch(uiSavingEAP(true));
  const endpoint = HCMEndpoint.EmployeeProfile;
  const preprocessor = response => response;
  const onSuccess = payload => {
    dispatch(fetchEAPsByPersonIds(requestData.personId));
    dispatch(fetchEAPEligibility());
    dispatch(uiSavingEAP(false));
    return payload;
  };
  const onError = response => {
    buildAndDispatchError(dispatch, errorEAP, response);
  };
  const customBody = HCMProfileRequests.EAPRequester.updateStatus(requestData);
  makeRequest(endpoint, onSuccess, {
    preprocessor: preprocessor,
    onError: onError,
    forceBodyRequest: customBody,
    requestUrl: getRequestUrlProfile(),
    requestHeaders: getRequestHeadersProfile(),
  });
};

export const doPostMissionAndMainActivity = (dispatch, requestData) => {
  const endpoint = HCMEndpoint.EmployeeProfile;
  const preprocessor = response => response;
  const onSuccess = payload => {
    return payload;
  };
  const onError = response => {
    buildAndDispatchError(dispatch, errorEAP, response);
  };
  const customBody =
    HCMProfileRequests.MissionAndMainActivityRequester.createOrUpdate(requestData);
  makeRequest(endpoint, onSuccess, {
    preprocessor: preprocessor,
    onError: onError,
    forceBodyRequest: customBody,
    requestUrl: getRequestUrlProfile(),
    requestHeaders: getRequestHeadersProfile(),
  });
};

const checkIfEDIHasBeenUpdated = async requestData => {
  const lastUpdateBy = requestData._lastUpdateBy;
  console.warn(
    `--- Checking if EDI (${requestData._profileItemId}) has been updated: ${lastUpdateBy}`,
  );

  return doFetchProfileItemLastUpdateBy(requestData._profileItemId).then(response => {
    if (response != null && response.length > 0) {
      const item = response[0];
      if (
        requestData._profileItemId == item.PPROFILEITEMID &&
        lastUpdateBy != item.LAST_UPDATE_BY &&
        lastUpdateBy
      ) {
        return true;
      }
    }
    return false;
  });
};

export const doPostPerformanceGoal = (dispatch, requestData) => {
  // dispatch(uiSavingDevelopmentGoal(true));
  const endpoint = HCMEndpoint.EmployeeProfile;
  const preprocessor = response => response;
  const onSuccess = () => {
    dispatch(fetchPerformanceGoals(requestData.personId));
    //dispatch(uiSavingDevelopmentGoal(false));
  };
  const customBody =
    HCMProfileRequests.PerformanceGoalsRequester.createOrUpdate(requestData);
  makeRequest(endpoint, onSuccess, {
    preprocessor: preprocessor,
    forceBodyRequest: customBody,
    requestUrl: getRequestUrlProfile(),
    requestHeaders: getRequestHeadersProfile(),
  });
};

export const doFetchPerformanceGoals = (dispatch, personIds) => {
  const endpoint = HCMEndpoint.EmployeeProfile;
  const preprocessor = payload => genericPreprocessor(payload);
  const onSuccess = payload => {
    dispatch(setPerformanceGoals(payload));
  };
  const customBody =
    HCMProfileRequests.PerformanceGoalsRequester.getByPersonIds(personIds);
  makeRequest(endpoint, onSuccess, {
    preprocessor: preprocessor,
    forceBodyRequest: customBody,
    requestUrl: getRequestUrlProfile(),
    requestHeaders: getRequestHeadersProfile(),
  });
};

export const doFetchEAPsByPersonIds = (dispatch, personIds) => {
  const endpoint = HCMEndpoint.EmployeeProfile;
  const preprocessor = payload => genericPreprocessor(payload);
  const onSuccess = payload => {
    dispatch(setEAPs(payload));
  };
  const onError = response => {
    buildAndDispatchError(dispatch, errorEAP, response);
  };
  const customBody = HCMProfileRequests.EAPRequester.getByPersonIds(personIds);
  makeRequest(endpoint, onSuccess, {
    preprocessor: preprocessor,
    onError: onError,
    forceBodyRequest: customBody,
    requestUrl: getRequestUrlProfile(),
    requestHeaders: getRequestHeadersProfile(),
  });
};

export const doDeletePerformanceGoal = (dispatch, { personId, profileItemId }) => {
  const endpoint = HCMEndpoint.EmployeeProfile;
  const preprocessor = response => response;
  const onSuccess = () => {
    dispatch(fetchPerformanceGoals(personId));
  };
  const customBody =
    HCMProfileRequests.PerformanceGoalsRequester.deleteByProfileItemId(profileItemId);
  makeRequest(endpoint, onSuccess, {
    preprocessor: preprocessor,
    forceBodyRequest: customBody,
    requestUrl: getRequestUrlProfile(),
    requestHeaders: getRequestHeadersProfile(),
  });
};

export const doFetchEAPsByManagerId = (dispatch, managerId) => {
  const endpoint = HCMEndpoint.EmployeeProfile;
  const preprocessor = payload => genericPreprocessor(payload);
  const onSuccess = payload => {
    dispatch(setEAPs(payload));
  };
  const onError = response => {
    buildAndDispatchError(dispatch, errorEAP, response);
  };
  const customBody = HCMProfileRequests.EAPRequester.getByManagerId(managerId);
  makeRequest(endpoint, onSuccess, {
    preprocessor: preprocessor,
    onError: onError,
    forceBodyRequest: customBody,
    requestUrl: getRequestUrlProfile(),
    requestHeaders: getRequestHeadersProfile(),
  });
};

export const doPostEAP = (dispatch, requestData) => {
  dispatch(uiSavingEAP(true));
  const endpoint = HCMEndpoint.EmployeeProfile;
  const preprocessor = response => response;
  const onSuccess = payload => {
    dispatch(fetchEAPsByPersonIds(requestData.personId));
    dispatch(fetchEAPEligibility());
    dispatch(uiSavingEAP(false));
    return payload;
  };
  const onError = response => {
    buildAndDispatchError(dispatch, errorEAP, response);
  };
  const customBody = HCMProfileRequests.EAPRequester.createOrUpdate(requestData);
  makeRequest(endpoint, onSuccess, {
    preprocessor: preprocessor,
    onError: onError,
    forceBodyRequest: customBody,
    requestUrl: getRequestUrlProfile(),
    requestHeaders: getRequestHeadersProfile(),
  });
};

export const doFetchEPR = (dispatch, personId) => {
  const endpoint = HCMEndpoint.EmployeeProfile;
  const preprocessor = payload => genericPreprocessor(payload);
  const onSuccess = payload => {
    const data = payload[personId]['EPR_DOCUMENT'];

    dispatch(
      setEPRs(
        data.map(el => ({
          ...el,
          PERSON_ID: personId,
        })),
      ),
    );
  };
  const customBody = HCMProfileRequests.EPRRequester.getByPersonId(personId);
  makeRequest(endpoint, onSuccess, {
    preprocessor: preprocessor,
    forceBodyRequest: customBody,
    requestUrl: getRequestUrlProfile(),
    requestHeaders: getRequestHeadersProfile(),
  });
};

export const doPostEPR = (dispatch, profileId, profileItemId, payload, onSuccess) => {
  dispatch(uiLoadingProfessionalProject(true));
  dispatch(uiSavingEDI(true));
  const endpoint = HCMEndpoint.EmployeeProfile;
  const preprocessor = response => response;
  const onSuccessWrapper = payload => {
    onSuccess();
    return payload;
  };
  const customBody = HCMProfileRequests.EPRRequester.update(
    profileId,
    profileItemId,
    payload,
  );
  makeRequest(endpoint, onSuccessWrapper, {
    preprocessor: preprocessor,
    forceBodyRequest: customBody,
    requestUrl: getRequestUrlProfile(),
    requestHeaders: getRequestHeadersProfile(),
  });
};

export const doSetRatingEAP = (dispatch, requestData) => {
  dispatch(uiSavingEAP(true));
  const endpoint = HCMEndpoint.EmployeeProfile;
  const preprocessor = response => response;
  const onSuccess = payload => {
    dispatch(fetchEAPsByPersonIds(requestData.personId));
    dispatch(fetchEAPEligibility());
    dispatch(uiSavingEAP(false));
    return payload;
  };
  const onError = response => {
    buildAndDispatchError(dispatch, errorEAP, response);
  };
  const customBody = HCMProfileRequests.EAPRequester.setRating(requestData);
  makeRequest(endpoint, onSuccess, {
    preprocessor: preprocessor,
    onError: onError,
    forceBodyRequest: customBody,
    requestUrl: getRequestUrlProfile(),
    requestHeaders: getRequestHeadersProfile(),
  });
};

export const doPostEAPGoalsData = (dispatch, requestData) => {
  dispatch(uiSavingEAP(true));
  const endpoint = HCMEndpoint.EmployeeProfile;
  const preprocessor = response => response;
  const onSuccess = payload => {
    dispatch(fetchEAPsByPersonIds(requestData.personId));
    dispatch(fetchEAPEligibility());
    dispatch(uiSavingEAP(false));
    return payload;
  };
  const onError = response => {
    buildAndDispatchError(dispatch, errorEAP, response);
  };
  const customBody = HCMProfileRequests.EAPRequester.setGoalsData(requestData);
  makeRequest(endpoint, onSuccess, {
    preprocessor: preprocessor,
    onError: onError,
    forceBodyRequest: customBody,
    requestUrl: getRequestUrlProfile(),
    requestHeaders: getRequestHeadersProfile(),
  });
};

export const doCreateNewFeedback = (dispatch, requestData) => {
  dispatch(uiSavingFeedback(true));
  const endpoint = HCMEndpoint.EmployeeProfile;
  const preprocessor = response => response;
  const onSuccess = payload => {
    dispatch(fetchMyTeamEAPFeedbacks());
    dispatch(uiSavingFeedback(false));
    return payload;
  };
  const customBody = HCMProfileRequests.FeedbackRequester.createNewFeedback(requestData);
  makeRequest(endpoint, onSuccess, {
    preprocessor: preprocessor,
    forceBodyRequest: customBody,
    requestUrl: getRequestUrlProfile(),
    requestHeaders: getRequestHeadersProfile(),
  });
};

export const doAnswerFeedback = (dispatch, requestData) => {
  dispatch(uiSavingFeedback(true));
  const endpoint = HCMEndpoint.EmployeeProfile;
  const preprocessor = response => response;
  const onSuccess = payload => {
    dispatch(fetchEAPFeedbacks(requestData.personId));
    dispatch(uiSavingFeedback(false));
    return payload;
  };
  const customBody = HCMProfileRequests.FeedbackRequester.answerFeedback(requestData);
  makeRequest(endpoint, onSuccess, {
    preprocessor: preprocessor,
    forceBodyRequest: customBody,
    requestUrl: getRequestUrlProfile(),
    requestHeaders: getRequestHeadersProfile(),
  });
};

export const doTransferEAP = (dispatch, requestData) => {
  const endpoint = HCMEndpoint.EmployeeProfile;
  const preprocessor = response => response;
  const onSuccess = payload => {
    dispatch(fetchEAPsByPersonIds(requestData.personId));
    dispatch(fetchEAPEligibility());
    return payload;
  };
  const onError = response => {
    buildAndDispatchError(dispatch, errorEAP, response);
  };
  const customBody = HCMProfileRequests.EAPRequester.transferDocument(requestData);
  makeRequest(endpoint, onSuccess, {
    preprocessor: preprocessor,
    onError: onError,
    forceBodyRequest: customBody,
    requestUrl: getRequestUrlProfile(),
    requestHeaders: getRequestHeadersProfile(),
  });
};

export const doCreateEmptyEAP = (dispatch, requestData) => {
  dispatch(uiSavingEAP(true));
  const endpoint = HCMEndpoint.EmployeeProfile;
  const preprocessor = response => response;
  const onSuccess = payload => {
    dispatch(fetchEAPsByPersonIds(requestData.personId));
    dispatch(fetchEAPEligibility());
    dispatch(uiSavingEAP(false));
    return payload;
  };
  const onError = response => {
    buildAndDispatchError(dispatch, errorEAP, response);
  };
  const customBody = HCMProfileRequests.EAPRequester.createEmptyEAP(requestData);
  makeRequest(endpoint, onSuccess, {
    preprocessor: preprocessor,
    onError: onError,
    forceBodyRequest: customBody,
    requestUrl: getRequestUrlProfile(),
    requestHeaders: getRequestHeadersProfile(),
  });
};

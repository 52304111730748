import React from 'react';
import { Card } from 'react-bootstrap';
import LoaderInCard from '../../common/LoaderInCard';
import { JobReqsSource, filterOngoingJobReqsBySource } from '../../../utils/data';
import EmployeeCard from './EmployeeCard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import NoDataTop from '../../common/NoDataTop';
import { useTranslation } from 'react-i18next';

const MyTeamSlicker = ({ recruitingJobReqs }) => {
  const { t } = useTranslation();
  const display = recruitingJobReqs.fetched;
  const userToJobReqs = extractUserData(recruitingJobReqs);
  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    slidesToScroll: 1,
    slidesToShow: Math.min(4, userToJobReqs.size),
  };
  return (
    <Card id="recruiting_team">
      <Card.Header>
        <Card.Title>{t('my_team')}</Card.Title>
      </Card.Header>
      {display ? (
        <>
          {userToJobReqs.size > 0 ? (
            <Card.Body id="team_slider_card">
              <Slider {...settings}>
                {Array.from(userToJobReqs).map((user, index) => {
                  return <EmployeeCard user={user} key={index} />;
                })}
              </Slider>
            </Card.Body>
          ) : (
            <NoDataTop />
          )}
        </>
      ) : (
        <LoaderInCard loaderMarginTop="0%" />
      )}
    </Card>
  );
};

export default MyTeamSlicker;

const extractUserData = jobReqs => {
  if (jobReqs == undefined) {
    return [];
  } else {
    const filteredJobReqs = filterOngoingJobReqsBySource(
      jobReqs,
      JobReqsSource.FromMyTeam,
    );
    const userToJobReqs = new Map();
    filteredJobReqs.forEach(jobReq => {
      const key = jobReq.HIRING_NAME;
      const user = userToJobReqs.get(key);
      if (user == undefined) {
        userToJobReqs.set(key, { id: jobReq.PERSON_ID, count: 1 });
      } else {
        user.count += 1;
      }
    });
    return userToJobReqs;
  }
};

import React from 'react';
import { getEmployeeDisplayName } from '../../../model/employee';
import { Card, Row } from 'react-bootstrap';
import DocumentViewerHeader from './DocumentViewerHeader';

const DocumentViewerTopBar = ({ employeeId, title }) => {
  const employeeName = getEmployeeDisplayName(employeeId);

  return (
    <div className="perf_edi_top_bar">
      <div className="card-status bg-green" />
      <Card.Header>
        <Row>
          <DocumentViewerHeader employeeId={employeeId} />
        </Row>
      </Card.Header>
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p className="font-weight-bold"> {title} </p>
          <footer>{employeeName}</footer>
        </blockquote>
      </Card.Body>
    </div>
  );
};

export default DocumentViewerTopBar;

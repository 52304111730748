import {
  setEmployeesProfileData,
  setEmployeesEAPProfileData,
} from '../../actions/performanceActions';
import { getCurrentEnv, getCurrentUserId } from '../manager';

export const doFetchEmployeeProfileData = async (
  dispatch,
  personIds,
  forceFetchCurrentUser = true,
) => {
  if (!Array.isArray(personIds)) {
    personIds = [personIds];
  }
  let completeEmployeesProfileData;
  if (forceFetchCurrentUser) {
    const managerData = fetchManagerData(getCurrentUserId());
    const profileDataMapping = fetchEmployeesProfileData(personIds);
    completeEmployeesProfileData = mergeData(await profileDataMapping, await managerData);
  } else {
    const profileDataMapping = fetchEmployeesProfileData(personIds);
    completeEmployeesProfileData = await profileDataMapping;
  }
  dispatch(setEmployeesProfileData(completeEmployeesProfileData));
  dispatch(setEmployeesEAPProfileData(completeEmployeesProfileData));
};

const mergeData = (teamData, currentUserManagerData) => {
  if (teamData) {
    const found = teamData[getCurrentUserId()];
    if (found) {
      Object.assign(teamData[getCurrentUserId()], currentUserManagerData);
    } else {
      teamData[getCurrentUserId()] = currentUserManagerData;
    }
    return teamData;
  } else {
    let result = {};
    result[getCurrentUserId()] = currentUserManagerData;
    return result;
  }
};

export const fetchEmployeesProfileData = personIds => {
  // TOOD: fix shity authorization
  const myHeaders = new Headers();
  myHeaders.append('REST-Framework-Version', '4');
  myHeaders.append('Authorization', 'Basic bGVtcmFiZXRrYTpOYXRpeGlzMTIzKyo=');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  return fetch(
    `https://ekez${getCurrentEnv()}.fa.em2.oraclecloud.com//hcmRestApi/resources/11.13.18.05/talentPersonProfiles?onlyData=true&q=PersonId in (${personIds.join(
      ',',
    )})&fields=ProfileId,PersonId,ProfileCode,PersonNumber,DisplayName&limit=1000`,
    requestOptions,
  )
    .then(response => response.json())
    .then(result => constructEmployeesProfileData(result))
    .catch(error => console.error('Error HCMRest/api : ', error));
};

const constructEmployeesProfileData = data => {
  let result = {};
  const count = data['count'];
  if (count > 0 && data['items']) {
    const items = data['items'];
    result = items.reduce((acc, item) => {
      const personId = item['PersonId'];
      acc[personId] = item;
      return acc;
    }, {});
  }
  return result;
};

async function fetchManagerData(personId) {
  // TOOD: fix shity authorization
  var myHeaders = new Headers();
  myHeaders.append('REST-Framework-Version', '4');
  myHeaders.append('Authorization', 'Basic bGVtcmFiZXRrYTpOYXRpeGlzMTIzKyo=');

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  return fetch(
    `https://ekez${getCurrentEnv()}.fa.em2.oraclecloud.com:443/hcmRestApi/resources/11.13.18.05/publicWorkers/${personId}?expand=assignments.managers&fields=assignments:PrimaryFlag;assignments.managers:ManagerPersonId,DisplayName,ManagerType&onlyData=true`,
    requestOptions,
  )
    .then(response => response.json())
    .then(result => constructManagerData(result))
    .catch(error => console.error('Error HCMRest/api : ', error));
}

const constructManagerData = data => {
  const managerData = {};
  const assignments = data?.assignments;
  const count = assignments?.count;
  if (count > 0 && assignments?.items) {
    const items = assignments?.items;
    const primaryAssignment = items.find(item => item['PrimaryFlag'] === true);
    const managers = primaryAssignment.managers?.items || [];
    for (const manager of managers) {
      if (manager['ManagerType'] == 'LINE_MANAGER') {
        managerData['ManagerPersonId'] = manager['ManagerPersonId'];
        managerData['ManagerDisplayName'] = manager['DisplayName'];
        managerData['ManagerType'] = manager['ManagerType'];
      }
    }
  }
  return managerData;
};

import {
  doDeleteDevelopmentGoal,
  doDeletePerformanceGoal,
  doFetchAllProfileSections,
  doFetchDevelopmentGoals,
  doFetchEDIsByManagerId,
  doFetchEDIsByPersonIds,
  doFetchPerformanceGoals,
  doPostDevelopmentGoal,
  doPostEDI,
  doPostEAP,
  doPostMissionAndMainActivity,
  doPostPerformanceGoal,
  doPostProfessionalProject,
  doTransferEDI,
  doUpdateEDIStatus,
  doUpdateEAPStatus,
  doFetchEAPsByPersonIds,
  doFetchEAPsByManagerId,
  doSetRatingEAP,
  doPostEAPGoalsData,
  doCreateNewFeedback,
  doAnswerFeedback,
  doPostEPR,
  doTransferEAP,
  doCreateEmptyEAP,
} from '../api/HCMProfile/api';
import { doFetchEmployeeProfileData } from '../api/HCMRest';
import {
  doFetchEDIEligibility,
  doFetchEAPEligibility,
  doFecthEPRList,
  doFecthEAPFeedbacks,
  doFetchEAPFeedbacksByEAPItemId,
  doFecthMyTeamEAPFeedbacks,
  doFetchJimmyEligibility,
} from '../api/OracleBIP/api';

export function fetchEmployeesProfileData(personIds, forceFetchCurrentUser = true) {
  return function (dispatch) {
    doFetchEmployeeProfileData(dispatch, personIds, forceFetchCurrentUser);
  };
}

export function postProfessionalProject(professionalProject) {
  return function (dispatch) {
    doPostProfessionalProject(dispatch, professionalProject);
  };
}

export function fetchAllProfileSections(personId) {
  return function (dispatch) {
    doFetchAllProfileSections(dispatch, personId);
  };
}

export function postDevelopmentGoal(requestData) {
  return function (dispatch) {
    doPostDevelopmentGoal(dispatch, requestData);
  };
}

export function deleteDevelopmentGoal(requestData) {
  return function (dispatch) {
    doDeleteDevelopmentGoal(dispatch, requestData);
  };
}

export function fetchDevelopmentGoals(personIds) {
  return function (dispatch) {
    doFetchDevelopmentGoals(dispatch, personIds);
  };
}

export function postEDI(edi) {
  return function (dispatch) {
    doPostEDI(dispatch, edi);
  };
}

export function fetchEDIsByPersonIds(personIds) {
  return function (dispatch) {
    doFetchEDIsByPersonIds(dispatch, personIds);
  };
}

export function fetchEDIsByManagerId(managerId) {
  return function (dispatch) {
    doFetchEDIsByManagerId(dispatch, managerId);
  };
}

export function fetchEDIEligibility() {
  return function (dispatch) {
    doFetchEDIEligibility(dispatch);
  };
}

export function transferEDI(edi) {
  return function (dispatch) {
    doTransferEDI(dispatch, edi);
  };
}

export function postEDIStatus(edi) {
  return function (dispatch) {
    doUpdateEDIStatus(dispatch, edi);
  };
}

export function postEAPStatus(eap) {
  return function (dispatch) {
    doUpdateEAPStatus(dispatch, eap);
  };
}

export function fetchEAPEligibility() {
  return function (dispatch) {
    doFetchEAPEligibility(dispatch);
  };
}

export function fetchEAPsByPersonIds(personIds) {
  return function (dispatch) {
    doFetchEAPsByPersonIds(dispatch, personIds);
  };
}

export function fetchEAPsByManagerId(managerId) {
  return function (dispatch) {
    doFetchEAPsByManagerId(dispatch, managerId);
  };
}

export function postEAP(eap) {
  return function (dispatch) {
    doPostEAP(dispatch, eap);
  };
}

export function postMissionAndMainActivity(requestData) {
  return function (dispatch) {
    doPostMissionAndMainActivity(dispatch, requestData);
  };
}

export function postPerformanceGoal(requestData) {
  return function (dispatch) {
    doPostPerformanceGoal(dispatch, requestData);
  };
}

export function deletePerformanceGoal(requestData) {
  return function (dispatch) {
    doDeletePerformanceGoal(dispatch, requestData);
  };
}

export function fetchPerformanceGoals(personIds) {
  return function (dispatch) {
    doFetchPerformanceGoals(dispatch, personIds);
  };
}

export function fetchEPRs(personId) {
  return function (dispatch) {
    doFecthEPRList(dispatch, personId);
  };
}

export function postEPR(profileId, profileItemId, payload, onSuccess) {
  return function (dispatch) {
    doPostEPR(dispatch, profileId, profileItemId, payload, onSuccess);
  };
}

export function postEAPRating(eap) {
  return function (dispatch) {
    doSetRatingEAP(dispatch, eap);
  };
}

export function postEAPGoalsData(r) {
  return function (dispatch) {
    doPostEAPGoalsData(dispatch, r);
  };
}

export function postCreateNewFeedback(r) {
  return function (dispatch) {
    doCreateNewFeedback(dispatch, r);
  };
}

export function fetchEAPFeedbacks(personId) {
  return function (dispatch) {
    doFecthEAPFeedbacks(dispatch, personId);
  };
}

export function fetchMyTeamEAPFeedbacks() {
  return function (dispatch) {
    doFecthMyTeamEAPFeedbacks(dispatch);
  };
}

export function postAnswerFeedback(r) {
  return function (dispatch) {
    doAnswerFeedback(dispatch, r);
  };
}

export function fetchEAPFeedbacksByEAPItemId(eapItemId) {
  return function (dispatch) {
    doFetchEAPFeedbacksByEAPItemId(dispatch, eapItemId);
  };
}

export function transferEAP(r) {
  return function (dispatch) {
    doTransferEAP(dispatch, r);
  };
}

export function createEmptyEAP(r) {
  return function (dispatch) {
    doCreateEmptyEAP(dispatch, r);
  };
}

export function fetchJimmyEligibility(r) {
  return function (dispatch) {
    doFetchJimmyEligibility(dispatch, r);
  };
}

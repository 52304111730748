import React from 'react';
import { Button, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ActionButtons = props => {
  const { t } = useTranslation();
  const handleBack = () => {
    if (props.prevCallback) {
      props.prevCallback();
    }
    props.previousStep();
  };

  const handleNext = () => {
    if (props.nextCallback) {
      props.nextCallback();
    }
    props.nextStep();
  };

  const handleFinish = () => {
    if (props.finishCallback) {
      props.finishCallback();
    }
    props.lastStep();
  };

  return (
    <div>
      <Row className="justify-content-md-center">
        {props.currentStep > 1 && (
          <Button
            className="btn-lg btn-primary  m-4 text-center btn-block"
            onClick={handleBack}
            style={{ height: '60px', width: '250px' }}
          >
            {t('Previous')}
          </Button>
        )}
        {props.currentStep < props.totalSteps && (
          <Button
            className="btn-lg btn-next m-4 text-center btn-block"
            onClick={handleNext}
            style={{ height: '60px', width: '250px' }}
          >
            {t('Next')}
          </Button>
        )}

        {props.currentStep === props.totalSteps ? (
          <>
            {props.forceReadOnly ? null : (
              <>
                {props.isEmployee ? (
                  <Button
                    className="btn-lg btn-next  m-4 text-center btn-block"
                    onClick={handleFinish}
                    style={{ height: '60px', width: '250px' }}
                  >
                    {t('tile_signing_validation')}
                  </Button>
                ) : (
                  <>
                    {props.managerCanClickValidate && (
                      <Button
                        className="btn-lg btn-next  m-4 text-center btn-block"
                        onClick={handleFinish}
                        style={{ height: '60px', width: '250px' }}
                      >
                        {t('Validate')}
                      </Button>
                    )}
                    {props.isMatrixManager && (
                      <Button
                        className="btn-lg btn-next  m-4 text-center btn-block"
                        style={{ height: '60px', width: '250px' }}
                        onClick={handleFinish}
                      >
                        {t('ValidateAndShare')}
                      </Button>
                    )}
                  </>
                )}
              </>
            )}
          </>
        ) : null}
      </Row>
    </div>
  );
};

export default ActionButtons;

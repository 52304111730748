import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createEmptyEAP } from '../../actions/apiPerformanceActions';
import { EAPRequestData } from '../../api/HCMProfile/requests/EAPRequester';
import LoaderInCard from '../common/LoaderInCard';

const withCheckEAPExists = WrappedComponent => {
  const isNullOrEmpty = x => x == null || x == '';
  const WithCheckEAPExists = ({
    dispatchCreateEmptyEAP,
    savingEAP,
    employeesEAPProfileData,
    profileItemIdCollab,
    ...rest
  }) => {
    const [displayComponent, setDisplayComponent] = useState(false);
    const [localProfileItemIdCollab, setlocalProfileItemIdCollab] =
      useState(profileItemIdCollab);
    const { t } = useTranslation();

    useEffect(() => {
      if (isNullOrEmpty(localProfileItemIdCollab)) {
        // Manager should be the current user (used by cross feedback request)
        const requestData = new EAPRequestData(rest.employeeId, null, null, null);
        dispatchCreateEmptyEAP(requestData);
      } else {
        setDisplayComponent(true);
      }
    }, [rest.employeeId, localProfileItemIdCollab, dispatchCreateEmptyEAP]);

    useEffect(() => {
      if (employeesEAPProfileData.fetched && employeesEAPProfileData.data) {
        const found = employeesEAPProfileData.data[rest.employeeId];
        if (found) {
          const forceProfileItemIdCollab = found.EAP_EXIST;
          if (forceProfileItemIdCollab) {
            setlocalProfileItemIdCollab(forceProfileItemIdCollab);
            setDisplayComponent(true);
          }
        }
      }
    }, [employeesEAPProfileData, rest]);

    return (
      <>
        {displayComponent ? (
          <WrappedComponent {...rest} profileItemIdCollab={localProfileItemIdCollab} />
        ) : (
          <>
            <p>{t('eap_document_creation')}</p>
            {!savingEAP && <LoaderInCard />}
          </>
        )}
      </>
    );
  };

  const mapStateToProps = state => ({
    savingEAP: state.uiReducer.eaps.saving,
    employeesEAPProfileData: state.performance.employeesEAPProfileData,
  });

  const mapDispatchToProps = dispatch => ({
    dispatchCreateEmptyEAP: r => dispatch(createEmptyEAP(r)),
  });

  return connect(mapStateToProps, mapDispatchToProps)(WithCheckEAPExists);
};

export default withCheckEAPExists;

import DevelopmentGoalsRequester from './DevelopmentGoalsRequester';
import EDIRequester from './EDIRequester';
import EAPRequester from './EAPRequester';
import FetchAllSections from './FetchAllSections';
import MissionAndMainActivityRequester from './MissionAndMainActivityRequester';
import PerformanceGoalsRequester from './PerformanceGoalsRequester';
import ProfessionalProject from './ProfessionalProjectRequests';
import EPRRequester from './EPRRequester';
import FeedbackRequester from './FeedbackRequester';

const HCMProfileRequests = {
  ProfessionalProject: ProfessionalProject,
  FetchAllSections: FetchAllSections,
  DevelopmentGoalsRequester: DevelopmentGoalsRequester,
  EDIRequester: EDIRequester,
  EAPRequester: EAPRequester,
  MissionAndMainActivityRequester: MissionAndMainActivityRequester,
  PerformanceGoalsRequester: PerformanceGoalsRequester,
  EPRRequester: EPRRequester,
  FeedbackRequester: FeedbackRequester,
};

export default HCMProfileRequests;

import { getEmployeeProfileId } from '../../../model/employee';
import { DATE_FORMAT_ISO, getTodayDate } from '../../../utils/data';
import { getCurrentEnv } from '../../manager';
import { GenericDecoder } from '../common/GenericDecoder';
import { MISSION_MAIN_SECTION_IDS } from '../common/section_ids_configuration';
import { decodeBase64 } from '../helpers';

export class MissionAndMainActivityDecoder extends GenericDecoder {
  static SECTION_ID = MISSION_MAIN_SECTION_IDS[getCurrentEnv()];
  static NAME = 'MISSIONANDMAINACTIVITY';
  static ATTRIBUTES = {
    'ns1:ProfileItemId': { key: 'profileItemId' },
    'ns1:DateFrom': { key: 'date' },
    'ns1:ItemClob1File': { key: 'missionAndMainActivity', prepare: x => decodeBase64(x) },
  };

  static getContentType() {
    return 'ACCOMPLISHMENT';
  }

  static getSectionName() {
    return `${MissionAndMainActivityDecoder.getContentType()}_${
      MissionAndMainActivityDecoder.SECTION_ID
    }`;
  }

  static getRequestHeader() {
    return `<pub:ContentType>${MissionAndMainActivityDecoder.getContentType()}</pub:ContentType>
            <pub:SectionName>${MissionAndMainActivityDecoder.getSectionName()}</pub:SectionName>`;
  }
}

export class MissionAndMainActivityRequestData {
  constructor(personId, profileItemId, payload) {
    this._personId = personId;
    this._profileItemId = profileItemId;
    this._payload = payload;
  }

  get personId() {
    return this._personId;
  }

  destructure() {
    return [this._personId, this._profileItemId, this._payload];
  }
}

const createOrUpdate = requestData => {
  const [personId, profileItemId, payloadMission] = requestData.destructure();
  const profileId = getEmployeeProfileId(personId);

  console.info('--- Debug MissionAndMainActivity POST data');
  console.info(`- PersonId: ${personId}`);
  console.info(`- ProfileId: ${profileId}`);
  console.info(`- ProfileItemId: ${profileItemId}`);
  let today = null;
  if (profileItemId == null || profileItemId == '') {
    today = getTodayDate(DATE_FORMAT_ISO);
  }

  const requestBody = `<soapenv:Envelope
  xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"
  xmlns:typ="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/types/"
  xmlns:pub="http://xmlns.oracle.com/apps/hcm/profiles/core/publicProfileServiceV2/"
  xmlns:prof="http://xmlns.oracle.com/apps/hcm/profiles/core/flex/profileItem/">
  <soapenv:Header/>
  <soapenv:Body>
    <typ:mergeProfile>
      <typ:profile>
        <pub:ProfileId>${profileId}</pub:ProfileId>
        <pub:ProfileItem>
          <pub:ProfileId>${profileId}</pub:ProfileId>
          ${MissionAndMainActivityDecoder.getRequestHeader()}
          ${today ? `<pub:DateFrom>${today}</pub:DateFrom>` : ''}
          <pub:ProfileItemId>${profileItemId}</pub:ProfileItemId>
          <pub:ItemClob1File>${payloadMission}</pub:ItemClob1File>
        </pub:ProfileItem>
      </typ:profile>
    </typ:mergeProfile>
  </soapenv:Body>
</soapenv:Envelope>`;
  return requestBody;
};

const MissionAndMainActivityRequester = {
  createOrUpdate: createOrUpdate,
};

export default MissionAndMainActivityRequester;
